// @ts-ignore
import React, { useCallback, useEffect, useState } from "react";
// @ts-ignore
import VirtualTourMap from "../../images/virtual-tour-map.png";
// @ts-ignore
import VirtualTourMapUsa from "../../images/virtual-tour-map-usa.png";
// @ts-ignore
import VirtualTourMapFr from "../../images/virtual-tour-map-fr.png";
// @ts-ignore
import VirtualTourMapEs from "../../images/virtual-tour-map-es.png";
import {
  DarkSun,
  DarkMoon,
  DarkForm,
  DarkMap,
  DarkPosition,
  DarkHelp,
} from "../icons";
import { getLanguageTranslation } from "../utils/translations/get-language-translation";
import { LOCALSTORAGE_LANGUAGE } from "../consts";

const HamburgerMenu: React.FC<{
  setPopupInfos: any;
  technicalSpecifics: any;
  setTechnicalSpecifics: any;
}> = ({ setPopupInfos, technicalSpecifics, setTechnicalSpecifics }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [classes, setClasses] = useState<string>("initial");
  const [headerItems, setHeaderItems] = useState([]);

  const setDay = useCallback(
    () => {
      setTechnicalSpecifics({
        ...technicalSpecifics,
        time: "day",
      });
    },
    typeof window !== `undefined`
      ? [
          technicalSpecifics,
          setTechnicalSpecifics,
          localStorage.getItem(LOCALSTORAGE_LANGUAGE),
        ]
      : [technicalSpecifics, setTechnicalSpecifics]
  );

  const setNight = useCallback(
    () => {
      setTechnicalSpecifics({
        ...technicalSpecifics,
        time: "night",
      });
    },
    typeof window !== `undefined`
      ? [
          technicalSpecifics,
          setTechnicalSpecifics,
          localStorage.getItem(LOCALSTORAGE_LANGUAGE),
        ]
      : [technicalSpecifics, setTechnicalSpecifics]
  );

  const openMap = useCallback(
    () => {
      setPopupInfos({
        title: "",
        type: "map",
        currentPosition: technicalSpecifics.position,
        map: VirtualTourMap,
        popupClass: "popup-map_container",
        noCloseIcon: true,
      });
    },
    typeof window !== `undefined`
      ? [
          technicalSpecifics,
          setPopupInfos,
          localStorage.getItem(LOCALSTORAGE_LANGUAGE),
        ]
      : [technicalSpecifics, setPopupInfos]
  );

  const openPosition = useCallback(
    () => {
      setPopupInfos({
        title: "",
        type: "positions",
        currentPosition: technicalSpecifics.position,
        popupClass: "popup-positions_container",
        noCloseIcon: true,
      });
    },
    typeof window !== `undefined`
      ? [
          technicalSpecifics,
          setPopupInfos,
          localStorage.getItem(LOCALSTORAGE_LANGUAGE),
        ]
      : [technicalSpecifics, setPopupInfos]
  );

  const openPositionMap = useCallback(
    () => {
      setPopupInfos({
        title: "",
        type: "positions-map",
        currentPosition: technicalSpecifics.position,
        map:
          (typeof window !== `undefined`
            ? localStorage.getItem(LOCALSTORAGE_LANGUAGE)
            : "en-us") === "en-us"
            ? VirtualTourMapUsa
            : localStorage.getItem(LOCALSTORAGE_LANGUAGE) === "fr"
            ? VirtualTourMapFr
            : localStorage.getItem(LOCALSTORAGE_LANGUAGE) === "es"
            ? VirtualTourMapEs
            : VirtualTourMap,
        popupClass: "popup-positions-map_container",
        noCloseIcon: true,
      });
    },
    typeof window !== `undefined`
      ? [
          technicalSpecifics,
          setPopupInfos,
          localStorage.getItem(LOCALSTORAGE_LANGUAGE),
        ]
      : [technicalSpecifics, setPopupInfos]
  );

  const [baseMenuElements, setBaseMenuElements] = useState<any[]>([]);
  const [endingMenuElements, setEndingMenuElements] = useState<any[]>([]);

  useEffect(
    () => {
      setBaseMenuElements([
        {
          onClick: setDay,
          image: {
            src: DarkSun,
            alt: "set-day",
          },
          text: getLanguageTranslation("GIORNO"),
        },
        {
          onClick: setNight,
          image: {
            src: DarkMoon,
            alt: "set-night",
          },
          text: getLanguageTranslation("NOTTE"),
        },
      ]);
      setEndingMenuElements([
        {
          onClick: () =>
            setPopupInfos({
              title: "",
              type: "form",
              popupClass: "popup-form_container",
              noCloseIcon: true,
            }),
          image: {
            src: DarkForm,
            alt: "open-form",
          },
          text: "FORM",
        },
        {
          text: "INFO",
          image: {
            src: DarkHelp,
            alt: "help",
          },
          onClick: () =>
            setPopupInfos({
              title: "",
              type: "instructions",
              popupClass: "popup-instructions_container",
            }),
        },
      ]);
    },
    typeof window !== `undefined`
      ? [
          technicalSpecifics,
          setPopupInfos,
          localStorage.getItem(LOCALSTORAGE_LANGUAGE),
        ]
      : [technicalSpecifics, setPopupInfos]
  );

  useEffect(
    () => {
      let isMobile = true;
      if (typeof window !== `undefined`) {
        isMobile = window.innerWidth <= 768;
      }
      const optionalMenuElements = isMobile
        ? [
            {
              onClick: openMap,
              image: {
                src: DarkMap,
                alt: "open-map",
              },
              text: getLanguageTranslation("MAPPA"),
            },
            {
              text: getLanguageTranslation("POSIZIONE"),
              image: {
                src: DarkPosition,
                alt: "change-position",
              },
              onClick: openPosition,
            },
          ]
        : [
            {
              onClick: openPositionMap,
              image: {
                src: DarkMap,
                alt: "open-map",
              },
              text: getLanguageTranslation("MAPPA"),
            },
          ];

      setHeaderItems(optionalMenuElements);
    },
    typeof window !== `undefined`
      ? [
          technicalSpecifics,
          setPopupInfos,
          setHeaderItems,
          localStorage.getItem(LOCALSTORAGE_LANGUAGE),
        ]
      : [technicalSpecifics, setPopupInfos, setHeaderItems]
  );

  return (
    <div className="hamburger-menu__container">
      <div className="hamburger-menu__voices">
        <ul
          className={
            isOpen
              ? "hamburger-menu__voices-container active"
              : "hamburger-menu__voices-container"
          }
        >
          <li
            className={
              isOpen
                ? "hamburger-item__toggle active"
                : "hamburger-item__toggle"
            }
            onClick={() => {
              setIsOpen(!isOpen);
              if (!isOpen) {
                setClasses(" close show-item");
                setTimeout(() => {
                  if (isOpen) setClasses("open");
                }, 1200);
              } else {
                setClasses("open hide-item");
                setTimeout(() => {
                  if (!isOpen) setClasses("close");
                }, 1200);
              }
            }}
          >
            <div className="hamburger-item__content hamburger-item__circle">
              <div className="menu-icon_container">
                <div
                  className={
                    isOpen ? "hamburger-menu animate" : "hamburger-menu"
                  }
                />
              </div>
            </div>
          </li>
          {[...baseMenuElements, ...headerItems, ...endingMenuElements].map(
            (h, i) => (
              <li
                key={i}
                className={
                  isOpen
                    ? "hamburger-item " + classes
                    : "hamburger-item " + classes
                }
                id="hamburger-item"
                onClick={h.onClick}
              >
                <div className="hamburger-item__content hamburger-item__circle">
                  <img
                    src={h.image.src}
                    alt={h.image.alt}
                    className="menu-icon"
                  />
                </div>
                <div className="hamburger-item__content hamburger-item__title">
                  {h.text}
                </div>
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  );
};

export default HamburgerMenu;
