import { getLanguageTranslation } from "../translations/get-language-translation";

export const createVeneziaGoldToScreeny2InfoPoints = (
  createPositionPoint,
  lang
) => [
  createPositionPoint(
    [-156.16, -952.96, -4896.29],
    "venezia-gold",
    "VENEZIA GOLD"
  ),
  createPositionPoint([-4992.02, -129.7, -55.01], "qubica_to_kolibrie", ""),
  createPositionPoint(
    [4006.9, -2959.82, -414.31],
    "kedry-skylife_to_kolibrie",
    ""
  ),
];

export const createVeneziaGoldToScreeny2FrInfoPoints = (
  createPositionPoint,
  lang
) => [
  createPositionPoint(
    [-156.16, -952.96, -4896.29],
    "venezia-gold",
    "GENEROSA GOLD"
  ),
  createPositionPoint(
    [-605.13, -2462.91, -4299.28],
    "antalia",
    "COMPACTO"
  ),
  createPositionPoint([-4992.02, -129.7, -55.01], "qubica_to_kolibrie", ""),
  createPositionPoint(
    [4006.9, -2959.82, -414.31],
    "kedry-skylife_to_kolibrie",
    ""
  ),
];

export const createVeneziaGoldToScreeny2EsInfoPoints = (
  createPositionPoint,
  lang
) => [
  createPositionPoint([-4992.02, -129.7, -55.01], "qubica_to_kolibrie", ""),
  createPositionPoint(
    [4006.9, -2959.82, -414.31],
    "kedry-skylife_to_kolibrie",
    ""
  ),
];

export const createVeneziaGoldToScreeny2UsInfoPoints = (
    createPositionPoint,
    lang
) => [
  createPositionPoint(
      [-605.13, -2462.91, -4299.28],
      "bella-plus",
      "BELLA PLUS"
  ),
  createPositionPoint(
      [-4992.02, -129.7, -55.01],
      "qubica_to_kolibrie",
      ""
  ),
  createPositionPoint(
      [4006.9, -2959.82, -414.31],
      "kedry-skylife_to_kolibrie",
      ""
  ),
];

export const createQubicaToKolibrieInfoPoints = (createPositionPoint, lang) => [
  createPositionPoint(
    [-61.15, -540.35, -4963.86],
    "qubica-flat",
    "QUBICA FLAT B50"
  ),
  createPositionPoint(
    [4127.27, -2810.92, -63.92],
    "venezia-gold_to_screeny2",
    ""
  ),
  createPositionPoint(
    [-1459.85, 201.16, 4769.9],
    "kolibrie",
    "GENNIUS SAILS KOLIBRIE"
  ),
];
export const createQubicaToKolibrieUsInfoPoints = (createPositionPoint, lang) => [
  createPositionPoint(
      [-61.15, -540.35, -4963.86],
      "qubica-flat",
      "QUBICA FLAT B50"
  ),
  createPositionPoint(
      [4127.27, -2810.92, -63.92],
      "venezia-gold_to_screeny2",
      ""
  ),
  createPositionPoint(
      [-1459.85, 201.16, 4769.9],
      "kolibrie",
      "GENNIUS SAILS KOLIBRIE"
  ),
  createPositionPoint(
      [-366.66, -1937.07, -4589.28],
      "domina",
      "DOMINA"
  ),
];

export const createQubicaToKolibrieFrInfoPoints = (createPositionPoint, lang) => [
  createPositionPoint(
    [-366.66, -1937.07, -4589.28],
    "qubica-lite",
    "QUBICA LITE"
  ),
  createPositionPoint(
    [4127.27, -2810.92, -63.92],
    "venezia-gold_to_screeny2",
    ""
  ),
  createPositionPoint(
    [-1459.85, 201.16, 4769.9],
    "kolibrie",
    "GENNIUS SAILS KOLIBRIE"
  ),
  createPositionPoint(
    [-4994.61, 45.16, 68.98],
    "qubica_to_kolibrie2",
    ""
  ),
];

export const createQubicaToKolibrieEsInfoPoints = (createPositionPoint, lang) => [
  createPositionPoint(
    [4127.27, -2810.92, -63.92],
    "venezia-gold_to_screeny2",
    ""
  ),
  createPositionPoint(
    [-1459.85, 201.16, 4769.9],
    "kolibrie",
    "GENNIUS SAILS KOLIBRIE"
  ),
];

export const createQubicaToKolibrie2FrInfoPoints = (createPositionPoint, lang) => [
  createPositionPoint(
    [3602.56, -2420.11, -2466.93],
    "qubica-lite",
    "QUBICA LITE"
  ),
  createPositionPoint(
    [4127.27, -2810.92, -63.92],
    "qubica_to_kolibrie",
    ""
  ),
  createPositionPoint(
    [1887.50, -1022.29, 4506.90],
    "kolibrie",
    "GENNIUS SAILS KOLIBRIE"
  ),
];

export const createVegaToXtesaInfoPoints = (createPositionPoint, lang) => [
  createPositionPoint([-4574.13, -2003.31, 72.93], "vega", "GENNIUS VEGA"),
  createPositionPoint([4988.76, 74.86, -158.42], "xtesa_to_vega_1", ""),
];

export const createScreeny1ToScreeny2InfoPoints = (
  createPositionPoint,
  lang
) => [
  createPositionPoint(
    [4742.69, -1547.5, -223.34],
    "screeny2-back",
    "SCREENY 110 GPZ UNICA S32"
  ),
  createPositionPoint([271.71, -1444.74, -4769.34], "screeny2_to_kolibrie", ""),
];

export const createScreeny1ToScreeny2FrInfoPoints = (
  createPositionPoint,
  lang
) => [
  createPositionPoint(
    [4742.69, -1547.5, -223.34],
    "screeny2-back",
    "SCREENY 110 COULISSE ZIP"
  ),
  createPositionPoint([271.71, -1444.74, -4769.34], "screeny2_to_kolibrie", ""),
];

export const createIsola3ToVegaInfoPoints = (createPositionPoint, lang) => [
  createPositionPoint([-102.19, -1434.84, -4781.02], "vega_to_isola3", ""),
  createPositionPoint(
    [-4949.08, -682.02, 23.28],
    "isola3_to_kedry-skylife",
    ""
  ),
  createPositionPoint([15.27, -678.95, 4949.86], "isola3", "ISOLA 3"),
];

export const createIsola3ToVegaUsInfoPoints = (createPositionPoint, lang) => [
  createPositionPoint([-102.19, -1434.84, -4781.02], "vega_to_isola3", ""),
  createPositionPoint([-102.19, -1434.84, 4781.02], "screenystorm_to_isola3", ""),
  createPositionPoint(
    [-4949.08, -682.02, 23.28],
    "isola3_to_kedry-skylife",
    ""
  ),
];

export const createXtesaToVega1InfoPoints = (createPositionPoint, lang) => [
  createPositionPoint([-4391.2, -2378.08, 62.53], "vega_to_xtesa", ""),
  createPositionPoint([4392.9, -789.01, -883.98], "keplan", "KEPLAN"),
];

export const createScreeny2ToKolibrieInfoPoints = (
  createPositionPoint,
  lang
) => [
  createPositionPoint([-4909.27, 903.95, -94.4], "kolibrie_to_screeny2", ""),
  createPositionPoint([428.48, -1522.16, 4739.29], "screeny1_to_screeny2", ""),
  createPositionPoint(
    [4750.9, -1528.12, -174.88],
    "screeny2",
    "SCREENY 110 GPZ UNICA S32"
  ),
  createPositionPoint(
    [81.71, -671.7, -4947.23],
    "kedry-skylife_to_kolibrie",
    ""
  ),
];

export const createScreeny2ToKolibrieFrInfoPoints = (
  createPositionPoint,
  lang
) => [
  createPositionPoint([-4909.27, 903.95, -94.4], "kolibrie_to_screeny2", ""),
  createPositionPoint([428.48, -1522.16, 4739.29], "screeny1_to_screeny2", ""),
  createPositionPoint(
    [4750.9, -1528.12, -174.88],
    "screeny2",
    "SCREENY 110 COULISSE ZIP"
  ),
  createPositionPoint(
    [81.71, -671.7, -4947.23],
    "kedry-skylife_to_kolibrie",
    ""
  ),
];

export const createScreeny2ToKolibrieEsInfoPoints = (
  createPositionPoint,
  lang
) => [
  createPositionPoint([-4909.27, 903.95, -94.4], "kolibrie_to_screeny2", ""),
  createPositionPoint([4982.86, -366.39, -52.30], "isola3", "ISOLA 3"),
  createPositionPoint(
    [81.71, -671.7, -4947.23],
    "kedry-skylife_to_kolibrie",
    ""
  ),
];

export const createIsola3ToKedrySkylifeInfoPoints = (
  createPositionPoint,
  lang
) => [
  createPositionPoint([4941.7, -728.08, -60.07], "isola3_to_vega", ""),
  createPositionPoint(
    [-4929.04, -713.69, 334.11],
    "kedry-skylife_to_kolibrie",
    ""
  ),
];
export const createScreenyStormToIsola3InfoPoints = (
    createPositionPoint,
    lang
) => [
  createPositionPoint([4941.7, -728.08, 4160.07], "isola3_to_vega", ""),
  createPositionPoint(
      [5129.04, -813.69, -4334.11],
      "screeny-storm",
      ""
  ),
];

export const createKolibrieToScreeny2InfoPoints = (
  createPositionPoint,
  lang
) => [
  createPositionPoint([4478.86, -2206.96, -99.36], "screeny2_to_kolibrie", ""),
  createPositionPoint([-3642.94, 2019.47, -2754.0], "kolibrie", "GENNIUS SAILS KOLIBRIE"),
];

export const createKedrySkylifeToKolibrieInfoPoints = (
  createPositionPoint,
  lang
) => [
  createPositionPoint(
    [-4924.79, -820.92, 113.42],
    "venezia-gold_to_screeny2",
    ""
  ),
  createPositionPoint(
    [4941.75, -686.74, 152.45],
    "isola3_to_kedry-skylife",
    ""
  ),
  createPositionPoint(
    [-130.74, -1002.88, -4887.69],
    "kedry-skylife",
    "KEDRY SKYLIFE"
  ),
];

export const createKedrySkylifeToKolibrieUsInfoPoints = (
  createPositionPoint,
  lang
) => [
  createPositionPoint(
    [-4924.79, -820.92, 113.42],
    "venezia-gold_to_screeny2",
    ""
  ),
  createPositionPoint(
    [4941.75, -686.74, 152.45],
    "isola3_to_kedry-skylife",
    ""
  ),
  createPositionPoint(
    [-64.35, -443.82, -4975.12],
    "entrance",
    getLanguageTranslation("ENTRATA")
  ),
];

export const createXtesaToVega2UsInfoPoints = (createPositionPoint, lang) => [
  createPositionPoint([74.91, -1266.12, 4830.03], "xtesa_to_vega_1", ""),
  createPositionPoint([4969.32, -520.53, 1.76], "a100", "A100"),
];

export const createXtesaToVega2InfoPoints = (createPositionPoint, lang) => [
  createPositionPoint([74.91, -1266.12, 4830.03], "xtesa_to_vega_1", ""),
  createPositionPoint([4805.09, -1321.32, -253.84], "xtesa", "XTESA"),
];

export const createVegaToHydraInfoPoints = (createPositionPoint, lang) => [
  createPositionPoint([-4666.16, -1779.74, -48.83], "vega_to_isola3", ""),
  createPositionPoint([15.52, -1759.7, 4675.29], "vega", "GENNIUS VEGA"),
  createPositionPoint([37.17, -516.04, -4967.99], "hydra", "HYDRA"),
];

export const createVegaToHydraFrInfoPoints = (createPositionPoint, lang) => [
  createPositionPoint([-4666.16, -1779.74, -48.83], "vega_to_isola3", ""),
  createPositionPoint([15.52, -1759.7, 4675.29], "vega", "GENNIUS VEGA"),
  createPositionPoint([37.17, -516.04, -4967.99], "hydra", "HYDRAE PLUS"),
];

export const createVegaToHydraUsInfoPoints = (createPositionPoint, lang) => [
  createPositionPoint([-4666.16, -1779.74, -48.83], "vega_to_isola3", ""),
  createPositionPoint([15.52, -1759.7, 4675.29], "vega", "GENNIUS VEGA"),
];

export const createVegaToKedryPrimeInfoPoints = (createPositionPoint, lang) => [
  createPositionPoint([-4666.28, -1782.74, -25.37], "vega_to_isola3", ""),
  createPositionPoint([4901.09, -924.81, -171.38], "hydra_to_kedry-prime", ""),
];

export const createHydraToKedryPrimeInfoPoints = (
  createPositionPoint,
  lang
) => [
  createPositionPoint([118.69, -965.54, -4895.75], "vega_to_kedry-prime", ""),
  createPositionPoint([-4858.51, -1178.61, 2.45], "hydra", "HYDRA"),
  createPositionPoint([4850.99, -1198.32, 39.85], "kedry-prime", "KEDRY PRIME"),
];

export const createHydraToKedryPrimeFrInfoPoints = (
  createPositionPoint,
  lang
) => [
  createPositionPoint([118.69, -965.54, -4895.75], "vega_to_kedry-prime", ""),
  createPositionPoint([-4858.51, -1178.61, 2.45], "hydra", "HYDRAE PLUS"),
  createPositionPoint([4850.99, -1198.32, 39.85], "kedry-prime", "KEDRY PRIME"),
];

export const createHydraToKedryPrimeUsInfoPoints = (
  createPositionPoint,
  lang
) => [
  createPositionPoint([118.69, -965.54, -4895.75], "vega_to_kedry-prime", ""),
  createPositionPoint([4850.99, -1198.32, 39.85], "kedry-prime", "KEDRY PRIME"),
];

export const createVegaToIsola3InfoPoints = (createPositionPoint, lang) => [
  createPositionPoint([4946.76, -574.62, -310.05], "vega_to_hydra", ""),
  createPositionPoint([-41.34, -1515.26, 4761.53], "isola3_to_vega", ""),
  createPositionPoint([-36.52, -588.44, -4959.46], "vega_to_kedry-prime", ""),
];
