import { getLanguageTranslation } from "../translations/get-language-translation";
import {
  Screeny2Image1,
  Screeny2Image2,
  Screeny2Image3,
  Screeny2Image4,
  Screeny2Depliant20x25Screeny,
  Screeny2DepliantPaletteColori2021It,
  Screeny2SchedaTecnica,
  Screeny2GLB,
  Screeny2USDZ,
  ScreenyCoulisseScreenyCoulisseZipSchedaTecnica,
} from "./index";

export const createScreeny2InfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [3964.15, -3024.47, -257.63],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 110 GPZ UNICA S32",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda tessuto filtrante/oscurante a rullo con cassonetto da 110 mm disponibile in 2 varianti (tondo/quadro) dotata di guida profilo zip."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Adatto per la schermatura esterna di finestre e facciate continue in molte tipologie di costruzioni: dalle abitazioni private agli edifici commerciali più moderni."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Sistema brevettato che garantisce performance e standard elevati."
        ) +
        "<br /></p>",
      images: [Screeny2Image1, Screeny2Image2, Screeny2Image3, Screeny2Image4],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Screeny"),
          href: Screeny2Depliant20x25Screeny,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: Screeny2DepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: Screeny2SchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 110 GPZ UNICA S32</strong><br />" +
      getLanguageTranslation(
        "Tenda tessuto filtrante/oscurante a rullo con cassonetto da 110 mm disponibile in 2 varianti (tondo/quadro) dotata di guida profilo zip."
      ) +
      "</p>"
  ),
  createPositionPoint(
    [-55.46, -646.41, -4951.6],
    "screeny1",
    "SCREENY 110 GC S12"
  ),
  createOptionalPoint(
    [4012.92, 1167.54, 2734.99],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createColorPoint(
    [3409.46, -2741.64, 2408.51],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "gray",
        value: "gray",
        text: "RAL9006",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createPositionPoint([-205.05, -1353.63, 4798.53], "screeny2_to_kolibrie", ""),
  createVrPoint([3242.74, -2648.19, -2722.71], Screeny2GLB, Screeny2USDZ),
];

export const createScreeny2BackInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [4001.03, -2970.2, -370.1],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 110 GPZ UNICA S32",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda tessuto filtrante/oscurante a rullo con cassonetto da 110 mm disponibile in 2 varianti (tondo/quadro) dotata di guida profilo zip."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Adatto per la schermatura esterna di finestre e facciate continue in molte tipologie di costruzioni: dalle abitazioni private agli edifici commerciali più moderni."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Sistema brevettato che garantisce performance e standard elevati."
        ) +
        "<br /></p>",
      images: [Screeny2Image1, Screeny2Image2, Screeny2Image3, Screeny2Image4],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Screeny"),
          href: Screeny2Depliant20x25Screeny,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: Screeny2DepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: Screeny2SchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 110 GPZ UNICA S32</strong><br />" +
      getLanguageTranslation(
        "Tenda tessuto filtrante/oscurante a rullo con cassonetto da 110 mm disponibile in 2 varianti (tondo/quadro) dotata di guida profilo zip."
      ) +
      "</p>"
  ),
  createPositionPoint(
    [30.71, -602.37, 4958.11],
    "screeny1-back",
    "SCREENY 110 GC S12"
  ),
  createPositionPoint([299.93, -1339.43, -4797.56], "screeny1_to_screeny2", ""),
  createOptionalPoint(
    [4254.99, 1111.81, 2367.82],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createColorPoint(
    [3464.47, -2572.02, 2522.71],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "gray",
        value: "gray",
        text: "RAL9006",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createVrPoint([3440.42, -2692.26, -2421.32], Screeny2GLB, Screeny2USDZ),
];

export const createScreeny2FrInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [3964.15, -3024.47, -257.63],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 110 COULISSE",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda tessuto filtrante/oscurante a rullo con cassonetto da 110 mm disponibile in 2 varianti (tondo/quadro) dotata di guida profilo zip."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Adatto per la schermatura esterna di finestre e facciate continue in molte tipologie di costruzioni: dalle abitazioni private agli edifici commerciali più moderni."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Sistema brevettato che garantisce performance e standard elevati."
        ) +
        "<br /></p>",
      images: [Screeny2Image1, Screeny2Image2, Screeny2Image3, Screeny2Image4],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Screeny"),
          href: Screeny2Depliant20x25Screeny,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: Screeny2DepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: ScreenyCoulisseScreenyCoulisseZipSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 110 COULISSE</strong><br />" +
      getLanguageTranslation(
        "Tenda tessuto filtrante/oscurante a rullo con cassonetto da 110 mm disponibile in 2 varianti (tondo/quadro) dotata di guida profilo zip."
      ) +
      "</p>"
  ),
  createPositionPoint(
    [-55.46, -646.41, -4951.6],
    "screeny1",
    "SCREENY 110 COULISSE"
  ),
  createOptionalPoint(
    [4012.92, 1167.54, 2734.99],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createColorPoint(
    [3409.46, -2741.64, 2408.51],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "gray",
        value: "gray",
        text: "RAL9006",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createPositionPoint([-205.05, -1353.63, 4798.53], "screeny2_to_kolibrie", ""),
  createVrPoint([3242.74, -2648.19, -2722.71], Screeny2GLB, Screeny2USDZ),
];

export const createScreeny2BackFrInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [4001.03, -2970.2, -370.1],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 110 COULISSE ZIP",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda tessuto filtrante/oscurante a rullo con cassonetto da 110 mm disponibile in 2 varianti (tondo/quadro) dotata di guida profilo zip."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Adatto per la schermatura esterna di finestre e facciate continue in molte tipologie di costruzioni: dalle abitazioni private agli edifici commerciali più moderni."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Sistema brevettato che garantisce performance e standard elevati."
        ) +
        "<br /></p>",
      images: [Screeny2Image1, Screeny2Image2, Screeny2Image3, Screeny2Image4],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Screeny"),
          href: Screeny2Depliant20x25Screeny,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: Screeny2DepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: ScreenyCoulisseScreenyCoulisseZipSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 110 COULISSE ZIP</strong><br />" +
      getLanguageTranslation(
        "Tenda tessuto filtrante/oscurante a rullo con cassonetto da 110 mm disponibile in 2 varianti (tondo/quadro) dotata di guida profilo zip."
      ) +
      "</p>"
  ),
  createPositionPoint(
    [30.71, -602.37, 4958.11],
    "screeny1-back",
    "SCREENY 110 COULISSE"
  ),
  createPositionPoint([299.93, -1339.43, -4797.56], "screeny1_to_screeny2", ""),
  createOptionalPoint(
    [4254.99, 1111.81, 2367.82],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createColorPoint(
    [3464.47, -2572.02, 2522.71],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "gray",
        value: "gray",
        text: "RAL9006",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createVrPoint([3440.42, -2692.26, -2421.32], Screeny2GLB, Screeny2USDZ),
];
