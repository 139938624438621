import { getLanguageTranslation } from "../translations/get-language-translation";
import {
  A100GLB,
  A100USDZ,
  A100Depliant20x25Gennius,
  A100Image1,
  A100Image2,
  A100Image3,
  A100Image4,
  A100Image5,
  A100Image6,
} from "./index";

/*export const createA100InfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics
) => [
  createInfoPoint(
    [4850.4, 287.57, 1146.51],
    a100InfoPopupDatas,
    undefined,
    "<p><strong>A100</strong><br />" +
      getLanguageTranslation(
        "Tenda a pergola in alluminio con copertura scorrevole ad impacchettamento."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Design squadrato ed essenziale e ideale per ampie coperture e differenti contesti."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Il tamponamento e le chiusure laterali si armonizzano con il disegno pulito dei profili."
      ) +
      "</p>"
  ),
  createOptionalPoint(
    [4917.65, 748.75, 472.17],
    "Copertura",
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createColorPoint(
    [4650.29, -1072.18, -1476.84],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "black",
        value: "black",
        text: "CARBON RUVIDO",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createPositionPoint(
    [-2457.12, -223.55, 4342.13],
    "kheope",
    "GENNIUS SAILS KHEOPE K1"
  ),
  createPositionPoint([1943.02, -576.47, -4562.72], "xtesa", "XTESA"),
  createVrPoint([4885.68, 66.02, -1048.42], A100GLB, A100USDZ),
];*/

export const createA100UsInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [4850.4, 287.57, 1146.51],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "A 100",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda a pergola in alluminio con copertura scorrevole ad impacchettamento."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Design squadrato ed essenziale e ideale per ampie coperture e differenti contesti."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Il tamponamento e le chiusure laterali si armonizzano con il disegno pulito dei profili."
        ) +
        "<br /></p>",
      images: [
        A100Image1,
        A100Image2,
        A100Image3,
        A100Image4,
        A100Image5,
        A100Image6,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Gennius"),
          href: A100Depliant20x25Gennius,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: null,
        },
      ],
    },
    undefined,
    "<p><strong>A100</strong><br />" +
      getLanguageTranslation(
        "Tenda a pergola in alluminio con copertura scorrevole ad impacchettamento."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Design squadrato ed essenziale e ideale per ampie coperture e differenti contesti."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Il tamponamento e le chiusure laterali si armonizzano con il disegno pulito dei profili."
      ) +
      "</p>"
  ),
  createOptionalPoint(
    [4917.65, 748.75, 472.17],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createColorPoint(
    [4650.29, -1072.18, -1476.84],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "black",
        value: "black",
        text: "CARBON RUVIDO",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createPositionPoint(
    [-2707.36, -1495.30, 3920.80],
    "sunlight",
    "SUNLIGHT"
  ),
  createPositionPoint([1625.88, -362.14, -4709.05], "xtesa_to_vega_2", ""),
  createVrPoint([4885.68, 66.02, -1048.42], A100GLB, A100USDZ),
];
