import {
    DominaDepliant20x25,
    DominaImage1,
    DominaImage2,
    DominaImage3
} from "./index";
import { getLanguageTranslation } from "../translations/get-language-translation";

export const createDominaUsInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [4393.61, -1497.43, -1842.81],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "DOMINA",
      description:
      "<h2>CASSETTE RETRACTABLE AWNING</h2><p>The Domina is a modern lightweight full cassette retractable awning that mounts easily on a wall or soffit combining classical design with advanced technology. Easy to install, easy to adjust pitch, beautiful and sleek.</p>",

      images: [
          DominaImage1,
          DominaImage2,
          DominaImage3,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: DominaDepliant20x25,
        },
      ],
    },
    undefined,
    "<p><strong>DOMINA</strong><br />The Domina is a modern lightweight full cassette retractable awning that mounts easily on a wall or soffit combining classical design with advanced technology. Easy to install, easy to adjust pitch, beautiful and sleek.</p>"
  ),
    createPositionPoint(
        [-3308.07, -1410.82, 3461.17],
        "entrance",
        getLanguageTranslation("ENTRATA")
    ),
    createPositionPoint(
        [-4703.97, -1429.4, -864.86],
        "kedry-skylife-sotto",
        getLanguageTranslation("KEDRY SKYLIFE (Sotto)")
    ),
    createOptionalPoint(
        [3926.84, 293.61, -3066.64],
        getLanguageTranslation("Copertura"),
        "roof",
        undefined,
        technicalSpecifics.roof === "close" ? "open" : "close",
        (roof: "close" | "open") => roof === "close"
    ),
    createPositionPoint([679.26, -941.21, -4853.58], "qubica_to_kolibrie", ""),
    createPositionPoint([679.26, -941.21, 4853.58], "qubica-flat", "QUBICA FLAT B50"),
    // createVrPoint([3018.55, -1082.46, -3824.75], VeneziaGLB, VeneziaUSDZ),
];
