export const createEntranceUsInfoPoints = (createPositionPoint, lang) => [
  createPositionPoint(
    [4977.04, -398.78, 129.39],
    "kedry-skylife_to_kolibrie",
    ""
  ),
];

export const createEntranceInfoPoints = (createPositionPoint, lang) => [
  createPositionPoint(
    [4940.66, -707.82, 125.52],
    "kedry-skylife",
    "KEDRY SKYLIFE"
  ),
];
