import { TechnicalSpecificsType } from "../../types";
import { getTechnicalSpecificsColor } from "./get-technical-specifics-color";
import { getTechnicalSpecificsTime } from "./get-technical-specifics-time";
import { getTechnicalSpecificsBooleanRoof } from "./get-technical-specifics-boolean-roof";
import { getTechnicalSpecificsGlass } from "./get-technical-specifics-glass";
import { getTechnicalSpecificsCurtain } from "./get-technical-specifics-curtain";

export const getKedryPrimeSottoImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `kedry-prime-sotto${getTechnicalSpecificsColor(
    technicalSpecifics,
    ["white", "black"],
    "black"
  )}${getTechnicalSpecificsTime(
    technicalSpecifics
  )}${getTechnicalSpecificsBooleanRoof(
    technicalSpecifics
  )}${getTechnicalSpecificsGlass(
    technicalSpecifics
  )}${getTechnicalSpecificsCurtain(technicalSpecifics)}`;
