//  @ts-ignore
import React from "react";
//  @ts-ignore
/*import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";*/
import { Close, CloseWhite } from "../icons";
import InfoSlider from "./info-slider";

const PopupInfo: React.FC<{ popupInfos: any; setPopupInfos: any }> = ({
  popupInfos,
  setPopupInfos,
}) => {
  return (
    <div className="info-container">
      <div className="popup-header">
        <div className="close-button" onClick={() => setPopupInfos(null)}>
          <img
            src={CloseWhite}
            alt="close-icon-white"
            className="close-icon only-mobile"
          />
          <img
            src={Close}
            alt="close-icon-white"
            className="close-icon only-desktop"
          />
        </div>
      </div>
      {/*<div className="info-slider_container">
        <Slider
          dots={true}
          lazyLoad={true}
          infinite={true}
          autoplay={true}
          autoplaySpeed={5000}
          slidesToShow={1}
          pauseOnHover={true}
          className="info-slider"
          style={{ width: "100%" }}
        >
          {popupInfos.images.map((img, i) => (
            <div key={"img_" + i} className={"images-container"}>
              <img src={img} alt={"img_" + i} className={"image"} />
            </div>
          ))}
        </Slider>
      </div>*/}
        <InfoSlider popupInfos={popupInfos} />
      <div className="info-content_container">
        <h3>{popupInfos.sectionTitle}</h3>
        <div
          className="info-description"
          dangerouslySetInnerHTML={{ __html: popupInfos.description }}
        />
        <div className="info-buttons-container">
          {popupInfos.buttons.map((b, i) => (
            <a
              key={"link_" + i}
              href={b.href}
              style={{ width: "100%", maxWidth: "250px" }}
              target="_blank"
            >
              <span>{b.text}</span>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PopupInfo;
