import { getLanguageTranslation } from "../translations/get-language-translation";
import {
  QubicaImage1,
  QubicaImage2,
  QubicaImage3,
  QubicaImage4,
  QubicaImage5,
  QubicaImage6,
  QubicaImage7,
  QubicaImage8,
  QubicaFlatDepliant20x25Tende,
  QubicaFlatBrochureAKI,
  QubicaFlatDepliantPaletteColori2021It,
  QubicaFlatSchedaTecnica,
  QubicaFlatGLB,
  QubicaFlatUSDZ,
} from "./index";

export const createQubicaInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [3940.46, -2111.87, 2226.32],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "QUBICA FLAT",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda da sole a cassonetto dalle linee essenziali ed eleganti adatta ad ogni ambiente architettonico."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Disponibile in 4 colori che possono essere combinati per raggiungere 16 diverse configurazioni."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "È possibile scegliere tra oltre 500 tipologie diverse di tessuto tutte confezionate senza cuciture."
        ) +
        "<br /></p>",
      images: [
        QubicaImage1,
        QubicaImage2,
        QubicaImage3,
        QubicaImage4,
        QubicaImage5,
        QubicaImage6,
        QubicaImage7,
        QubicaImage8,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Tende"),
          href: QubicaFlatDepliant20x25Tende,
        },
        {
          text: getLanguageTranslation("Brochure AKI"),
          href: QubicaFlatBrochureAKI,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: QubicaFlatDepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: QubicaFlatSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>QUBICA FLAT</strong><br />" +
      getLanguageTranslation(
        "Tenda da sole a cassonetto dalle linee essenziali ed eleganti adatta ad ogni ambiente architettonico."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Disponibile in 4 colori che possono essere combinati per raggiungere 16 diverse configurazioni."
      ) +
      "<br />" +
      getLanguageTranslation(
        "È possibile scegliere tra oltre 500 tipologie diverse di tessuto tutte confezionate senza cuciture."
      ) +
      "</p>"
  ),
  createPositionPoint(
    [-3858.77, -1397.2, -2846.86],
    "kedry-skylife-sotto",
    getLanguageTranslation("KEDRY SKYLIFE (Sotto)")
  ),
  createPositionPoint(
    [-2971.89, -4013.93, 124.99],
    "venezia-gold",
    "VENEZIA GOLD"
  ),
  createOptionalPoint(
    [4308.53, 333.87, 2509.21],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createPositionPoint([413.03, -575.87, -4941.21], "qubica_to_kolibrie", ""),
  createPositionPoint(
    [788.32, 167.18, -4923.89],
    "kolibrie",
    "GENNIUS SAILS KOLIBRIE"
  ),
  createColorPoint(
    [3317.94, -2713.11, 2561.01],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "BIANCO 9010",
      },
      {
        technicalValue: "gray",
        value: "gray",
        text: "GRIGIO 7030",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createVrPoint([2371.73, -1730.29, 4040.68], QubicaFlatGLB, QubicaFlatUSDZ),
];

export const createQubicaUsInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [3940.46, -2111.87, 2226.32],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "QUBICA FLAT",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda da sole a cassonetto dalle linee essenziali ed eleganti adatta ad ogni ambiente architettonico."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Disponibile in 4 colori che possono essere combinati per raggiungere 16 diverse configurazioni."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "È possibile scegliere tra oltre 500 tipologie diverse di tessuto tutte confezionate senza cuciture."
        ) +
        "<br /></p>",
      images: [
        QubicaImage1,
        QubicaImage2,
        QubicaImage3,
        QubicaImage4,
        QubicaImage5,
        QubicaImage6,
        QubicaImage7,
        QubicaImage8,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Tende"),
          href: QubicaFlatDepliant20x25Tende,
        },
        {
          text: getLanguageTranslation("Brochure AKI"),
          href: QubicaFlatBrochureAKI,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: QubicaFlatDepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: QubicaFlatSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>QUBICA FLAT</strong><br />" +
      getLanguageTranslation(
        "Tenda da sole a cassonetto dalle linee essenziali ed eleganti adatta ad ogni ambiente architettonico."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Disponibile in 4 colori che possono essere combinati per raggiungere 16 diverse configurazioni."
      ) +
      "<br />" +
      getLanguageTranslation(
        "È possibile scegliere tra oltre 500 tipologie diverse di tessuto tutte confezionate senza cuciture."
      ) +
      "</p>"
  ),
  createOptionalPoint(
    [4308.53, 333.87, 2509.21],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "close"
  ),
  createPositionPoint([413.03, -575.87, -4941.21], "qubica_to_kolibrie", ""),
  createPositionPoint(
    [608.32, -800.18, -4441.89],
    "domina",
    "DOMINA"
  ),
  createColorPoint(
    [3317.94, -2713.11, 2561.01],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "BIANCO 9010",
      },
      {
        technicalValue: "gray",
        value: "gray",
        text: "GRIGIO 7030",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createVrPoint([2371.73, -1730.29, 4040.68], QubicaFlatGLB, QubicaFlatUSDZ),
];
