import { TechnicalSpecificsType } from "../../types";
import { getTechnicalSpecificsColor } from "./get-technical-specifics-color";
import { getTechnicalSpecificsTime } from "./get-technical-specifics-time";
import { getTechnicalSpecificsBooleanRoof } from "./get-technical-specifics-boolean-roof";

export const getXtesaImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `xtesa${getTechnicalSpecificsColor(
    technicalSpecifics,
    ["white", "black"],
    "black"
  )}${getTechnicalSpecificsTime(
    technicalSpecifics
  )}${getTechnicalSpecificsBooleanRoof(technicalSpecifics)}`;
