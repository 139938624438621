import { TechnicalSpecificsType } from "../../types";
import { getTechnicalSpecificsTime } from "./get-technical-specifics-time";
import { getTechnicalSpecificsBooleanRoof } from "./get-technical-specifics-boolean-roof";
import { getTechnicalSpecificsColor } from "./get-technical-specifics-color";

export const getQubicaFlatImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `qubica-flat${getTechnicalSpecificsColor(
    technicalSpecifics,
    ["white", "gray"],
    "white"
  )}${getTechnicalSpecificsTime(
    technicalSpecifics
  )}${getTechnicalSpecificsBooleanRoof(technicalSpecifics)}`;
