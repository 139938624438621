import { TechnicalSpecificsType } from "../types";
import {
  getEntranceImagesName,
  getHydraToKedryPrimeImageName,
  getIsola3ToKedrySkylifeImageName,
  getIsola3ToVegaImageName,
  getKedrySkylifeToKolibrieImageName,
  getKolibrieToScreeny2ImageName,
  getQubicaToKolibrie2ImageName,
  getQubicaToKolibrieImageName,
  getScreeny1ToScreeny2ImageName,
  getScreeny2ToKolibrieImageName,
  getVegaToHydraImageName,
  getVegaToIsola3ImageName,
  getVegaToKedryPrimeImageName,
  getVegaToXtesaImageName,
  getVeneziaGoldToScreeny2ImageName,
  getXtesaToVega1ImageName,
  getXtesaToVega2ImageName,
  getScreenyStormToIsola3ImageName
} from "./image-names/get-percorso-images-name";
import { getVeneziaGoldImageName } from "./image-names/get-venezia-gold-image-name";
import { getQubicaFlatImageName } from "./image-names/get-qubica-flat-image-name";
import { getA100ImageName } from "./image-names/get-a100-image-name";
import { getKolibrieImageName } from "./image-names/get-kolibrie-image-name";
import { getKheopeImageName } from "./image-names/get-kheope-image-name";
import { getKheopeSottoImageName } from "./image-names/get-kheope-sotto-image-name";
import { getXtesaImageName } from "./image-names/get-xtesa-image-name";
import { getkeplanImageName } from "./image-names/get-keplan-image-name";
import { getScreeny1ImageName } from "./image-names/get-screeny1-image-name";
import { getScreeny1BackImageName } from "./image-names/get-screeny1-back-image-name";
import { getScreeny2ImageName } from "./image-names/get-screeny2-image-name";
import { getScreeny2BackImageName } from "./image-names/get-screeny2-back-image-name";
import { getVegaImageName } from "./image-names/get-vega-image-name";
import { getHydraImageName } from "./image-names/get-hydra-image-name";
import { getIsola3ImageName } from "./image-names/get-isola3-image-name";
import { getIsola3SottoImageName } from "./image-names/get-isola3-sotto-image-name";
import { getKedryPrimeImageName } from "./image-names/get-kedry-prime-image-name";
import { getKedryPrimeSottoImageName } from "./image-names/get-kedry-prime-sotto-image-name";
import { getKedrySkylifeImageName } from "./image-names/get-kedry-skylife-image-name";
import { getKedrySkylifeSottoImageName } from "./image-names/get-kedry-skylife-sotto-image-name";
import { LOCALSTORAGE_LANGUAGE } from "../consts";
import { getAntaliaImageName } from "./image-names/get-antalia-image-name";
import { getBellaPlusImageName } from "./image-names/get-bella-plus-image-name";
import { getDominaImageName } from "./image-names/get-domina-image-name";
import { getScreenyStormImageName } from "./image-names/get-screeny-storm-image-name";
import { getScreenyStormBackImageName } from "./image-names/get-screeny-storm-back-image-name";
import { getQubicaLiteImageName } from "./image-names/get-qubica-lite-image-name";
import { getSunlightImageName } from "./image-names/get-sunlight-image-name";
import { getSunlightSottoImageName } from "./image-names/get-sunlight-sotto-image-name";

export const getImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string => {
  let imageName: string = "";

  const lang = localStorage.getItem(LOCALSTORAGE_LANGUAGE);

  switch (technicalSpecifics.position) {
    case "entrance":
      imageName = getEntranceImagesName(technicalSpecifics);
      if (lang === "fr") return imageName + "_fr";
      if (lang === "en-us") return imageName + "_us";
      return imageName;
    case "a100":
      return getA100ImageName(technicalSpecifics);
    case "antalia":
      imageName = getAntaliaImageName(technicalSpecifics);
      if (lang === "fr") return imageName + "_fr";
      return imageName;
    case "bella-plus":
      imageName = getBellaPlusImageName(technicalSpecifics);
      if (lang === "en-us") return imageName + "_noBlur";
      return imageName;
    case "domina":
      imageName = getDominaImageName(technicalSpecifics);
      if (lang === "en-us") return imageName + "_noBlur";
      return imageName;
    case "screeny-storm":
      imageName = getScreenyStormImageName(technicalSpecifics);
      if (lang === "en-us") return imageName + "_noBlur";
      return imageName;
    case "screeny-storm-back":
      imageName = getScreenyStormBackImageName(technicalSpecifics);
      if (lang === "en-us") return imageName + "_noBlur";
      return imageName;
    case "qubica-lite":
      imageName = getQubicaLiteImageName(technicalSpecifics);
      if (lang === "fr") return imageName + "_fr";
      return imageName;
    case "vega_to_xtesa":
      return getVegaToXtesaImageName(technicalSpecifics);
    case "screeny1_to_screeny2":
      return getScreeny1ToScreeny2ImageName(technicalSpecifics);
    case "isola3_to_vega":
      imageName = getIsola3ToVegaImageName(technicalSpecifics);
      if (lang !== "en-us") return imageName + "_noBlur";
      return imageName;
    case "qubica_to_kolibrie":
      imageName = getQubicaToKolibrieImageName(technicalSpecifics);
      if (lang === "fr") return imageName + "_fr";
      if (lang === "en-us") return imageName + "_us";
      return imageName;
    case "qubica_to_kolibrie2":
      imageName = getQubicaToKolibrie2ImageName(technicalSpecifics);
      if (lang === "fr") return imageName + "_fr";
      return imageName;
    case "xtesa_to_vega_1":
      imageName = getXtesaToVega1ImageName(technicalSpecifics);
      return imageName;
    case "screeny2_to_kolibrie":
      //return getScreeny2ToKolibrieImageName(technicalSpecifics);
      imageName = getScreeny2ToKolibrieImageName(technicalSpecifics);
      if (lang === "en-us") return imageName + "_noBlur";
      return  imageName;
    case "screenystorm_to_isola3":
      imageName = getScreenyStormToIsola3ImageName(technicalSpecifics);
      if (lang === "en-us") return imageName + "_noBlur";
      return  imageName;
    case "venezia-gold_to_screeny2":
      imageName = getVeneziaGoldToScreeny2ImageName(technicalSpecifics);
      if (lang === "en-us") return imageName + "_noBlur";
      if (lang === "fr") return imageName + "_fr";
      return imageName;
    case "isola3_to_kedry-skylife":
      imageName = getIsola3ToKedrySkylifeImageName(technicalSpecifics);
      if (lang !== "en-us") return imageName + "_noBlur";
      return imageName;
    case "kolibrie_to_screeny2":
      return getKolibrieToScreeny2ImageName(technicalSpecifics);
    case "kedry-skylife_to_kolibrie":
      imageName = getKedrySkylifeToKolibrieImageName(technicalSpecifics);
      if (lang === "en-us") return imageName + "_noBlur";
      if (lang === "fr") return imageName + "_fr";
      return imageName;
    case "xtesa_to_vega_2":
      imageName = getXtesaToVega2ImageName(technicalSpecifics);
      return imageName;
    case "vega_to_hydra":
      return getVegaToHydraImageName(technicalSpecifics);
    case "vega_to_kedry-prime":
      return getVegaToKedryPrimeImageName(technicalSpecifics);
    case "hydra_to_kedry-prime":
      imageName = getHydraToKedryPrimeImageName(technicalSpecifics);
      if (lang !== "en-us") return imageName + "_noBlur";
      return imageName;
    case "vega_to_isola3":
      return getVegaToIsola3ImageName(technicalSpecifics);
    case "venezia-gold":
      imageName = getVeneziaGoldImageName(technicalSpecifics);
      if (lang === "fr") return imageName + "_fr";
      return imageName;
    case "qubica-flat":
      imageName = getQubicaFlatImageName(technicalSpecifics);
      if (lang === "en-us") return imageName + "_noBlur";
      return imageName;
    case "kolibrie":
      imageName = getKolibrieImageName(technicalSpecifics);
      if (lang === "fr") return imageName + "_fr";
      if (lang === "en-us") return imageName + "_noBlur";
      return imageName;
    case "kheope":
      imageName = getKheopeImageName(technicalSpecifics);
      return imageName;
    case "kheope-sotto":
      return getKheopeSottoImageName(technicalSpecifics);
    case "xtesa":
      imageName = getXtesaImageName(technicalSpecifics);
      return imageName;
    case "keplan":
      imageName = getkeplanImageName(technicalSpecifics);
      return imageName;
    case "screeny1":
      return getScreeny1ImageName(technicalSpecifics);
    case "screeny1-back":
      return getScreeny1BackImageName(technicalSpecifics);
    case "screeny2":
      return getScreeny2ImageName(technicalSpecifics);
    case "screeny2-back":
      return getScreeny2BackImageName(technicalSpecifics);
    case "vega":
      return getVegaImageName(technicalSpecifics);
    case "hydra":
      imageName = getHydraImageName(technicalSpecifics);
      if (lang !== "en-us") return imageName + "_noBlur";
      return imageName;
    case "isola3":
      return getIsola3ImageName(technicalSpecifics);
    case "isola3-sotto":
      return getIsola3SottoImageName(technicalSpecifics);
    case "sunlight":
      return getSunlightImageName(technicalSpecifics);
    case "sunlight-sotto":
      return getSunlightSottoImageName(technicalSpecifics);
    case "kedry-prime":
      return getKedryPrimeImageName(technicalSpecifics);
    case "kedry-prime-sotto":
      return getKedryPrimeSottoImageName(technicalSpecifics);
    case "kedry-skylife":
      return getKedrySkylifeImageName(technicalSpecifics);
    case "kedry-skylife-sotto":
      return getKedrySkylifeSottoImageName(technicalSpecifics);
    default:
      return getEntranceImagesName(technicalSpecifics);
  }
};
