import { LOCALSTORAGE_LANGUAGE } from "../consts";

export const positionsTitlesUs = [
  {
    name: "qubica-flat",
    title: "QUBICA FLAT B50",
    type: "product",
  },
  {
    name: "kedry-prime",
    title: "KEDRY PRIME",
    type: "product",
  },
  {
    name: "kedry-prime-sotto",
    title: "KEDRY PRIME - BENEATH",
    type: "product",
  },
  {
    name: "vega",
    title: "GENNIUS VEGA",
    type: "product",
  },
  {
    name: "kolibrie",
    title: "GENNIUS SAILS KOLIBRIE",
    type: "product",
  },
  {
    name: "a100",
    title: "A 100",
    type: "product",
  },
  {
    name: "kheope",
    title: "GENNIUS SAILS KHEOPE K1",
    type: "product",
  },
  {
    name: "sunlight",
    title: "SUNLIGHT",
    type: "product",
  },
  {
    name: "sunlight-sotto",
    title: "SUNLIGHT - BENEATH",
    type: "product",
  },
  {
    name: "bella-plus",
    title: "BELLA PLUS",
    type: "product",
  },
  {
    name: "domina",
    title: "DOMINA",
    type: "product",
  },
  {
    name: "screeny-storm",
    title: "SCREENY STORM",
    type: "product",
  },
  {
    name: "screeny-storm-back",
    title: "SCREENY STORM - BACK",
    type: "product",
  },
  {
    name: "entrance",
    title: "ENTRANCE",
    type: "path",
  },
  {
    name: "vega_to_xtesa",
    title: "GENNIUS VEGA - A 100",
    type: "path",
  },
  {
    name: "isola3_to_vega",
    title: "QUBICA FLAT - GENNIUS VEGA",
    type: "path",
  },
  {
    name: "qubica_to_kolibrie",
    title: "QUBICA FLAT B50 - GENNIUS SAILS KOLIBRIE",
    type: "path",
  },
  {
    name: "xtesa_to_vega_1",
    title: "A 100 - GENNIUS VEGA",
    type: "path",
  },
  {
    name: "venezia-gold_to_screeny2",
    title: "QUBICA FLAT - GENNIUS SAILS KOLIBRIE",
    type: "path",
  },
  {
    name: "isola3_to_kedry-skylife",
    title: "QUBICA FLAT - GENNIUS VEGA",
    type: "path",
  },
  {
    name: "kedry-skylife_to_kolibrie",
    title: "QUBICA FLAT - GENNIUS SAILS KOLIBRIE",
    type: "path",
  },
  {
    name: "xtesa_to_vega_2",
    title: "A 100 - GENNIUS VEGA",
    type: "path",
  },
  {
    name: "vega_to_hydra",
    title: "GENNIUS VEGA",
    type: "path",
  },
  {
    name: "vega_to_kedry-prime",
    title: "GENNIUS VEGA - KEDRY PRIME",
    type: "path",
  },
  {
    name: "hydra_to_kedry-prime",
    title: "KEDRY PRIME - GENNIUS VEGA",
    type: "path",
  },
  {
    name: "vega_to_isola3",
    title: "GENNIUS VEGA - KEDRY PRIME",
    type: "path",
  },
  {
    name: "screenystorm_to_isola3",
    title: "SCREENY STORM - VEGA",
    type: "path",
  },
];

export const positionsTitlesEs = [
  {
    name: "kedry-prime",
    title: "KEDRY PRIME",
    type: "product",
  },
  {
    name: "kedry-prime-sotto",
    title: "KEDRY PRIME - BAJO",
    type: "product",
  },
  {
    name: "vega",
    title: "GENNIUS VEGA",
    type: "product",
  },
  {
    name: "xtesa",
    title: "XTESA",
    type: "product",
  },
  {
    name: "keplan",
    title: "KEPLAN",
    type: "product",
  },
  {
    name: "isola3",
    title: "ISOLA 3",
    type: "product",
  },
  {
    name: "isola3-sotto",
    title: "ISOLA 3 - BAJO",
    type: "product",
  },
  {
    name: "sunlight",
    title: "SUNLIGHT",
    type: "product",
  },
  {
    name: "sunlight-sotto",
    title: "SUNLIGHT - BAJO",
    type: "product",
  },
  {
    name: "kolibrie",
    title: "GENNIUS SAILS KOLIBRIE",
    type: "product",
  },
  {
    name: "kheope",
    title: "GENNIUS SAILS KHEOPE K1",
    type: "product",
  },
  {
    name: "hydra",
    title: "HYDRA",
    type: "product",
  },
  {
    name: "entrance",
    title: "ENTRADA",
    type: "path",
  },
  {
    name: "vega_to_xtesa",
    title: "GENNIUS VEGA - XTESA",
    type: "path",
  },
  {
    name: "isola3_to_vega",
    title: "ISOLA 3 - GENNIUS VEGA",
    type: "path",
  },
  {
    name: "qubica_to_kolibrie",
    title: "KEDRY SKYLIFE - GENNIUS SAILS KOLIBRIE",
    type: "path",
  },
  {
    name: "xtesa_to_vega_1",
    title: "XTESA - GENNIUS VEGA",
    type: "path",
  },
  {
    name: "screeny2_to_kolibrie",
    title: "ISOLA 3 - GENNIUS SAILS KOLIBRIE",
    type: "path",
  },
  {
    name: "venezia-gold_to_screeny2",
    title: "KEDRY SKYLIFE - GENNIUS SAILS KOLIBRIE",
    type: "path",
  },
  {
    name: "isola3_to_kedry-skylife",
    title: "KEDRY SKYLIFE - ISOLA 3",
    type: "path",
  },
  {
    name: "kolibrie_to_screeny2",
    title: "GENNIUS SAILS KOLIBRIE - ISOLA 3",
    type: "path",
  },
  {
    name: "kedry-skylife_to_kolibrie",
    title: "GENNIUS SAILS KOLIBRIE - KEDRY SKYLIFE",
    type: "path",
  },
  {
    name: "xtesa_to_vega_2",
    title: "XTESA - GENNIUS VEGA",
    type: "path",
  },
  {
    name: "vega_to_hydra",
    title: "GENNIUS VEGA - HYDRA",
    type: "path",
  },
  {
    name: "vega_to_kedry-prime",
    title: "GENNIUS VEGA - KEDRY PRIME",
    type: "path",
  },
  {
    name: "hydra_to_kedry-prime",
    title: "GENNIUS VEGA - KEDRY PRIME",
    type: "path",
  },
  {
    name: "vega_to_isola3",
    title: "KEDRY PRIME - ISOLA 3",
    type: "path",
  },
];

export const positionsTitlesEn = [
  {
    name: "venezia-gold",
    title: "VENEZIA GOLD",
    type: "product",
  },
  {
    name: "qubica-flat",
    title: "QUBICA FLAT B50",
    type: "product",
  },
  {
    name: "kedry-prime",
    title: "KEDRY PRIME",
    type: "product",
  },
  {
    name: "kedry-prime-sotto",
    title: "KEDRY PRIME - BENEATH",
    type: "product",
  },
  {
    name: "kedry-skylife",
    title: "KEDRY SKYLIFE",
    type: "product",
  },
  {
    name: "kedry-skylife-sotto",
    title: "KEDRY SKYLIFE - BENEATH",
    type: "product",
  },
  {
    name: "sunlight",
    title: "SUNLIGHT",
    type: "product",
  },
  {
    name: "sunlight-sotto",
    title: "SUNLIGHT - BENEATH",
    type: "product",
  },
  {
    name: "vega",
    title: "GENNIUS VEGA",
    type: "product",
  },
  {
    name: "xtesa",
    title: "XTESA",
    type: "product",
  },
  {
    name: "keplan",
    title: "KEPLAN",
    type: "product",
  },
  {
    name: "isola3",
    title: "ISOLA 3",
    type: "product",
  },
  {
    name: "isola3-sotto",
    title: "ISOLA 3 - UNTER",
    type: "product",
  },
  {
    name: "screeny1",
    title: "SCREENY 110 GC S12",
    type: "product",
  },
  {
    name: "screeny1-back",
    title: "SCREENY 110 GC S12",
    type: "product",
  },
  {
    name: "screeny2",
    title: "SCREENY 110 GPZ UNICA S32",
    type: "product",
  },
  {
    name: "screeny2-back",
    title: "SCREENY 110 GPZ UNICA S32",
    type: "product",
  },
  {
    name: "kolibrie",
    title: "GENNIUS SAILS KOLIBRIE",
    type: "product",
  },
  {
    name: "kheope",
    title: "GENNIUS SAILS KHEOPE K1",
    type: "product",
  },
  {
    name: "hydra",
    title: "HYDRA",
    type: "product",
  },
  {
    name: "entrance",
    title: "ENTRANCE",
    type: "path",
  },
  {
    name: "vega_to_xtesa",
    title: "GENNIUS VEGA - XTESA",
    type: "path",
  },
  {
    name: "screeny1_to_screeny2",
    title: "SCREENY 110 GC S12 - SCREENY 110 GPZ UNICA S32",
    type: "path",
  },
  {
    name: "isola3_to_vega",
    title: "ISOLA 3 - GENNIUS VEGA",
    type: "path",
  },
  {
    name: "qubica_to_kolibrie",
    title: "QUBICA FLAT B50 - GENNIUS SAILS KOLIBRIE",
    type: "path",
  },
  {
    name: "xtesa_to_vega_1",
    title: "XTESA - GENNIUS VEGA",
    type: "path",
  },
  {
    name: "screeny2_to_kolibrie",
    title: "SCREENY 110 GPZ UNICA S32 - GENNIUS SAILS KOLIBRIE",
    type: "path",
  },
  {
    name: "venezia-gold_to_screeny2",
    title: "VENEZIA GOLD - KEDRY SKYLIFE",
    type: "path",
  },
  {
    name: "isola3_to_kedry-skylife",
    title: "ISOLA 3 - KEDRY SKYLIFE",
    type: "path",
  },
  {
    name: "kolibrie_to_screeny2",
    title: "GENNIUS SAILS KOLIBRIE - SCREENY 110 GPZ UNICA S32",
    type: "path",
  },
  {
    name: "kedry-skylife_to_kolibrie",
    title: "KEDRY SKYLIFE - GENNIUS SAILS KOLIBRIE",
    type: "path",
  },
  {
    name: "xtesa_to_vega_2",
    title: "XTESA - GENNIUS VEGA",
    type: "path",
  },
  {
    name: "vega_to_hydra",
    title: "GENNIUS VEGA - HYDRA",
    type: "path",
  },
  {
    name: "vega_to_kedry-prime",
    title: "GENNIUS VEGA - KEDRY PRIME",
    type: "path",
  },
  {
    name: "hydra_to_kedry-prime",
    title: "HYDRA - KEDRY PRIME",
    type: "path",
  },
  {
    name: "vega_to_isola3",
    title: "GENNIUS VEGA - ISOLA 3",
    type: "path",
  },
];

export const positionsTitlesIt = [
  {
    name: "venezia-gold",
    title: "VENEZIA GOLD",
    type: "product",
  },
  {
    name: "qubica-flat",
    title: "QUBICA FLAT B50",
    type: "product",
  },
  {
    name: "kedry-prime",
    title: "KEDRY PRIME",
    type: "product",
  },
  {
    name: "kedry-prime-sotto",
    title: "KEDRY PRIME - SOTTO",
    type: "product",
  },
  {
    name: "sunlight",
    title: "SUNLIGHT",
    type: "product",
  },
  {
    name: "sunlight-sotto",
    title: "SUNLIGHT - SOTTO",
    type: "product",
  },
  {
    name: "kedry-skylife",
    title: "KEDRY SKYLIFE",
    type: "product",
  },
  {
    name: "kedry-skylife-sotto",
    title: "KEDRY SKYLIFE - SOTTO",
    type: "product",
  },
  {
    name: "vega",
    title: "GENNIUS VEGA",
    type: "product",
  },
  {
    name: "xtesa",
    title: "XTESA",
    type: "product",
  },
  {
    name: "keplan",
    title: "KEPLAN",
    type: "product",
  },
  {
    name: "isola3",
    title: "ISOLA 3",
    type: "product",
  },
  {
    name: "isola3-sotto",
    title: "ISOLA 3 - SOTTO",
    type: "product",
  },
  {
    name: "screeny1",
    title: "SCREENY 110 GC S12",
    type: "product",
  },
  {
    name: "screeny1-back",
    title: "SCREENY 110 GC S12",
    type: "product",
  },
  {
    name: "screeny2",
    title: "SCREENY 110 GPZ UNICA S32",
    type: "product",
  },
  {
    name: "screeny2-back",
    title: "SCREENY 110 GPZ UNICA S32",
    type: "product",
  },
  {
    name: "kolibrie",
    title: "GENNIUS SAILS KOLIBRIE",
    type: "product",
  },
  {
    name: "kheope",
    title: "GENNIUS SAILS KHEOPE K1",
    type: "product",
  },
  {
    name: "hydra",
    title: "HYDRA",
    type: "product",
  },
  {
    name: "entrance",
    title: "ENTRATA",
    type: "path",
  },
  {
    name: "vega_to_xtesa",
    title: "GENNIUS VEGA - XTESA",
    type: "path",
  },
  {
    name: "screeny1_to_screeny2",
    title: "SCREENY 110 GC S12 - SCREENY 110 GPZ UNICA S32",
    type: "path",
  },
  {
    name: "isola3_to_vega",
    title: "ISOLA 3 - GENNIUS VEGA",
    type: "path",
  },
  {
    name: "qubica_to_kolibrie",
    title: "QUBICA FLAT B50 - GENNIUS SAILS KOLIBRIE",
    type: "path",
  },
  {
    name: "xtesa_to_vega_1",
    title: "XTESA - GENNIUS VEGA",
    type: "path",
  },
  {
    name: "screeny2_to_kolibrie",
    title: "SCREENY 110 GPZ UNICA S32 - GENNIUS SAILS KOLIBRIE",
    type: "path",
  },
  {
    name: "venezia-gold_to_screeny2",
    title: "VENEZIA GOLD - KEDRY SKYLIFE",
    type: "path",
  },
  {
    name: "isola3_to_kedry-skylife",
    title: "ISOLA 3 - KEDRY SKYLIFE",
    type: "path",
  },
  {
    name: "kolibrie_to_screeny2",
    title: "GENNIUS SAILS KOLIBRIE - SCREENY 110 GPZ UNICA S32",
    type: "path",
  },
  {
    name: "kedry-skylife_to_kolibrie",
    title: "KEDRY SKYLIFE - GENNIUS SAILS KOLIBRIE",
    type: "path",
  },
  {
    name: "xtesa_to_vega_2",
    title: "XTESA - GENNIUS VEGA",
    type: "path",
  },
  {
    name: "vega_to_hydra",
    title: "GENNIUS VEGA - HYDRA",
    type: "path",
  },
  {
    name: "vega_to_kedry-prime",
    title: "GENNIUS VEGA - KEDRY PRIME",
    type: "path",
  },
  {
    name: "hydra_to_kedry-prime",
    title: "HYDRA - KEDRY PRIME",
    type: "path",
  },
  {
    name: "vega_to_isola3",
    title: "GENNIUS VEGA - ISOLA 3",
    type: "path",
  },
];

export const positionsTitlesDe = [
  {
    name: "venezia-gold",
    title: "VENEZIA GOLD",
    type: "product",
  },
  {
    name: "qubica-flat",
    title: "QUBICA FLAT B50",
    type: "product",
  },
  {
    name: "kedry-prime",
    title: "KEDRY PRIME",
    type: "product",
  },
  {
    name: "kedry-prime-sotto",
    title: "KEDRY PRIME - UNTER",
    type: "product",
  },
  {
    name: "sunlight",
    title: "SUNLIGHT",
    type: "product",
  },
  {
    name: "sunlight-sotto",
    title: "SUNLIGHT - UNTER",
    type: "product",
  },
  {
    name: "kedry-skylife",
    title: "KEDRY SKYLIFE",
    type: "product",
  },
  {
    name: "kedry-skylife-sotto",
    title: "KEDRY SKYLIFE - UNTER",
    type: "product",
  },
  {
    name: "vega",
    title: "GENNIUS VEGA",
    type: "product",
  },
  {
    name: "xtesa",
    title: "XTESA",
    type: "product",
  },
  {
    name: "keplan",
    title: "KEPLAN",
    type: "product",
  },
  {
    name: "isola3",
    title: "ISOLA 3",
    type: "product",
  },
  {
    name: "isola3-sotto",
    title: "ISOLA 3 - UNTER",
    type: "product",
  },
  {
    name: "screeny1",
    title: "SCREENY 110 GC S12",
    type: "product",
  },
  {
    name: "screeny1-back",
    title: "SCREENY 110 GC S12",
    type: "product",
  },
  {
    name: "screeny2",
    title: "SCREENY 110 GPZ UNICA S32",
    type: "product",
  },
  {
    name: "screeny2-back",
    title: "SCREENY 110 GPZ UNICA S32",
    type: "product",
  },
  {
    name: "kolibrie",
    title: "GENNIUS SAILS KOLIBRIE",
    type: "product",
  },
  {
    name: "kheope",
    title: "GENNIUS SAILS KHEOPE K1",
    type: "product",
  },
  {
    name: "hydra",
    title: "HYDRA",
    type: "product",
  },
  {
    name: "entrance",
    title: "EINGABE",
    type: "path",
  },
  {
    name: "vega_to_xtesa",
    title: "GENNIUS VEGA - XTESA",
    type: "path",
  },
  {
    name: "screeny1_to_screeny2",
    title: "SCREENY 110 GC S12 - SCREENY 110 GPZ UNICA S32",
    type: "path",
  },
  {
    name: "isola3_to_vega",
    title: "ISOLA 3 - GENNIUS VEGA",
    type: "path",
  },
  {
    name: "qubica_to_kolibrie",
    title: "QUBICA FLAT B50 - GENNIUS SAILS KOLIBRIE",
    type: "path",
  },
  {
    name: "xtesa_to_vega_1",
    title: "XTESA - GENNIUS VEGA",
    type: "path",
  },
  {
    name: "screeny2_to_kolibrie",
    title: "SCREENY 110 GPZ UNICA S32 - GENNIUS SAILS KOLIBRIE",
    type: "path",
  },
  {
    name: "venezia-gold_to_screeny2",
    title: "VENEZIA GOLD - KEDRY SKYLIFE",
    type: "path",
  },
  {
    name: "isola3_to_kedry-skylife",
    title: "ISOLA 3 - KEDRY SKYLIFE",
    type: "path",
  },
  {
    name: "kolibrie_to_screeny2",
    title: "GENNIUS SAILS KOLIBRIE - SCREENY 110 GPZ UNICA S32",
    type: "path",
  },
  {
    name: "kedry-skylife_to_kolibrie",
    title: "KEDRY SKYLIFE - GENNIUS SAILS KOLIBRIE",
    type: "path",
  },
  {
    name: "xtesa_to_vega_2",
    title: "XTESA - GENNIUS VEGA",
    type: "path",
  },
  {
    name: "vega_to_hydra",
    title: "GENNIUS VEGA - HYDRA",
    type: "path",
  },
  {
    name: "vega_to_kedry-prime",
    title: "GENNIUS VEGA - KEDRY PRIME",
    type: "path",
  },
  {
    name: "hydra_to_kedry-prime",
    title: "HYDRA - KEDRY PRIME",
    type: "path",
  },
  {
    name: "vega_to_isola3",
    title: "GENNIUS VEGA - ISOLA 3",
    type: "path",
  },
];

export const positionsTitlesFr = [
  {
    name: "venezia-gold",
    title: "GENEROSA GOLD",
    type: "product",
  },
  {
    name: "qubica-lite",
    title: "QUBICA LITE",
    type: "product",
  },
  {
    name: "antalia",
    title: "COMPACTO",
    type: "product",
  },
  {
    name: "kedry-prime",
    title: "KEDRY PRIME",
    type: "product",
  },
  {
    name: "kedry-prime-sotto",
    title: "KEDRY PRIME - SOUS",
    type: "product",
  },
  {
    name: "sunlight",
    title: "SUNLIGHT",
    type: "product",
  },
  {
    name: "sunlight-sotto",
    title: "SUNLIGHT - SOUS",
    type: "product",
  },
  {
    name: "kedry-skylife",
    title: "KEDRY SKYLIFE",
    type: "product",
  },
  {
    name: "kedry-skylife-sotto",
    title: "KEDRY SKYLIFE - SOUS",
    type: "product",
  },
  {
    name: "vega",
    title: "GENNIUS VEGA",
    type: "product",
  },
  {
    name: "xtesa",
    title: "XTESA",
    type: "product",
  },
  {
    name: "isola3",
    title: "ISOLA 3",
    type: "product",
  },
  {
    name: "isola3-sotto",
    title: "ISOLA 3 - SOUS",
    type: "product",
  },
  {
    name: "screeny1",
    title: "SCREENY 110 COULISSE",
    type: "product",
  },
  {
    name: "screeny1-back",
    title: "SCREENY 110 COULISSE",
    type: "product",
  },
  {
    name: "screeny2",
    title: "SCREENY 110 COULISSE ZIP",
    type: "product",
  },
  {
    name: "screeny2-back",
    title: "SCREENY 110 COULISSE ZIP",
    type: "product",
  },
  {
    name: "kolibrie",
    title: "GENNIUS SAILS KOLIBRIE",
    type: "product",
  },
  {
    name: "kheope",
    title: "GENNIUS SAILS KHEOPE K1",
    type: "product",
  },
  {
    name: "hydra",
    title: "HYDRAE PLUS",
    type: "product",
  },
  {
    name: "entrance",
    title: "ENTRÉE",
    type: "path",
  },
  {
    name: "vega_to_xtesa",
    title: "GENNIUS VEGA - XTESA",
    type: "path",
  },
  {
    name: "screeny1_to_screeny2",
    title: "SCREENY 110 COULISSE - SCREENY 110 COULISSE ZIP",
    type: "path",
  },
  {
    name: "isola3_to_vega",
    title: "ISOLA 3 - GENNIUS VEGA",
    type: "path",
  },
  {
    name: "qubica_to_kolibrie",
    title: "QUBICA LITE - GENNIUS SAILS KOLIBRIE",
    type: "path",
  },
  {
    name: "qubica_to_kolibrie2",
    title: "GENNIUS SAILS KOLIBRIE - QUBICA LITE",
    type: "path",
  },
  {
    name: "xtesa_to_vega_1",
    title: "XTESA - GENNIUS VEGA",
    type: "path",
  },
  {
    name: "screeny2_to_kolibrie",
    title: "SCREENY 110 COULISSE ZIP - GENNIUS SAILS KOLIBRIE",
    type: "path",
  },
  {
    name: "venezia-gold_to_screeny2",
    title: "GENEROSA GOLD - KEDRY SKYLIFE",
    type: "path",
  },
  {
    name: "isola3_to_kedry-skylife",
    title: "ISOLA 3 - KEDRY SKYLIFE",
    type: "path",
  },
  {
    name: "kolibrie_to_screeny2",
    title: "GENNIUS SAILS KOLIBRIE - SCREENY 110 COULISSE ZIP",
    type: "path",
  },
  {
    name: "kedry-skylife_to_kolibrie",
    title: "KEDRY SKYLIFE - GENNIUS SAILS KOLIBRIE",
    type: "path",
  },
  {
    name: "xtesa_to_vega_2",
    title: "XTESA - GENNIUS VEGA",
    type: "path",
  },
  {
    name: "vega_to_hydra",
    title: "GENNIUS VEGA - HYDRA",
    type: "path",
  },
  {
    name: "vega_to_kedry-prime",
    title: "GENNIUS VEGA - KEDRY PRIME",
    type: "path",
  },
  {
    name: "hydra_to_kedry-prime",
    title: "HYDRAE PLUS - KEDRY PRIME",
    type: "path",
  },
  {
    name: "vega_to_isola3",
    title: "GENNIUS VEGA - ISOLA 3",
    type: "path",
  },
];

export const getPositionsTitlesByLang = () => {
  let language = "it";
  //let language = localStorage.getItem(LOCALSTORAGE_LANGUAGE)
  if (typeof window !== `undefined`) {
    language = localStorage.getItem(LOCALSTORAGE_LANGUAGE);
  }
  if (language === "en-us") return positionsTitlesUs;
  if (language === "es") return positionsTitlesEs;
  if (language === "fr") return positionsTitlesFr;
  if (language === "en") return positionsTitlesEn;
  if (language === "it") return positionsTitlesIt;
  if (language === "de") return positionsTitlesDe;
  return positionsTitlesIt;
};

export const mapPositionsUs = [
  {
    name: "qubica-flat",
    top: "",
    bottom: "14%",
    left: "12%",
    right: "",
    title: "QUBICA FLAT B50",
    type: "product",
  },
  {
    name: "kolibrie",
    top: "",
    bottom: "47.5%",
    left: "8%",
    right: "",
    title: "GENNIUS SAILS KOLIBRIE",
    type: "product",
  },
  {
    name: "kheope",
    top: "27.5%",
    bottom: "",
    left: "9.5%",
    right: "",
    title: "GENNIUS SAILS KHEOPE K1",
    type: "product",
  },
  {
    name: "sunlight",
    top: "25.5%",
    bottom: "",
    left: "30.5%",
    right: "",
    title: "SUNLIGHT",
    type: "product",
  },
  {
    name: "sunlight-sotto",
    top: "18.5%",
    bottom: "",
    left: "30.5%",
    right: "",
    title: "SUNLIGHT - BENEATH",
    type: "product",
  },
  {
    name: "a100",
    top: "25.5%",
    bottom: "",
    left: "",
    right: "43%",
    title: "A 100",
    type: "product",
  },
  {
    name: "kedry-prime",
    top: "",
    bottom: "9%",
    left: "",
    right: "38%",
    title: "KEDRY PRIME",
    type: "product",
  },
  {
    name: "kedry-prime-sotto",
    top: "",
    bottom: "15.5%",
    left: "",
    right: "33%",
    title: "KEDRY PRIME - BENEATH",
    type: "product",
  },
  {
    name: "vega",
    top: "",
    bottom: "34.5%",
    left: "",
    right: "14%",
    title: "GENNIUS VEGA",
    type: "product",
  },
  {
    name: "bella-plus",
    top: "",
    bottom: "28%",
    left: "23%",
    right: "",
    title: "BELLA PLUS",
    type: "product",
  },
  {
    name: "domina",
    top: "",
    bottom: "28%",
    left: "13%",
    right: "",
    title: "DOMINA",
    type: "product",
  },
  {
    name: "screeny-storm",
    top: "",
    bottom: "55%",
    right: "",
    left: "24%",
    title: "SCREENY STORM",
    type: "product",
  },
  {
    name: "screeny-storm-back",
    top: "32%",
    bottom: "",
    left: "24%",
    right: "",
    title: "SCREENY STORM - BACK",
    type: "product",
  },
  {
    name: "entrance",
    top: "",
    bottom: "8%",
    left: "30.5%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "vega_to_xtesa",
    top: "",
    bottom: "40%",
    left: "",
    right: "12.5%",
    title: "",
    type: "path",
  },
  {
    name: "isola3_to_vega",
    top: "",
    bottom: "37.8%",
    left: "",
    right: "22.8%",
    title: "",
    type: "path",
  },
  {
    name: "qubica_to_kolibrie",
    top: "",
    bottom: "37.9%",
    left: "12%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "xtesa_to_vega_1",
    top: "",
    bottom: "54%",
    left: "",
    right: "12.5%",
    title: "",
    type: "path",
  },
  {
    name: "venezia-gold_to_screeny2",
    top: "",
    bottom: "37.9%",
    left: "22%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "isola3_to_kedry-skylife",
    top: "",
    bottom: "37.9%",
    left: "",
    right: "42.5%",
    title: "",
    type: "path",
  },
  {
    name: "kedry-skylife_to_kolibrie",
    top: "",
    bottom: "37.9%",
    left: "29%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "xtesa_to_vega_2",
    top: "",
    bottom: "64%",
    left: "",
    right: "12.5%",
    title: "",
    type: "path",
  },
  {
    name: "vega_to_hydra",
    top: "",
    bottom: "30%",
    left: "",
    right: "15%",
    title: "",
    type: "path",
  },
  {
    name: "vega_to_kedry-prime",
    top: "",
    bottom: "18%",
    left: "",
    right: "22.8%",
    title: "",
    type: "path",
  },
  {
    name: "hydra_to_kedry-prime",
    top: "",
    bottom: "9%",
    left: "",
    right: "23.8%",
    title: "",
    type: "path",
  },
  {
    name: "vega_to_isola3",
    top: "",
    bottom: "27.8%",
    left: "",
    right: "22.8%",
    title: "",
    type: "path",
  },
  {
    name: "screenystorm_to_isola3",
    top: "41%",
    bottom: "",
    left: "",
    right: "23%",
    title: "",
    type: "path",
  },
];

export const mapPositionsEs = [
  {
    name: "kolibrie",
    top: "",
    bottom: "45.5%",
    left: "5%",
    right: "",
    title: "GENNIUS SAILS KOLIBRIE",
    type: "product",
  },
  {
    name: "kheope",
    top: "26.5%",
    bottom: "",
    left: "10.5%",
    right: "",
    title: "GENNIUS SAILS KHEOPE K1",
    type: "product",
  },
  {
    name: "sunlight",
    top: "25.5%",
    bottom: "",
    left: "30.5%",
    right: "",
    title: "SUNLIGHT",
    type: "product",
  },
  {
    name: "sunlight-sotto",
    top: "18.5%",
    bottom: "",
    left: "30.5%",
    right: "",
    title: "SUNLIGHT - BAJO",
    type: "product",
  },
  {
    name: "isola3",
    top: "41%",
    bottom: "",
    left: "",
    right: "23%",
    title: "ISOLA 3",
    type: "product",
  },
  {
    name: "isola3-sotto",
    top: "",
    bottom: "46.5%",
    left: "",
    right: "33.5%",
    title: "ISOLA 3 - BAJO",
    type: "product",
  },
  {
    name: "kedry-prime",
    top: "",
    bottom: "8.5%",
    left: "",
    right: "38%",
    title: "KEDRY PRIME",
    type: "product",
  },
  {
    name: "kedry-prime-sotto",
    top: "",
    bottom: "15.5%",
    left: "",
    right: "33%",
    title: "KEDRY PRIME - BAJO",
    type: "product",
  },
  {
    name: "hydra",
    top: "",
    bottom: "10%",
    left: "",
    right: "11%",
    title: "HYDRA",
    type: "product",
  },
  {
    name: "vega",
    top: "",
    bottom: "34.5%",
    left: "",
    right: "13%",
    title: "GENNIUS VEGA",
    type: "product",
  },
  {
    name: "xtesa",
    top: "",
    bottom: "70%",
    left: "",
    right: "27.5%",
    title: "XTESA",
    type: "product",
  },
  {
    name: "keplan",
    top: "",
    bottom: "69%",
    left: "",
    right: "10.5%",
    title: "KEPLAN",
    type: "product",
  },
  {
    name: "kedry-skylife",
    top: "",
    bottom: "25%",
    left: "30.5%",
    right: "",
    title: "KEDRY SKYLIFE",
    type: "product",
  },
  {
    name: "kedry-skylife-sotto",
    top: "",
    bottom: "29%",
    left: "39.5%",
    right: "",
    title: "KEDRY SKYLIFE - BAJO",
    type: "product",
  },
  {
    name: "entrance",
    top: "",
    bottom: "8%",
    left: "30.5%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "vega_to_xtesa",
    top: "",
    bottom: "40%",
    left: "",
    right: "12.5%",
    title: "",
    type: "path",
  },
  {
    name: "isola3_to_vega",
    top: "",
    bottom: "38%",
    left: "",
    right: "23%",
    title: "",
    type: "path",
  },
  {
    name: "qubica_to_kolibrie",
    top: "",
    bottom: "38%",
    left: "13%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "xtesa_to_vega_1",
    top: "",
    bottom: "54%",
    left: "",
    right: "12.5%",
    title: "",
    type: "path",
  },
  {
    name: "screeny2_to_kolibrie",
    top: "",
    bottom: "55%",
    left: "29%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "venezia-gold_to_screeny2",
    top: "",
    bottom: "38%",
    left: "22%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "isola3_to_kedry-skylife",
    top: "",
    bottom: "38%",
    left: "",
    right: "42.5%",
    title: "",
    type: "path",
  },
  {
    name: "kolibrie_to_screeny2",
    top: "",
    bottom: "55%",
    left: "14%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "kedry-skylife_to_kolibrie",
    top: "",
    bottom: "35.9%",
    left: "30.5%",
    right: "",
    title: "",
    type: "path",
  },
  /*{
    name: "xtesa_to_vega_2",
    top: "",
    bottom: "64%",
    left: "",
    right: "12.5%",
    title: "",
    type: "path",
  },*/
  {
    name: "vega_to_hydra",
    top: "",
    bottom: "29%",
    left: "",
    right: "13%",
    title: "",
    type: "path",
  },
  {
    name: "vega_to_kedry-prime",
    top: "",
    bottom: "18%",
    left: "",
    right: "23%",
    title: "",
    type: "path",
  },
  {
    name: "hydra_to_kedry-prime",
    top: "",
    bottom: "8.5%",
    left: "",
    right: "23%",
    title: "",
    type: "path",
  },
  {
    name: "vega_to_isola3",
    top: "",
    bottom: "29.8%",
    left: "",
    right: "23%",
    title: "",
    type: "path",
  },
];

export const mapPositionsEn = [
  {
    name: "venezia-gold",
    top: "",
    bottom: "16%",
    left: "22%",
    right: "",
    title: "VENEZIA GOLD",
    type: "product",
  },
  {
    name: "qubica-flat",
    top: "",
    bottom: "16%",
    left: "12%",
    right: "",
    title: "QUBICA FLAT B50",
    type: "product",
  },
  {
    name: "kolibrie",
    top: "",
    bottom: "47.5%",
    left: "5%",
    right: "",
    title: "GENNIUS SAILS KOLIBRIE",
    type: "product",
  },
  {
    name: "kheope",
    top: "27.5%",
    bottom: "",
    left: "7.5%",
    right: "",
    title: "GENNIUS SAILS KHEOPE K1",
    type: "product",
  },
  {
    name: "sunlight",
    top: "25.5%",
    bottom: "",
    left: "30.5%",
    right: "",
    title: "SUNLIGHT",
    type: "product",
  },
  {
    name: "sunlight-sotto",
    top: "18.5%",
    bottom: "",
    left: "30.5%",
    right: "",
    title: "SUNLIGHT - BENEATH",
    type: "product",
  },
  {
    name: "screeny1",
    top: "",
    bottom: "55%",
    left: "",
    right: "35%",
    title: "SCREENY 110 GC S12",
    type: "product",
  },
  {
    name: "screeny1-back",
    top: "32%",
    bottom: "",
    left: "",
    right: "35%",
    title: "SCREENY 110 GC S12",
    type: "product",
  },
  {
    name: "screeny2",
    top: "",
    bottom: "55%",
    left: "39%",
    right: "",
    title: "SCREENY 110 GPZ UNICA S32",
    type: "product",
  },
  {
    name: "screeny2-back",
    top: "32%",
    bottom: "",
    left: "39%",
    right: "",
    title: "SCREENY 110 GPZ UNICA S32",
    type: "product",
  },
  {
    name: "isola3",
    top: "41%",
    bottom: "",
    left: "",
    right: "23%",
    title: "ISOLA 3",
    type: "product",
  },
  {
    name: "isola3-sotto",
    top: "",
    bottom: "44.5%",
    left: "",
    right: "34.5%",
    title: "ISOLA 3 - BENEATH",
    type: "product",
  },
  {
    name: "kedry-prime",
    top: "",
    bottom: "9%",
    left: "",
    right: "38%",
    title: "KEDRY PRIME",
    type: "product",
  },
  {
    name: "kedry-prime-sotto",
    top: "",
    bottom: "15.5%",
    left: "",
    right: "33%",
    title: "KEDRY PRIME - BENEATH",
    type: "product",
  },
  {
    name: "hydra",
    top: "",
    bottom: "10%",
    left: "",
    right: "13%",
    title: "HYDRA",
    type: "product",
  },
  {
    name: "vega",
    top: "",
    bottom: "34.5%",
    left: "",
    right: "13%",
    title: "GENNIUS VEGA",
    type: "product",
  },
  {
    name: "xtesa",
    top: "",
    bottom: "69%",
    left: "",
    right: "27%",
    title: "XTESA",
    type: "product",
  },
  {
    name: "keplan",
    top: "",
    bottom: "69%",
    left: "",
    right: "10.5%",
    title: "KEPLAN",
    type: "product",
  },
  {
    name: "kedry-skylife",
    top: "",
    bottom: "25%",
    left: "29.5%",
    right: "",
    title: "KEDRY SKYLIFE",
    type: "product",
  },
  {
    name: "kedry-skylife-sotto",
    top: "",
    bottom: "27%",
    left: "37.5%",
    right: "",
    title: "KEDRY SKYLIFE - BENEATH",
    type: "product",
  },
  {
    name: "entrance",
    top: "",
    bottom: "8%",
    left: "28.5%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "vega_to_xtesa",
    top: "",
    bottom: "42%",
    left: "",
    right: "12.5%",
    title: "",
    type: "path",
  },
  {
    name: "screeny1_to_screeny2",
    top: "",
    bottom: "62%",
    left: "29%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "isola3_to_vega",
    top: "",
    bottom: "35.8%",
    left: "",
    right: "23.8%",
    title: "",
    type: "path",
  },
  {
    name: "qubica_to_kolibrie",
    top: "",
    bottom: "37.9%",
    left: "12%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "xtesa_to_vega_1",
    top: "",
    bottom: "56%",
    left: "",
    right: "12.5%",
    title: "",
    type: "path",
  },
  {
    name: "screeny2_to_kolibrie",
    top: "",
    bottom: "55%",
    left: "29%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "venezia-gold_to_screeny2",
    top: "",
    bottom: "37.9%",
    left: "22%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "isola3_to_kedry-skylife",
    top: "",
    bottom: "37.9%",
    left: "",
    right: "44.5%",
    title: "",
    type: "path",
  },
  {
    name: "kolibrie_to_screeny2",
    top: "",
    bottom: "55%",
    left: "14%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "kedry-skylife_to_kolibrie",
    top: "",
    bottom: "37.9%",
    left: "29%",
    right: "",
    title: "",
    type: "path",
  },
  /*{
    name: "xtesa_to_vega_2",
    top: "",
    bottom: "66%",
    left: "",
    right: "12.5%",
    title: "",
    type: "path",
  },*/
  {
    name: "vega_to_hydra",
    top: "",
    bottom: "30%",
    left: "",
    right: "15%",
    title: "",
    type: "path",
  },
  {
    name: "vega_to_kedry-prime",
    top: "",
    bottom: "20%",
    left: "",
    right: "23.8%",
    title: "",
    type: "path",
  },
  {
    name: "hydra_to_kedry-prime",
    top: "",
    bottom: "10%",
    left: "",
    right: "23.8%",
    title: "",
    type: "path",
  },
  {
    name: "vega_to_isola3",
    top: "",
    bottom: "29.8%",
    left: "",
    right: "23.8%",
    title: "",
    type: "path",
  },
];

export const mapPositionsIt = [
  {
    name: "venezia-gold",
    top: "",
    bottom: "16%",
    left: "22%",
    right: "",
    title: "VENEZIA GOLD",
    type: "product",
  },
  {
    name: "qubica-flat",
    top: "",
    bottom: "16%",
    left: "12%",
    right: "",
    title: "QUBICA FLAT B50",
    type: "product",
  },
  {
    name: "kolibrie",
    top: "",
    bottom: "47.5%",
    left: "5%",
    right: "",
    title: "GENNIUS SAILS KOLIBRIE",
    type: "product",
  },
  {
    name: "kheope",
    top: "27.5%",
    bottom: "",
    left: "7.5%",
    right: "",
    title: "GENNIUS SAILS KHEOPE K1",
    type: "product",
  },
  {
    name: "sunlight",
    top: "25.5%",
    bottom: "",
    left: "30.5%",
    right: "",
    title: "SUNLIGHT",
    type: "product",
  },
  {
    name: "sunlight-sotto",
    top: "18.5%",
    bottom: "",
    left: "30.5%",
    right: "",
    title: "SUNLIGHT - SOTTO",
    type: "product",
  },
  {
    name: "screeny1",
    top: "",
    bottom: "55%",
    left: "",
    right: "35%",
    title: "SCREENY 110 GC S12",
    type: "product",
  },
  {
    name: "screeny1-back",
    top: "32%",
    bottom: "",
    left: "",
    right: "35%",
    title: "SCREENY 110 GC S12",
    type: "product",
  },
  {
    name: "screeny2",
    top: "",
    bottom: "55%",
    left: "39%",
    right: "",
    title: "SCREENY 110 GPZ UNICA S32",
    type: "product",
  },
  {
    name: "screeny2-back",
    top: "32%",
    bottom: "",
    left: "39%",
    right: "",
    title: "SCREENY 110 GPZ UNICA S32",
    type: "product",
  },
  {
    name: "isola3",
    top: "41%",
    bottom: "",
    left: "",
    right: "23%",
    title: "ISOLA 3",
    type: "product",
  },
  {
    name: "isola3-sotto",
    top: "",
    bottom: "44.5%",
    left: "",
    right: "34.5%",
    title: "ISOLA 3 - SOTTO",
    type: "product",
  },
  {
    name: "kedry-prime",
    top: "",
    bottom: "9%",
    left: "",
    right: "38%",
    title: "KEDRY PRIME",
    type: "product",
  },
  {
    name: "kedry-prime-sotto",
    top: "",
    bottom: "15.5%",
    left: "",
    right: "33%",
    title: "KEDRY PRIME - SOTTO",
    type: "product",
  },
  {
    name: "hydra",
    top: "",
    bottom: "10%",
    left: "",
    right: "13%",
    title: "HYDRA",
    type: "product",
  },
  {
    name: "vega",
    top: "",
    bottom: "34.5%",
    left: "",
    right: "13%",
    title: "GENNIUS VEGA",
    type: "product",
  },
  {
    name: "xtesa",
    top: "",
    bottom: "69%",
    left: "",
    right: "27%",
    title: "XTESA",
    type: "product",
  },
  {
    name: "keplan",
    top: "",
    bottom: "69%",
    left: "",
    right: "10.5%",
    title: "KEPLAN",
    type: "product",
  },
  {
    name: "kedry-skylife",
    top: "",
    bottom: "25%",
    left: "29.5%",
    right: "",
    title: "KEDRY SKYLIFE",
    type: "product",
  },
  {
    name: "kedry-skylife-sotto",
    top: "",
    bottom: "27%",
    left: "37.5%",
    right: "",
    title: "KEDRY SKYLIFE - SOTTO",
    type: "product",
  },
  {
    name: "entrance",
    top: "",
    bottom: "8%",
    left: "28.5%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "vega_to_xtesa",
    top: "",
    bottom: "42%",
    left: "",
    right: "12.5%",
    title: "",
    type: "path",
  },
  {
    name: "screeny1_to_screeny2",
    top: "",
    bottom: "62%",
    left: "29%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "isola3_to_vega",
    top: "",
    bottom: "35.8%",
    left: "",
    right: "23.8%",
    title: "",
    type: "path",
  },
  {
    name: "qubica_to_kolibrie",
    top: "",
    bottom: "37.9%",
    left: "12%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "xtesa_to_vega_1",
    top: "",
    bottom: "56%",
    left: "",
    right: "12.5%",
    title: "",
    type: "path",
  },
  {
    name: "screeny2_to_kolibrie",
    top: "",
    bottom: "55%",
    left: "29%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "venezia-gold_to_screeny2",
    top: "",
    bottom: "37.9%",
    left: "22%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "isola3_to_kedry-skylife",
    top: "",
    bottom: "37.9%",
    left: "",
    right: "44.5%",
    title: "",
    type: "path",
  },
  {
    name: "kolibrie_to_screeny2",
    top: "",
    bottom: "55%",
    left: "14%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "kedry-skylife_to_kolibrie",
    top: "",
    bottom: "37.9%",
    left: "29%",
    right: "",
    title: "",
    type: "path",
  },
  /*{
    name: "xtesa_to_vega_2",
    top: "",
    bottom: "66%",
    left: "",
    right: "12.5%",
    title: "",
    type: "path",
  },*/
  {
    name: "vega_to_hydra",
    top: "",
    bottom: "30%",
    left: "",
    right: "15%",
    title: "",
    type: "path",
  },
  {
    name: "vega_to_kedry-prime",
    top: "",
    bottom: "20%",
    left: "",
    right: "23.8%",
    title: "",
    type: "path",
  },
  {
    name: "hydra_to_kedry-prime",
    top: "",
    bottom: "10%",
    left: "",
    right: "23.8%",
    title: "",
    type: "path",
  },
  {
    name: "vega_to_isola3",
    top: "",
    bottom: "29.8%",
    left: "",
    right: "23.8%",
    title: "",
    type: "path",
  },
];

export const mapPositionsDe = [
  {
    name: "venezia-gold",
    top: "",
    bottom: "16%",
    left: "22%",
    right: "",
    title: "VENEZIA GOLD",
    type: "product",
  },
  {
    name: "qubica-flat",
    top: "",
    bottom: "16%",
    left: "12%",
    right: "",
    title: "QUBICA FLAT B50",
    type: "product",
  },
  {
    name: "kolibrie",
    top: "",
    bottom: "47.5%",
    left: "5%",
    right: "",
    title: "GENNIUS SAILS KOLIBRIE",
    type: "product",
  },
  {
    name: "kheope",
    top: "27.5%",
    bottom: "",
    left: "7.5%",
    right: "",
    title: "GENNIUS SAILS KHEOPE K1",
    type: "product",
  },
  {
    name: "sunlight",
    top: "25.5%",
    bottom: "",
    left: "30.5%",
    right: "",
    title: "SUNLIGHT",
    type: "product",
  },
  {
    name: "sunlight-sotto",
    top: "18.5%",
    bottom: "",
    left: "30.5%",
    right: "",
    title: "SUNLIGHT - UNTER",
    type: "product",
  },
  {
    name: "screeny1",
    top: "",
    bottom: "55%",
    left: "",
    right: "35%",
    title: "SCREENY 110 GC S12",
    type: "product",
  },
  {
    name: "screeny1-back",
    top: "32%",
    bottom: "",
    left: "",
    right: "35%",
    title: "SCREENY 110 GC S12",
    type: "product",
  },
  {
    name: "screeny2",
    top: "",
    bottom: "55%",
    left: "39%",
    right: "",
    title: "SCREENY 110 GPZ UNICA S32",
    type: "product",
  },
  {
    name: "screeny2-back",
    top: "32%",
    bottom: "",
    left: "39%",
    right: "",
    title: "SCREENY 110 GPZ UNICA S32",
    type: "product",
  },
  {
    name: "isola3",
    top: "41%",
    bottom: "",
    left: "",
    right: "23%",
    title: "ISOLA 3",
    type: "product",
  },
  {
    name: "isola3-sotto",
    top: "",
    bottom: "44.5%",
    left: "",
    right: "34.5%",
    title: "ISOLA 3 - UNTER",
    type: "product",
  },
  {
    name: "kedry-prime",
    top: "",
    bottom: "9%",
    left: "",
    right: "38%",
    title: "KEDRY PRIME",
    type: "product",
  },
  {
    name: "kedry-prime-sotto",
    top: "",
    bottom: "15.5%",
    left: "",
    right: "33%",
    title: "KEDRY PRIME - UNTER",
    type: "product",
  },
  {
    name: "hydra",
    top: "",
    bottom: "10%",
    left: "",
    right: "13%",
    title: "HYDRA",
    type: "product",
  },
  {
    name: "vega",
    top: "",
    bottom: "34.5%",
    left: "",
    right: "13%",
    title: "GENNIUS VEGA",
    type: "product",
  },
  {
    name: "xtesa",
    top: "",
    bottom: "69%",
    left: "",
    right: "27%",
    title: "XTESA",
    type: "product",
  },
  {
    name: "keplan",
    top: "",
    bottom: "69%",
    left: "",
    right: "10.5%",
    title: "KEPLAN",
    type: "product",
  },
  {
    name: "kedry-skylife",
    top: "",
    bottom: "25%",
    left: "29.5%",
    right: "",
    title: "KEDRY SKYLIFE",
    type: "product",
  },
  {
    name: "kedry-skylife-sotto",
    top: "",
    bottom: "27%",
    left: "37.5%",
    right: "",
    title: "KEDRY SKYLIFE - UNTER",
    type: "product",
  },
  {
    name: "entrance",
    top: "",
    bottom: "8%",
    left: "28.5%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "vega_to_xtesa",
    top: "",
    bottom: "42%",
    left: "",
    right: "12.5%",
    title: "",
    type: "path",
  },
  {
    name: "screeny1_to_screeny2",
    top: "",
    bottom: "62%",
    left: "29%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "isola3_to_vega",
    top: "",
    bottom: "35.8%",
    left: "",
    right: "23.8%",
    title: "",
    type: "path",
  },
  {
    name: "qubica_to_kolibrie",
    top: "",
    bottom: "37.9%",
    left: "12%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "xtesa_to_vega_1",
    top: "",
    bottom: "56%",
    left: "",
    right: "12.5%",
    title: "",
    type: "path",
  },
  {
    name: "screeny2_to_kolibrie",
    top: "",
    bottom: "55%",
    left: "29%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "venezia-gold_to_screeny2",
    top: "",
    bottom: "37.9%",
    left: "22%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "isola3_to_kedry-skylife",
    top: "",
    bottom: "37.9%",
    left: "",
    right: "44.5%",
    title: "",
    type: "path",
  },
  {
    name: "kolibrie_to_screeny2",
    top: "",
    bottom: "55%",
    left: "14%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "kedry-skylife_to_kolibrie",
    top: "",
    bottom: "37.9%",
    left: "29%",
    right: "",
    title: "",
    type: "path",
  },
  /*{
    name: "xtesa_to_vega_2",
    top: "",
    bottom: "66%",
    left: "",
    right: "12.5%",
    title: "",
    type: "path",
  },*/
  {
    name: "vega_to_hydra",
    top: "",
    bottom: "30%",
    left: "",
    right: "15%",
    title: "",
    type: "path",
  },
  {
    name: "vega_to_kedry-prime",
    top: "",
    bottom: "20%",
    left: "",
    right: "23.8%",
    title: "",
    type: "path",
  },
  {
    name: "hydra_to_kedry-prime",
    top: "",
    bottom: "10%",
    left: "",
    right: "23.8%",
    title: "",
    type: "path",
  },
  {
    name: "vega_to_isola3",
    top: "",
    bottom: "29.8%",
    left: "",
    right: "23.8%",
    title: "",
    type: "path",
  },
];

export const mapPositionsFr = [
  {
    name: "venezia-gold",
    top: "",
    bottom: "18%",
    left: "23%",
    right: "",
    title: "GENEROSA GOLD",
    type: "product",
  },
  {
    name: "antalia",
    top: "",
    bottom: "28%",
    left: "23%",
    right: "",
    title: "COMPACTO",
    type: "product",
  },
  {
    name: "qubica-lite",
    top: "",
    bottom: "28%",
    left: "13%",
    right: "",
    title: "QUBICA LITE",
    type: "product",
  },
  {
    name: "kolibrie",
    top: "",
    bottom: "45.5%",
    left: "5%",
    right: "",
    title: "GENNIUS SAILS KOLIBRIE",
    type: "product",
  },
  {
    name: "kheope",
    top: "26.5%",
    bottom: "",
    left: "10.5%",
    right: "",
    title: "GENNIUS SAILS KHEOPE K1",
    type: "product",
  },
  {
    name: "sunlight",
    top: "25.5%",
    bottom: "",
    left: "30.5%",
    right: "",
    title: "SUNLIGHT",
    type: "product",
  },
  {
    name: "sunlight-sotto",
    top: "18.5%",
    bottom: "",
    left: "30.5%",
    right: "",
    title: "SUNLIGHT - SOUS",
    type: "product",
  },
  {
    name: "screeny1",
    top: "39%",
    bottom: "",
    left: "",
    right: "35%",
    title: "SCREENY 110 COULISSE",
    type: "product",
  },
  {
    name: "screeny1-back",
    top: "32%",
    bottom: "",
    left: "",
    right: "35%",
    title: "SCREENY 110 COULISSE",
    type: "product",
  },
  {
    name: "screeny2",
    top: "39%",
    bottom: "",
    left: "39%",
    right: "",
    title: "SCREENY 110 COULISSE ZIP",
    type: "product",
  },
  {
    name: "screeny2-back",
    top: "32%",
    bottom: "",
    left: "39%",
    right: "",
    title: "SCREENY 110 COULISSE ZIP",
    type: "product",
  },
  {
    name: "isola3",
    top: "41%",
    bottom: "",
    left: "",
    right: "23%",
    title: "ISOLA 3",
    type: "product",
  },
  {
    name: "isola3-sotto",
    top: "",
    bottom: "46.5%",
    left: "",
    right: "33.5%",
    title: "ISOLA 3 - SOUS",
    type: "product",
  },
  {
    name: "kedry-prime",
    top: "",
    bottom: "10%",
    left: "",
    right: "38%",
    title: "KEDRY PRIME",
    type: "product",
  },
  {
    name: "kedry-prime-sotto",
    top: "",
    bottom: "15.5%",
    left: "",
    right: "33%",
    title: "KEDRY PRIME - SOUS",
    type: "product",
  },
  {
    name: "hydra",
    top: "",
    bottom: "10%",
    left: "",
    right: "11%",
    title: "HYDRAE PLUS",
    type: "product",
  },
  {
    name: "vega",
    top: "",
    bottom: "34.5%",
    left: "",
    right: "13%",
    title: "GENNIUS VEGA",
    type: "product",
  },
  {
    name: "xtesa",
    top: "",
    bottom: "70%",
    left: "",
    right: "27.5%",
    title: "XTESA",
    type: "product",
  },
  {
    name: "kedry-skylife",
    top: "",
    bottom: "27%",
    left: "30%",
    right: "",
    title: "KEDRY SKYLIFE",
    type: "product",
  },
  {
    name: "kedry-skylife-sotto",
    top: "",
    bottom: "29%",
    left: "39.5%",
    right: "",
    title: "KEDRY SKYLIFE - SOUS",
    type: "product",
  },
  {
    name: "entrance",
    top: "",
    bottom: "8%",
    left: "30%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "vega_to_xtesa",
    top: "",
    bottom: "41%",
    left: "",
    right: "12.5%",
    title: "",
    type: "path",
  },
  {
    name: "screeny1_to_screeny2",
    top: "",
    bottom: "62%",
    left: "30%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "isola3_to_vega",
    top: "",
    bottom: "38%",
    left: "",
    right: "23%",
    title: "",
    type: "path",
  },
  {
    name: "qubica_to_kolibrie",
    top: "",
    bottom: "38%",
    left: "13%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "qubica_to_kolibrie2",
    top: "",
    bottom: "38%",
    left: "5%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "xtesa_to_vega_1",
    top: "",
    bottom: "55%",
    left: "",
    right: "12.5%",
    title: "",
    type: "path",
  },
  {
    name: "screeny2_to_kolibrie",
    top: "",
    bottom: "55%",
    left: "29%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "venezia-gold_to_screeny2",
    top: "",
    bottom: "38%",
    left: "22%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "isola3_to_kedry-skylife",
    top: "",
    bottom: "38%",
    left: "",
    right: "42.5%",
    title: "",
    type: "path",
  },
  {
    name: "kolibrie_to_screeny2",
    top: "",
    bottom: "55%",
    left: "14%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "kedry-skylife_to_kolibrie",
    top: "",
    bottom: "37.9%",
    left: "30%",
    right: "",
    title: "",
    type: "path",
  },
  {
    name: "xtesa_to_vega_2",
    top: "",
    bottom: "65%",
    left: "",
    right: "12.5%",
    title: "",
    type: "path",
  },
  {
    name: "vega_to_hydra",
    top: "",
    bottom: "29%",
    left: "",
    right: "13%",
    title: "",
    type: "path",
  },
  {
    name: "vega_to_kedry-prime",
    top: "",
    bottom: "18%",
    left: "",
    right: "23%",
    title: "",
    type: "path",
  },
  {
    name: "hydra_to_kedry-prime",
    top: "",
    bottom: "10%",
    left: "",
    right: "23%",
    title: "",
    type: "path",
  },
  {
    name: "vega_to_isola3",
    top: "",
    bottom: "29.8%",
    left: "",
    right: "23%",
    title: "",
    type: "path",
  },
];

export const getMapPositionsByLang = () => {
  let language = "it";
  //let language = localStorage.getItem(LOCALSTORAGE_LANGUAGE)
  if (typeof window !== `undefined`) {
    language = localStorage.getItem(LOCALSTORAGE_LANGUAGE);
  }
  if (language === "en-us") return mapPositionsUs;
  if (language === "es") return mapPositionsEs;
  if (language === "fr") return mapPositionsFr;
  if (language === "en") return mapPositionsEn;
  if (language === "it") return mapPositionsIt;
  if (language === "de") return mapPositionsDe;
  return mapPositionsIt;
};
