import { TechnicalSpecificsType } from "../types";
import { getImageName } from "../utils/get-image-name";

export const getImagesByTechnicalSpecifics = (
  technicalSpecifics: TechnicalSpecificsType,
  images: Record<string, string>
): string[] => {
  switch (getImageName(technicalSpecifics)) {
    /**
     * VENEZIA GOLD (GENEROSA GOLD)
     */
    case `venezia-gold_aky_day_open`:
      return [
        images["venezia-gold_aky_day_close"],
        images["venezia-gold_balteus_day_open"],
        images["entrance_day"],
        images["kedry-skylife-sotto_black_day_open"],
        images["venezia-gold_to_screeny2_day"],
        images[`venezia-gold_aky_night_open`],
      ];
    case `venezia-gold_aky_day_close`:
      return [
        images["venezia-gold_aky_day_open"],
        images["venezia-gold_balteus_day_close"],
        images["entrance_day"],
        images["kedry-skylife-sotto_black_day_open"],
        images["venezia-gold_to_screeny2_day"],
        images[`venezia-gold_aky_night_close`],
      ];
    case `venezia-gold_balteus_day_open`:
      return [
        images["venezia-gold_aky_day_open"],
        images[`venezia-gold_balteus_day_close`],
        "entrance_day",
        images["kedry-skylife-sotto_black_day_open"],
        images["venezia-gold_to_screeny2_day"],
        images["venezia-gold_aky_night_open"],
      ];
    case `venezia-gold_balteus_day_close`:
      return [
        images["venezia-gold_aky_day_close"],
        images[`venezia-gold_balteus_day_open`],
        images["entrance_day"],
        images["kedry-skylife-sotto_black_day_open"],
        images["venezia-gold_to_screeny2_day"],
        images["venezia-gold_aky_night_close"],
      ];
    case `venezia-gold_aky_night_open`:
      return [
        images[`venezia-gold_aky_night_close`],
        images["entrance_night"],
        images["kedry-skylife-sotto_black_night_open"],
        images["venezia-gold_to_screeny2_night"],
        images[`venezia-gold_aky_day_open`],
      ];
    case `venezia-gold_aky_night_close`:
      return [
        images[`venezia-gold_aky_night_open`],
        images["entrance_night"],
        images["kedry-skylife-sotto_black_night_open"],
        images["venezia-gold_to_screeny2_night"],
        images[`venezia-gold_aky_day_close`],
      ];
    /**
     * QUBICA FLAT
     */
    case `qubica-flat_white_day_open`:
      return [
        images[`qubica-flat_white_night_open`],
        images[`qubica-flat_white_day_close`],
        images[`qubica-flat_gray_day_open`],
        images["venezia-gold_aky_day_close"],
        images["kedry-skylife-sotto_black_day_open"],
        images["qubica_to_kolibrie_day"],
        images["kolibrie_day_close"],
      ];
    case `qubica-flat_white_day_close`:
      return [
        images[`qubica-flat_white_night_close`],
        images[`qubica-flat_white_day_open`],
        images[`qubica-flat_gray_day_close`],
        images["venezia-gold_aky_day_close"],
        images["kedry-skylife-sotto_black_day_open"],
        images["qubica_to_kolibrie_day"],
        images["kolibrie_day_close"],
      ];
    case `qubica-flat_gray_day_open`:
      return [
        images[`qubica-flat_gray_night_open`],
        images[`qubica-flat_gray_day_close`],
        images[`qubica-flat_white_day_open`],
        images["venezia-gold_aky_day_close"],
        images["kedry-skylife-sotto_black_day_open"],
        images["qubica_to_kolibrie_day"],
        images["kolibrie_day_close"],
      ];
    case `qubica-flat_gray_day_close`:
      return [
        images[`qubica-flat_gray_night_close`],
        images[`qubica-flat_gray_day_open`],
        images[`qubica-flat_white_day_close`],
        images["venezia-gold_aky_day_close"],
        images["kedry-skylife-sotto_black_day_open"],
        images["qubica_to_kolibrie_day"],
        images["kolibrie_day_close"],
      ];
    case `qubica-flat_white_night_open`:
      return [
        images[`qubica-flat_white_day_open`],
        images[`qubica-flat_white_night_close`],
        images[`qubica-flat_gray_night_open`],
        images["venezia-gold_aky_night_close"],
        images["kedry-skylife-sotto_black_night_open"],
        images["qubica_to_kolibrie_night"],
        images["kolibrie_night_close"],
      ];
    case `qubica-flat_white_night_close`:
      return [
        images[`qubica-flat_white_day_close`],
        images[`qubica-flat_white_night_open`],
        images[`qubica-flat_gray_night_close`],
        images["venezia-gold_aky_night_close"],
        images["kedry-skylife-sotto_black_night_open"],
        images["qubica_to_kolibrie_night"],
        images["kolibrie_night_close"],
      ];
    case `qubica-flat_gray_night_open`:
      return [
        images[`qubica-flat_gray_day_open`],
        images[`qubica-flat_gray_night_close`],
        images[`qubica-flat_white_night_open`],
        images["venezia-gold_aky_night_close"],
        images["kedry-skylife-sotto_black_night_open"],
        images["qubica_to_kolibrie_night"],
        images["kolibrie_night_close"],
      ];
    case `qubica-flat_gray_night_close`:
      return [
        images[`qubica-flat_gray_day_close`],
        images[`qubica-flat_gray_night_open`],
        images[`qubica-flat_white_night_close`],
        images["venezia-gold_aky_night_close"],
        images["kedry-skylife-sotto_black_night_open"],
        images["qubica_to_kolibrie_night"],
        images["kolibrie_night_close"],
      ];
    /**
     * KOLIBRIE
     */
    case `kolibrie_day_open`:
      return [
        images[`kolibrie_day_close`],
        images[`kolibrie_night_open`],
        images["kheope_day_close"],
        images["kolibrie_to_screeny2_day"],
        images["qubica-flat_white_day_close"],
      ];
    case `kolibrie_day_close`:
      return [
        images[`kolibrie_day_open`],
        images[`kolibrie_night_close`],
        images["kheope_day_close"],
        images["kolibrie_to_screeny2_day"],
        images["qubica-flat_white_day_close"],
      ];
    case `kolibrie_night_open`:
      return [
        images[`kolibrie_night_close`],
        images[`kolibrie_day_open`],
        images["kheope_night_close"],
        images["kolibrie_to_screeny2_night"],
        images["qubica-flat_white_night_close"],
      ];
    case `kolibrie_night_close`:
      return [
        images[`kolibrie_night_open`],
        images[`kolibrie_day_close`],
        images["kheope_night_close"],
        images["kolibrie_to_screeny2_night"],
        images["qubica-flat_white_night_close"],
      ];
    /**
     * KHEOPE
     */
    case `kheope_day_open`:
      return [
        images[`kheope_day_close`],
        images[`kheope_night_open`],
        images["xtesa_black_day_close"],
        images["kolibrie_day_close"],
      ];
    case `kheope_day_close`:
      return [
        images[`kheope_day_open`],
        images[`kheope_night_close`],
        images["xtesa_black_day_close"],
        images["kolibrie_day_close"],
      ];
    case `kheope_night_open`:
      return [
        images[`kheope_night_close`],
        images[`kheope_day_open`],
        images["xtesa_black_night_close"],
        images["kolibrie_night_close"],
      ];
    case `kheope_night_close`:
      return [
        images[`kheope_night_open`],
        images[`kheope_day_close`],
        images["xtesa_black_night_close"],
        images["kolibrie_night_close"],
      ];
    /**
     * XTESA
     */
    case `xtesa_white_day_open`:
      return [
        images[`xtesa_white_day_close`],
        images[`xtesa_black_day_open`],
        images[`xtesa_white_night_open`],
        images["kheope_day_close"],
        images["xtesa_to_vega_2_day"],
      ];
    case `xtesa_white_day_close`:
      return [
        images[`xtesa_white_day_open`],
        images[`xtesa_black_day_close`],
        images[`xtesa_white_night_close`],
        images["kheope_day_close"],
        images["xtesa_to_vega_2_day"],
      ];
    case `xtesa_black_day_open`:
      return [
        images[`xtesa_black_day_close`],
        images[`xtesa_white_day_open`],
        images[`xtesa_black_night_open`],
        images["kheope_day_close"],
        images["xtesa_to_vega_2_day"],
      ];
    case `xtesa_black_day_close`:
      return [
        images[`xtesa_black_day_open`],
        images[`xtesa_white_day_close`],
        images[`xtesa_black_night_close`],
        images["kheope_day_close"],
        images["xtesa_to_vega_2_day"],
      ];
    case `xtesa_white_night_open`:
      return [
        images[`xtesa_white_night_close`],
        images[`xtesa_black_night_open`],
        images[`xtesa_white_day_open`],
        images["kheope_night_close"],
        images["xtesa_to_vega_2_night"],
      ];
    case `xtesa_white_night_close`:
      return [
        images[`xtesa_white_night_open`],
        images[`xtesa_black_night_close`],
        images[`xtesa_white_day_close`],
        images["kheope_night_close"],
        images["xtesa_to_vega_2_night"],
      ];
    case `xtesa_black_night_open`:
      return [
        images[`xtesa_black_night_close`],
        images[`xtesa_white_night_open`],
        images[`xtesa_black_day_open`],
        images["kheope_night_close"],
        images["xtesa_to_vega_2_night"],
      ];
    case `xtesa_black_night_close`:
      return [
        images[`xtesa_black_night_open`],
        images[`xtesa_white_night_close`],
        images[`xtesa_black_day_close`],
        images["kheope_night_close"],
        images["xtesa_to_vega_2_night"],
      ];
    /**
     * SCREENY 1
     */
    case `screeny1_gray_day_close`:
      return [
        images[`screeny1_gray_day_open`],
        images[`screeny1_gray_night_close`],
        images[`screeny1_white_day_close`],
        images["isola3_black_day_close"],
        images["isola3-sotto_black_day_close"],
        images["screeny2_white_day_close"],
      ];
    case `screeny1_white_day_close`:
      return [
        images[`screeny1_white_day_open`],
        images[`screeny1_white_night_close`],
        images[`screeny1_gray_day_close`],
        images["isola3_black_day_close"],
        images["isola3-sotto_black_day_close"],
        images["screeny2_white_day_close"],
      ];
    case `screeny1_gray_day_open`:
      return [
        images[`screeny1_gray_day_close`],
        images[`screeny1_gray_night_open`],
        images[`screeny1_white_day_open`],
        images["isola3_black_day_close"],
        images["isola3-sotto_black_day_close"],
        images["screeny2_white_day_close"],
      ];
    case `screeny1_white_day_open`:
      return [
        images[`screeny1_white_day_close`],
        images[`screeny1_white_night_open`],
        images[`screeny1_gray_day_open`],
        images["isola3_black_day_close"],
        images["isola3-sotto_black_day_close"],
        images["screeny2_white_day_close"],
      ];
    case `screeny1_gray_night_close`:
      return [
        images[`screeny1_gray_night_open`],
        images[`screeny1_gray_day_close`],
        images[`screeny1_white_night_close`],
        images["isola3_black_night_close"],
        images["isola3-sotto_black_night_close"],
        images["screeny2_white_night_close"],
      ];
    case `screeny1_white_night_close`:
      return [
        images[`screeny1_white_night_open`],
        images[`screeny1_white_day_close`],
        images[`screeny1_gray_night_close`],
        images["isola3_black_night_close"],
        images["isola3-sotto_black_night_close"],
        images["screeny2_white_night_close"],
      ];
    case `screeny1_gray_night_open`:
      return [
        images[`screeny1_gray_night_close`],
        images[`screeny1_gray_day_open`],
        images[`screeny1_white_night_open`],
        images["isola3_black_night_close"],
        images["isola3-sotto_black_night_close"],
        images["screeny2_white_night_close"],
      ];
    case `screeny1_white_night_open`:
      return [
        images[`screeny1_white_night_close`],
        images[`screeny1_white_day_open`],
        images[`screeny1_gray_night_open`],
        images["isola3_black_night_close"],
        images["isola3-sotto_black_night_close"],
        images["screeny2_white_night_close"],
      ];
    /**
     * SCREENY 1 BACK
     */
    case `screeny1-back_white_day_close`:
      return [
        images[`screeny1-back_gray_day_open`],
        images[`screeny1-back_gray_night_close`],
        images[`screeny1-back_white_day_close`],
        images["screeny2-back_white_day_close"],
      ];
    case `screeny1-back_gray_day_close`:
      return [
        images[`screeny1-back_white_day_open`],
        images[`screeny1-back_white_night_close`],
        images[`screeny1-back_gray_day_close`],
        images["screeny2-back_white_day_close"],
      ];
    case `screeny1-back_white_day_open`:
      return [
        images[`screeny1-back_gray_day_close`],
        images[`screeny1-back_gray_night_open`],
        images[`screeny1-back_white_day_open`],
        images["screeny2-back_white_day_close"],
      ];
    case `screeny1-back_gray_day_open`:
      return [
        images[`screeny1-back_white_day_close`],
        images[`screeny1-back_white_night_open`],
        images[`screeny1-back_gray_day_open`],
        images["screeny2-back_white_day_close"],
      ];
    case `screeny1-back_white_night_close`:
      return [
        images[`screeny1-back_gray_night_open`],
        images[`screeny1-back_gray_day_close`],
        images[`screeny1-back_white_night_close`],
        images["screeny2-back_white_night_close"],
      ];
    case `screeny1-back_gray_night_close`:
      return [
        images[`screeny1-back_white_night_open`],
        images[`screeny1-back_white_day_close`],
        images[`screeny1-back_gray_night_close`],
        images["screeny2-back_white_night_close"],
      ];
    case `screeny1-back_white_night_open`:
      return [
        images[`screeny1-back_gray_night_close`],
        images[`screeny1-back_gray_day_open`],
        images[`screeny1-back_white_night_open`],
        images["screeny2-back_white_night_close"],
      ];
    case `screeny1-back_gray_night_open`:
      return [
        images[`screeny1-back_white_night_close`],
        images[`screeny1-back_white_day_open`],
        images[`screeny1-back_gray_night_open`],
        images["screeny2-back_white_night_close"],
      ];
    /**
     * SCREENY 2
     */
    case `screeny2_gray_day_close`:
      return [
        images[`screeny2_gray_day_open`],
        images[`screeny2_gray_night_close`],
        images[`screeny2_white_day_close`],
        images["screeny1_white_day_close"],
        images["screeny2_to_kolibrie_day"],
      ];
    case `screeny2_white_day_close`:
      return [
        images[`screeny2_white_day_open`],
        images[`screeny2_white_night_close`],
        images[`screeny2_gray_day_close`],
        images["screeny1_white_day_close"],
        images["screeny2_to_kolibrie_day"],
      ];
    case `screeny2_gray_day_open`:
      return [
        images[`screeny2_gray_day_close`],
        images[`screeny2_gray_night_open`],
        images[`screeny2_white_day_open`],
        images["screeny1_white_day_close"],
        images["screeny2_to_kolibrie_day"],
      ];
    case `screeny2_white_day_open`:
      return [
        images[`screeny2_white_day_close`],
        images[`screeny2_white_night_open`],
        images[`screeny2_gray_day_open`],
        images["screeny1_white_day_close"],
        images["screeny2_to_kolibrie_day"],
      ];
    case `screeny2_gray_night_close`:
      return [
        images[`screeny2_gray_night_open`],
        images[`screeny2_gray_day_close`],
        images[`screeny2_white_night_close`],
        images["screeny1_white_night_close"],
        images["screeny2_to_kolibrie_night"],
      ];
    case `screeny2_white_night_close`:
      return [
        images[`screeny2_white_night_open`],
        images[`screeny2_white_day_close`],
        images[`screeny2_gray_night_close`],
        images["screeny1_white_night_close"],
        images["screeny2_to_kolibrie_night"],
      ];
    case `screeny2_gray_night_open`:
      return [
        images[`screeny2_gray_night_close`],
        images[`screeny2_gray_day_open`],
        images[`screeny2_white_night_open`],
        images["screeny1_white_night_close"],
        images["screeny2_to_kolibrie_night"],
      ];
    case `screeny2_white_night_open`:
      return [
        images[`screeny2_white_night_close`],
        images[`screeny2_white_day_open`],
        images[`screeny2_gray_night_open`],
        images["screeny1_white_night_close"],
        images["screeny2_to_kolibrie_night"],
      ];
    /**
     * SCREENY 2 BACK
     */
    case `screeny2-back_white_day_close`:
      return [
        images[`screeny2-back_gray_day_open`],
        images[`screeny2-back_gray_night_close`],
        images[`screeny2-back_white_day_close`],
        images["screeny1-back_white_day_close"],
        images["screeny1_to_screeny2_day"],
      ];
    case `screeny2-back_gray_day_close`:
      return [
        images[`screeny2-back_white_day_open`],
        images[`screeny2-back_white_night_close`],
        images[`screeny2-back_gray_day_close`],
        images["screeny1-back_white_day_close"],
        images["screeny1_to_screeny2_day"],
      ];
    case `screeny2-back_white_day_open`:
      return [
        images[`screeny2-back_gray_day_close`],
        images[`screeny2-back_gray_night_open`],
        images[`screeny2-back_white_day_open`],
        images["screeny1-back_white_day_close"],
        images["screeny1_to_screeny2_day"],
      ];
    case `screeny2-back_gray_day_open`:
      return [
        images[`screeny2-back_white_day_close`],
        images[`screeny2-back_white_night_open`],
        images[`screeny2-back_gray_day_open`],
        images["screeny1-back_white_day_close"],
        images["screeny1_to_screeny2_day"],
      ];
    case `screeny2-back_white_night_close`:
      return [
        images[`screeny2-back_gray_night_open`],
        images[`screeny2-back_gray_day_close`],
        images[`screeny2-back_white_night_close`],
        images["screeny1-back_white_night_close"],
        images["screeny1_to_screeny2_night"],
      ];
    case `screeny2-back_gray_night_close`:
      return [
        images[`screeny2-back_white_night_open`],
        images[`screeny2-back_white_day_close`],
        images[`screeny2-back_gray_night_close`],
        images["screeny1-back_white_night_close"],
        images["screeny1_to_screeny2_night"],
      ];
    case `screeny2-back_white_night_open`:
      return [
        images[`screeny2-back_gray_night_close`],
        images[`screeny2-back_gray_day_open`],
        images[`screeny2-back_white_night_open`],
        images["screeny1-back_white_night_close"],
        images["screeny1_to_screeny2_night"],
      ];
    case `screeny2-back_gray_night_open`:
      return [
        images[`screeny2-back_white_night_close`],
        images[`screeny2-back_white_day_open`],
        images[`screeny2-back_gray_night_open`],
        images["screeny1-back_white_night_close"],
        images["screeny1_to_screeny2_night"],
      ];
    /**
     * VEGA
     */
    case `vega_black_day_close_glass`:
      return [
        images[`vega_black_day_close`],
        images[`vega_black_night_close_glass`],
        images[`vega_white_day_close_glass`],
        images["vega_to_xtesa_day"],
        images["vega_to_hydra_day"],
      ];
    case `vega_white_day_close_glass`:
      return [
        images[`vega_white_day_close`],
        images[`vega_white_night_close_glass`],
        images[`vega_black_day_close_glass`],
        images["vega_to_xtesa_day"],
        images["vega_to_hydra_day"],
      ];
    case `vega_black_day_close`:
      return [
        images[`vega_black_day_close_glass`],
        images[`vega_black_night_close`],
        images[`vega_white_day_close`],
        images["vega_to_xtesa_day"],
        images["vega_to_hydra_day"],
      ];
    case `vega_white_day_close`:
      return [
        images[`vega_white_day_close_glass`],
        images[`vega_white_night_close`],
        images[`vega_black_day_close`],
        images["vega_to_xtesa_day"],
        images["vega_to_hydra_day"],
      ];
    case `vega_black_night_close_glass`:
      return [
        images[`vega_black_night_close`],
        images[`vega_black_day_close_glass`],
        images[`vega_white_night_close_glass`],
        images["vega_to_xtesa_night"],
        images["vega_to_hydra_night"],
      ];
    case `vega_white_night_close_glass`:
      return [
        images[`vega_white_night_close`],
        images[`vega_white_day_close_glass`],
        images[`vega_black_night_close_glass`],
        images["vega_to_xtesa_night"],
        images["vega_to_hydra_night"],
      ];
    case `vega_black_night_close`:
      return [
        images[`vega_black_night_close_glass`],
        images[`vega_black_day_close`],
        images[`vega_white_night_close`],
        images["vega_to_xtesa_night"],
        images["vega_to_hydra_night"],
      ];
    case `vega_white_night_close`:
      return [
        images[`vega_white_night_close_glass`],
        images[`vega_white_day_close`],
        images[`vega_black_night_close`],
        images["vega_to_xtesa_night"],
        images["vega_to_hydra_night"],
      ];
    /**
     * HYDRA
     */
    case `hydra_black_day_close`:
      return [
        images["hydra_black_night_close"],
        images["hydra_black_day_open"],
        images["hydra_to_kedry-prime_day"],
        images["vega_to_hydra_day"],
      ];
    case `hydra_white_day_close`:
      return [
        images["hydra_white_night_close"],
        images["hydra_white_day_open"],
        images["hydra_to_kedry-prime_day"],
        images["vega_to_hydra_day"],
      ];
    case `hydra_black_day_open`:
      return [
        images["hydra_black_night_open"],
        images["hydra_black_day_close"],
        images["hydra_to_kedry-prime_day"],
        images["vega_to_hydra_day"],
      ];
    case `hydra_white_day_open`:
      return [
        images["hydra_white_night_open"],
        images["hydra_white_day_close"],
        images["hydra_to_kedry-prime_day"],
        images["vega_to_hydra_day"],
      ];
    case `hydra_black_night_close`:
      return [
        images["hydra_black_day_close"],
        images["hydra_black_night_open"],
        images["hydra_to_kedry-prime_night"],
        images["vega_to_hydra_night"],
      ];
    case `hydra_white_night_close`:
      return [
        images["hydra_white_day_close"],
        images["hydra_white_night_open"],
        images["hydra_to_kedry-prime_night"],
        images["vega_to_hydra_night"],
      ];
    case `hydra_black_night_open`:
      return [
        images["hydra_black_day_open"],
        images["hydra_black_night_close"],
        images["hydra_to_kedry-prime_night"],
        images["vega_to_hydra_night"],
      ];
    case `hydra_white_night_open`:
      return [
        images["hydra_white_day_open"],
        images["hydra_white_night_close"],
        images["hydra_to_kedry-prime_night"],
        images["vega_to_hydra_night"],
      ];
    /**
     * ISOLA 3 SOTTO
     */
    case `isola3-sotto_white_day_close_glass_curtain`:
      return [
        images[`isola3-sotto_white_night_close_glass_curtain`],
        images[`isola3-sotto_white_day_close_curtain`],
        images[`isola3-sotto_white_day_close_glass`],
        images[`isola3-sotto_white_day_open_glass_curtain`],
        images[`isola3-sotto_black_day_close_glass_curtain`],
      ];
    case `isola3-sotto_black_day_close_glass_curtain`:
      return [
        images[`isola3-sotto_black_night_close_glass_curtain`],
        images[`isola3-sotto_black_day_close_glass`],
        images[`isola3-sotto_black_day_close_curtain`],
        images[`isola3-sotto_black_day_open_glass_curtain`],
        images[`isola3-sotto_white_day_close_glass_curtain`],
      ];
    case `isola3-sotto_white_day_close_curtain`:
      return [
        images[`isola3-sotto_white_night_close_curtain`],
        images[`isola3-sotto_white_day_close`],
        images[`isola3-sotto_white_day_close_glass_curtain`],
        images[`isola3-sotto_white_day_open_curtain`],
        images[`isola3-sotto_black_day_close_curtain`],
      ];
    case `isola3-sotto_black_day_close_curtain`:
      return [
        images[`isola3-sotto_black_night_close_curtain`],
        images[`isola3-sotto_black_day_close`],
        images[`isola3-sotto_black_day_close_glass_curtain`],
        images[`isola3-sotto_black_day_open_curtain`],
        images[`isola3-sotto_white_day_close_curtain`],
      ];
    case `isola3-sotto_white_day_close_glass`:
      return [
        images[`isola3-sotto_white_night_close_glass`],
        images[`isola3-sotto_white_day_close`],
        images[`isola3-sotto_white_day_close_glass_curtain`],
        images[`isola3-sotto_white_day_open_glass`],
        images[`isola3-sotto_black_day_close_glass`],
      ];
    case `isola3-sotto_black_day_close_glass`:
      return [
        images[`isola3-sotto_black_night_close_glass`],
        images[`isola3-sotto_black_day_close`],
        images[`isola3-sotto_black_day_close_glass_curtain`],
        images[`isola3-sotto_black_day_open_glass`],
        images[`isola3-sotto_white_day_close_glass`],
      ];
    case `isola3-sotto_white_day_close`:
      return [
        images[`isola3-sotto_white_night_close`],
        images[`isola3-sotto_white_day_close_glass`],
        images[`isola3-sotto_white_day_close_curtain`],
        images[`isola3-sotto_white_day_open`],
        images[`isola3-sotto_black_day_close`],
      ];
    case `isola3-sotto_black_day_close`:
      return [
        images[`isola3-sotto_black_night_close`],
        images[`isola3-sotto_black_day_close_glass`],
        images[`isola3-sotto_black_day_close_curtain`],
        images[`isola3-sotto_black_day_open`],
        images[`isola3-sotto_white_day_close`],
      ];
    case `isola3-sotto_white_day_open_glass_curtain`:
      return [
        images[`isola3-sotto_white_night_open_glass_curtain`],
        images[`isola3-sotto_white_day_open_glass`],
        images[`isola3-sotto_white_day_open_curtain`],
        images[`isola3-sotto_white_day_close_glass_curtain`],
        images[`isola3-sotto_black_day_open_glass_curtain`],
      ];
    case `isola3-sotto_black_day_open_glass_curtain`:
      return [
        images[`isola3-sotto_black_night_open_glass_curtain`],
        images[`isola3-sotto_black_day_open_glass`],
        images[`isola3-sotto_black_day_open_curtain`],
        images[`isola3-sotto_black_day_close_glass_curtain`],
        images[`isola3-sotto_white_day_open_glass_curtain`],
      ];
    case `isola3-sotto_white_day_open_curtain`:
      return [
        images[`isola3-sotto_white_night_open_curtain`],
        images[`isola3-sotto_white_day_open`],
        images[`isola3-sotto_white_day_open_glass_curtain`],
        images[`isola3-sotto_white_day_close_curtain`],
        images[`isola3-sotto_black_day_open_curtain`],
      ];
    case `isola3-sotto_black_day_open_curtain`:
      return [
        images[`isola3-sotto_black_night_open_curtain`],
        images[`isola3-sotto_black_day_open`],
        images[`isola3-sotto_black_day_open_glass_curtain`],
        images[`isola3-sotto_black_day_close_curtain`],
        images[`isola3-sotto_white_day_open_curtain`],
      ];
    case `isola3-sotto_white_day_open_glass`:
      return [
        images[`isola3-sotto_white_night_open_glass`],
        images[`isola3-sotto_white_day_open_glass_curtain`],
        images[`isola3-sotto_white_day_open`],
        images[`isola3-sotto_white_day_close_glass`],
        images[`isola3-sotto_black_day_open_glass`],
      ];
    case `isola3-sotto_black_day_open_glass`:
      return [
        images[`isola3-sotto_black_night_open_glass`],
        images[`isola3-sotto_black_day_open_glass_curtain`],
        images[`isola3-sotto_black_day_open`],
        images[`isola3-sotto_black_day_close_glass`],
        images[`isola3-sotto_white_day_open_glass`],
      ];
    case `isola3-sotto_white_day_open`:
      return [
        images[`isola3-sotto_white_night_open`],
        images[`isola3-sotto_white_day_open_curtain`],
        images[`isola3-sotto_white_day_open_glass`],
        images[`isola3-sotto_white_day_close`],
        images[`isola3-sotto_black_day_open`],
      ];
    case `isola3-sotto_black_day_open`:
      return [
        images[`isola3-sotto_black_night_open`],
        images[`isola3-sotto_black_day_open_glass`],
        images[`isola3-sotto_black_day_open_curtain`],
        images[`isola3-sotto_black_day_close`],
        images[`isola3-sotto_white_day_open`],
      ];
    case `isola3-sotto_white_night_close_glass_curtain`:
      return [
        images[`isola3-sotto_white_day_close_glass_curtain`],
        images[`isola3-sotto_white_night_close_glass`],
        images[`isola3-sotto_white_night_close_curtain`],
        images[`isola3-sotto_white_night_open_glass_curtain`],
        images[`isola3-sotto_black_night_close_glass_curtain`],
      ];
    case `isola3-sotto_black_night_close_glass_curtain`:
      return [
        images[`isola3-sotto_black_day_close_glass_curtain`],
        images[`isola3-sotto_black_night_close_glass`],
        images[`isola3-sotto_black_night_close_curtain`],
        images[`isola3-sotto_black_night_open_glass_curtain`],
        images[`isola3-sotto_white_night_close_glass_curtain`],
      ];
    case `isola3-sotto_white_night_close_curtain`:
      return [
        images[`isola3-sotto_white_day_close_curtain`],
        images[`isola3-sotto_white_night_close`],
        images[`isola3-sotto_white_night_close_glass_curtain`],
        images[`isola3-sotto_white_night_open_curtain`],
        images[`isola3-sotto_black_night_close_curtain`],
      ];
    case `isola3-sotto_black_night_close_curtain`:
      return [
        images[`isola3-sotto_black_day_close_curtain`],
        images[`isola3-sotto_black_night_close`],
        images[`isola3-sotto_black_night_close_glass_curtain`],
        images[`isola3-sotto_black_night_open_curtain`],
        images[`isola3-sotto_white_night_close_curtain`],
      ];
    case `isola3-sotto_white_night_close_glass`:
      return [
        images[`isola3-sotto_white_day_close_glass`],
        images[`isola3-sotto_white_night_close_glass_curtain`],
        images[`isola3-sotto_white_night_close`],
        images[`isola3-sotto_white_night_open_glass`],
        images[`isola3-sotto_black_night_close_glass`],
      ];
    case `isola3-sotto_black_night_close_glass`:
      return [
        images[`isola3-sotto_black_day_close_glass`],
        images[`isola3-sotto_black_night_close`],
        images[`isola3-sotto_black_night_close_glass_curtain`],
        images[`isola3-sotto_black_night_open_glass`],
        images[`isola3-sotto_white_night_close_glass`],
      ];
    case `isola3-sotto_white_night_close`:
      return [
        images[`isola3-sotto_white_day_close`],
        images[`isola3-sotto_white_night_close_glass`],
        images[`isola3-sotto_white_night_close_curtain`],
        images[`isola3-sotto_white_night_open`],
        images[`isola3-sotto_black_night_close`],
      ];
    case `isola3-sotto_black_night_close`:
      return [
        images[`isola3-sotto_black_day_close`],
        images[`isola3-sotto_black_night_close_glass`],
        images[`isola3-sotto_black_night_close_curtain`],
        images[`isola3-sotto_black_night_open`],
        images[`isola3-sotto_white_night_close`],
      ];
    case `isola3-sotto_white_night_open_glass_curtain`:
      return [
        images[`isola3-sotto_white_day_open_glass_curtain`],
        images[`isola3-sotto_white_night_open_glass`],
        images[`isola3-sotto_white_night_open_curtain`],
        images[`isola3-sotto_white_night_close_glass_curtain`],
        images[`isola3-sotto_black_night_open_glass_curtain`],
      ];
    case `isola3-sotto_black_night_open_glass_curtain`:
      return [
        images[`isola3-sotto_black_day_open_glass_curtain`],
        images[`isola3-sotto_black_night_open_glass`],
        images[`isola3-sotto_black_night_open_curtain`],
        images[`isola3-sotto_black_night_close_glass_curtain`],
        images[`isola3-sotto_white_night_open_glass_curtain`],
      ];
    case `isola3-sotto_white_night_open_curtain`:
      return [
        images[`isola3-sotto_white_day_open_curtain`],
        images[`isola3-sotto_white_night_open`],
        images[`isola3-sotto_white_night_open_glass_curtain`],
        images[`isola3-sotto_white_night_close_curtain`],
        images[`isola3-sotto_black_night_open_curtain`],
      ];
    case `isola3-sotto_black_night_open_curtain`:
      return [
        images[`isola3-sotto_black_day_open_curtain`],
        images[`isola3-sotto_black_night_open`],
        images[`isola3-sotto_black_night_open_glass_curtain`],
        images[`isola3-sotto_black_night_close_curtain`],
        images[`isola3-sotto_white_night_open_curtain`],
      ];
    case `isola3-sotto_white_night_open_glass`:
      return [
        images[`isola3-sotto_white_day_open_glass`],
        images[`isola3-sotto_white_night_open_glass_curtain`],
        images[`isola3-sotto_white_night_open`],
        images[`isola3-sotto_white_night_close_glass`],
        images[`isola3-sotto_black_night_open_glass`],
      ];
    case `isola3-sotto_black_night_open_glass`:
      return [
        images[`isola3-sotto_black_day_open_glass`],
        images[`isola3-sotto_black_night_open_glass_curtain`],
        images[`isola3-sotto_black_night_open`],
        images[`isola3-sotto_black_night_close_glass`],
        images[`isola3-sotto_white_night_open_glass`],
      ];
    case `isola3-sotto_white_night_open`:
      return [
        images[`isola3-sotto_white_day_open`],
        images[`isola3-sotto_white_night_open_glass`],
        images[`isola3-sotto_white_night_open_curtain`],
        images[`isola3-sotto_white_night_close`],
        images[`isola3-sotto_black_night_open`],
      ];
    case `isola3-sotto_black_night_open`:
      return [
        images[`isola3-sotto_black_day_open`],
        images[`isola3-sotto_black_night_open_glass`],
        images[`isola3-sotto_black_night_open_curtain`],
        images[`isola3-sotto_black_night_close`],
        images[`isola3-sotto_white_night_open`],
      ];
    /**
     * ISOLA 3
     */
    case `isola3_white_day_close_glass_curtain`:
      return [
        images[`isola3_white_night_close_glass_curtain`],
        images[`isola3_white_day_close_curtain`],
        images[`isola3_white_day_close_glass`],
        images[`isola3_white_day_open_glass_curtain`],
        images[`isola3_black_day_close_glass_curtain`],
      ];
    case `isola3_black_day_close_glass_curtain`:
      return [
        images[`isola3_black_night_close_glass_curtain`],
        images[`isola3_black_day_close_glass`],
        images[`isola3_black_day_close_curtain`],
        images[`isola3_black_day_open_glass_curtain`],
        images[`isola3_white_day_close_glass_curtain`],
      ];
    case `isola3_white_day_close_curtain`:
      return [
        images[`isola3_white_night_close_curtain`],
        images[`isola3_white_day_close`],
        images[`isola3_white_day_close_glass_curtain`],
        images[`isola3_white_day_open_curtain`],
        images[`isola3_black_day_close_curtain`],
      ];
    case `isola3_black_day_close_curtain`:
      return [
        images[`isola3_black_night_close_curtain`],
        images[`isola3_black_day_close`],
        images[`isola3_black_day_close_glass_curtain`],
        images[`isola3_black_day_open_curtain`],
        images[`isola3_white_day_close_curtain`],
      ];
    case `isola3_white_day_close_glass`:
      return [
        images[`isola3_white_night_close_glass`],
        images[`isola3_white_day_close`],
        images[`isola3_white_day_close_glass_curtain`],
        images[`isola3_white_day_open_glass`],
        images[`isola3_black_day_close_glass`],
      ];
    case `isola3_black_day_close_glass`:
      return [
        images[`isola3_black_night_close_glass`],
        images[`isola3_black_day_close`],
        images[`isola3_black_day_close_glass_curtain`],
        images[`isola3_black_day_open_glass`],
        images[`isola3_white_day_close_glass`],
      ];
    case `isola3_white_day_close`:
      return [
        images[`isola3_white_night_close`],
        images[`isola3_white_day_close_glass`],
        images[`isola3_white_day_close_curtain`],
        images[`isola3_white_day_open`],
        images[`isola3_black_day_close`],
      ];
    case `isola3_black_day_close`:
      return [
        images[`isola3_black_night_close`],
        images[`isola3_black_day_close_glass`],
        images[`isola3_black_day_close_curtain`],
        images[`isola3_black_day_open`],
        images[`isola3_white_day_close`],
      ];
    case `isola3_white_night_close_glass_curtain`:
      return [
        images[`isola3_white_night_open_glass_curtain`],
        images[`isola3_white_day_open_glass`],
        images[`isola3_white_day_open_curtain`],
        images[`isola3_white_day_close_glass_curtain`],
        images[`isola3_black_day_open_glass_curtain`],
      ];
    case `isola3_black_night_close_glass_curtain`:
      return [
        images[`isola3_black_night_open_glass_curtain`],
        images[`isola3_black_day_open_glass`],
        images[`isola3_black_day_open_curtain`],
        images[`isola3_black_day_close_glass_curtain`],
        images[`isola3_white_day_open_glass_curtain`],
      ];
    case `isola3_white_night_close_curtain`:
      return [
        images[`isola3_white_night_open_curtain`],
        images[`isola3_white_day_open`],
        images[`isola3_white_day_open_glass_curtain`],
        images[`isola3_white_day_close_curtain`],
        images[`isola3_black_day_open_curtain`],
      ];
    case `isola3_black_night_close_curtain`:
      return [
        images[`isola3_black_night_open_curtain`],
        images[`isola3_black_day_open`],
        images[`isola3_black_day_open_glass_curtain`],
        images[`isola3_black_day_close_curtain`],
        images[`isola3_white_day_open_curtain`],
      ];
    case `isola3_white_night_close_glass`:
      return [
        images[`isola3_white_night_open_glass`],
        images[`isola3_white_day_open_glass_curtain`],
        images[`isola3_white_day_open`],
        images[`isola3_white_day_close_glass`],
        images[`isola3_black_day_open_glass`],
      ];
    case `isola3_black_night_close_glass`:
      return [
        images[`isola3_black_night_open_glass`],
        images[`isola3_black_day_open_glass_curtain`],
        images[`isola3_black_day_open`],
        images[`isola3_black_day_close_glass`],
        images[`isola3_white_day_open_glass`],
      ];
    case `isola3_white_night_close`:
      return [
        images[`isola3_white_night_open`],
        images[`isola3_white_day_open_curtain`],
        images[`isola3_white_day_open_glass`],
        images[`isola3_white_day_close`],
        images[`isola3_black_day_open`],
      ];
    case `isola3_black_night_close`:
      return [
        images[`isola3_black_night_open`],
        images[`isola3_black_day_open_glass`],
        images[`isola3_black_day_open_curtain`],
        images[`isola3_black_day_close`],
        images[`isola3_white_day_open`],
      ];
    case `isola3_black_night_open_glass_curtain`:
      return [
        images[`isola3_black_day_open_glass_curtain`],
        images[`isola3_black_night_open_glass`],
        images[`isola3_black_night_open_curtain`],
        images[`isola3_black_night_close_glass_curtain`],
        images[`isola3_white_night_open_glass_curtain`],
      ];
    case `isola3_white_night_open_glass_curtain`:
      return [
        images[`isola3_white_day_open_glass_curtain`],
        images[`isola3_white_night_open_glass`],
        images[`isola3_white_night_open_curtain`],
        images[`isola3_white_night_close_glass_curtain`],
        images[`isola3_black_night_open_glass_curtain`],
      ];
    case `isola3_black_night_open_curtain`:
      return [
        images[`isola3_black_day_open_curtain`],
        images[`isola3_black_night_open`],
        images[`isola3_black_night_open_glass_curtain`],
        images[`isola3_black_night_close_curtain`],
        images[`isola3_white_night_open_curtain`],
      ];
    case `isola3_white_night_open_curtain`:
      return [
        images[`isola3_white_day_open_curtain`],
        images[`isola3_white_night_open`],
        images[`isola3_white_night_open_glass_curtain`],
        images[`isola3_white_night_close_curtain`],
        images[`isola3_black_night_open_curtain`],
      ];
    case `isola3_black_night_open_glass`:
      return [
        images[`isola3_black_day_open_glass`],
        images[`isola3_black_night_open_glass_curtain`],
        images[`isola3_black_night_open`],
        images[`isola3_black_night_close_glass`],
        images[`isola3_white_night_open_glass`],
      ];
    case `isola3_white_night_open_glass`:
      return [
        images[`isola3_white_day_open_glass`],
        images[`isola3_white_night_open_glass_curtain`],
        images[`isola3_white_night_open`],
        images[`isola3_white_night_close_glass`],
        images[`isola3_black_night_open_glass`],
      ];
    case `isola3_black_night_open`:
      return [
        images[`isola3_black_day_open`],
        images[`isola3_black_night_open_glass`],
        images[`isola3_black_night_open_curtain`],
        images[`isola3_black_night_close`],
        images[`isola3_white_night_open`],
      ];
    case `isola3_white_night_open`:
      return [
        images[`isola3_white_day_open`],
        images[`isola3_white_night_open_glass`],
        images[`isola3_white_night_open_curtain`],
        images[`isola3_white_night_close`],
        images[`isola3_black_night_open`],
      ];
    /**
     * KEDRY PRIME
     */
    case `kedry-prime_black_day_close_glass_curtain`:
      return [
        images[`kedry-prime_white_day_close_glass_curtain`],
        images[`kedry-prime_black_day_close_curtain`],
        images[`kedry-prime_black_day_close_glass`],
        images[`kedry-prime_black_night_close_glass_curtain`],
        images["kedry-prime-sotto_black_day_close"],
        images["hydra_black_day_close"],
      ];
    case `kedry-prime_black_day_close_curtain`:
      return [
        images[`kedry-prime_white_day_close_curtain`],
        images[`kedry-prime_black_day_close_glass_curtain`],
        images[`kedry-prime_black_day_close`],
        images[`kedry-prime_black_night_close_curtain`],
        images["kedry-prime-sotto_black_day_close"],
        images["hydra_black_day_close"],
      ];
    case `kedry-prime_white_day_close_glass_curtain`:
      return [
        images[`kedry-prime_black_day_close_glass_curtain`],
        images[`kedry-prime_white_day_close_curtain`],
        images[`kedry-prime_white_day_close_glass`],
        images[`kedry-prime_white_night_close_glass_curtain`],
        images["kedry-prime-sotto_black_day_close"],
        images["hydra_black_day_close"],
      ];
    case `kedry-prime_white_day_close_curtain`:
      return [
        images[`kedry-prime_black_day_close_curtain`],
        images[`kedry-prime_white_day_close_glass_curtain`],
        images[`kedry-prime_white_day_close`],
        images[`kedry-prime_white_night_close_curtain`],
        images["kedry-prime-sotto_black_day_close"],
        images["hydra_black_day_close"],
      ];
    case `kedry-prime_white_day_close_glass`:
      return [
        images[`kedry-prime_black_day_close_glass`],
        images[`kedry-prime_white_day_close`],
        images[`kedry-prime_white_day_close_glass_curtain`],
        images[`kedry-prime_white_night_close_glass`],
        images["kedry-prime-sotto_black_day_close"],
        images["hydra_black_day_close"],
      ];
    case `kedry-prime_black_day_close_glass`:
      return [
        images[`kedry-prime_white_day_close_glass`],
        images[`kedry-prime_black_day_close`],
        images[`kedry-prime_black_day_close_glass_curtain`],
        images[`kedry-prime_black_night_close_glass`],
        images["kedry-prime-sotto_black_day_close"],
        images["hydra_black_day_close"],
      ];
    case `kedry-prime_black_day_close`:
      return [
        images[`kedry-prime_white_day_close`],
        images[`kedry-prime_black_day_close_glass`],
        images[`kedry-prime_black_day_close_curtain`],
        images[`kedry-prime_black_night_close`],
        images["kedry-prime-sotto_black_day_close"],
        images["hydra_black_day_close"],
      ];
    case `kedry-prime_white_day_close`:
      return [
        images[`kedry-prime_black_day_close`],
        images[`kedry-prime_white_day_close_glass`],
        images[`kedry-prime_white_day_close_curtain`],
        images[`kedry-prime_white_night_close`],
        images["kedry-prime-sotto_black_day_close"],
        images["hydra_black_day_close"],
      ];
    case `kedry-prime_black_night_close_glass_curtain`:
      return [
        images[`kedry-prime_white_night_close_glass_curtain`],
        images[`kedry-prime_black_night_close_curtain`],
        images[`kedry-prime_black_night_close_glass`],
        images[`kedry-prime_black_day_close_glass_curtain`],
        images["kedry-prime-sotto_black_day_close"],
        images["hydra_black_night_close"],
      ];
    case `kedry-prime_black_night_close_curtain`:
      return [
        images[`kedry-prime_white_night_close_curtain`],
        images[`kedry-prime_black_night_close_glass_curtain`],
        images[`kedry-prime_black_night_close`],
        images[`kedry-prime_black_day_close_curtain`],
        images["kedry-prime-sotto_black_day_close"],
        images["hydra_black_night_close"],
      ];
    case `kedry-prime_white_night_close_glass_curtain`:
      return [
        images[`kedry-prime_black_night_close_glass_curtain`],
        images[`kedry-prime_white_night_close_curtain`],
        images[`kedry-prime_white_night_close_glass`],
        images[`kedry-prime_white_day_close_glass_curtain`],
        images["kedry-prime-sotto_black_day_close"],
        images["hydra_black_night_close"],
      ];
    case `kedry-prime_white_night_close_curtain`:
      return [
        images[`kedry-prime_black_night_close_curtain`],
        images[`kedry-prime_white_night_close_glass_curtain`],
        images[`kedry-prime_white_night_close`],
        images[`kedry-prime_white_day_close_curtain`],
        images["kedry-prime-sotto_black_day_close"],
        images["hydra_black_night_close"],
      ];
    case `kedry-prime_white_night_close_glass`:
      return [
        images[`kedry-prime_black_night_close_glass`],
        images[`kedry-prime_white_night_close`],
        images[`kedry-prime_white_night_close_glass_curtain`],
        images[`kedry-prime_white_day_close_glass`],
        images["kedry-prime-sotto_black_day_close"],
        images["hydra_black_night_close"],
      ];
    case `kedry-prime_black_night_close_glass`:
      return [
        images[`kedry-prime_white_night_close_glass`],
        images[`kedry-prime_black_night_close`],
        images[`kedry-prime_black_night_close_glass_curtain`],
        images[`kedry-prime_black_day_close_glass`],
        images["kedry-prime-sotto_black_day_close"],
        images["hydra_black_night_close"],
      ];
    case `kedry-prime_black_night_close`:
      return [
        images[`kedry-prime_white_night_close`],
        images[`kedry-prime_black_night_close_glass`],
        images[`kedry-prime_black_night_close_curtain`],
        images[`kedry-prime_black_day_close`],
        images["kedry-prime-sotto_black_day_close"],
        images["hydra_black_night_close"],
      ];
    case `kedry-prime_white_night_close`:
      return [
        images[`kedry-prime_black_night_close`],
        images[`kedry-prime_white_night_close_glass`],
        images[`kedry-prime_white_night_close_curtain`],
        images[`kedry-prime_white_day_close`],
        images["kedry-prime-sotto_black_day_close"],
        images["hydra_black_night_close"],
      ];
    /**
     * KEDRY PRIME SOTTO
     */
    case `kedry-prime-sotto_black_day_open_glass_curtain`:
      return [
        images[`kedry-prime-sotto_white_day_open_glass_curtain`],
        images[`kedry-prime-sotto_black_day_open_curtain`],
        images[`kedry-prime-sotto_black_day_open_glass`],
        images[`kedry-prime-sotto_black_day_close_glass_curtain`],
        images[`kedry-prime-sotto_black_night_open_glass_curtain`],
        images["kedry-prime_black_day_close"],
      ];
    case `kedry-prime-sotto_white_day_open_glass_curtain`:
      return [
        images[`kedry-prime-sotto_black_day_open_glass_curtain`],
        images[`kedry-prime-sotto_white_day_open_curtain`],
        images[`kedry-prime-sotto_white_day_open_glass`],
        images[`kedry-prime-sotto_white_day_close_glass_curtain`],
        images[`kedry-prime-sotto_white_night_open_glass_curtain`],
        images["kedry-prime_black_day_close"],
      ];
    case `kedry-prime-sotto_black_day_close_glass_curtain`:
      return [
        images[`kedry-prime-sotto_white_day_close_glass_curtain`],
        images[`kedry-prime-sotto_black_day_close_curtain`],
        images[`kedry-prime-sotto_black_day_close_glass`],
        images[`kedry-prime-sotto_black_day_open_glass_curtain`],
        images[`kedry-prime-sotto_black_night_close_glass_curtain`],
        images["kedry-prime_black_day_close"],
      ];
    case `kedry-prime-sotto_white_day_close_glass_curtain`:
      return [
        images[`kedry-prime-sotto_black_day_close_glass_curtain`],
        images[`kedry-prime-sotto_white_day_close_curtain`],
        images[`kedry-prime-sotto_white_day_close_glass`],
        images[`kedry-prime-sotto_white_day_open_glass_curtain`],
        images[`kedry-prime-sotto_white_night_close_glass_curtain`],
        images["kedry-prime_black_day_close"],
      ];
    case `kedry-prime-sotto_white_day_close_curtain`:
      return [
        images[`kedry-prime-sotto_black_day_close_curtain`],
        images[`kedry-prime-sotto_white_day_close_glass_curtain`],
        images[`kedry-prime-sotto_white_day_close`],
        images[`kedry-prime-sotto_white_day_open_curtain`],
        images[`kedry-prime-sotto_white_night_close_curtain`],
        images["kedry-prime_black_day_close"],
      ];
    case `kedry-prime-sotto_black_day_close_curtain`:
      return [
        images[`kedry-prime-sotto_white_day_close_curtain`],
        images[`kedry-prime-sotto_black_day_close_glass_curtain`],
        images[`kedry-prime-sotto_black_day_close`],
        images[`kedry-prime-sotto_black_day_open_curtain`],
        images[`kedry-prime-sotto_black_night_close_curtain`],
        images["kedry-prime_black_day_close"],
      ];
    case `kedry-prime-sotto_white_day_open_curtain`:
      return [
        images[`kedry-prime-sotto_black_day_open_curtain`],
        images[`kedry-prime-sotto_white_day_open_glass_curtain`],
        images[`kedry-prime-sotto_white_day_open`],
        images[`kedry-prime-sotto_white_day_close_curtain`],
        images[`kedry-prime-sotto_white_night_open_curtain`],
        images["kedry-prime_black_day_close"],
      ];
    case `kedry-prime-sotto_black_day_open_curtain`:
      return [
        images[`kedry-prime-sotto_white_day_open_curtain`],
        images[`kedry-prime-sotto_black_day_open_glass_curtain`],
        images[`kedry-prime-sotto_black_day_open`],
        images[`kedry-prime-sotto_black_day_close_curtain`],
        images[`kedry-prime-sotto_black_night_open_curtain`],
        images["kedry-prime_black_day_close"],
      ];
    case `kedry-prime-sotto_white_day_close_glass`:
      return [
        images[`kedry-prime-sotto_black_day_close_glass`],
        images[`kedry-prime-sotto_white_day_close`],
        images[`kedry-prime-sotto_white_day_close_glass_curtain`],
        images[`kedry-prime-sotto_white_day_open_glass`],
        images[`kedry-prime-sotto_white_night_close_glass`],
        images["kedry-prime_black_day_close"],
      ];
    case `kedry-prime-sotto_black_day_close_glass`:
      return [
        images[`kedry-prime-sotto_white_day_close_glass`],
        images[`kedry-prime-sotto_black_day_close`],
        images[`kedry-prime-sotto_black_day_close_glass_curtain`],
        images[`kedry-prime-sotto_black_day_open_glass`],
        images[`kedry-prime-sotto_black_night_close_glass`],
        images["kedry-prime_black_day_close"],
      ];
    case `kedry-prime-sotto_white_day_open_glass`:
      return [
        images[`kedry-prime-sotto_black_day_open_glass`],
        images[`kedry-prime-sotto_white_day_open`],
        images[`kedry-prime-sotto_white_day_open_glass_curtain`],
        images[`kedry-prime-sotto_white_day_close_glass`],
        images[`kedry-prime-sotto_white_night_open_glass`],
        images["kedry-prime_black_day_close"],
      ];
    case `kedry-prime-sotto_black_day_open_glass`:
      return [
        images[`kedry-prime-sotto_white_day_open_glass`],
        images[`kedry-prime-sotto_black_day_open`],
        images[`kedry-prime-sotto_black_day_open_glass_curtain`],
        images[`kedry-prime-sotto_black_day_close_glass`],
        images[`kedry-prime-sotto_black_night_open_glass`],
        images["kedry-prime_black_day_close"],
      ];
    case `kedry-prime-sotto_white_day_close`:
      return [
        images[`kedry-prime-sotto_black_day_close`],
        images[`kedry-prime-sotto_white_day_close_glass`],
        images[`kedry-prime-sotto_white_day_close_curtain`],
        images[`kedry-prime-sotto_white_day_open`],
        images[`kedry-prime-sotto_white_night_close`],
        images["kedry-prime_black_day_close"],
      ];
    case `kedry-prime-sotto_black_day_close`:
      return [
        images[`kedry-prime-sotto_white_day_close`],
        images[`kedry-prime-sotto_black_day_close_glass`],
        images[`kedry-prime-sotto_black_day_close_curtain`],
        images[`kedry-prime-sotto_black_day_open`],
        images[`kedry-prime-sotto_black_night_close`],
        images["kedry-prime_black_day_close"],
      ];
    case `kedry-prime-sotto_white_day_open`:
      return [
        images[`kedry-prime-sotto_black_day_open`],
        images[`kedry-prime-sotto_white_day_open_glass`],
        images[`kedry-prime-sotto_white_day_open_curtain`],
        images[`kedry-prime-sotto_white_day_close`],
        images[`kedry-prime-sotto_white_night_open`],
        images["kedry-prime_black_day_close"],
      ];
    case `kedry-prime-sotto_black_day_open`:
      return [
        images[`kedry-prime-sotto_white_day_open`],
        images[`kedry-prime-sotto_black_day_open_glass`],
        images[`kedry-prime-sotto_black_day_open_curtain`],
        images[`kedry-prime-sotto_black_day_close`],
        images[`kedry-prime-sotto_black_night_open`],
        images["kedry-prime_black_day_close"],
      ];
    case `kedry-prime-sotto_black_night_open_glass_curtain`:
      return [
        images[`kedry-prime-sotto_white_night_open_glass_curtain`],
        images[`kedry-prime-sotto_black_night_open_curtain`],
        images[`kedry-prime-sotto_black_night_open_glass`],
        images[`kedry-prime-sotto_black_night_close_glass_curtain`],
        images[`kedry-prime-sotto_black_day_open_glass_curtain`],
        images["kedry-prime_black_night_close"],
      ];
    case `kedry-prime-sotto_white_night_open_glass_curtain`:
      return [
        images[`kedry-prime-sotto_black_night_open_glass_curtain`],
        images[`kedry-prime-sotto_white_night_open_curtain`],
        images[`kedry-prime-sotto_white_night_open_glass`],
        images[`kedry-prime-sotto_white_night_close_glass_curtain`],
        images[`kedry-prime-sotto_white_day_open_glass_curtain`],
        images["kedry-prime_black_night_close"],
      ];
    case `kedry-prime-sotto_black_night_close_glass_curtain`:
      return [
        images[`kedry-prime-sotto_white_night_close_glass_curtain`],
        images[`kedry-prime-sotto_black_night_close_curtain`],
        images[`kedry-prime-sotto_black_night_close_glass`],
        images[`kedry-prime-sotto_black_night_open_glass_curtain`],
        images[`kedry-prime-sotto_black_day_close_glass_curtain`],
        images["kedry-prime_black_night_close"],
      ];
    case `kedry-prime-sotto_white_night_close_glass_curtain`:
      return [
        images[`kedry-prime-sotto_black_night_close_glass_curtain`],
        images[`kedry-prime-sotto_white_night_close_curtain`],
        images[`kedry-prime-sotto_white_night_close_glass`],
        images[`kedry-prime-sotto_white_night_open_glass_curtain`],
        images[`kedry-prime-sotto_white_day_close_glass_curtain`],
        images["kedry-prime_black_night_close"],
      ];
    case `kedry-prime-sotto_white_night_close_curtain`:
      return [
        images[`kedry-prime-sotto_black_night_close_curtain`],
        images[`kedry-prime-sotto_white_night_close_glass_curtain`],
        images[`kedry-prime-sotto_white_night_close`],
        images[`kedry-prime-sotto_white_night_open_curtain`],
        images[`kedry-prime-sotto_white_day_close_curtain`],
        images["kedry-prime_black_night_close"],
      ];
    case `kedry-prime-sotto_black_night_close_curtain`:
      return [
        images[`kedry-prime-sotto_white_night_close_curtain`],
        images[`kedry-prime-sotto_black_night_close_glass_curtain`],
        images[`kedry-prime-sotto_black_night_close`],
        images[`kedry-prime-sotto_black_night_open_curtain`],
        images[`kedry-prime-sotto_black_day_close_curtain`],
        images["kedry-prime_black_night_close"],
      ];
    case `kedry-prime-sotto_white_night_open_curtain`:
      return [
        images[`kedry-prime-sotto_black_night_open_curtain`],
        images[`kedry-prime-sotto_white_night_open_glass_curtain`],
        images[`kedry-prime-sotto_white_night_open`],
        images[`kedry-prime-sotto_white_night_close_curtain`],
        images[`kedry-prime-sotto_white_day_open_curtain`],
        images["kedry-prime_black_night_close"],
      ];
    case `kedry-prime-sotto_black_night_open_curtain`:
      return [
        images[`kedry-prime-sotto_white_night_open_curtain`],
        images[`kedry-prime-sotto_black_night_open_glass_curtain`],
        images[`kedry-prime-sotto_black_night_open`],
        images[`kedry-prime-sotto_black_night_close_curtain`],
        images[`kedry-prime-sotto_black_day_open_curtain`],
        images["kedry-prime_black_night_close"],
      ];
    case `kedry-prime-sotto_white_night_close_glass`:
      return [
        images[`kedry-prime-sotto_black_night_close_glass`],
        images[`kedry-prime-sotto_white_night_close`],
        images[`kedry-prime-sotto_white_night_close_glass_curtain`],
        images[`kedry-prime-sotto_white_night_open_glass`],
        images[`kedry-prime-sotto_white_day_close_glass`],
        images["kedry-prime_black_night_close"],
      ];
    case `kedry-prime-sotto_black_night_close_glass`:
      return [
        images[`kedry-prime-sotto_white_night_close_glass`],
        images[`kedry-prime-sotto_black_night_close`],
        images[`kedry-prime-sotto_black_night_close_glass_curtain`],
        images[`kedry-prime-sotto_black_night_open_glass`],
        images[`kedry-prime-sotto_black_day_close_glass`],
        images["kedry-prime_black_night_close"],
      ];
    case `kedry-prime-sotto_white_night_open_glass`:
      return [
        images[`kedry-prime-sotto_black_night_open_glass`],
        images[`kedry-prime-sotto_white_night_open`],
        images[`kedry-prime-sotto_white_night_open_glass_curtain`],
        images[`kedry-prime-sotto_white_night_close_glass`],
        images[`kedry-prime-sotto_white_day_open_glass`],
        images["kedry-prime_black_night_close"],
      ];
    case `kedry-prime-sotto_black_night_open_glass`:
      return [
        images[`kedry-prime-sotto_white_night_open_glass`],
        images[`kedry-prime-sotto_black_night_open`],
        images[`kedry-prime-sotto_black_night_open_glass_curtain`],
        images[`kedry-prime-sotto_black_night_close_glass`],
        images[`kedry-prime-sotto_black_day_open_glass`],
        images["kedry-prime_black_night_close"],
      ];
    case `kedry-prime-sotto_white_night_close`:
      return [
        images[`kedry-prime-sotto_black_night_close`],
        images[`kedry-prime-sotto_white_night_close_glass`],
        images[`kedry-prime-sotto_white_night_close_curtain`],
        images[`kedry-prime-sotto_white_night_open`],
        images[`kedry-prime-sotto_white_day_close`],
        images["kedry-prime_black_night_close"],
      ];
    case `kedry-prime-sotto_black_night_close`:
      return [
        images[`kedry-prime-sotto_white_night_close`],
        images[`kedry-prime-sotto_black_night_close_glass`],
        images[`kedry-prime-sotto_black_night_close_curtain`],
        images[`kedry-prime-sotto_black_night_open`],
        images[`kedry-prime-sotto_black_day_close`],
        images["kedry-prime_black_night_close"],
      ];
    case `kedry-prime-sotto_white_night_open`:
      return [
        images[`kedry-prime-sotto_black_night_open`],
        images[`kedry-prime-sotto_white_night_open_glass`],
        images[`kedry-prime-sotto_white_night_open_curtain`],
        images[`kedry-prime-sotto_white_night_close`],
        images[`kedry-prime-sotto_white_day_open`],
        images["kedry-prime_black_night_close"],
      ];
    case `kedry-prime-sotto_black_night_open`:
      return [
        images[`kedry-prime-sotto_white_night_open`],
        images[`kedry-prime-sotto_black_night_open_glass`],
        images[`kedry-prime-sotto_black_night_open_curtain`],
        images[`kedry-prime-sotto_black_night_close`],
        images[`kedry-prime-sotto_black_day_open`],
        images["kedry-prime_black_night_close"],
      ];
    /**
     * KEDRY SKYLIFE
     */
    case `kedry-skylife_white_day_close`:
      return [
        images[`kedry-skylife_black_day_close`],
        images[`kedry-skylife_white_night_close`],
        images[`kedry-skylife_white_day_open`],
        images[`kedry-skylife_white_day_close_curtain`],
        images[`kedry-skylife_white_day_close_glass`],
        images["entrance_day"],
        images["kedry-skylife-sotto_black_day_open"],
        images["kedry-skylife_to_kolibrie_day"],
      ];
    case `kedry-skylife_black_day_close`:
      return [
        images[`kedry-skylife_white_day_close`],
        images[`kedry-skylife_black_night_close`],
        images[`kedry-skylife_black_day_open`],
        images[`kedry-skylife_black_day_close_curtain`],
        images[`kedry-skylife_black_day_close_glass`],
        images["entrance_day"],
        images["kedry-skylife-sotto_black_day_open"],
        images["kedry-skylife_to_kolibrie_day"],
      ];
    case `kedry-skylife_white_day_close_glass`:
      return [
        images[`kedry-skylife_black_day_close_glass`],
        images[`kedry-skylife_white_night_close_glass`],
        images[`kedry-skylife_white_day_open_glass`],
        images[`kedry-skylife_white_day_close_glass_curtain`],
        images[`kedry-skylife_white_day_close`],
        images["entrance_day"],
        images["kedry-skylife-sotto_black_day_open"],
        images["kedry-skylife_to_kolibrie_day"],
      ];
    case `kedry-skylife_black_day_close_glass`:
      return [
        images[`kedry-skylife_white_day_close_glass`],
        images[`kedry-skylife_black_night_close_glass`],
        images[`kedry-skylife_black_day_open_glass`],
        images[`kedry-skylife_black_day_close_glass_curtain`],
        images[`kedry-skylife_black_day_close`],
        images["entrance_day"],
        images["kedry-skylife-sotto_black_day_open"],
        images["kedry-skylife_to_kolibrie_day"],
      ];
    case `kedry-skylife_white_day_close_curtain`:
      return [
        images[`kedry-skylife_black_day_close_curtain`],
        images[`kedry-skylife_white_night_close_curtain`],
        images[`kedry-skylife_white_day_open_curtain`],
        images[`kedry-skylife_white_day_close`],
        images[`kedry-skylife_white_day_close_glass_curtain`],
        images["entrance_day"],
        images["kedry-skylife-sotto_black_day_open"],
        images["kedry-skylife_to_kolibrie_day"],
      ];
    case `kedry-skylife_black_day_close_curtain`:
      return [
        images[`kedry-skylife_white_day_close_curtain`],
        images[`kedry-skylife_black_night_close_curtain`],
        images[`kedry-skylife_black_day_open_curtain`],
        images[`kedry-skylife_black_day_close`],
        images[`kedry-skylife_black_day_close_glass_curtain`],
        images["entrance_day"],
        images["kedry-skylife-sotto_black_day_open"],
        images["kedry-skylife_to_kolibrie_day"],
      ];
    case `kedry-skylife_white_day_close_glass_curtain`:
      return [
        images[`kedry-skylife_black_day_close_glass_curtain`],
        images[`kedry-skylife_white_night_close_glass_curtain`],
        images[`kedry-skylife_white_day_open_glass_curtain`],
        images[`kedry-skylife_white_day_close_glass`],
        images[`kedry-skylife_white_day_close_curtain`],
        images["entrance_day"],
        images["kedry-skylife-sotto_black_day_open"],
        images["kedry-skylife_to_kolibrie_day"],
      ];
    case `kedry-skylife_black_day_close_glass_curtain`:
      return [
        images[`kedry-skylife_white_day_close_glass_curtain`],
        images[`kedry-skylife_black_night_close_glass_curtain`],
        images[`kedry-skylife_black_day_open_glass_curtain`],
        images[`kedry-skylife_black_day_close_glass`],
        images[`kedry-skylife_black_day_close_curtain`],
        images["entrance_day"],
        images["kedry-skylife-sotto_black_day_open"],
        images["kedry-skylife_to_kolibrie_day"],
      ];
    case `kedry-skylife_white_day_open`:
      return [
        images[`kedry-skylife_black_day_open`],
        images[`kedry-skylife_white_night_open`],
        images[`kedry-skylife_white_day_close`],
        images[`kedry-skylife_white_day_open_curtain`],
        images[`kedry-skylife_white_day_open_glass`],
        images["entrance_day"],
        images["kedry-skylife-sotto_black_day_open"],
        images["kedry-skylife_to_kolibrie_day"],
      ];
    case `kedry-skylife_black_day_open`:
      return [
        images[`kedry-skylife_white_day_open`],
        images[`kedry-skylife_black_night_open`],
        images[`kedry-skylife_black_day_close`],
        images[`kedry-skylife_black_day_open_curtain`],
        images[`kedry-skylife_black_day_open_glass`],
        images["entrance_day"],
        images["kedry-skylife-sotto_black_day_open"],
        images["kedry-skylife_to_kolibrie_day"],
      ];
    case `kedry-skylife_white_day_open_glass`:
      return [
        images[`kedry-skylife_black_day_open_glass`],
        images[`kedry-skylife_white_night_open_glass`],
        images[`kedry-skylife_white_day_close_glass`],
        images[`kedry-skylife_white_day_open_glass_curtain`],
        images[`kedry-skylife_white_day_open`],
        images["entrance_day"],
        images["kedry-skylife-sotto_black_day_open"],
        images["kedry-skylife_to_kolibrie_day"],
      ];
    case `kedry-skylife_black_day_open_glass`:
      return [
        images[`kedry-skylife_white_day_open_glass`],
        images[`kedry-skylife_black_night_open_glass`],
        images[`kedry-skylife_black_day_close_glass`],
        images[`kedry-skylife_black_day_open_glass_curtain`],
        images[`kedry-skylife_black_day_open`],
        images["entrance_day"],
        images["kedry-skylife-sotto_black_day_open"],
        images["kedry-skylife_to_kolibrie_day"],
      ];
    case `kedry-skylife_white_day_open_curtain`:
      return [
        images[`kedry-skylife_black_day_open_curtain`],
        images[`kedry-skylife_white_night_open_curtain`],
        images[`kedry-skylife_white_day_close_curtain`],
        images[`kedry-skylife_white_day_open`],
        images[`kedry-skylife_white_day_open_glass_curtain`],
        images["entrance_day"],
        images["kedry-skylife-sotto_black_day_open"],
        images["kedry-skylife_to_kolibrie_day"],
      ];
    case `kedry-skylife_black_day_open_curtain`:
      return [
        images[`kedry-skylife_white_day_open_curtain`],
        images[`kedry-skylife_black_night_open_curtain`],
        images[`kedry-skylife_black_day_close_curtain`],
        images[`kedry-skylife_black_day_open`],
        images[`kedry-skylife_black_day_open_glass_curtain`],
        images["entrance_day"],
        images["kedry-skylife-sotto_black_day_open"],
        images["kedry-skylife_to_kolibrie_day"],
      ];
    case `kedry-skylife_white_day_open_glass_curtain`:
      return [
        images[`kedry-skylife_black_day_open_glass_curtain`],
        images[`kedry-skylife_white_night_open_glass_curtain`],
        images[`kedry-skylife_white_day_close_glass_curtain`],
        images[`kedry-skylife_white_day_open_glass`],
        images[`kedry-skylife_white_day_open_curtain`],
        images["entrance_day"],
        images["kedry-skylife-sotto_black_day_open"],
        images["kedry-skylife_to_kolibrie_day"],
      ];
    case `kedry-skylife_black_day_open_glass_curtain`:
      return [
        images[`kedry-skylife_white_day_open_glass_curtain`],
        images[`kedry-skylife_black_night_open_glass_curtain`],
        images[`kedry-skylife_black_day_close_glass_curtain`],
        images[`kedry-skylife_black_day_open_glass`],
        images[`kedry-skylife_black_day_open_curtain`],
        images["entrance_day"],
        images["kedry-skylife-sotto_black_day_open"],
        images["kedry-skylife_to_kolibrie_day"],
      ];
    case `kedry-skylife_white_night_close`:
      return [
        images[`kedry-skylife_black_night_close`],
        images[`kedry-skylife_white_day_close`],
        images[`kedry-skylife_white_night_open`],
        images[`kedry-skylife_white_night_close_curtain`],
        images[`kedry-skylife_white_night_close_glass`],
        images["entrance_night"],
        images["kedry-skylife-sotto_black_night_open"],
        images["kedry-skylife_to_kolibrie_night"],
      ];
    case `kedry-skylife_black_night_close`:
      return [
        images[`kedry-skylife_white_night_close`],
        images[`kedry-skylife_black_day_close`],
        images[`kedry-skylife_black_night_open`],
        images[`kedry-skylife_black_night_close_curtain`],
        images[`kedry-skylife_black_night_close_glass`],
        images["entrance_night"],
        images["kedry-skylife-sotto_black_night_open"],
        images["kedry-skylife_to_kolibrie_night"],
      ];
    case `kedry-skylife_white_night_close_glass`:
      return [
        images[`kedry-skylife_black_night_close_glass`],
        images[`kedry-skylife_white_day_close_glass`],
        images[`kedry-skylife_white_night_open_glass`],
        images[`kedry-skylife_white_night_close_glass_curtain`],
        images[`kedry-skylife_white_night_close`],
        images["entrance_night"],
        images["kedry-skylife-sotto_black_night_open"],
        images["kedry-skylife_to_kolibrie_night"],
      ];
    case `kedry-skylife_black_night_close_glass`:
      return [
        images[`kedry-skylife_white_night_close_glass`],
        images[`kedry-skylife_black_day_close_glass`],
        images[`kedry-skylife_black_night_open_glass`],
        images[`kedry-skylife_black_night_close_glass_curtain`],
        images[`kedry-skylife_black_night_close`],
        images["entrance_night"],
        images["kedry-skylife-sotto_black_night_open"],
        images["kedry-skylife_to_kolibrie_night"],
      ];
    case `kedry-skylife_white_night_close_curtain`:
      return [
        images[`kedry-skylife_black_night_close_curtain`],
        images[`kedry-skylife_white_day_close_curtain`],
        images[`kedry-skylife_white_night_open_curtain`],
        images[`kedry-skylife_white_night_close`],
        images[`kedry-skylife_white_night_close_glass_curtain`],
        images["entrance_night"],
        images["kedry-skylife-sotto_black_night_open"],
        images["kedry-skylife_to_kolibrie_night"],
      ];
    case `kedry-skylife_black_night_close_curtain`:
      return [
        images[`kedry-skylife_white_night_close_curtain`],
        images[`kedry-skylife_black_day_close_curtain`],
        images[`kedry-skylife_black_night_open_curtain`],
        images[`kedry-skylife_black_night_close`],
        images[`kedry-skylife_black_night_close_glass_curtain`],
        images["entrance_night"],
        images["kedry-skylife-sotto_black_night_open"],
        images["kedry-skylife_to_kolibrie_night"],
      ];
    case `kedry-skylife_white_night_close_glass_curtain`:
      return [
        images[`kedry-skylife_black_night_close_glass_curtain`],
        images[`kedry-skylife_white_day_close_glass_curtain`],
        images[`kedry-skylife_white_night_open_glass_curtain`],
        images[`kedry-skylife_white_night_close_glass`],
        images[`kedry-skylife_white_night_close_curtain`],
        images["entrance_night"],
        images["kedry-skylife-sotto_black_night_open"],
        images["kedry-skylife_to_kolibrie_night"],
      ];
    case `kedry-skylife_black_night_close_glass_curtain`:
      return [
        images[`kedry-skylife_white_night_close_glass_curtain`],
        images[`kedry-skylife_black_day_close_glass_curtain`],
        images[`kedry-skylife_black_night_open_glass_curtain`],
        images[`kedry-skylife_black_night_close_glass`],
        images[`kedry-skylife_black_night_close_curtain`],
        images["entrance_night"],
        images["kedry-skylife-sotto_black_night_open"],
        images["kedry-skylife_to_kolibrie_night"],
      ];
    case `kedry-skylife_white_night_open`:
      return [
        images[`kedry-skylife_black_night_open`],
        images[`kedry-skylife_white_day_open`],
        images[`kedry-skylife_white_night_close`],
        images[`kedry-skylife_white_night_open_curtain`],
        images[`kedry-skylife_white_night_open_glass`],
        images["entrance_night"],
        images["kedry-skylife-sotto_black_night_open"],
        images["kedry-skylife_to_kolibrie_night"],
      ];
    case `kedry-skylife_black_night_open`:
      return [
        images[`kedry-skylife_white_night_open`],
        images[`kedry-skylife_black_day_open`],
        images[`kedry-skylife_black_night_close`],
        images[`kedry-skylife_black_night_open_curtain`],
        images[`kedry-skylife_black_night_open_glass`],
        images["entrance_night"],
        images["kedry-skylife-sotto_black_night_open"],
        images["kedry-skylife_to_kolibrie_night"],
      ];
    case `kedry-skylife_white_night_open_glass`:
      return [
        images[`kedry-skylife_black_night_open_glass`],
        images[`kedry-skylife_white_day_open_glass`],
        images[`kedry-skylife_white_night_close_glass`],
        images[`kedry-skylife_white_night_open_glass_curtain`],
        images[`kedry-skylife_white_night_open`],
        images["entrance_night"],
        images["kedry-skylife-sotto_black_night_open"],
        images["kedry-skylife_to_kolibrie_night"],
      ];
    case `kedry-skylife_black_night_open_glass`:
      return [
        images[`kedry-skylife_white_night_open_glass`],
        images[`kedry-skylife_black_day_open_glass`],
        images[`kedry-skylife_black_night_close_glass`],
        images[`kedry-skylife_black_night_open_glass_curtain`],
        images[`kedry-skylife_black_night_open`],
        images["entrance_night"],
        images["kedry-skylife-sotto_black_night_open"],
        images["kedry-skylife_to_kolibrie_night"],
      ];
    case `kedry-skylife_white_night_open_curtain`:
      return [
        images[`kedry-skylife_black_night_open_curtain`],
        images[`kedry-skylife_white_day_open_curtain`],
        images[`kedry-skylife_white_night_close_curtain`],
        images[`kedry-skylife_white_night_open`],
        images[`kedry-skylife_white_night_open_glass_curtain`],
        images["entrance_night"],
        images["kedry-skylife-sotto_black_night_open"],
        images["kedry-skylife_to_kolibrie_night"],
      ];
    case `kedry-skylife_black_night_open_curtain`:
      return [
        images[`kedry-skylife_white_night_open_curtain`],
        images[`kedry-skylife_black_day_open_curtain`],
        images[`kedry-skylife_black_night_close_curtain`],
        images[`kedry-skylife_black_night_open`],
        images[`kedry-skylife_black_night_open_glass_curtain`],
        images["entrance_night"],
        images["kedry-skylife-sotto_black_night_open"],
        images["kedry-skylife_to_kolibrie_night"],
      ];
    case `kedry-skylife_white_night_open_glass_curtain`:
      return [
        images[`kedry-skylife_black_night_open_glass_curtain`],
        images[`kedry-skylife_white_day_open_glass_curtain`],
        images[`kedry-skylife_white_night_close_glass_curtain`],
        images[`kedry-skylife_white_night_open_glass`],
        images[`kedry-skylife_white_night_open_curtain`],
        images["entrance_night"],
        images["kedry-skylife-sotto_black_night_open"],
        images["kedry-skylife_to_kolibrie_night"],
      ];
    case `kedry-skylife_black_night_open_glass_curtain`:
      return [
        images[`kedry-skylife_white_night_open_glass_curtain`],
        images[`kedry-skylife_black_day_open_glass_curtain`],
        images[`kedry-skylife_black_night_close_glass_curtain`],
        images[`kedry-skylife_black_night_open_curtain`],
        images[`kedry-skylife_black_night_open_curtain`],
        images["entrance_night"],
        images["kedry-skylife-sotto_black_night_open"],
        images["kedry-skylife_to_kolibrie_night"],
      ];
    /**
     * KEDRY SKYLIFE SOTTO
     */
    case `kedry-skylife-sotto_black_day_close_glass_curtain`:
      return [
        images[`kedry-skylife-sotto_black_night_close_glass_curtain`],
        images[`kedry-skylife-sotto_white_day_close_glass_curtain`],
        images[`kedry-skylife-sotto_black_day_close_curtain`],
        images[`kedry-skylife-sotto_black_day_close_glass`],
        images[`kedry-skylife-sotto_black_day_semi-open_glass_curtain`],
        images["kedry-skylife_to_kolibrie_day"],
        images["kedry-skylife_black_day_close"],
      ];
    case `kedry-skylife-sotto_white_day_close_glass_curtain`:
      return [
        images[`kedry-skylife-sotto_white_night_close_glass_curtain`],
        images[`kedry-skylife-sotto_black_day_close_glass_curtain`],
        images[`kedry-skylife-sotto_white_day_close_curtain`],
        images[`kedry-skylife-sotto_white_day_close_glass`],
        images[`kedry-skylife-sotto_white_day_semi-open_glass_curtain`],
        images["kedry-skylife_to_kolibrie_day"],
        images["kedry-skylife_black_day_close"],
      ];
    case `kedry-skylife-sotto_white_day_close_curtain`:
      return [
        images[`kedry-skylife-sotto_white_night_close_curtain`],
        images[`kedry-skylife-sotto_black_day_close_curtain`],
        images[`kedry-skylife-sotto_white_day_close_glass_curtain`],
        images[`kedry-skylife-sotto_white_day_close`],
        images[`kedry-skylife-sotto_white_day_semi-open_curtain`],
        images["kedry-skylife_to_kolibrie_day"],
        images["kedry-skylife_black_day_close"],
      ];
    case `kedry-skylife-sotto_black_day_close_curtain`:
      return [
        images[`kedry-skylife-sotto_black_night_close_curtain`],
        images[`kedry-skylife-sotto_white_day_close_curtain`],
        images[`kedry-skylife-sotto_black_day_close_glass_curtain`],
        images[`kedry-skylife-sotto_black_day_close`],
        images[`kedry-skylife-sotto_black_day_semi-open_curtain`],
        images["kedry-skylife_to_kolibrie_day"],
        images["kedry-skylife_black_day_close"],
      ];
    case `kedry-skylife-sotto_white_day_close_glass`:
      return [
        images[`kedry-skylife-sotto_white_night_close_glass`],
        images[`kedry-skylife-sotto_black_day_close_glass`],
        images[`kedry-skylife-sotto_white_day_close`],
        images[`kedry-skylife-sotto_white_day_close_glass_curtain`],
        images[`kedry-skylife-sotto_white_day_semi-open_glass`],
        images["kedry-skylife_to_kolibrie_day"],
        images["kedry-skylife_black_day_close"],
      ];
    case `kedry-skylife-sotto_black_day_close_glass`:
      return [
        images[`kedry-skylife-sotto_black_night_close_glass`],
        images[`kedry-skylife-sotto_white_day_close_glass`],
        images[`kedry-skylife-sotto_black_day_close`],
        images[`kedry-skylife-sotto_black_day_close_glass_curtain`],
        images[`kedry-skylife-sotto_black_day_semi-open_glass`],
        images["kedry-skylife_to_kolibrie_day"],
        images["kedry-skylife_black_day_close"],
      ];
    case `kedry-skylife-sotto_white_day_close`:
      return [
        images[`kedry-skylife-sotto_white_night_close`],
        images[`kedry-skylife-sotto_black_day_close`],
        images[`kedry-skylife-sotto_white_day_close_glass`],
        images[`kedry-skylife-sotto_white_day_close_curtain`],
        images[`kedry-skylife-sotto_white_day_semi-open`],
        images["kedry-skylife_to_kolibrie_day"],
        images["kedry-skylife_black_day_close"],
      ];
    case `kedry-skylife-sotto_black_day_close`:
      return [
        images[`kedry-skylife-sotto_black_night_close`],
        images[`kedry-skylife-sotto_white_day_close`],
        images[`kedry-skylife-sotto_black_day_close_glass`],
        images[`kedry-skylife-sotto_black_day_close_curtain`],
        images[`kedry-skylife-sotto_black_day_semi-open`],
        images["kedry-skylife_to_kolibrie_day"],
        images["kedry-skylife_black_day_close"],
      ];
    case `kedry-skylife-sotto_white_day_semi-open_glass_curtain`:
      return [
        images[`kedry-skylife-sotto_white_night_semi-open_glass_curtain`],
        images[`kedry-skylife-sotto_black_day_semi-open_glass_curtain`],
        images[`kedry-skylife-sotto_white_day_semi-open_curtain`],
        images[`kedry-skylife-sotto_white_day_semi-open_glass`],
        images[`kedry-skylife-sotto_white_day_open_glass_curtain`],
        images[`kedry-skylife-sotto_white_day_close_glass_curtain`],
        images["kedry-skylife_to_kolibrie_day"],
        images["kedry-skylife_black_day_close"],
      ];
    case `kedry-skylife-sotto_black_day_semi-open_glass_curtain`:
      return [
        images[`kedry-skylife-sotto_black_night_semi-open_glass_curtain`],
        images[`kedry-skylife-sotto_white_day_semi-open_glass_curtain`],
        images[`kedry-skylife-sotto_black_day_semi-open_curtain`],
        images[`kedry-skylife-sotto_black_day_semi-open_glass`],
        images[`kedry-skylife-sotto_black_day_open_glass_curtain`],
        images[`kedry-skylife-sotto_black_day_close_glass_curtain`],
        images["kedry-skylife_to_kolibrie_day"],
        images["kedry-skylife_black_day_close"],
      ];
    case `kedry-skylife-sotto_white_day_semi-open_curtain`:
      return [
        images[`kedry-skylife-sotto_white_night_semi-open_curtain`],
        images[`kedry-skylife-sotto_black_day_semi-open_curtain`],
        images[`kedry-skylife-sotto_white_day_semi-open_glass_curtain`],
        images[`kedry-skylife-sotto_white_day_semi-open`],
        images[`kedry-skylife-sotto_white_day_open_curtain`],
        images[`kedry-skylife-sotto_white_day_close_curtain`],
        images["kedry-skylife_to_kolibrie_day"],
        images["kedry-skylife_black_day_close"],
      ];
    case `kedry-skylife-sotto_black_day_semi-open_curtain`:
      return [
        images[`kedry-skylife-sotto_black_night_semi-open_curtain`],
        images[`kedry-skylife-sotto_white_day_semi-open_curtain`],
        images[`kedry-skylife-sotto_black_day_semi-open_glass_curtain`],
        images[`kedry-skylife-sotto_black_day_semi-open`],
        images[`kedry-skylife-sotto_black_day_open_curtain`],
        images[`kedry-skylife-sotto_black_day_close_curtain`],
        images["kedry-skylife_to_kolibrie_day"],
        images["kedry-skylife_black_day_close"],
      ];
    case `kedry-skylife-sotto_black_day_semi-open_glass`:
      return [
        images[`kedry-skylife-sotto_black_night_semi-open_glass`],
        images[`kedry-skylife-sotto_white_day_semi-open_glass`],
        images[`kedry-skylife-sotto_black_day_semi-open`],
        images[`kedry-skylife-sotto_black_day_semi-open_glass_curtain`],
        images[`kedry-skylife-sotto_black_day_open_glass`],
        images[`kedry-skylife-sotto_black_day_close_glass`],
        images["kedry-skylife_to_kolibrie_day"],
        images["kedry-skylife_black_day_close"],
      ];
    case `kedry-skylife-sotto_white_day_semi-open_glass`:
      return [
        images[`kedry-skylife-sotto_white_night_semi-open_glass`],
        images[`kedry-skylife-sotto_black_day_semi-open_glass`],
        images[`kedry-skylife-sotto_white_day_semi-open`],
        images[`kedry-skylife-sotto_white_day_semi-open_glass_curtain`],
        images[`kedry-skylife-sotto_white_day_open_glass`],
        images[`kedry-skylife-sotto_white_day_close_glass`],
        images["kedry-skylife_to_kolibrie_day"],
        images["kedry-skylife_black_day_close"],
      ];
    case `kedry-skylife-sotto_white_day_semi-open`:
      return [
        images[`kedry-skylife-sotto_white_night_semi-open`],
        images[`kedry-skylife-sotto_black_day_semi-open`],
        images[`kedry-skylife-sotto_white_day_semi-open_glass`],
        images[`kedry-skylife-sotto_white_day_semi-open_curtain`],
        images[`kedry-skylife-sotto_white_day_open`],
        images[`kedry-skylife-sotto_white_day_close`],
        images["kedry-skylife_to_kolibrie_day"],
        images["kedry-skylife_black_day_close"],
      ];
    case `kedry-skylife-sotto_black_day_semi-open`:
      return [
        images[`kedry-skylife-sotto_black_night_semi-open`],
        images[`kedry-skylife-sotto_white_day_semi-open`],
        images[`kedry-skylife-sotto_black_day_semi-open_glass`],
        images[`kedry-skylife-sotto_black_day_semi-open_curtain`],
        images[`kedry-skylife-sotto_black_day_open`],
        images[`kedry-skylife-sotto_black_day_close`],
        images["kedry-skylife_to_kolibrie_day"],
        images["kedry-skylife_black_day_close"],
      ];
    case `kedry-skylife-sotto_black_day_open_glass_curtain`:
      return [
        images[`kedry-skylife-sotto_black_night_open_glass_curtain`],
        images[`kedry-skylife-sotto_white_day_open_glass_curtain`],
        images[`kedry-skylife-sotto_black_day_open_glass_curtain_glass`],
        images[`kedry-skylife-sotto_black_day_open_glass_curtain_curtain`],
        images[`kedry-skylife-sotto_black_day_semi-open_glass_curtain`],
        images["kedry-skylife_to_kolibrie_day"],
        images["kedry-skylife_black_day_close"],
      ];
    case `kedry-skylife-sotto_white_day_open_glass_curtain`:
      return [
        images[`kedry-skylife-sotto_white_night_open_glass_curtain`],
        images[`kedry-skylife-sotto_black_day_open_glass_curtain`],
        images[`kedry-skylife-sotto_white_day_open_curtain`],
        images[`kedry-skylife-sotto_white_day_open_glass`],
        images[`kedry-skylife-sotto_white_day_semi-open_glass_curtain`],
        images["kedry-skylife_to_kolibrie_day"],
        images["kedry-skylife_black_day_close"],
      ];
    case `kedry-skylife-sotto_black_day_open_curtain`:
      return [
        images[`kedry-skylife-sotto_black_night_open_curtain`],
        images[`kedry-skylife-sotto_white_day_open_curtain`],
        images[`kedry-skylife-sotto_black_day_open_glass_curtain`],
        images[`kedry-skylife-sotto_black_day_open`],
        images[`kedry-skylife-sotto_black_day_semi-open_curtain`],
        images["kedry-skylife_to_kolibrie_day"],
        images["kedry-skylife_black_day_close"],
      ];
    case `kedry-skylife-sotto_white_day_open_curtain`:
      return [
        images[`kedry-skylife-sotto_white_night_open_curtain`],
        images[`kedry-skylife-sotto_black_day_open_curtain`],
        images[`kedry-skylife-sotto_white_day_open_glass_curtain`],
        images[`kedry-skylife-sotto_white_day_open`],
        images[`kedry-skylife-sotto_white_day_semi-open_curtain`],
        images["kedry-skylife_to_kolibrie_day"],
        images["kedry-skylife_black_day_close"],
      ];
    case `kedry-skylife-sotto_white_day_open_glass`:
      return [
        images[`kedry-skylife-sotto_white_night_open_glass`],
        images[`kedry-skylife-sotto_black_day_open_glass`],
        images[`kedry-skylife-sotto_white_day_open`],
        images[`kedry-skylife-sotto_white_day_open_glass_curtain`],
        images[`kedry-skylife-sotto_white_day_semi-open_glass`],
        images["kedry-skylife_to_kolibrie_day"],
        images["kedry-skylife_black_day_close"],
      ];
    case `kedry-skylife-sotto_black_day_open_glass`:
      return [
        images[`kedry-skylife-sotto_black_night_open_glass`],
        images[`kedry-skylife-sotto_white_day_open_glass`],
        images[`kedry-skylife-sotto_black_day_open`],
        images[`kedry-skylife-sotto_black_day_open_glass_curtain`],
        images[`kedry-skylife-sotto_black_day_semi-open_glass`],
        images["kedry-skylife_to_kolibrie_day"],
        images["kedry-skylife_black_day_close"],
      ];
    case `kedry-skylife-sotto_black_day_open`:
      return [
        images[`kedry-skylife-sotto_black_night_open`],
        images[`kedry-skylife-sotto_white_day_open`],
        images[`kedry-skylife-sotto_black_day_open_glass`],
        images[`kedry-skylife-sotto_black_day_open_curtain`],
        images[`kedry-skylife-sotto_black_day_semi-open`],
        images["kedry-skylife_to_kolibrie_day"],
        images["kedry-skylife_black_day_close"],
      ];
    case `kedry-skylife-sotto_white_day_open`:
      return [
        images[`kedry-skylife-sotto_white_night_open`],
        images[`kedry-skylife-sotto_black_day_open`],
        images[`kedry-skylife-sotto_white_day_open_glass`],
        images[`kedry-skylife-sotto_white_day_open_curtain`],
        images[`kedry-skylife-sotto_white_day_semi-open`],
        images["kedry-skylife_to_kolibrie_day"],
        images["kedry-skylife_black_day_close"],
      ];
    case `kedry-skylife-sotto_black_night_close_glass_curtain`:
      return [
        images[`kedry-skylife-sotto_black_day_close_glass_curtain`],
        images[`kedry-skylife-sotto_white_night_close_glass_curtain`],
        images[`kedry-skylife-sotto_black_night_close_curtain`],
        images[`kedry-skylife-sotto_black_night_close_glass`],
        images[`kedry-skylife-sotto_black_night_semi-open_glass_curtain`],
        images["kedry-skylife_to_kolibrie_night"],
        images["kedry-skylife_black_night_close"],
      ];
    case `kedry-skylife-sotto_white_night_close_glass_curtain`:
      return [
        images[`kedry-skylife-sotto_white_day_close_glass_curtain`],
        images[`kedry-skylife-sotto_black_night_close_glass_curtain`],
        images[`kedry-skylife-sotto_white_night_close_curtain`],
        images[`kedry-skylife-sotto_white_night_close_glass`],
        images[`kedry-skylife-sotto_white_night_semi-open_glass_curtain`],
        images["kedry-skylife_to_kolibrie_night"],
        images["kedry-skylife_black_night_close"],
      ];
    case `kedry-skylife-sotto_white_night_close_curtain`:
      return [
        images[`kedry-skylife-sotto_white_day_close_curtain`],
        images[`kedry-skylife-sotto_black_night_close_curtain`],
        images[`kedry-skylife-sotto_white_night_close_glass_curtain`],
        images[`kedry-skylife-sotto_white_night_close`],
        images[`kedry-skylife-sotto_white_night_semi-open_curtain`],
        images["kedry-skylife_to_kolibrie_night"],
        images["kedry-skylife_black_night_close"],
      ];
    case `kedry-skylife-sotto_black_night_close_curtain`:
      return [
        images[`kedry-skylife-sotto_black_day_close_curtain`],
        images[`kedry-skylife-sotto_white_night_close_curtain`],
        images[`kedry-skylife-sotto_black_night_close_glass_curtain`],
        images[`kedry-skylife-sotto_black_night_close`],
        images[`kedry-skylife-sotto_black_night_semi-open_curtain`],
        images["kedry-skylife_to_kolibrie_night"],
        images["kedry-skylife_black_night_close"],
      ];
    case `kedry-skylife-sotto_white_night_close_glass`:
      return [
        images[`kedry-skylife-sotto_white_day_close_glass`],
        images[`kedry-skylife-sotto_black_night_close_glass`],
        images[`kedry-skylife-sotto_white_night_close`],
        images[`kedry-skylife-sotto_white_night_close_glass_curtain`],
        images[`kedry-skylife-sotto_white_night_semi-open_glass`],
        images["kedry-skylife_to_kolibrie_night"],
        images["kedry-skylife_black_night_close"],
      ];
    case `kedry-skylife-sotto_black_night_close_glass`:
      return [
        images[`kedry-skylife-sotto_black_day_close_glass`],
        images[`kedry-skylife-sotto_white_night_close_glass`],
        images[`kedry-skylife-sotto_black_night_close`],
        images[`kedry-skylife-sotto_black_night_close_glass_curtain`],
        images[`kedry-skylife-sotto_black_night_semi-open_glass`],
        images["kedry-skylife_to_kolibrie_night"],
        images["kedry-skylife_black_night_close"],
      ];
    case `kedry-skylife-sotto_white_night_close`:
      return [
        images[`kedry-skylife-sotto_white_day_close`],
        images[`kedry-skylife-sotto_black_night_close`],
        images[`kedry-skylife-sotto_white_night_close_glass`],
        images[`kedry-skylife-sotto_white_night_close_curtain`],
        images[`kedry-skylife-sotto_white_night_semi-open`],
        images["kedry-skylife_to_kolibrie_night"],
        images["kedry-skylife_black_night_close"],
      ];
    case `kedry-skylife-sotto_black_night_close`:
      return [
        images[`kedry-skylife-sotto_black_day_close`],
        images[`kedry-skylife-sotto_white_night_close`],
        images[`kedry-skylife-sotto_black_night_close_glass`],
        images[`kedry-skylife-sotto_black_night_close_curtain`],
        images[`kedry-skylife-sotto_black_night_semi-open`],
        images["kedry-skylife_to_kolibrie_night"],
        images["kedry-skylife_black_night_close"],
      ];
    case `kedry-skylife-sotto_white_night_semi-open_glass_curtain`:
      return [
        images[`kedry-skylife-sotto_white_day_semi-open_glass_curtain`],
        images[`kedry-skylife-sotto_black_night_semi-open_glass_curtain`],
        images[`kedry-skylife-sotto_white_night_semi-open_curtain`],
        images[`kedry-skylife-sotto_white_night_semi-open_glass`],
        images[`kedry-skylife-sotto_white_night_open_glass_curtain`],
        images[`kedry-skylife-sotto_white_night_close_glass_curtain`],
        images["kedry-skylife_to_kolibrie_night"],
        images["kedry-skylife_black_night_close"],
      ];
    case `kedry-skylife-sotto_black_night_semi-open_glass_curtain`:
      return [
        images[`kedry-skylife-sotto_black_day_semi-open_glass_curtain`],
        images[`kedry-skylife-sotto_white_night_semi-open_glass_curtain`],
        images[`kedry-skylife-sotto_black_night_semi-open_curtain`],
        images[`kedry-skylife-sotto_black_night_semi-open_glass`],
        images[`kedry-skylife-sotto_black_night_open_glass_curtain`],
        images[`kedry-skylife-sotto_black_night_close_glass_curtain`],
        images["kedry-skylife_to_kolibrie_night"],
        images["kedry-skylife_black_night_close"],
      ];
    case `kedry-skylife-sotto_white_night_semi-open_curtain`:
      return [
        images[`kedry-skylife-sotto_white_day_semi-open_curtain`],
        images[`kedry-skylife-sotto_black_night_semi-open_curtain`],
        images[`kedry-skylife-sotto_white_night_semi-open_glass_curtain`],
        images[`kedry-skylife-sotto_white_night_semi-open`],
        images[`kedry-skylife-sotto_white_night_open_curtain`],
        images[`kedry-skylife-sotto_white_night_close_curtain`],
        images["kedry-skylife_to_kolibrie_night"],
        images["kedry-skylife_black_night_close"],
      ];
    case `kedry-skylife-sotto_black_night_semi-open_curtain`:
      return [
        images[`kedry-skylife-sotto_black_day_semi-open_curtain`],
        images[`kedry-skylife-sotto_white_night_semi-open_curtain`],
        images[`kedry-skylife-sotto_black_night_semi-open_glass_curtain`],
        images[`kedry-skylife-sotto_black_night_semi-open`],
        images[`kedry-skylife-sotto_black_night_open_curtain`],
        images[`kedry-skylife-sotto_black_night_close_curtain`],
        images["kedry-skylife_to_kolibrie_night"],
        images["kedry-skylife_black_night_close"],
      ];
    case `kedry-skylife-sotto_black_night_semi-open_glass`:
      return [
        images[`kedry-skylife-sotto_black_day_semi-open_glass`],
        images[`kedry-skylife-sotto_white_night_semi-open_glass`],
        images[`kedry-skylife-sotto_black_night_semi-open`],
        images[`kedry-skylife-sotto_black_night_semi-open_glass_curtain`],
        images[`kedry-skylife-sotto_black_night_open_glass`],
        images[`kedry-skylife-sotto_black_night_close_glass`],
        images["kedry-skylife_to_kolibrie_night"],
        images["kedry-skylife_black_night_close"],
      ];
    case `kedry-skylife-sotto_white_night_semi-open_glass`:
      return [
        images[`kedry-skylife-sotto_white_day_semi-open_glass`],
        images[`kedry-skylife-sotto_black_night_semi-open_glass`],
        images[`kedry-skylife-sotto_white_night_semi-open`],
        images[`kedry-skylife-sotto_white_night_semi-open_glass_curtain`],
        images[`kedry-skylife-sotto_white_night_open_glass`],
        images[`kedry-skylife-sotto_white_night_close_glass`],
        images["kedry-skylife_to_kolibrie_night"],
        images["kedry-skylife_black_night_close"],
      ];
    case `kedry-skylife-sotto_white_night_semi-open`:
      return [
        images[`kedry-skylife-sotto_white_day_semi-open`],
        images[`kedry-skylife-sotto_black_night_semi-open`],
        images[`kedry-skylife-sotto_white_night_semi-open_glass`],
        images[`kedry-skylife-sotto_white_night_semi-open_curtain`],
        images[`kedry-skylife-sotto_white_night_open`],
        images[`kedry-skylife-sotto_white_night_close`],
        images["kedry-skylife_to_kolibrie_night"],
        images["kedry-skylife_black_night_close"],
      ];
    case `kedry-skylife-sotto_black_night_semi-open`:
      return [
        images[`kedry-skylife-sotto_black_day_semi-open`],
        images[`kedry-skylife-sotto_white_night_semi-open`],
        images[`kedry-skylife-sotto_black_night_semi-open_glass`],
        images[`kedry-skylife-sotto_black_night_semi-open_curtain`],
        images[`kedry-skylife-sotto_black_night_open`],
        images[`kedry-skylife-sotto_black_night_close`],
        images["kedry-skylife_to_kolibrie_night"],
        images["kedry-skylife_black_night_close"],
      ];
    case `kedry-skylife-sotto_black_night_open_glass_curtain`:
      return [
        images[`kedry-skylife-sotto_black_day_open_glass_curtain`],
        images[`kedry-skylife-sotto_white_night_open_glass_curtain`],
        images[`kedry-skylife-sotto_black_night_open_curtain`],
        images[`kedry-skylife-sotto_black_night_open_glass`],
        images[`kedry-skylife-sotto_black_night_semi-open_glass_curtain`],
        images["kedry-skylife_to_kolibrie_night"],
        images["kedry-skylife_black_night_close"],
      ];
    case `kedry-skylife-sotto_white_night_open_glass_curtain`:
      return [
        images[`kedry-skylife-sotto_white_day_open_glass_curtain`],
        images[`kedry-skylife-sotto_black_night_open_glass_curtain`],
        images[`kedry-skylife-sotto_white_night_open_curtain`],
        images[`kedry-skylife-sotto_white_night_open_glass`],
        images[`kedry-skylife-sotto_white_night_semi-open_glass_curtain`],
        images["kedry-skylife_to_kolibrie_night"],
        images["kedry-skylife_black_night_close"],
      ];
    case `kedry-skylife-sotto_black_night_open_curtain`:
      return [
        images[`kedry-skylife-sotto_black_night_open_curtain`],
        images[`kedry-skylife-sotto_white_night_open_curtain`],
        images[`kedry-skylife-sotto_black_night_open_glass_curtain`],
        images[`kedry-skylife-sotto_black_night_open`],
        images[`kedry-skylife-sotto_black_night_semi-open_curtain`],
        images["kedry-skylife_to_kolibrie_night"],
        images["kedry-skylife_black_night_close"],
      ];
    case `kedry-skylife-sotto_white_night_open_curtain`:
      return [
        images[`kedry-skylife-sotto_white_night_open_curtain`],
        images[`kedry-skylife-sotto_black_night_open_curtain`],
        images[`kedry-skylife-sotto_white_night_open_glass_curtain`],
        images[`kedry-skylife-sotto_white_night_open`],
        images[`kedry-skylife-sotto_white_night_semi-open_curtain`],
        images["kedry-skylife_to_kolibrie_night"],
        images["kedry-skylife_black_night_close"],
      ];
    case `kedry-skylife-sotto_white_night_open_glass`:
      return [
        images[`kedry-skylife-sotto_white_night_open_glass`],
        images[`kedry-skylife-sotto_black_night_open_glass`],
        images[`kedry-skylife-sotto_white_night_open`],
        images[`kedry-skylife-sotto_white_night_open_glass_curtain`],
        images[`kedry-skylife-sotto_white_night_semi-open_glass`],
        images["kedry-skylife_to_kolibrie_night"],
        images["kedry-skylife_black_night_close"],
      ];
    case `kedry-skylife-sotto_black_night_open_glass`:
      return [
        images[`kedry-skylife-sotto_black_night_open_glass`],
        images[`kedry-skylife-sotto_white_night_open_glass`],
        images[`kedry-skylife-sotto_black_night_open`],
        images[`kedry-skylife-sotto_black_night_open_glass_curtain`],
        images[`kedry-skylife-sotto_black_night_semi-open_glass`],
        images["kedry-skylife_to_kolibrie_night"],
        images["kedry-skylife_black_night_close"],
      ];
    case `kedry-skylife-sotto_black_night_open`:
      return [
        images[`kedry-skylife-sotto_black_night_open`],
        images[`kedry-skylife-sotto_white_night_open`],
        images[`kedry-skylife-sotto_black_night_open_glass`],
        images[`kedry-skylife-sotto_black_night_open_curtain`],
        images[`kedry-skylife-sotto_black_night_semi-open`],
        images["kedry-skylife_to_kolibrie_night"],
        images["kedry-skylife_black_night_close"],
      ];
    case `kedry-skylife-sotto_white_night_open`:
      return [
        images[`kedry-skylife-sotto_white_night_open`],
        images[`kedry-skylife-sotto_black_night_open`],
        images[`kedry-skylife-sotto_white_night_open_glass`],
        images[`kedry-skylife-sotto_white_night_open_curtain`],
        images[`kedry-skylife-sotto_white_night_semi-open`],
        images["kedry-skylife_to_kolibrie_night"],
        images["kedry-skylife_black_night_close"],
      ];
    /**
     * PERCORSO
     */
    case `entrance_day`:
      return [
        images[`entrance_night`],
        images["kedry-skylife_black_day_close"],
      ];
    case `venezia-gold_to_screeny2_day`:
      return [
        images[`venezia-gold_to_screeny2_night`],
        images["kedry-skylife_to_kolibrie_day"],
        images["venezia-gold_aky_day_close"],
        images["qubica_to_kolibrie_day"],
      ];
    case `qubica_to_kolibrie_day`:
      return [
        images[`qubica_to_kolibrie_night`],
        images["venezia-gold_to_screeny2_day"],
        images["qubica-flat_white_day_close"],
        images["kolibrie_day_close"],
      ];
    case `vega_to_xtesa_day`:
      return [
        images[`vega_to_xtesa_night`],
        images["xtesa_to_vega_1_day"],
        images["vega_black_day_close"],
      ];
    case `screeny1_to_screeny2_day`:
      return [
        images[`screeny1_to_screeny2_night`],
        images["screeny2-back_white_day_close"],
        images["screeny2_to_kolibrie_day"],
      ];
    case `isola3_to_vega_day`:
      return [
        images[`isola3_to_vega_night`],
        images["vega_to_isola3_day"],
        images["isola3_black_day_close"],
        images["isola3_to_kedry-skylife_day"],
      ];
    case `xtesa_to_vega_1_day`:
      return [
        images[`xtesa_to_vega_1_night`],
        images["xtesa_to_vega_2_day"],
        images["vega_to_xtesa_day"],
      ];
    case `screeny2_to_kolibrie_day`:
      return [
        images[`screeny2_to_kolibrie_night`],
        images["screeny2_white_day_close"],
        images["screeny1_to_screeny2_day"],
        images["kolibrie_to_screeny2_day"],
        images["kedry-skylife_to_kolibrie_day"],
      ];
    case `isola3_to_kedry-skylife_day`:
      return [
        images[`isola3_to_kedry-skylife_night`],
        images["isola3_to_vega_day"],
        images["kedry-skylife_to_kolibrie_day"],
      ];
    case `kolibrie_to_screeny2_day`:
      return [
        images[`kolibrie_to_screeny2_night`],
        images["screeny2_to_kolibrie_day"],
      ];
    case `kedry-skylife_to_kolibrie_day`:
      return [
        images[`kedry-skylife_to_kolibrie_night`],
        images["kedry-skylife_black_day_close"],
        images["kedry-skylife-sotto_black_day_open"],
        images["venezia-gold_to_screeny2_day"],
        images["isola3_to_kedry-skylife_day"],
        images["screeny2_to_kolibrie_day"],
      ];
    case `xtesa_to_vega_2_day`:
      return [
        images[`xtesa_to_vega_2_night`],
        images["xtesa_black_day_close"],
        images["xtesa_to_vega_1_day"],
      ];
    case `vega_to_hydra_day`:
      return [
        images[`vega_to_hydra_night`],
        images["vega_black_day_close"],
        images["vega_to_isola3_day"],
        images["hydra_black_day_close"],
      ];
    case `vega_to_kedry-prime_day`:
      return [
        images[`vega_to_kedry-prime_night`],
        images["vega_to_isola3_day"],
        images["hydra_to_kedry-prime_day"],
      ];
    case `hydra_to_kedry-prime_day`:
      return [
        images[`hydra_to_kedry-prime_night`],
        images["hydra_black_day_close"],
        images["vega_to_kedry-prime_day"],
        images["kedry-prime_black_day_close"],
      ];
    case `vega_to_isola3_day`:
      return [
        images[`vega_to_isola3_night`],
        images["vega_to_kedry-prime_day"],
        images["vega_to_hydra_day"],
        images["isola3_to_vega_day"],
      ];
    case `entrance_night`:
      return [
        images[`entrance_day`],
        images["kedry-skylife_black_night_close"],
      ];
    case `venezia-gold_to_screeny2_night`:
      return [
        images[`venezia-gold_to_screeny2_day`],
        images["kedry-skylife_to_kolibrie_night"],
        images["venezia-gold_aky_night_close"],
        images["qubica_to_kolibrie_night"],
      ];
    case `qubica_to_kolibrie_night`:
      return [
        images[`qubica_to_kolibrie_day`],
        images["venezia-gold_to_screeny2_night"],
        images["qubica-flat_white_night_close"],
        images["kolibrie_night_close"],
      ];
    case `vega_to_xtesa_night`:
      return [
        images[`vega_to_xtesa_day`],
        images["xtesa_to_vega_1_night"],
        images["vega_black_night_close"],
      ];
    case `screeny1_to_screeny2_night`:
      return [
        images[`screeny1_to_screeny2_day`],
        images["screeny2-back_white_night_close"],
        images["screeny2_to_kolibrie_night"],
      ];
    case `isola3_to_vega_night`:
      return [
        images[`isola3_to_vega_day`],
        images["vega_to_isola3_night"],
        images["isola3_black_night_close"],
        images["isola3_to_kedry-skylife_night"],
      ];
    case `xtesa_to_vega_1_night`:
      return [
        images[`xtesa_to_vega_1_day`],
        images["xtesa_to_vega_2_night"],
        images["vega_to_xtesa_night"],
      ];
    case `screeny2_to_kolibrie_night`:
      return [
        images[`screeny2_to_kolibrie_day`],
        images["screeny2_white_night_close"],
        images["screeny1_to_screeny2_night"],
        images["kolibrie_to_screeny2_night"],
        images["kedry-skylife_to_kolibrie_night"],
      ];
    case `isola3_to_kedry-skylife_night`:
      return [
        images[`isola3_to_kedry-skylife_day`],
        images["isola3_to_vega_night"],
        images["kedry-skylife_to_kolibrie_night"],
      ];
    case `kolibrie_to_screeny2_night`:
      return [
        images[`kolibrie_to_screeny2_day`],
        images["screeny2_to_kolibrie_night"],
      ];
    case `kedry-skylife_to_kolibrie_night`:
      return [
        images[`kedry-skylife_to_kolibrie_day`],
        images["kedry-skylife_black_night_close"],
        images["kedry-skylife-sotto_black_night_open"],
        images["venezia-gold_to_screeny2_night"],
        images["isola3_to_kedry-skylife_night"],
        images["screeny2_to_kolibrie_night"],
      ];
    case `xtesa_to_vega_2_night`:
      return [
        images[`xtesa_to_vega_2_day`],
        images["xtesa_black_night_close"],
        images["xtesa_to_vega_1_night"],
      ];
    case `vega_to_hydra_night`:
      return [
        images[`vega_to_hydra_day`],
        images["vega_black_night_close"],
        images["vega_to_isola3_night"],
        images["hydra_black_night_close"],
      ];
    case `vega_to_kedry-prime_night`:
      return [
        images[`vega_to_kedry-prime_day`],
        images["vega_to_isola3_night"],
        images["hydra_to_kedry-prime_night"],
      ];
    case `hydra_to_kedry-prime_night`:
      return [
        images[`hydra_to_kedry-prime_day`],
        images["hydra_black_night_close"],
        images["vega_to_kedry-prime_night"],
        images["kedry-prime_black_night_close"],
      ];
    case `vega_to_isola3_night`:
      return [
        images[`vega_to_isola3_day`],
        images["vega_to_kedry-prime_night"],
        images["vega_to_hydra_night"],
        images["isola3_to_vega_night"],
      ];
    default:
      return [];
  }
};
