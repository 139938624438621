export const LOCALSTORAGE_LANGUAGE = "ke-group/language";

export const languages: Array<{
  code: string;
  title: string;
}> = [
  {
    code: "it",
    title: "Italiano",
  },
  {
    code: "en",
    title: "English",
  },
  {
    code: "en-us",
    title: "American",
  },
  {
    code: "es",
    title: "Español",
  },
  {
    code: "fr",
    title: "French",
  },
  {
    code: "de",
    title: "German",
  },
];
