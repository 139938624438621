import { getLanguageTranslation } from "../translations/get-language-translation";
import {
  SunlightDepliant20x25Gennius,
  SunlightImage1,
  SunlightImage2,
  SunlightImage3,
  SunlightImage4,
  SunlightImage5,
  SunlightSchedaTecnica,
} from "./index";

export const createSunlightInfoPoints = (
  createInfoPoint,
  createOptionalPoint,
  createVideoPoint,
  createPositionPoint,
  technicalSpecifics,
  createVrPoint,
  createColorPoint,
  lang
) => [
  createInfoPoint(
    [4182.93, -996.93, 2535.47],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SUNLIGHT",
      description:
        "<p>" +
        getLanguageTranslation(
          "Un Giardino d'inverno, una stanza outdoor studiata per ridisegnare gli spazi esterni in perfetta simbiosi con l’ambiente circostante."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Donando un nuovo senso di ampiezza e rivoluziona il concetto di comfort e luce naturale in qualsiasi stagione dell’anno."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Sunlight è stata progettata per avere importanti prestazioni in caso di situazioni metereologiche avverse."
        ) +
        "<br /></p>",
      images: [
        SunlightImage1,
        SunlightImage2,
        SunlightImage3,
        SunlightImage4,
        SunlightImage5,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Sunlight"),
          href: SunlightDepliant20x25Gennius,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: SunlightSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>SUNLIGHT</strong><br />" +
      getLanguageTranslation(
        "Un Giardino d'inverno, una stanza outdoor studiata per ridisegnare gli spazi esterni in perfetta simbiosi con l’ambiente circostante."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Donando un nuovo senso di ampiezza e rivoluziona il concetto di comfort e luce naturale in qualsiasi stagione dell’anno."
      ) +
      "</p>"
  ),
  createPositionPoint(
    [4852.46, -902.62, -735.3],
    "sunlight-sotto",
    getLanguageTranslation("SUNLIGHT (Sotto)", lang)
  ),
  createPositionPoint([421.08, -71.71, -4975.11], "xtesa", "XTESA"),
  createPositionPoint(
    [228.37, 253.6, 4978.07],
    "kheope",
    "GENNIUS SAILS KHEOPE K1"
  ),
  createOptionalPoint(
    [4607.58, -23.98, -1927.23],
    getLanguageTranslation("Vetrata", lang),
    "glass"
  ),
  createOptionalPoint(
    [4677.33, 197.5, 1726.53],
    getLanguageTranslation("Tenda a caduta", lang),
    "curtain"
  ),
  createColorPoint(
    [4610.22, -1074.39, -1589.48],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "black",
        value: "black",
        text: "CARBON RUVIDO",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA", lang)
  ),
];

export const createSunlightSottoInfoPoints = (
  createInfoPoint,
  createOptionalPoint,
  createVideoPoint,
  createPositionPoint,
  technicalSpecifics,
  createVrPoint,
  createColorPoint,
  lang
) => [
  createInfoPoint(
    [3449.82, -1949.1, 3043.96],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SUNLIGHT",
      description:
        "<p>" +
        getLanguageTranslation(
          "Un Giardino d'inverno, una stanza outdoor studiata per ridisegnare gli spazi esterni in perfetta simbiosi con l’ambiente circostante."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Donando un nuovo senso di ampiezza e rivoluziona il concetto di comfort e luce naturale in qualsiasi stagione dell’anno."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Sunlight è stata progettata per avere importanti prestazioni in caso di situazioni metereologiche avverse."
        ) +
        "<br /></p>",
      images: [
        SunlightImage1,
        SunlightImage2,
        SunlightImage3,
        SunlightImage4,
        SunlightImage5,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Sunlight"),
          href: SunlightDepliant20x25Gennius,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: SunlightSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>SUNLIGHT</strong><br />" +
      getLanguageTranslation(
        "Un Giardino d'inverno, una stanza outdoor studiata per ridisegnare gli spazi esterni in perfetta simbiosi con l’ambiente circostante."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Donando un nuovo senso di ampiezza e rivoluziona il concetto di comfort e luce naturale in qualsiasi stagione dell’anno."
      ) +
      "</p>"
  ),
  createPositionPoint(
    [-4764.61, -1508.0, -21.67],
    "sunlight",
    getLanguageTranslation("SUNLIGHT")
  ),
  createOptionalPoint(
    [2865.1, 661.88, -4036.68],
    getLanguageTranslation("Vetrata"),
    "glass"
  ),
  createOptionalPoint(
    [-165.09, -357.12, 4977.32],
    getLanguageTranslation("Tenda a caduta"),
    "curtain"
  ),
  createColorPoint(
    [-3100.64, -2157.7, 3264.18],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "black",
        value: "black",
        text: "CARBON RUVIDO",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
];

export const createSunlightUsInfoPoints = (
  createInfoPoint,
  createOptionalPoint,
  createVideoPoint,
  createPositionPoint,
  technicalSpecifics,
  createVrPoint,
  createColorPoint,
  lang
) => [
  createInfoPoint(
    [4182.93, -996.93, 2535.47],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SUNLIGHT",
      description:
        "<p>" +
        getLanguageTranslation(
          "Un Giardino d'inverno, una stanza outdoor studiata per ridisegnare gli spazi esterni in perfetta simbiosi con l’ambiente circostante."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Donando un nuovo senso di ampiezza e rivoluziona il concetto di comfort e luce naturale in qualsiasi stagione dell’anno."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Sunlight è stata progettata per avere importanti prestazioni in caso di situazioni metereologiche avverse."
        ) +
        "<br /></p>",
      images: [
        SunlightImage1,
        SunlightImage2,
        SunlightImage3,
        SunlightImage4,
        SunlightImage5,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Sunlight"),
          href: SunlightDepliant20x25Gennius,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: SunlightSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>SUNLIGHT</strong><br />" +
      getLanguageTranslation(
        "Un Giardino d'inverno, una stanza outdoor studiata per ridisegnare gli spazi esterni in perfetta simbiosi con l’ambiente circostante."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Donando un nuovo senso di ampiezza e rivoluziona il concetto di comfort e luce naturale in qualsiasi stagione dell’anno."
      ) +
      "</p>"
  ),
  createPositionPoint(
    [4852.46, -902.62, -735.3],
    "sunlight-sotto",
    getLanguageTranslation("SUNLIGHT (Sotto)", lang)
  ),
  createPositionPoint([690.08, -257.95, -4935.84], "a100", "A 100"),
  createPositionPoint(
    [228.37, 253.6, 4978.07],
    "kheope",
    "GENNIUS SAILS KHEOPE K1"
  ),
  createOptionalPoint(
    [4607.58, -23.98, -1927.23],
    getLanguageTranslation("Vetrata", lang),
    "glass"
  ),
  createOptionalPoint(
    [4677.33, 197.5, 1726.53],
    getLanguageTranslation("Tenda a caduta", lang),
    "curtain"
  ),
  createColorPoint(
    [4610.22, -1074.39, -1589.48],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "black",
        value: "black",
        text: "CARBON RUVIDO",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA", lang)
  ),
];
