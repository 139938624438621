import { getLanguageTranslation } from "../translations/get-language-translation";
import {
  KedryPrimeImage1,
  KedryPrimeImage2,
  KedryPrimeImage3,
  KedryPrimeImage4,
  KedryPrimeImage5,
  KedryPrimeImage6,
  KedryPrimeImage7,
  KedryPrimeImage8,
  KedryPrimeImage9,
  KedryPrimeImage10,
  KedryPrimeImage11,
  KedryPrimeImage12,
  KedryPrimeImage13,
  KedryPrimeImage14,
  KedryPrimeImage15,
  KedryPrimeImage16,
  KedryPrimeDepliant20x25Kedry,
  KedryPrimeDepliantLineGlassIt,
  KedryPrimeDepliantPaletteColori2021It,
  KedryPrimeSchedaTecnica,
  KedryPrimeGLB,
  KedryPrimeUSDZ,
} from "./index";

export const createKedryPrimeInfoPoints = (
  createInfoPoint,
  createVideoPoint,
  createPositionPoint,
  createOptionalPoint,
  createVrPoint,
  createColorPoint,
  lang
) => [
  createInfoPoint(
    [4182.93, -996.93, 2535.47],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "KEDRY PRIME",
      description:
        "<p>" +
        getLanguageTranslation(
          "Schermatura a lame in alluminio e tetto a lame orientabili fino a fino a 135°."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Sistema di strip led integrate nei profili perimetrali esterni e interni."
        ) +
        "<br /></p>",
      images: [
        KedryPrimeImage1,
        KedryPrimeImage2,
        KedryPrimeImage3,
        KedryPrimeImage4,
        KedryPrimeImage5,
        KedryPrimeImage6,
        KedryPrimeImage7,
        KedryPrimeImage8,
        KedryPrimeImage9,
        KedryPrimeImage10,
        KedryPrimeImage11,
        KedryPrimeImage12,
        KedryPrimeImage13,
        KedryPrimeImage14,
        KedryPrimeImage15,
        KedryPrimeImage16,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Kedry"),
          href: KedryPrimeDepliant20x25Kedry,
        },
        {
          text: "Line Glass",
          href: KedryPrimeDepliantLineGlassIt,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: KedryPrimeDepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: KedryPrimeSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>KEDRY PRIME</strong><br />" +
      getLanguageTranslation(
        "Schermatura a lame in alluminio e tetto a lame orientabili fino a fino a 135°."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Sistema di strip led integrate nei profili perimetrali esterni e interni."
      ) +
      "</p>"
  ),
  createVideoPoint(
    [4285.39, -1456.58, 2104.41],
    "Video",
    "https://www.youtube.com/watch?v=dmKn7ao_dk8"
  ),
  createPositionPoint(
    [4852.46, -902.62, -735.3],
    "kedry-prime-sotto",
    getLanguageTranslation("KEDRY PRIME (Sotto)", lang)
  ),
  createPositionPoint([3431.16, -128.41, -3624.53], "hydra_to_kedry-prime", ""),
  createOptionalPoint(
    [4607.58, -23.98, -1927.23],
    getLanguageTranslation("Vetrata", lang),
    "glass"
  ),
  createOptionalPoint(
    [4677.33, 197.5, 1726.53],
    getLanguageTranslation("Tenda a caduta", lang),
    "curtain"
  ),
  createColorPoint(
    [4610.22, -1074.39, -1589.48],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "black",
        value: "black",
        text: "CARBON RUVIDO",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA", lang)
  ),
  createVrPoint([4661.47, -1742.68, 399.15], KedryPrimeGLB, KedryPrimeUSDZ),
];

export const createKedryPrimeSottoInfoPoints = (
  createInfoPoint,
  createVideoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [3449.82, -1949.1, 3043.96],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "KEDRY PRIME",
      description:
        "<p>" +
        getLanguageTranslation(
          "Schermatura a lame in alluminio e tetto a lame orientabili fino a fino a 135°."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Sistema di strip led integrate nei profili perimetrali esterni e interni."
        ) +
        "<br /></p>",
      images: [
        KedryPrimeImage1,
        KedryPrimeImage2,
        KedryPrimeImage3,
        KedryPrimeImage4,
        KedryPrimeImage5,
        KedryPrimeImage6,
        KedryPrimeImage7,
        KedryPrimeImage8,
        KedryPrimeImage9,
        KedryPrimeImage10,
        KedryPrimeImage11,
        KedryPrimeImage12,
        KedryPrimeImage13,
        KedryPrimeImage14,
        KedryPrimeImage15,
        KedryPrimeImage16,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Kedry"),
          href: KedryPrimeDepliant20x25Kedry,
        },
        {
          text: "Line Glass",
          href: KedryPrimeDepliantLineGlassIt,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: KedryPrimeDepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: KedryPrimeSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>KEDRY PRIME</strong><br />" +
      getLanguageTranslation(
        "Schermatura a lame in alluminio e tetto a lame orientabili fino a fino a 135°."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Sistema di strip led integrate nei profili perimetrali esterni e interni."
      ) +
      "</p>"
  ),
  createVideoPoint(
    [4152.0, -2602.49, -975.3],
    "Video",
    "https://www.youtube.com/watch?v=dmKn7ao_dk8"
  ),
  createPositionPoint(
    [-3534.4, -1614.57, -3140.57],
    "kedry-prime",
    "KEDRY PRIME"
  ),
  createOptionalPoint(
    [3916.72, 2163.31, 2220.75],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createOptionalPoint(
    [4414.26, -477.13, -2278.9],
    getLanguageTranslation("Vetrata"),
    "glass"
  ),
  createOptionalPoint(
    [-165.09, -357.12, 4977.32],
    getLanguageTranslation("Tenda a caduta"),
    "curtain"
  ),
  createColorPoint(
    [2645.0, -3290.55, 2667.96],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "black",
        value: "black",
        text: "CARBON RUVIDO",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createVrPoint([-1719.2, -2734.43, 3810.85], KedryPrimeGLB, KedryPrimeUSDZ),
];

export const createKedryPrimeUsInfoPoints = (
  createInfoPoint,
  createVideoPoint,
  createPositionPoint,
  createOptionalPoint,
  createVrPoint,
  createColorPoint,
  lang
) => [
  createInfoPoint(
    [4182.93, -996.93, 2535.47],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "KEDRY PRIME",
      description:
        "<p>" +
        getLanguageTranslation(
          "Schermatura a lame in alluminio e tetto a lame orientabili fino a fino a 135°."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Sistema di strip led integrate nei profili perimetrali esterni e interni."
        ) +
        "<br /></p>",
      images: [
        KedryPrimeImage1,
        KedryPrimeImage2,
        KedryPrimeImage3,
        KedryPrimeImage4,
        KedryPrimeImage5,
        KedryPrimeImage6,
        KedryPrimeImage7,
        KedryPrimeImage8,
        KedryPrimeImage9,
        KedryPrimeImage10,
        KedryPrimeImage11,
        KedryPrimeImage12,
        KedryPrimeImage13,
        KedryPrimeImage14,
        KedryPrimeImage15,
        KedryPrimeImage16,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Kedry"),
          href: KedryPrimeDepliant20x25Kedry,
        },
        {
          text: "Line Glass",
          href: KedryPrimeDepliantLineGlassIt,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: KedryPrimeDepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: KedryPrimeSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>KEDRY PRIME</strong><br />" +
      getLanguageTranslation(
        "Schermatura a lame in alluminio e tetto a lame orientabili fino a fino a 135°."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Sistema di strip led integrate nei profili perimetrali esterni e interni."
      ) +
      "</p>"
  ),
  createVideoPoint(
    [4285.39, -1456.58, 2104.41],
    "Video",
    "https://www.youtube.com/watch?v=dmKn7ao_dk8"
  ),
  createPositionPoint(
    [4852.46, -902.62, -735.3],
    "kedry-prime-sotto",
    getLanguageTranslation("KEDRY PRIME (Sotto)")
  ),
  createOptionalPoint(
    [4607.58, -23.98, -1927.23],
    getLanguageTranslation("Vetrata"),
    "glass"
  ),
  createOptionalPoint(
    [4677.33, 197.5, 1726.53],
    getLanguageTranslation("Tenda a caduta"),
    "curtain"
  ),
  createPositionPoint([3394.33, -151.76, -3658.63], "hydra_to_kedry-prime", ""),
  createColorPoint(
    [4610.22, -1074.39, -1589.48],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "black",
        value: "black",
        text: "CARBON RUVIDO",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createVrPoint([4661.47, -1742.68, 399.15], KedryPrimeGLB, KedryPrimeUSDZ),
];
