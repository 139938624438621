import { TechnicalSpecificsType } from "../../types";
import { getTechnicalSpecificsColor } from "./get-technical-specifics-color";
import { getTechnicalSpecificsTime } from "./get-technical-specifics-time";
import { getTechnicalSpecificsBooleanRoof } from "./get-technical-specifics-boolean-roof";

export const getkeplanImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `keplan${getTechnicalSpecificsColor(
    technicalSpecifics,
    ["white", "black"],
    "black"
  )}${getTechnicalSpecificsTime(
    technicalSpecifics
  )}${getTechnicalSpecificsBooleanRoof(technicalSpecifics)}`;
