import {
    BellaPlusDepliant20x25,
    BellaPlusImage1,
    BellaPlusImage2,
    BellaPlusImage3,
    BellaPlusImage4,
    BellaPlusImage5,
    BellaPlusImage6,
    BellaPlusImage7
} from "./index";
import { getLanguageTranslation } from "../translations/get-language-translation";

export const createBellaPlusUsInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [4393.61, -1497.43, -1842.81],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "BELLA PLUS",
      description:
      "<h2>RETRACTABLE AWNING</h2><p>Our top of the line Bella Plus comes with a bottom cover so your awning is protected from the elements. Bella Plus is available in the full or semi cassette option. Our designers have created a technologically advanced awning that protects the fabric with a full cassette housing. Bella Plus features encapsulated fabric protection with additional arm protection and an expanded projection range up to 13′ 5″.</p>",

      images: [
          BellaPlusImage1,
          BellaPlusImage2,
          BellaPlusImage3,
          BellaPlusImage4,
          BellaPlusImage5,
          BellaPlusImage6,
          BellaPlusImage7,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: BellaPlusDepliant20x25,
        },
      ],
    },
    undefined,
    "<p><strong>BELLA PLUS</strong><br />Our top of the line Bella Plus comes with a bottom cover so your awning is protected from the elements. Bella Plus is available in the full or semi cassette option.</p>"
  ),
  createOptionalPoint(
    [3926.84, 293.61, -3066.64],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "close"
  ),
  createPositionPoint(
    [156.21, -989.48, -4889.02],
    "venezia-gold_to_screeny2",
    ""
  ),
  //createVrPoint([3018.55, -1082.46, -3824.75], VeneziaGLB, VeneziaUSDZ),
];
