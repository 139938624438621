import { TechnicalSpecificsType } from "../types";
import { Minus, Plus } from "../icons";

export const useCreateOptionalPoint: (
  setTechnicalSpecifics: any,
  technicalSpecifics: TechnicalSpecificsType,
  lang: string
) => (
  position: number[],
  title: string,
  optional: string,
  image?: any,
  customSetValue?: any,
  customGetIcon?: (v: any) => boolean,
  show?: boolean
) => Record<string, any> = (setTechnicalSpecifics, technicalSpecifics) => (
  position,
  title,
  optional,
  image,
  customSetValue,
  customGetIcon,
  show
) =>
  (show !== undefined ? show : true)
    ? {
        position,
        text: title,
        image: !!image
          ? image
          : !!customGetIcon
          ? customGetIcon(technicalSpecifics[optional])
            ? Minus
            : Plus
          : technicalSpecifics[optional]
          ? Minus
          : Plus,
        type: "optional",
        description: null,
        clickListener: () => {
          setTechnicalSpecifics({
            ...technicalSpecifics,
            [optional]: !!customSetValue
              ? customSetValue
              : !technicalSpecifics[optional],
          });
        },
      }
    : null;
