import { getLanguageTranslation } from "../translations/get-language-translation";
import {
  XtesaImage1,
  XtesaImage2,
  XtesaImage3,
  XtesaImage4,
  XtesaDepliant20x25Gennius,
  XtesaDepliantLineGlassIt,
  XtesaDepliantPaletteColori2021It,
  XtesaSchedaTecnica,
  XtesaGLB,
  XtesaUSDZ,
} from "./index";

export const createXtesaInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [4197.76, -1264.17, -2395.75],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "XTESA",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda a pergola con struttura in alluminio e tessuto ad avvolgimento."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Ideale per installazioni in ambienti architettonici estremi, raggiunge notevoli sporgenze con pendenze contenute."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Il tessuto arrotolato garantisce un ottimo risultato estetico, rispetto al classico ingombro del tessuto a impacchettamento."
        ) +
        "<br /></p>",
      images: [XtesaImage1, XtesaImage2, XtesaImage3, XtesaImage4],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Gennius"),
          href: XtesaDepliant20x25Gennius,
        },
        {
          text: "Line Glass",
          href: XtesaDepliantLineGlassIt,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: XtesaDepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: XtesaSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>XTESA</strong><br />" +
      getLanguageTranslation(
        "Tenda a pergola con struttura in alluminio e tessuto ad avvolgimento."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Ideale per installazioni in ambienti architettonici estremi, raggiunge notevoli sporgenze con pendenze contenute."
      ) +
      "</p>"
  ),
  createPositionPoint(
    [-2355.32, -489.51, 4373.67],
    "sunlight",
    "SUNLIGHT"
  ),
  createOptionalPoint(
    [4811.55, 1239.41, -527.66],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createColorPoint(
    [4545.78, -1240.76, 1648.09],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "black",
        value: "black",
        text: "CARBON RUVIDO",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createPositionPoint([1433.47, -696.32, -4732.05], "keplan", "KEPLAN"),
  createVrPoint([4123.38, -2803.87, -227.91], XtesaGLB, XtesaUSDZ),
];
