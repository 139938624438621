//  @ts-ignore
import React from "react";
import { Toaster } from "react-hot-toast";

const CustomToaster: React.FC = () => (
  <Toaster
    position="bottom-center"
    reverseOrder={false}
    gutter={8}
    containerClassName=""
    containerStyle={{}}
    toastOptions={{
      // Define default options
      className: "",
      duration: 5000,
      style: {
        background: "#363636",
        color: "#fff",
      },
      // Default options for specific types
      success: {
        duration: 5000,
        theme: {
          primary: "red",
          secondary: "black",
        },
      },
    }}
  />
);

export default CustomToaster;
