import { TechnicalSpecificsType } from "../../types";
import { getTechnicalSpecificsBooleanRoof } from "./get-technical-specifics-boolean-roof";
import { getTechnicalSpecificsColor } from "./get-technical-specifics-color";
import { getTechnicalSpecificsTime } from "./get-technical-specifics-time";
import { getTechnicalSpecificsGlass } from "./get-technical-specifics-glass";
import { getTechnicalSpecificsCurtain } from "./get-technical-specifics-curtain";

export const getIsola3ImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `isola3${getTechnicalSpecificsColor(
    technicalSpecifics,
    ["white", "black"],
    "white"
  )}${getTechnicalSpecificsTime(technicalSpecifics)}${
    technicalSpecifics.time === "day"
      ? "_close"
      : getTechnicalSpecificsBooleanRoof(technicalSpecifics)
  }${getTechnicalSpecificsGlass(
    technicalSpecifics
  )}${getTechnicalSpecificsCurtain(technicalSpecifics)}`;
