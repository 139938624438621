import { getLanguageTranslation } from "../translations/get-language-translation";
import {
  KolibrieImage1,
  KolibrieImage2,
  KolibrieImage3,
  KolibrieImage4,
  KolibrieImage5,
  KolibrieImage6,
  KolibrieImage7,
  KolibrieImage8,
  KolibrieDepliant20x25VeleSails,
  KolibrieSchedaTecnica,
  KolibrieGLB,
  KolibrieUSDZ,
} from "./index";

export const createKolibrieInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [4367.49, -1788.29, -1623.37],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "KOLIBRIE",
      description:
        "<p>" +
        getLanguageTranslation(
          "Ombreggiatore a vela con un unico palo di sostegno disassato in acciaio INOX."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Orientabile a 360° grazie al piedistallo a cuscinetti e resistente all'usura."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Movimentazione avvolgimento telo manuale oppure motorizzata."
        ) +
        "<br /></p>",
      images: [
        KolibrieImage1,
        KolibrieImage2,
        KolibrieImage3,
        KolibrieImage4,
        KolibrieImage5,
        KolibrieImage6,
        KolibrieImage7,
        KolibrieImage8,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Vele Sails"),
          href: KolibrieDepliant20x25VeleSails,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: KolibrieSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>KOLIBRIE</strong><br />" +
      getLanguageTranslation(
        "Ombreggiatore a vela con un unico palo di sostegno disassato in acciaio INOX."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Orientabile a 360° grazie al piedistallo a cuscinetti e resistente all'usura."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Movimentazione avvolgimento telo manuale oppure motorizzata."
      ) +
      "</p>"
  ),
  createPositionPoint(
    [329.12, -567.09, 4946.65],
    "kheope",
    "GENNIUS SAILS KHEOPE K1"
  ),
  createPositionPoint(
    [608.1, -741.99, -4901.64],
    "qubica-flat",
    "QUBICA FLAT B50"
  ),
  createOptionalPoint(
    [4827.2, 1247.35, 229.98],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createPositionPoint([2023.26, -1218.73, 4401.92], "kolibrie_to_screeny2", ""),
  createPositionPoint([-341.14, -1263.94, -4816.69], "qubica_to_kolibrie", ""),
  createVrPoint([4325.82, -1847.54, 1671.0], KolibrieGLB, KolibrieUSDZ),
];

export const createKolibrieUsInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [4367.49, -1788.29, -1623.37],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "KOLIBRIE",
      description:
        "<p>" +
        getLanguageTranslation(
          "Ombreggiatore a vela con un unico palo di sostegno disassato in acciaio INOX."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Orientabile a 360° grazie al piedistallo a cuscinetti e resistente all'usura."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Movimentazione avvolgimento telo manuale oppure motorizzata."
        ) +
        "<br /></p>",
      images: [
        KolibrieImage1,
        KolibrieImage2,
        KolibrieImage3,
        KolibrieImage4,
        KolibrieImage5,
        KolibrieImage6,
        KolibrieImage7,
        KolibrieImage8,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Vele Sails"),
          href: KolibrieDepliant20x25VeleSails,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: KolibrieSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>KOLIBRIE</strong><br />" +
      getLanguageTranslation(
        "Ombreggiatore a vela con un unico palo di sostegno disassato in acciaio INOX."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Orientabile a 360° grazie al piedistallo a cuscinetti e resistente all'usura."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Movimentazione avvolgimento telo manuale oppure motorizzata."
      ) +
      "</p>"
  ),
  createPositionPoint(
    [329.12, -567.09, 4946.65],
    "kheope",
    "GENNIUS SAILS KHEOPE K1"
  ),
  createPositionPoint([2023.26, -1218.73, 4401.92], "screeny-storm", "SCREENY STORM"),
  createPositionPoint([-341.14, -1263.94, -4816.69], "qubica_to_kolibrie", ""),
  createOptionalPoint(
    [4827.2, 1247.35, 229.98],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createVrPoint([4325.82, -1847.54, 1671.0], KolibrieGLB, KolibrieUSDZ),
];

export const createKolibrieFrInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [4367.49, -1788.29, -1623.37],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "KOLIBRIE",
      description:
        "<p>" +
        getLanguageTranslation(
          "Ombreggiatore a vela con un unico palo di sostegno disassato in acciaio INOX."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Orientabile a 360° grazie al piedistallo a cuscinetti e resistente all'usura."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Movimentazione avvolgimento telo manuale oppure motorizzata."
        ) +
        "<br /></p>",
      images: [
        KolibrieImage1,
        KolibrieImage2,
        KolibrieImage3,
        KolibrieImage4,
        KolibrieImage5,
        KolibrieImage6,
        KolibrieImage7,
        KolibrieImage8,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Vele Sails"),
          href: KolibrieDepliant20x25VeleSails,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: KolibrieSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>KOLIBRIE</strong><br />" +
      getLanguageTranslation(
        "Ombreggiatore a vela con un unico palo di sostegno disassato in acciaio INOX."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Orientabile a 360° grazie al piedistallo a cuscinetti e resistente all'usura."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Movimentazione avvolgimento telo manuale oppure motorizzata."
      ) +
      "</p>"
  ),
  createPositionPoint(
    [329.12, -567.09, 4946.65],
    "kheope",
    "GENNIUS SAILS KHEOPE K1"
  ),
  createPositionPoint(
    [1130.72, -1137.25, -4729.65],
    "qubica-lite",
    "QUBICA LITE"
  ),
  createOptionalPoint(
    [4827.2, 1247.35, 229.98],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createPositionPoint([2023.26, -1218.73, 4401.92], "kolibrie_to_screeny2", ""),
  createPositionPoint([-1575.75, -1097.79, -4610.51], "qubica_to_kolibrie2", ""),
  createVrPoint([4325.82, -1847.54, 1671.0], KolibrieGLB, KolibrieUSDZ),
];

export const createKolibrieEsInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [4367.49, -1788.29, -1623.37],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "KOLIBRIE",
      description:
        "<p>" +
        getLanguageTranslation(
          "Ombreggiatore a vela con un unico palo di sostegno disassato in acciaio INOX."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Orientabile a 360° grazie al piedistallo a cuscinetti e resistente all'usura."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Movimentazione avvolgimento telo manuale oppure motorizzata."
        ) +
        "<br /></p>",
      images: [
        KolibrieImage1,
        KolibrieImage2,
        KolibrieImage3,
        KolibrieImage4,
        KolibrieImage5,
        KolibrieImage6,
        KolibrieImage7,
        KolibrieImage8,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Vele Sails"),
          href: KolibrieDepliant20x25VeleSails,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: KolibrieSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>KOLIBRIE</strong><br />" +
      getLanguageTranslation(
        "Ombreggiatore a vela con un unico palo di sostegno disassato in acciaio INOX."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Orientabile a 360° grazie al piedistallo a cuscinetti e resistente all'usura."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Movimentazione avvolgimento telo manuale oppure motorizzata."
      ) +
      "</p>"
  ),
  createPositionPoint(
    [329.12, -567.09, 4946.65],
    "kheope",
    "GENNIUS SAILS KHEOPE K1"
  ),
  createOptionalPoint(
    [4827.2, 1247.35, 229.98],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createPositionPoint([2023.26, -1218.73, 4401.92], "kolibrie_to_screeny2", ""),
  createPositionPoint([-341.14, -1263.94, -4816.69], "qubica_to_kolibrie", ""),
  createVrPoint([4325.82, -1847.54, 1671.0], KolibrieGLB, KolibrieUSDZ),
];
