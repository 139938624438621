import { getLanguageTranslation } from "../translations/get-language-translation";
import {
  Screeny1Image1,
  Screeny1Image2,
  Screeny1Image3,
  Screeny1Image4,
  Screeny1Depliant20x25Screeny,
  Screeny1DepliantPaletteColori2021It,
  Screeny1SchedaTecnica,
  Screeny1GLB,
  Screeny1USDZ,
  ScreenyCoulisseScreenyCoulisseZipSchedaTecnica,
} from "./index";

export const createScreeny1InfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [3993.94, -2993.03, 162.19],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 110 GC S12",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda tessuto filtrante/oscurante a rullo con cassonetto da 110 mm disponibile in 2 varianti (tondo/quadro)."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Adatto per la schermatura esterna di finestre e facciate continue in molte tipologie di costruzioni: dalle abitazioni private agli edifici commerciali più moderni."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Sistema brevettato che garantisce performance e standard elevati."
        ) +
        "<br /></p>",
      images: [Screeny1Image1, Screeny1Image2, Screeny1Image3, Screeny1Image4],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Screeny"),
          href: Screeny1Depliant20x25Screeny,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: Screeny1DepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: Screeny1SchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 110 GC S12</strong><br />" +
      getLanguageTranslation(
        "Tenda tessuto filtrante/oscurante a rullo con cassonetto da 110 mm disponibile in 2 varianti (tondo/quadro)."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Adatto per la schermatura esterna di finestre e facciate continue in molte tipologie di costruzioni: dalle abitazioni private agli edifici commerciali più moderni."
      ) +
      "</p>"
  ),
  /*createPositionPoint(
    [-4870.02, -1099.68, 100.51],
    "isola3-sotto",
    "ISOLA 3 (Sotto)"
  ),*/
  createPositionPoint([-52.42, -972.55, -4897.83], "isola3", "ISOLA 3"),
  createPositionPoint([-3889.67, -120.87, -3123.32], "vega", "GENNIUS VEGA"),
  createPositionPoint(
    [-63.28, -512.02, 4967.1],
    "screeny2",
    "SCREENY 110 GPZ UNICA S32"
  ),
  createOptionalPoint(
    [3998.64, 1174.2, 2753.53],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createColorPoint(
    [3317.94, -2713.11, 2561.01],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "gray",
        value: "gray",
        text: "RAL9006",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createVrPoint([3596.08, -2809.27, -2030.71], Screeny1GLB, Screeny1USDZ),
];

export const createScreeny1BackInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [3944.36, -3021.71, -502.26],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 110 GC S12",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda tessuto filtrante/oscurante a rullo con cassonetto da 110 mm disponibile in 2 varianti (tondo/quadro)."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Adatto per la schermatura esterna di finestre e facciate continue in molte tipologie di costruzioni: dalle abitazioni private agli edifici commerciali più moderni."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Sistema brevettato che garantisce performance e standard elevati."
        ) +
        "<br /></p>",
      images: [Screeny1Image1, Screeny1Image2, Screeny1Image3, Screeny1Image4],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Screeny"),
          href: Screeny1Depliant20x25Screeny,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: Screeny1DepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: Screeny1SchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 110 GC S12</strong><br />" +
      getLanguageTranslation(
        "Tenda tessuto filtrante/oscurante a rullo con cassonetto da 110 mm disponibile in 2 varianti (tondo/quadro)."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Adatto per la schermatura esterna di finestre e facciate continue in molte tipologie di costruzioni: dalle abitazioni private agli edifici commerciali più moderni."
      ) +
      "</p>"
  ),
  createPositionPoint(
    [226.24, -575.84, -4951.01],
    "screeny2-back",
    "SCREENY 110 GPZ UNICA S32"
  ),
  createPositionPoint(
    [2420.96, -853.56, 4285.49],
    "isola3",
    "ISOLA 3"
  ),
  createOptionalPoint(
    [4230.43, 1150.05, 2399.3],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createColorPoint(
    [3408.1, -2683.34, 2481.23],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "gray",
        value: "gray",
        text: "RAL9006",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createVrPoint([3253.97, -2666.95, -2688.95], Screeny1GLB, Screeny1USDZ),
];

export const createScreeny1FrInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [3993.94, -2993.03, 162.19],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 110 COULISSE",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda tessuto filtrante/oscurante a rullo con cassonetto da 110 mm disponibile in 2 varianti (tondo/quadro)."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Adatto per la schermatura esterna di finestre e facciate continue in molte tipologie di costruzioni: dalle abitazioni private agli edifici commerciali più moderni."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Sistema brevettato che garantisce performance e standard elevati."
        ) +
        "<br /></p>",
      images: [Screeny1Image1, Screeny1Image2, Screeny1Image3, Screeny1Image4],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Screeny"),
          href: Screeny1Depliant20x25Screeny,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: Screeny1DepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: ScreenyCoulisseScreenyCoulisseZipSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 110 COULISSE</strong><br />" +
      getLanguageTranslation(
        "Tenda tessuto filtrante/oscurante a rullo con cassonetto da 110 mm disponibile in 2 varianti (tondo/quadro)."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Adatto per la schermatura esterna di finestre e facciate continue in molte tipologie di costruzioni: dalle abitazioni private agli edifici commerciali più moderni."
      ) +
      "</p>"
  ),
  /*createPositionPoint(
    [-4870.02, -1099.68, 100.51],
    "isola3-sotto",
    "ISOLA 3 (Sotto)"
  ),*/
  createPositionPoint([-52.42, -972.55, -4897.83], "isola3", "ISOLA 3"),
  createPositionPoint([-3889.67, -120.87, -3123.32], "vega", "GENNIUS VEGA"),
  createPositionPoint(
    [-63.28, -512.02, 4967.1],
    "screeny2",
    "SCREENY 110 COULISSE ZIP"
  ),
  createOptionalPoint(
    [3998.64, 1174.2, 2753.53],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createColorPoint(
    [3317.94, -2713.11, 2561.01],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "gray",
        value: "gray",
        text: "RAL9006",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createVrPoint([3596.08, -2809.27, -2030.71], Screeny1GLB, Screeny1USDZ),
];

export const createScreeny1BackFrInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [3944.36, -3021.71, -502.26],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "SCREENY 110 COULISSE",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda tessuto filtrante/oscurante a rullo con cassonetto da 110 mm disponibile in 2 varianti (tondo/quadro)."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Adatto per la schermatura esterna di finestre e facciate continue in molte tipologie di costruzioni: dalle abitazioni private agli edifici commerciali più moderni."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Sistema brevettato che garantisce performance e standard elevati."
        ) +
        "<br /></p>",
      images: [Screeny1Image1, Screeny1Image2, Screeny1Image3, Screeny1Image4],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Screeny"),
          href: Screeny1Depliant20x25Screeny,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: Screeny1DepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: ScreenyCoulisseScreenyCoulisseZipSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY 110 COULISSE</strong><br />" +
      getLanguageTranslation(
        "Tenda tessuto filtrante/oscurante a rullo con cassonetto da 110 mm disponibile in 2 varianti (tondo/quadro)."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Adatto per la schermatura esterna di finestre e facciate continue in molte tipologie di costruzioni: dalle abitazioni private agli edifici commerciali più moderni."
      ) +
      "</p>"
  ),
  createPositionPoint(
    [226.24, -575.84, -4951.01],
    "screeny2-back",
    "SCREENY 110 COULISSE ZIP"
  ),
  createPositionPoint(
    [2420.96, -853.56, 4285.49],
    "isola3",
    "ISOLA 3"
  ),
  createOptionalPoint(
    [4230.43, 1150.05, 2399.3],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createColorPoint(
    [3408.1, -2683.34, 2481.23],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "gray",
        value: "gray",
        text: "RAL9006",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createVrPoint([3253.97, -2666.95, -2688.95], Screeny1GLB, Screeny1USDZ),
];
