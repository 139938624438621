import { TechnicalSpecificsType } from "../../types";
import { getTechnicalSpecificsColor } from "./get-technical-specifics-color";
import { getTechnicalSpecificsTime } from "./get-technical-specifics-time";
import { getTechnicalSpecificsBooleanRoof } from "./get-technical-specifics-boolean-roof";

export const getScreeny2BackImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `screeny2-back${getTechnicalSpecificsColor(
    technicalSpecifics,
    ["white", "gray"],
    "white"
  )}${getTechnicalSpecificsTime(
    technicalSpecifics
  )}${getTechnicalSpecificsBooleanRoof(technicalSpecifics)}`;
