import { TechnicalSpecificsType } from "../../types";
import { getTechnicalSpecificsTime } from "./get-technical-specifics-time";
import { getTechnicalSpecificsBooleanRoof } from "./get-technical-specifics-boolean-roof";

export const getQubicaLiteImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `qubica-lite${getTechnicalSpecificsTime(
    technicalSpecifics
  )}${getTechnicalSpecificsBooleanRoof(technicalSpecifics)}`;
