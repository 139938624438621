import { Play } from "../icons";

export const useCreateVideoPoint: (
  setPopupInfos: any,
  lang: string
) => (
  position: number[],
  title?: string,
  video?: string
) => Record<string, any> = (setPopupInfos) => (
  position,
  title = "Video",
  video
) => ({
  position,
  text: title,
  image: Play,
  type: "video",
  description: null,
  clickListener: () => {
    setPopupInfos({
      title: "",
      noCloseIcon: true,
      type: "video",
      popupClass: "popup-video_container",
      video,
    });
  },
});
