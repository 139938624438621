import { getLanguageTranslation } from "../translations/get-language-translation";
import {
  IsolaImage1,
  IsolaImage2,
  IsolaImage3,
  IsolaImage4,
  IsolaImage5,
  IsolaImage6,
  IsolaImage7,
  IsolaImage8,
  IsolaImage9,
  IsolaImage10,
  IsolaImage11,
  IsolaImage12,
  IsolaImage13,
  IsolaImage14,
  IsolaImage15,
  IsolaDepliant20x25Gennius,
  IsolaDepliantLineGlassIt,
  IsolaDepliantPaletteColori2021It,
  IsolaSchedaTecnica,
  Isola3GLB,
  Isola3USDZ,
} from "./index";

export const createIsola3InfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createVrPoint,
  createColorPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [4838.42, -1243.3, -37.63],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "ISOLA 3",
      description:
        "<p>" +
        getLanguageTranslation(
          "Schermatura a lame con tetto ad impachettamento e arco ribassato per il minimo ingombro."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Ampia selezione di tessuti tecnici per la copertura e kit luci dimmerabile integrato nel perimetro interno ed esterno."
        ) +
        "<br /></p>",
      images: [
        IsolaImage1,
        IsolaImage2,
        IsolaImage3,
        IsolaImage4,
        IsolaImage5,
        IsolaImage6,
        IsolaImage7,
        IsolaImage8,
        IsolaImage9,
        IsolaImage10,
        IsolaImage11,
        IsolaImage12,
        IsolaImage13,
        IsolaImage14,
        IsolaImage15,
      ],
      buttons: [
        {
          text: "Depliant Gennius",
          href: IsolaDepliant20x25Gennius,
        },
        {
          text: "Line Glass",
          href: IsolaDepliantLineGlassIt,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: IsolaDepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: IsolaSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>ISOLA 3</strong><br />" +
      getLanguageTranslation(
        "Schermatura a lame con tetto ad impachettamento e arco ribassato per il minimo ingombro."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni."
      ) +
      "</p>"
  ),
  createPositionPoint(
    [4830.51, -548.65, -1133.47],
    "isola3-sotto",
    getLanguageTranslation("ISOLA 3 (Sotto)")
  ),
  createOptionalPoint([4783.66, 12.88, -1437.23], getLanguageTranslation("Vetrata"), "glass"),
  createOptionalPoint(
    [4924.99, 704.11, 432.89],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open",
    technicalSpecifics.time === "night"
  ),
  createOptionalPoint(
    [4867.44, 120.74, 1108.2],
    getLanguageTranslation("Tenda a caduta"),
    "curtain"
  ),
  createColorPoint(
    [4774.26, -965.14, 1096.33],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "black",
        value: "black",
        text: "CARBON RUVIDO",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createVrPoint([4546.5, -664.43, -1956.28], Isola3GLB, Isola3USDZ),

  createPositionPoint(
    [3978.93, -963.43, -2861.82],
    "screeny1",
    "SCREENY 110 GC S12"
  ),
  createPositionPoint(
    [1359.9, -891.12, -4718.86],
    "screeny1-back",
    "SCREENY 110 GC S12"
  ),

  createPositionPoint([3781.79, -605.7, 3199.52], "isola3_to_vega", ""),
];

export const createIsola3SottoInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [4258.07, -2488.16, 775.05],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "ISOLA 3",
      description:
        "<p>" +
        getLanguageTranslation(
          "Schermatura a lame con tetto ad impachettamento e arco ribassato per il minimo ingombro."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Ampia selezione di tessuti tecnici per la copertura e kit luci dimmerabile integrato nel perimetro interno ed esterno."
        ) +
        "<br /></p>",
      images: [
        IsolaImage1,
        IsolaImage2,
        IsolaImage3,
        IsolaImage4,
        IsolaImage5,
        IsolaImage6,
        IsolaImage7,
        IsolaImage8,
        IsolaImage9,
        IsolaImage10,
        IsolaImage11,
        IsolaImage12,
        IsolaImage13,
        IsolaImage14,
        IsolaImage15,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Gennius"),
          href: IsolaDepliant20x25Gennius,
        },
        {
          text: "Line Glass",
          href: IsolaDepliantLineGlassIt,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: IsolaDepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: IsolaSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>ISOLA 3</strong><br />" +
      getLanguageTranslation(
        "Schermatura a lame con tetto ad impachettamento e arco ribassato per il minimo ingombro."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni."
      ) +
      "</p>"
  ),
  createPositionPoint(
    [695.07, -1693.83, 4642.39],
    "screeny1",
    "SCREENY 110 GC S12"
  ),
  createPositionPoint([4387.49, -969.25, 2170.16], "isola3", "ISOLA 3"),
  createOptionalPoint(
    [4571.35, 1647.91, -1140.95],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createOptionalPoint(
    [4746.61, -389.46, 1518.41],
    getLanguageTranslation("Vetrata"),
    "glass"
  ),
  createOptionalPoint(
    [2005.54, -290.51, -4566.32],
    getLanguageTranslation("Tenda a caduta"),
    "curtain"
  ),
  createColorPoint(
    [4729.07, -1406.8, 747.06],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "black",
        value: "black",
        text: "CARBON RUVIDO",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createPositionPoint([3538.18, -811.54, -3428.01], "isola3_to_vega", ""),
  createPositionPoint(
    [-2445.63, -1125.63, -4210.79],
    "isola3_to_kedry-skylife",
    ""
  ),
  createVrPoint([4165.09, -2109.55, -1770.04], Isola3GLB, Isola3USDZ),
];

export const createIsola3EsInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createVrPoint,
  createColorPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [4838.42, -1243.3, -37.63],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "ISOLA 3",
      description:
        "<p>" +
        getLanguageTranslation(
          "Schermatura a lame con tetto ad impachettamento e arco ribassato per il minimo ingombro."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Ampia selezione di tessuti tecnici per la copertura e kit luci dimmerabile integrato nel perimetro interno ed esterno."
        ) +
        "<br /></p>",
      images: [
        IsolaImage1,
        IsolaImage2,
        IsolaImage3,
        IsolaImage4,
        IsolaImage5,
        IsolaImage6,
        IsolaImage7,
        IsolaImage8,
        IsolaImage9,
        IsolaImage10,
        IsolaImage11,
        IsolaImage12,
        IsolaImage13,
        IsolaImage14,
        IsolaImage15,
      ],
      buttons: [
        {
          text: "Depliant Gennius",
          href: IsolaDepliant20x25Gennius,
        },
        {
          text: "Line Glass",
          href: IsolaDepliantLineGlassIt,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: IsolaDepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: IsolaSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>ISOLA 3</strong><br />" +
      getLanguageTranslation(
        "Schermatura a lame con tetto ad impachettamento e arco ribassato per il minimo ingombro."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni."
      ) +
      "<br />" +
      "</p>"
  ),
  createPositionPoint(
    [4830.51, -548.65, -1133.47],
    "isola3-sotto",
    getLanguageTranslation("ISOLA 3 (Sotto)")
  ),
  createOptionalPoint([4783.66, 12.88, -1437.23], getLanguageTranslation("Vetrata"), "glass"),
  createOptionalPoint(
    [4924.99, 704.11, 432.89],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open",
    technicalSpecifics.time === "night"
  ),
  createOptionalPoint(
    [4867.44, 120.74, 1108.2],
    getLanguageTranslation("Tenda a caduta"),
    "curtain"
  ),
  createColorPoint(
    [4774.26, -965.14, 1096.33],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "black",
        value: "black",
        text: "CARBON RUVIDO",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createVrPoint([4546.5, -664.43, -1956.28], Isola3GLB, Isola3USDZ),

  createPositionPoint([3781.79, -605.7, 3199.52], "isola3_to_vega", ""),
  createPositionPoint([3680.68, -292.63, -3364.46], "screeny2_to_kolibrie", ""),
];

export const createIsola3SottoEsInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [4258.07, -2488.16, 775.05],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "ISOLA 3",
      description:
        "<p>" +
        getLanguageTranslation(
          "Schermatura a lame con tetto ad impachettamento e arco ribassato per il minimo ingombro."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Ampia selezione di tessuti tecnici per la copertura e kit luci dimmerabile integrato nel perimetro interno ed esterno."
        ) +
        "<br /></p>",
      images: [
        IsolaImage1,
        IsolaImage2,
        IsolaImage3,
        IsolaImage4,
        IsolaImage5,
        IsolaImage6,
        IsolaImage7,
        IsolaImage8,
        IsolaImage9,
        IsolaImage10,
        IsolaImage11,
        IsolaImage12,
        IsolaImage13,
        IsolaImage14,
        IsolaImage15,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Gennius"),
          href: IsolaDepliant20x25Gennius,
        },
        {
          text: "Line Glass",
          href: IsolaDepliantLineGlassIt,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: IsolaDepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: IsolaSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>ISOLA 3</strong><br />" +
      getLanguageTranslation(
        "Schermatura a lame con tetto ad impachettamento e arco ribassato per il minimo ingombro."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni."
      ) +
      "</p>"
  ),
  createPositionPoint([4387.49, -969.25, 2170.16], "isola3", "ISOLA 3"),
  createOptionalPoint(
    [4571.35, 1647.91, -1140.95],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createOptionalPoint(
    [4746.61, -389.46, 1518.41],
    getLanguageTranslation("Vetrata"),
    "glass"
  ),
  createOptionalPoint(
    [2005.54, -290.51, -4566.32],
    getLanguageTranslation("Tenda a caduta"),
    "curtain"
  ),
  createColorPoint(
    [4729.07, -1406.8, 747.06],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "black",
        value: "black",
        text: "CARBON RUVIDO",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createPositionPoint([3538.18, -811.54, -3428.01], "isola3_to_vega", ""),
  createPositionPoint(
    [-2445.63, -1125.63, -4210.79],
    "isola3_to_kedry-skylife",
    ""
  ),
  createVrPoint([4165.09, -2109.55, -1770.04], Isola3GLB, Isola3USDZ),
];

export const createIsola3FrInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createVrPoint,
  createColorPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [4838.42, -1243.3, -37.63],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "ISOLA 3",
      description:
        "<p>" +
        getLanguageTranslation(
          "Schermatura a lame con tetto ad impachettamento e arco ribassato per il minimo ingombro."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Ampia selezione di tessuti tecnici per la copertura e kit luci dimmerabile integrato nel perimetro interno ed esterno."
        ) +
        "<br /></p>",
      images: [
        IsolaImage1,
        IsolaImage2,
        IsolaImage3,
        IsolaImage4,
        IsolaImage5,
        IsolaImage6,
        IsolaImage7,
        IsolaImage8,
        IsolaImage9,
        IsolaImage10,
        IsolaImage11,
        IsolaImage12,
        IsolaImage13,
        IsolaImage14,
        IsolaImage15,
      ],
      buttons: [
        {
          text: "Depliant Gennius",
          href: IsolaDepliant20x25Gennius,
        },
        {
          text: "Line Glass",
          href: IsolaDepliantLineGlassIt,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: IsolaDepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: IsolaSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>ISOLA 3</strong><br />" +
      getLanguageTranslation(
        "Schermatura a lame con tetto ad impachettamento e arco ribassato per il minimo ingombro."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni."
      ) +
      "</p>"
  ),
  createPositionPoint(
    [4830.51, -548.65, -1133.47],
    "isola3-sotto",
    getLanguageTranslation("ISOLA 3 (Sotto)")
  ),
  createOptionalPoint([4783.66, 12.88, -1437.23], getLanguageTranslation("Vetrata"), "glass"),
  createOptionalPoint(
    [4924.99, 704.11, 432.89],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open",
    technicalSpecifics.time === "night"
  ),
  createOptionalPoint(
    [4867.44, 120.74, 1108.2],
    getLanguageTranslation("Tenda a caduta"),
    "curtain"
  ),
  createColorPoint(
    [4774.26, -965.14, 1096.33],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "black",
        value: "black",
        text: "CARBON RUVIDO",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createVrPoint([4546.5, -664.43, -1956.28], Isola3GLB, Isola3USDZ),

  createPositionPoint(
    [3978.93, -963.43, -2861.82],
    "screeny1",
    "SCREENY 110 COULISSE"
  ),
  createPositionPoint(
    [1359.9, -891.12, -4718.86],
    "screeny1-back",
    "SCREENY 110 COULISSE"
  ),

  createPositionPoint([3781.79, -605.7, 3199.52], "isola3_to_vega", ""),
];

export const createIsola3SottoFrInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [4258.07, -2488.16, 775.05],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "ISOLA 3",
      description:
        "<p>" +
        getLanguageTranslation(
          "Schermatura a lame con tetto ad impachettamento e arco ribassato per il minimo ingombro."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Ampia selezione di tessuti tecnici per la copertura e kit luci dimmerabile integrato nel perimetro interno ed esterno."
        ) +
        "<br /></p>",
      images: [
        IsolaImage1,
        IsolaImage2,
        IsolaImage3,
        IsolaImage4,
        IsolaImage5,
        IsolaImage6,
        IsolaImage7,
        IsolaImage8,
        IsolaImage9,
        IsolaImage10,
        IsolaImage11,
        IsolaImage12,
        IsolaImage13,
        IsolaImage14,
        IsolaImage15,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Gennius"),
          href: IsolaDepliant20x25Gennius,
        },
        {
          text: "Line Glass",
          href: IsolaDepliantLineGlassIt,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: IsolaDepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: IsolaSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>ISOLA 3</strong><br />" +
      getLanguageTranslation(
        "Schermatura a lame con tetto ad impachettamento e arco ribassato per il minimo ingombro."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni."
      ) +
      "</p>"
  ),
  createPositionPoint(
    [695.07, -1693.83, 4642.39],
    "screeny1",
    "SCREENY 110 COULISSE"
  ),
  createPositionPoint([4387.49, -969.25, 2170.16], "isola3", "ISOLA 3"),
  createOptionalPoint(
    [4571.35, 1647.91, -1140.95],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createOptionalPoint(
    [4746.61, -389.46, 1518.41],
    getLanguageTranslation("Vetrata"),
    "glass"
  ),
  createOptionalPoint(
    [2005.54, -290.51, -4566.32],
    getLanguageTranslation("Tenda a caduta"),
    "curtain"
  ),
  createColorPoint(
    [4729.07, -1406.8, 747.06],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "black",
        value: "black",
        text: "CARBON RUVIDO",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createPositionPoint([3538.18, -811.54, -3428.01], "isola3_to_vega", ""),
  createPositionPoint(
    [-2445.63, -1125.63, -4210.79],
    "isola3_to_kedry-skylife",
    ""
  ),
  createVrPoint([4165.09, -2109.55, -1770.04], Isola3GLB, Isola3USDZ),
];