import { LOCALSTORAGE_LANGUAGE } from "../../consts";

export const getLanguageTranslation = (entry: string, lang?: string) => {
  let language = "it";
  //let language = localStorage.getItem(LOCALSTORAGE_LANGUAGE)
  if (typeof window !== `undefined`) {
    language = localStorage.getItem(LOCALSTORAGE_LANGUAGE);
  }

  if (lang !== undefined && lang !== null) language = lang;

  if (language === "it") return entry;
  if (language === "en") {
    switch (entry) {
      case "Dopo aver cambiato lingua, lo showroom verrà ricaricato.":
        return "After changing the language, the showroom will be reloaded.";
      //  MENU
      case "APERTO":
        return "OPEN";
      case "CHIUSO":
        return "CLOSE";
      case "NOTTE":
        return "NIGHT";
      case "GIORNO":
        return "DAY";
      case "MAPPA":
        return "MAP";
      case "POSIZIONE":
        return "POSITION";
      case "LINGUA":
        return "LANGUAGE";
      case "PRODOTTI":
        return "PRODUCTS";
      case "PERCORSO":
        return "PATH";
      case "COLORI":
        return "COLORS";

      //  LANGUAGES
      case "Italiano":
        return "Italian";
      case "Inglese":
        return "English";
      case "Spagnolo":
        return "Spanish";
      case "Francese":
        return "French";
      case "Tedesco":
        return "German";

      //  INFO
      case "Scheda Tecnica":
        return "Technical Sheet";
      case "COLORE STRUTTURA":
        return "STRUCTURE COLOR";
      case "Palette Colori":
        return "Colours Palette";
      case "BRACCIO":
        return "ARM";
      case "Depliant Gennius":
        return "Gennius dépliant";
      case "Depliant Sunlight":
        return "Sunlight dépliant";
      case "Depliant Kedry":
        return "Kedry dépliant";
      case "Depliant Screeny":
        return "Screeny dépliant";
      case "Depliant Tende":
        return "Awnings dépliant";
      case "Depliant Keplan":
        return "Keplan dépliant";
      case "Depliant Vele Sails":
        return "Sails dépliant";
      case "Copertura":
        return "Coverage";
      case "Tenda a caduta":
        return "Drop Awning";
      case "Vetrata":
        return "Glass";
      case "ISOLA 3 (Sotto)":
        return "ISOLA 3 (Beneath)";
      case "Apertura della copertura":
        return "Cover opening";
      case "Chiusura della copertura":
        return "Cover closing";
      case "KEDRY SKYLIFE (Angolo)":
        return "KEDRY SKYLIFE (Corner)";
      case "KEDRY PRIME (Sotto)":
        return "KEDRY PRIME (Beneath)";
      case "KEDRY SKYLIFE (Sotto)":
        return "KEDRY SKYLIFE (Beneath)";
      case "SUNLIGHT (Sotto)":
        return "SUNLIGHT (Beneath)";

      // MAPPA
      case "ISOLA 3 - SOTTO":
        return "ISOLA 3 - BENEATH";
      case "KEDRY PRIME - SOTTO":
        return "KEDRY PRIME - BENEATH";
      case "KEDRY SKYLIFE - SOTTO":
        return "KEDRY SKYLIFE - BENEATH";

      //  FORM
      case "Vuoi più informazioni sui prodotti KE?":
        return "Do you want more information on KE products?";
      case "Scrivici: ti offriamo un preventivo gratuito e senza impegno.":
        return "Write to us: we offer you a free, no-obligation quote.";
      case "Ti rispondiamo entro 24/48H":
        return "We will reply to you within 24 / 48H";
      case "Qualcosa è andato storto.":
        return "Something went wrong";
      case "Grazie per averci contattato!":
        return "Thanks for contacting us!";
      case "Il tuo nome":
        return "Your name";
      case "Il tuo indirizzo e-mail *":
        return "Your e-mail address *";
      case "Telefono *":
        return "Your phone *";
      case "La tua città *":
        return "Your city *";
      case "Vuoi raccontarci la tua esigenza?":
        return "Do you want to tell us your need?";
      case "Acconsento al trattamento dei miei dati personali":
        return "I agree to the treatment of my personal data";
      case "Regolamento UE 2016/679":
        return "EU Regulation 2016/679";
      case "Desidero un preventivo gratuito":
        return "I would like a free quote";
      case "Riceverai una risposta entro 24-48H":
        return "You will receive a reply within 24-48H";

      //  HELP
      case "Ambiente a 360°":
        return "360° environment";
      case "È possibile esplorare liberamente l'ambiente cliccando e trascinando il cursore, spostandosi attraverso i punti di movimento.":
        return "You can freely explore the environment by clicking and dragging the cursor, moving through the movement points.";
      case "Interattivo":
        return "Interactive";
      case "In ogni prodotto sono disponibili vari punti di informazione, con tutti i materiali relativi al prodotto che si sta visualizzando.":
        return "In each product there are various information points, with all the materials related to the product you are viewing.";
      case "Immersivo":
        return "Immersive";
      case "È possibile ingrandire o rimpicciolire a piacimento le immagini, per una visione più specifica del prodotto.":
        return "You can zoom in or out as you like, for a more specific view of the product.";
      case "Immagine non trovata. Verrai riportato all'introduzione.":
        return "Image not found. You will be taken back to the introduction.";
      case "Cambia Posizione":
        return "Change Position";

      //  INTRO
      case "ENTRATA":
        return "ENTRANCE";

      // A 100
      case "Tenda a pergola in alluminio con copertura scorrevole ad impacchettamento.":
        return "Aluminum pergola with contemporary design and retractable, waterproof roof.";
      case "Design squadrato ed essenziale e ideale per ampie coperture e differenti contesti.":
        return "Square, essential design is ideal for large areas and a variety of styles.";
      case "Il tamponamento e le chiusure laterali si armonizzano con il disegno pulito dei profili.":
        return "The roof and side closures harmonize with the clean design of the profiles.";

      // KEDRY PRIME
      case "Schermatura a lame in alluminio e tetto a lame orientabili fino a fino a 135°.":
        return "Louvered roof pergola with blades that adjust up to 135˚.";
      case "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni.":
        return "Modern design and integrated closure system makes this pergola suitable for all seasons.";
      case "Sistema di strip led integrate nei profili perimetrali esterni e interni.":
        return "Integrated LED strip lights provide both interior and exterior lighting.";

      // KEDRY SKYLIFE
      case "Perché scegliere la nuova pergola bioclimatica KE per il tuo outdoor?":
        return "Why choose the new KE bioclimatic pergola for your outdoor space?";
      case "- Novità: il tetto a lame mobili retrattili":
        return "- New: the retractable, movable louvered roof";
      case "Per regolare l’intensità della luce e la ventilazione, in funzione delle condizioni meteo.":
        return "To adjust the intensity of light and ventilation, according to the weather.";
      case "- Il sistema innovativo":
        return "- The innovative system";
      case "Con un unico movimento le lame ruotano e arretrano, in apertura e chiusura, consentendo di esprimere al massimo il concetto di regolazione termica e luminosa.":
        return "With a single movement, the blades rotate and retract, enabling you to regulate both light intensity and temperature for complete comfort.";
      case "- Sistema modulare":
        return "- Modular system";
      case "Kedry Skylife si presta ad essere inserita ed adattata in spazi di forme diverse, coprendo metrature importanti, e con un impatto estetico minimale.":
        return "Kedry Skylife has a modular design that is easily adapted to spaces of different shapes, covering large areas with a minimal footprint.";
      case "- Perfetta automazione":
        return "- Perfect automation";
      case "App per smartphone e tablet consentono una perfetta gestione anche da remoto, permettendo di definire tempo e modalità di apertura e chiusura della tenda.":
        return "Apps for smartphones and tablets allow perfect management, even remotely, allowing you to define the timing of awning operation.";
      case "- Illuminazione a LED":
        return "- LED illumination";
      case "È prevista la possibilità di illuminare il prodotto internamente ed esternamente grazie ad un sistema di strip led integrate nei profili perimetrali.":
        return "Both internal and external LED illumination is possible thanks to a system of LED strips integrated in the perimeter profiles.";
      case "Kedry Skylife: un nuovo modo di concepire la vita all’aria aperta, nel massimo del comfort.":
        return "Kedry Skylife: a new way of imagining life in the open air, in maximum comfort.";
      case "Lame mobili retrattili che regolano l'intensità della luce e la ventilazione in funzione delle condizioni meteo.":
        return "Retractable, movable blades that adjust light intensity and ventilation according to the weather and your preference.";
      case "Con il suo sistema modulare, Kedry Skylife si presta ad essere inserita ed adattata in spazi di forme diverse, coprendo metrature importanti, e con impatto estetico minimale.":
        return "With its modular design, Kedry Skylife lends itself to outdoor spaces of various shapes, covering large areas with a minimal footprint.";

      // KHEOPE
      case "Vela ombreggiante fissa dal design moderno con struttura in acciaio INOX.":
        return "Fixed position shade sail with modern design and stainless steel construction.";
      case "Copertura di ampie dimensioni e alta resistenza agenti atmosferici e salsedine.":
        return "Large coverage area and highly resistant to weather and saltwater.";
      case "Movimentazione avvolgimento telo motorizzata dotata di anemometro.":
        return "Automated motor control with wind speed sensor.";

      // KOLIBRIE
      case "Ombreggiatore a vela con un unico palo di sostegno disassato in acciaio INOX.":
        return "Shade sail with a single, stainless steel post on a rotating base.";
      case "Orientabile a 360° grazie al piedistallo a cuscinetti e resistente all'usura.":
        return "Durable, stainless pedestal rotates 360-degrees.";
      case "Movimentazione avvolgimento telo manuale oppure motorizzata.":
        return "Manual or motorized operation.";

      // QBICA FLAT
      case "Tenda da sole a cassonetto dalle linee essenziali ed eleganti adatta ad ogni ambiente architettonico.":
        return "Cassette awning with essential, elegant lines, compatible with any architectural style.";
      case "Disponibile in 4 colori che possono essere combinati per raggiungere 16 diverse configurazioni.":
        return "Available in 4 colors that can be arranged to achieve 16 different configurations.";
      case "È possibile scegliere tra oltre 500 tipologie diverse di tessuto tutte confezionate senza cuciture.":
        return "Select from more than 500 different seamless fabrics.";

      // SCREENY 110 GC S12
      case "Tenda tessuto filtrante/oscurante a rullo con cassonetto da 110 mm disponibile in 2 varianti (tondo/quadro).":
        return "Roller screen with light-filtering or blackout fabric and 110 mm cassette box, available in round or square styles.";
      case "Adatto per la schermatura esterna di finestre e facciate continue in molte tipologie di costruzioni: dalle abitazioni private agli edifici commerciali più moderni.":
        return "Suitable for external shading of windows and walls in many types of construction, from private homes to the most modern commercial buildings.";
      case "Sistema brevettato che garantisce performance e standard elevati.":
        return "Patented system provides excellent performance from high quality standards.";

      // SCREENY 110 GPZ UNICA S32
      case "Tenda tessuto filtrante/oscurante a rullo con cassonetto da 110 mm disponibile in 2 varianti (tondo/quadro) dotata di guida profilo zip.":
        return "Roller screen with light-filtering or blackout fabric and 110 mm cassette box, available in round or square styles and ZIP screen closure.";
      case "Adatto per la schermatura esterna di finestre e facciate continue in molte tipologie di costruzioni: dalle abitazioni private agli edifici commerciali più moderni.":
        return "Suitable for external shading of windows and walls in many types of construction, from private homes to the most modern commercial buildings.";
      case "Sistema brevettato che garantisce performance e standard elevati.":
        return "Patented system provides excellent performance from high quality standards.";

      // SUNLIGHT
      case "Un Giardino d'inverno, una stanza outdoor studiata per ridisegnare gli spazi esterni in perfetta simbiosi con l’ambiente circostante.":
        return "SUNLIGHT is a conservatory, an outdoor room designed to be in perfect harmony with the surrounding environment.";
      case "Donando un nuovo senso di ampiezza e rivoluziona il concetto di comfort e luce naturale in qualsiasi stagione dell’anno.":
        return "It gives a new sense of spaciousness and it revolutionizes the concept of comfort and natural light in any season of the year.";
      case "Sunlight è stata progettata per avere importanti prestazioni in caso di situazioni metereologiche avverse.":
        return "Sunlight is designed for superior performance in all weather conditions.";

      // VEGA
      case "Schermatura a lame con tetto ad impaccehttamento dal design lineare contemporaneo.":
        return "Aluminum pergola with contemporary design and retractable, waterproof roof.";
      case "Completa modularità della struttura ed elevata possibilità di personalizzazione.":
        return "Modular design with many customization options.";
      case "Ampia selezione di tessuti tecnici e Innovativi inserti luminosi a luce diffusa lungo guide e colonne.":
        return "Wide selection of fabrics and innovative LED illumination with diffused light along guides and columns.";

      // VENEZIA GOLD
      case "Tenda da sole a bracci motorizzata su barra quadra.":
        return "Motorized arm awning with square bar.";
      case "Design accattivante grazie al supporto Gold e al rivoluzionario braccio Aki.":
        return "Attractive design thanks to Gold support and revolutionary Aki arm.";
      case "Avvolgimento telo con sistema brevettato per evitare pieghe e grinze in fase di arrotolamento.":
        return "Includes a patented system to avoid creases and wrinkles during rolling.";

      // ISOLA 3
      case "Schermatura a lame con tetto ad impachettamento e arco ribassato per il minimo ingombro.":
        return "Retractable roof pergola with arched roof for minimal footprint.";
      case "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni.":
        return "Complete modularity and integrated closure system that makes the structure suitable for all seasons.";
      case "Ampia selezione di tessuti tecnici per la copertura e kit luci dimmerabile integrato nel perimetro interno ed esterno.":
        return "Wide selection of fabrics for the cover and dimmable LEDs integrated into the internal and external perimeter.";

      // HYDRA
      case "Tenda a pergola con telo avvolto dal design minimal priva di viteria in vista.":
        return "Retractable roof pergola with hidden hardware.";
      case "Scalabile in larghezza così da consentire la copertura di superfici di dimensioni importanti.":
        return "Adjustable width allows flexibility for a variety of coverage options.";
      case "Ampia selezione di tessuti tecnici e illuminazione integrata sulle guide e sul cassonetto.":
        return "Wide selection of fabrics and integrated lighting on the guides and on the cassette.";

      // XTESA
      case "Tenda a pergola con struttura in alluminio e tessuto ad avvolgimento.":
        return "Aluminum pergola awning with folding fabric.";
      case "Ideale per installazioni in ambienti architettonici estremi, raggiunge notevoli sporgenze con pendenze contenute.":
        return "Ideal for installations in extreme architectural environments, Xtesa reaches considerable projections with minimal slope.";
      case "Il tessuto arrotolato garantisce un ottimo risultato estetico, rispetto al classico ingombro del tessuto a impacchettamento.":
        return "Rolled fabric guarantees excellent aesthetics compared to the bulkiness of folding fabric.";

      // KEPLAN
      case "Innovativa pergola in alluminio a tetto piano. Il suo design minimale rompe con il tradizionale schema della pergola a tetto inclinato.":
        return "Innovative aluminum pergola with flat roof. Its minimal design breaks with the traditional pattern of the sloping roof pergola.";
      case "NOVITÀ SALONE DEL MOBILE 2023":
        return "NEW SALONE DEL MOBILE 2023";
      case "KE KEPLAN Design lineare e geometria piana al servizio della progettazione outdoor":
        return "KE KEPLAN Linear design and flat geometry in service to outdoor design";
      case "Armonia, ordine, essenzialità: tre concetti che si possono ricondurre in architettura alla linea retta. L’occhio per sottrazione distingue in questo tratto le caratteristiche di eleganza e semplicità. Fonte di ispirazione e aspirazione nel corso della storia del design, KE applica il principio della purezza delle linee a servizio del benessere abitativo e psicofisico nel nuovo prodotto KEPLAN.":
        return "Harmony, order, essentiality: three concepts that can be traced back to the straight line in architecture. These traits allow the eye to distinguish the characteristics of elegance and simplicity. A source of inspiration and aspiration throughout the history of design, KE applies the principle of purity of lines at the service of living and psychophysical well-being in the new KEPLAN product.";
      case "KEPLAN è l’innovativa pergola in alluminio a tetto piano e telo ad impacchettamento nata per integrarsi perfettamente in qualsiasi soluzione progettuale contemporanea. Il suo design minimale rompe con il tradizionale schema della pergola a tetto inclinato: funzionalità e comfort si uniscono ad una geometria piana adatta alle più diverse realizzazioni outdoor, sia in ambito residenziale sia ho.re.ca.":
        return "KEPLAN is the innovative aluminum pergola with flat roof and folding fabric created to integrate perfectly into any contemporary design solution. Its minimal design breaks with the traditional pattern of the sloping roof pergola: functionality and comfort are combined with a flat geometry suitable for the most diverse outdoor projects, both in residential and ho.re.ca.";
      case "Grazie all’innovativo sistema di colonne regolabili in salita e in discesa, l’installazione di KEPLAN si adatta perfettamente anche a contesti difficoltosi: questo sistema di regolazione infatti risolve la problematica di pendenze e dislivelli del terreno.":
        return "Thanks to the innovative system of adjustable vertical columns the installation of KEPLAN is perfectly suited even in challenging situations: this adjustment system in fact solves the problem of slopes and differences in height of the terrain.";
      case "Disponibile sia in versione autoportante che addossata, la nuova pergola KE può essere illuminata grazie ad un sistema di strip led dimmerabili inseriti nei traversi per creare suggestive ambientazioni luminose dedicate alla convivialità e al relax.":
        return "Available in both self-supporting and wall-mounted versions, the new KE pergola can be illuminated with a system of dimmable LED strips inserted in the profiles to create welcoming lighting environments dedicated to socializing and relaxation.";
      case "KEPLAN ha copertura con caratteristica forma del tetto ad arco ribassato che permette di diminuire l’ingombro in altezza e di migliorare la resa estetica complessiva. Per rispondere a due richieste fondamentali – la necessità di privacy e l’efficacia di protezione dal sole e dalla pioggia - la pergola è dotata di tenda, richiudibile ad impacchettamento. Il telo, che risulta teso nella sua massima apertura, è fissato su traversi ad arco e scorre orizzontalmente per mezzo di carrelli su guide motorizzate.":
        return "KEPLAN’s roof is a lowered arched that allows for minimal overall dimensions and improves the aesthetic results. To respond to two fundamental requests – the need for privacy and the effectiveness of protection from the sun and rain – the  pergola is equipped with a curtain, which can be folded up. The cloth, which is stretched tight at its maximum opening, is fixed on arched transoms and slides horizontally by means of trolleys on motorized guides.";
      case "Illuminazione e movimentazione del telo sono regolati da sistemi di domotica integrata.":
        return "Lighting and movement of the fabric are regulated by integrated home automation systems.";
      case "La massima resa estetica e la duttilità di KEPLAN si confermano anche nell’ampia gamma di tessuti disponibili funzionali alla personalizzazione massima.":
        return "The beauty and versatility of KEPLAN allow for a wide range of available fabrics to suit any taste.";
      case "Discrezione e piacevolezza, affidabilità e funzionalità, adattabilità e tecnologia sono le caratteristiche che KE sintetizza in KEPLAN, per creare nuovi ambienti outdoor raffinati e accoglienti dove poter godere del miglior “tempo per sé” e coltivare momenti di rilassata convivialità.":
        return "Discretion and pleasantness, reliability and functionality, adaptability and technology are the characteristics that KE has integrated into KEPLAN, to create new refined and welcoming outdoor environments where you can enjoy the best \"time for yourself\" and cultivate moments of relaxed socializing.";
      case "Caratteristiche di performance":
        return "Performance characteristics";
      case "Design squadrato ed essenziale":
        return "Squared and essential design";
      case "Ideale per ampie coperture":
        return "Ideal for large covers";
      case "Resistenza al sole":
        return "Sun resistance";
      case "Resistenza al vento, classe 6":
        return "Wind resistance, class 6";
      case "Massima larghezza: 550 cm":
        return "Maximum width: 550 cm";
      case "Massima sporgenza: 600cm":
        return "Maximum overhang: 600cm";

      default:
        return entry;
    }
  }
  if (language === "en-us") {
    switch (entry) {
      case "Dopo aver cambiato lingua, lo showroom verrà ricaricato.":
        return "After changing the language, the showroom will be reloaded.";
      //  MENU
      case "APERTO":
        return "OPEN";
      case "CHIUSO":
        return "CLOSE";
      case "NOTTE":
        return "NIGHT";
      case "GIORNO":
        return "DAY";
      case "MAPPA":
        return "MAP";
      case "POSIZIONE":
        return "POSITION";
      case "LINGUA":
        return "LANGUAGE";
      case "PRODOTTI":
        return "PRODUCTS";
      case "PERCORSO":
        return "PATH";
      case "COLORI":
        return "COLORS";

      //  LANGUAGES
      case "Italiano":
        return "Italian";
      case "Inglese":
        return "English";
      case "Spagnolo":
        return "Spanish";
      case "Francese":
        return "French";
      case "Tedesco":
        return "German";

      //  INFO
      case "Scheda Tecnica":
        return "Technical Sheet";
      case "COLORE STRUTTURA":
        return "STRUCTURE COLOR";
      case "Palette Colori":
        return "Colours Palette";
      case "BRACCIO":
        return "ARM";
      case "Depliant Gennius":
        return "Gennius dépliant";
        case "Depliant Sunlight":
        return "Sunlight dépliant";
      case "Depliant Kedry":
        return "Kedry dépliant";
      case "Depliant Screeny":
        return "Screeny dépliant";
      case "Depliant Tende":
        return "Awnings dépliant";
      case "Depliant Vele Sails":
        return "Sails déepliant";
      case "Copertura":
        return "Coverage";
      case "Tenda a caduta":
        return "Drop Awning";
      case "Vetrata":
        return "Glass";
      case "ISOLA 3 (Sotto)":
        return "ISOLA 3 (Beneath)";
      case "Apertura della copertura":
        return "Cover opening";
      case "Chiusura della copertura":
        return "Cover closing";
      case "KEDRY SKYLIFE (Angolo)":
        return "KEDRY SKYLIFE (Corner)";
      case "KEDRY PRIME (Sotto)":
        return "KEDRY PRIME (Beneath)";
      case "KEDRY SKYLIFE (Sotto)":
        return "KEDRY SKYLIFE (Beneath)";
      case "SUNLIGHT (Sotto)":
        return "SUNLIGHT (Beneath)";

      // MAPPA
      case "ISOLA 3 - SOTTO":
        return "ISOLA 3 - BENEATH";
      case "KEDRY PRIME - SOTTO":
        return "KEDRY PRIME - BENEATH";
      case "KEDRY SKYLIFE - SOTTO":
        return "KEDRY SKYLIFE - BENEATH";

      //  FORM
      case "Vuoi più informazioni sui prodotti KE?":
        return "Do you want more information on KE products?";
      case "Scrivici: ti offriamo un preventivo gratuito e senza impegno.":
        return "Write to us: we offer you a free, no-obligation quote.";
      case "Ti rispondiamo entro 24/48H":
        return "We will reply to you within 24 / 48H";
      case "Qualcosa è andato storto.":
        return "Something went wrong";
      case "Grazie per averci contattato!":
        return "Thanks for contacting us!";
      case "Il tuo nome":
        return "Your name";
      case "Il tuo indirizzo e-mail *":
        return "Your e-mail address *";
      case "Telefono *":
        return "Your phone *";
      case "La tua città *":
        return "Your city *";
      case "Vuoi raccontarci la tua esigenza?":
        return "Do you want to tell us your need?";
      case "Acconsento al trattamento dei miei dati personali":
        return "I agree to the treatment of my personal data";
      case "Regolamento UE 2016/679":
        return "EU Regulation 2016/679";
      case "Desidero un preventivo gratuito":
        return "I would like a free quote";
      case "Riceverai una risposta entro 24-48H":
        return "You will receive a reply within 24-48H";

      //  HELP
      case "Ambiente a 360°":
        return "360° environment";
      case "È possibile esplorare liberamente l'ambiente cliccando e trascinando il cursore, spostandosi attraverso i punti di movimento.":
        return "You can freely explore the environment by clicking and dragging the cursor, moving through the movement points.";
      case "Interattivo":
        return "Interactive";
      case "In ogni prodotto sono disponibili vari punti di informazione, con tutti i materiali relativi al prodotto che si sta visualizzando.":
        return "In each product there are various information points, with all the materials related to the product you are viewing.";
      case "Immersivo":
        return "Immersive";
      case "È possibile ingrandire o rimpicciolire a piacimento le immagini, per una visione più specifica del prodotto.":
        return "You can zoom in or out as you like, for a more specific view of the product.";
      case "Immagine non trovata. Verrai riportato all'introduzione.":
        return "Image not found. You will be taken back to the introduction.";
      case "Cambia Posizione":
        return "Change Position";

      //  INTRO
      case "ENTRATA":
        return "ENTRANCE";

      // A 100
      case "Tenda a pergola in alluminio con copertura scorrevole ad impacchettamento.":
        return "Aluminum pergola with contemporary design and retractable, waterproof roof.";
      case "Design squadrato ed essenziale e ideale per ampie coperture e differenti contesti.":
        return "Square, essential design is ideal for large areas and a variety of styles.";
      case "Il tamponamento e le chiusure laterali si armonizzano con il disegno pulito dei profili.":
        return "The roof and side closures harmonize with the clean design of the profiles.";

      // KEDRY PRIME
      case "Schermatura a lame in alluminio e tetto a lame orientabili fino a fino a 135°.":
        return "Louvered roof pergola with blades that adjust up to 135˚.";
      case "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni.":
        return "Modern design and integrated closure system makes this pergola suitable for all seasons.";
      case "Sistema di strip led integrate nei profili perimetrali esterni e interni.":
        return "Integrated LED strip lights provide both interior and exterior lighting.";

      // KEDRY SKYLIFE
      case "Perché scegliere la nuova pergola bioclimatica KE per il tuo outdoor?":
        return "Why choose the new KE bioclimatic pergola for your outdoor space?";
      case "- Novità: il tetto a lame mobili retrattili":
        return "- New: the retractable, movable louvered roof";
      case "Per regolare l’intensità della luce e la ventilazione, in funzione delle condizioni meteo.":
        return "To adjust the intensity of light and ventilation, according to the weather.";
      case "- Il sistema innovativo":
        return "- The innovative system";
      case "Con un unico movimento le lame ruotano e arretrano, in apertura e chiusura, consentendo di esprimere al massimo il concetto di regolazione termica e luminosa.":
        return "With a single movement, the blades rotate and retract, enabling you to regulate both light intensity and temperature for complete comfort.";
      case "- Sistema modulare":
        return "- Modular system";
      case "Kedry Skylife si presta ad essere inserita ed adattata in spazi di forme diverse, coprendo metrature importanti, e con un impatto estetico minimale.":
        return "Kedry Skylife has a modular design that is easily adapted to spaces of different shapes, covering large areas with a minimal footprint.";
      case "- Perfetta automazione":
        return "- Perfect automation";
      case "App per smartphone e tablet consentono una perfetta gestione anche da remoto, permettendo di definire tempo e modalità di apertura e chiusura della tenda.":
        return "Apps for smartphones and tablets allow perfect management, even remotely, allowing you to define the timing of awning operation.";
      case "- Illuminazione a LED":
        return "- LED illumination";
      case "È prevista la possibilità di illuminare il prodotto internamente ed esternamente grazie ad un sistema di strip led integrate nei profili perimetrali.":
        return "Both internal and external LED illumination is possible thanks to a system of LED strips integrated in the perimeter profiles.";
      case "Kedry Skylife: un nuovo modo di concepire la vita all’aria aperta, nel massimo del comfort.":
        return "Kedry Skylife: a new way of imagining life in the open air, in maximum comfort.";
      case "Lame mobili retrattili che regolano l'intensità della luce e la ventilazione in funzione delle condizioni meteo.":
        return "Retractable, movable blades that adjust light intensity and ventilation according to the weather and your preference.";
      case "Con il suo sistema modulare, Kedry Skylife si presta ad essere inserita ed adattata in spazi di forme diverse, coprendo metrature importanti, e con impatto estetico minimale.":
        return "With its modular design, Kedry Skylife lends itself to outdoor spaces of various shapes, covering large areas with a minimal footprint.";

      // KHEOPE
      case "Vela ombreggiante fissa dal design moderno con struttura in acciaio INOX.":
        return "Fixed position shade sail with modern design and stainless steel construction.";
      case "Copertura di ampie dimensioni e alta resistenza agenti atmosferici e salsedine.":
        return "Large coverage area and highly resistant to weather and saltwater.";
      case "Movimentazione avvolgimento telo motorizzata dotata di anemometro.":
        return "Automated motor control with wind speed sensor.";

      // KOLIBRIE
      case "Ombreggiatore a vela con un unico palo di sostegno disassato in acciaio INOX.":
        return "Shade sail with a single, stainless steel post on a rotating base.";
      case "Orientabile a 360° grazie al piedistallo a cuscinetti e resistente all'usura.":
        return "Durable, stainless pedestal rotates 360-degrees.";
      case "Movimentazione avvolgimento telo manuale oppure motorizzata.":
        return "Manual or motorized operation.";

      // QBICA FLAT
      case "Tenda da sole a cassonetto dalle linee essenziali ed eleganti adatta ad ogni ambiente architettonico.":
        return "Cassette awning with essential, elegant lines, compatible with any architectural style.";
      case "Disponibile in 4 colori che possono essere combinati per raggiungere 16 diverse configurazioni.":
        return "Available in 3 colors that can be arranged to achieve 9 different configurations.";
      case "È possibile scegliere tra oltre 500 tipologie diverse di tessuto tutte confezionate senza cuciture.":
        return "Select from more than 500 different seamless fabrics.";

      // SUNLIGHT
      case "Un Giardino d'inverno, una stanza outdoor studiata per ridisegnare gli spazi esterni in perfetta simbiosi con l’ambiente circostante.":
        return "SUNLIGHT is a conservatory, an outdoor room designed to be in perfect harmony with the surrounding environment.";
      case "Donando un nuovo senso di ampiezza e rivoluziona il concetto di comfort e luce naturale in qualsiasi stagione dell’anno.":
        return "It gives a new sense of spaciousness and it revolutionizes the concept of comfort and natural light in any season of the year.";
      case "Sunlight è stata progettata per avere importanti prestazioni in caso di situazioni metereologiche avverse.":
        return "Sunlight is designed for superior performance in all weather conditions.";

      // VEGA
      case "Schermatura a lame con tetto ad impaccehttamento dal design lineare contemporaneo.":
        return "Aluminum pergola with contemporary design and retractable, waterproof roof.";
      case "Completa modularità della struttura ed elevata possibilità di personalizzazione.":
        return "Modular design with many customization options.";
      case "Ampia selezione di tessuti tecnici e Innovativi inserti luminosi a luce diffusa lungo guide e colonne.":
        return "Integrated LED strip lights provide both interior and exterior lighting.";

      default:
        return entry;
    }
  }
  if (language === "fr") {
    switch (entry) {
      case "Dopo aver cambiato lingua, lo showroom verrà ricaricato.":
        return "After changing the language, the showroom will be reloaded.";
      //  MENU
      case "APERTO":
        return "OUVRIR";
      case "CHIUSO":
        return "FERMÉ";
      case "NOTTE":
        return "NUIT";
      case "GIORNO":
        return "JOUR";
      case "MAPPA":
        return "CARTE";
      case "POSIZIONE":
        return "POSITION";
      case "LINGUA":
        return "LANGUE";
      case "PRODOTTI":
        return "PRODUITS";
      case "PERCORSO":
        return "ROUTE";
      case "COLORI":
        return "COLEURS";

      //  LANGUAGES
      case "Italiano":
        return "Italien";
      case "Inglese":
        return "Anglais";
      case "Spagnolo":
        return "Espagnol";
      case "Francese":
        return "Français";
      case "Tedesco":
        return "Allemand";

      //  INFO
      case "Scheda Tecnica":
        return "Fiche technique";
      case "COLORE STRUTTURA":
        return "COLEUR DE LA STRUCTURE";
      case "Palette Colori":
        return "Palette de Coleurs";
      case "BRACCIO":
        return "BRAS";
      case "Depliant Vele Sails":
        return "Dépliant voiles Sails";
      case "Depliant Gennius":
        return "Dépliant Gennius";
        case "Depliant Sunlight":
        return "Dépliant Sunlight";
      case "Depliant Kedry":
        return "Dépliant Kedry";
      case "Depliant Screeny":
        return "Dépliant Screeny";
      case "Depliant Tende":
        return "Dépliant Tentes";
      case "Copertura":
        return "Couverture";
      case "Tenda a caduta":
        return "Store à projection";
      case "Vetrata":
        return "Vitrail";
      case "ISOLA 3 (Sotto)":
        return "ISOLA 3 (Sous)";
      case "Apertura della copertura":
        return "Ouverture du couvercle";
      case "Chiusura della copertura":
        return "Fermeture du couvercle";
      case "KEDRY SKYLIFE (Angolo)":
        return "KEDRY SKYLIFE (Coin)";
      case "KEDRY PRIME (Sotto)":
        return "KEDRY PRIME (Sous)";
      case "KEDRY SKYLIFE (Sotto)":
        return "KEDRY SKYLIFE (Sous)";
      case "SUNLIGHT (Sotto)":
        return "SUNLIGHT (Sous)";

      // MAPPA
      case "ISOLA 3 - SOTTO":
        return "ISOLA 3 - SOUS";
      case "KEDRY PRIME - SOTTO":
        return "KEDRY PRIME - SOUS";
      case "KEDRY SKYLIFE - SOTTO":
        return "KEDRY SKYLIFE - SOUS";

      //  FORM
      case "Vuoi più informazioni sui prodotti KE?":
        return "Vous voulez plus d'informations sur les produits KE?";
      case "Scrivici: ti offriamo un preventivo gratuito e senza impegno.":
        return "Écrivez-nous : nous vous offrons un devis gratuit et sans engagement.";
      case "Ti rispondiamo entro 24/48H":
        return "Nous répondons dans les 24/48H";
      case "Qualcosa è andato storto.":
        return "Quelque chose a mal tourné.";
      case "Grazie per averci contattato!":
        return "Merci de nous avoir contactés!";
      case "Il tuo nome":
        return "Votre nom";
      case "Il tuo indirizzo e-mail *":
        return "Votre adresse électronique *";
      case "Telefono *":
        return "Téléphone *";
      case "La tua città *":
        return "Votre ville *";
      case "Vuoi raccontarci la tua esigenza?":
        return "Vous voulez nous parler de votre besoin?";
      case "Acconsento al trattamento dei miei dati personali":
        return "J'accepte le traitement de mes données personnelles";
      case "Regolamento UE 2016/679":
        return "Règlement européen 2016/679";
      case "Desidero un preventivo gratuito":
        return "Je souhaite un devis gratuit";
      case "Riceverai una risposta entro 24-48H":
        return "Vous recevrez une réponse dans les 24-48H";

      //  HELP
      case "Ambiente a 360°":
        return "Environnement à 360°";
      case "È possibile esplorare liberamente l'ambiente cliccando e trascinando il cursore, spostandosi attraverso i punti di movimento.":
        return "Vous pouvez explorer librement l'environnement en cliquant et en faisant glisser le curseur, en vous déplaçant grâce aux points de mouvement.";
      case "Interattivo":
        return "Interactive";
      case "In ogni prodotto sono disponibili vari punti di informazione, con tutti i materiali relativi al prodotto che si sta visualizzando.":
        return "Dans chaque produit, il y a plusieurs points d'information, avec tous les matériaux liés au produit que vous consultez.";
      case "Immersivo":
        return "Immersive";
      case "È possibile ingrandire o rimpicciolire a piacimento le immagini, per una visione più specifica del prodotto.":
        return "Vous pouvez zoomer et dézoomer les images à volonté, pour obtenir une vue plus précise du produit.";
      case "Immagine non trovata. Verrai riportato all'introduzione.":
        return "Image non trouvée. Vous serez ramené à l'introduction.";
      case "Cambia Posizione":
        return "Changement de position";

      //  INTRO
      case "ENTRATA":
        return "ENTRÉE";

      // A 100
      case "Tenda a pergola in alluminio con copertura scorrevole ad impacchettamento.":
        return "Pergola adossée en aluminium équipée d'une toile polyvalente rétractable par empaquètement.";
      case "Design squadrato ed essenziale e ideale per ampie coperture e differenti contesti.":
        return "Design cubique et essentiel. Idéale pour les grandes surfaces à couvrir et adaptables à tous les environnements.";
      case "Il tamponamento e le chiusure laterali si armonizzano con il disegno pulito dei profili.":
        return "Les fermetures latérales et frontales s'harmonisent parfaitement avec le design épuré des profils, offrant un confort supérieur et un gain en intimité.";

      // KEDRY PRIME
      case "Schermatura a lame in alluminio e tetto a lame orientabili fino a fino a 135°.":
        return "Pergola bioclimatique sur mesure, toit à lames orientables jusqu'à 135°, structure en aluminium.";
      case "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni.":
        return "Modularité extrême et système de fermeture intégré (stores, vitrage et/ou rideaux), ce qui rend la structure adaptée à toutes les saisons (vent fort, pluie, charge de neige).";
      case "Sistema di strip led integrate nei profili perimetrali esterni e interni.":
        return "Système de bandeaux LED intégrés dans les profils de périmètre externe et interne.";

      // KEDRY SKYLIFE
      case "Perché scegliere la nuova pergola bioclimatica KE per il tuo outdoor?":
        return "";
      case "- Novità: il tetto a lame mobili retrattili":
        return "- Nouveauté  : un toit à lames mobiles orientables";
      case "Per regolare l’intensità della luce e la ventilazione, in funzione delle condizioni meteo.":
        return "Pour régler l'intensité de la lumière et de la ventilation, selon les conditions météorologiques.";
      case "- Il sistema innovativo":
        return "- Un système innovant ";
      case "Con un unico movimento le lame ruotano e arretrano, in apertura e chiusura, consentendo di esprimere al massimo il concetto di regolazione termica e luminosa.":
        return "D'un seul mouvement, les lames s’orientent et se rétractent, en phase d’ouverture comme de fermeture, permettant ainsi une parfaite régulation thermique et lumineuse.";
      case "- Sistema modulare":
        return "- Un système modulaire";
      case "Kedry Skylife si presta ad essere inserita ed adattata in spazi di forme diverse, coprendo metrature importanti, e con un impatto estetico minimale.":
        return "Kedry Skylife se prête parfaitement aux différents environnements architecturaux, couvrant de grands espaces, et cela avec un impact esthétique minimal.";
      case "- Perfetta automazione":
        return "- Une automatisation parfaite";
      case "App per smartphone e tablet consentono una perfetta gestione anche da remoto, permettendo di definire tempo e modalità di apertura e chiusura della tenda.":
        return "Des applications pour smartphones et tablettes permettent une gestion parfaite même à distance, permettant de définir l'heure et les modalités d'ouverture et de fermeture de votre pergola.";
      case "- Illuminazione a LED":
        return "- Un éclairage LED";
      case "È prevista la possibilità di illuminare il prodotto internamente ed esternamente grazie ad un sistema di strip led integrate nei profili perimetrali.":
        return "Créez un véritable cocon de douceur grâce à un système de bandeaux LED intégrés dans les profils périmétriques.";
      case "Kedry Skylife: un nuovo modo di concepire la vita all’aria aperta, nel massimo del comfort.":
        return "Kedry Skylife : une nouvelle façon de concevoir la vie au grand air, dans un confort maximum.";
      case "Lame mobili retrattili che regolano l'intensità della luce e la ventilazione in funzione delle condizioni meteo.":
        return "Lames mobiles rétractables qui ajustent l'intensité lumineuse et la ventilation en fonction des conditions météorologiques.";
      case "Con il suo sistema modulare, Kedry Skylife si presta ad essere inserita ed adattata in spazi di forme diverse, coprendo metrature importanti, e con impatto estetico minimale.":
        return "Avec son système modulaire, Kedry Skylife se prête à être installé et adapté dans différents espaces, couvrant de grandes surfaces,  avec un impact esthétique minimal.";

      // KHEOPE
      case "Vela ombreggiante fissa dal design moderno con struttura in acciaio INOX.":
        return "Voile d'ombrage rétractable au design moderne, structure en acier inoxydable (matériaux inspirés du monde nautique).";
      case "Copertura di ampie dimensioni e alta resistenza agenti atmosferici e salsedine.":
        return "Couverture de grandes dimensions grâce au chevauchement des voiles possible. Encombrement au sol pouvant être réduit grâce à une fixation murale et haute résistance aux agents atmosphériques et à la salinité.";
      case "Movimentazione avvolgimento telo motorizzata dotata di anemometro.":
        return "Mouvement motorisé pour l'enroulement de la toile, automatisation par anémomètre possible.";

      // KOLIBRIE
      case "Ombreggiatore a vela con un unico palo di sostegno disassato in acciaio INOX.":
        return "Voile d'ombrage avec un seul mât de soutien décalé en acier inoxydable (matériaux inspirés du monde nautique).";
      case "Orientabile a 360° grazie al piedistallo a cuscinetti e resistente all'usura.":
        return "Définition d'élégance, cette voile extrêmement fonctionnelle est orientable à 360 ° grâce à son plateau tournant. Grande résistance à l'usure.";
      case "Movimentazione avvolgimento telo manuale oppure motorizzata.":
        return "Mouvement manuel ou motorisé de l'enroulement de la toile sur le tube (bôme).";

      // QBICA LIGHT
      case "Tenda da sole a cassonetto dalle linee essenziali ed eleganti adatta ad ogni ambiente architettonico.":
        return "Qubica Lite se caractérise par la pureté des lignes et son design avant-gardiste. Sa polyvalence fait de QUBICA un produit exclusif. Disponible en 3 couleurs pouvant être combinées pour atteindre 9 configurations différentes.";
      case "Disponibile in 4 colori che possono essere combinati per raggiungere 16 diverse configurazioni.":
        return "La toile représente une caractéristique personnalisable supplémentaire: Un choix entre plus de 500 types est possible. ";
      case "È possibile scegliere tra oltre 500 tipologie diverse di tessuto tutte confezionate senza cuciture.":
        return "Un système breveté permet au tissu d'être enroulé sans déformations ni plis. Des bras à leds AKI sont proposés en option..";

      // SCREENY 110 GC S12
      case "Tenda tessuto filtrante/oscurante a rullo con cassonetto da 110 mm disponibile in 2 varianti (tondo/quadro).":
        return "Store vertical enrouleur en toile filtrante / occultante avec coffre de 110 mm disponible en 2 variantes (rond / carré).";
      case "Adatto per la schermatura esterna di finestre e facciate continue in molte tipologie di costruzioni: dalle abitazioni private agli edifici commerciali più moderni.":
        return "Convient pour la protection extérieure des fenêtres et grandes surfaces vitrées pour de nombreux types de bâtiments : des maisons privées aux bâtiments commerciaux les plus modernes. Apporte confort visuel et thermique dans les pièces de vie.";
      case "Sistema brevettato che garantisce performance e standard elevati.":
        return "Système breveté qui garantit des performances élevées et répond à de nombreuses normes.";

      // SCREENY 110 GPZ UNICA S32
      case "Tenda tessuto filtrante/oscurante a rullo con cassonetto da 110 mm disponibile in 2 varianti (tondo/quadro) dotata di guida profilo zip.":
        return "Store vertical enrouleur en toile filtrante / occultante avec coffre de 110 mm disponible en 2 variantes (rond / carré) et équipé d'un guidage Zip pour une meilleure résistance.";
      case "Adatto per la schermatura esterna di finestre e facciate continue in molte tipologie di costruzioni: dalle abitazioni private agli edifici commerciali più moderni.":
        return "Convient pour la protection extérieure des fenêtres et grandes surfaces vitrées pour de nombreux types de bâtiments : des maisons privées aux bâtiments commerciaux les plus modernes. Apporte confort visuel et thermique dans les pièces de vie.";
      case "Sistema brevettato che garantisce performance e standard elevati.":
        return "Système breveté qui garantit des performances élevées et répond à de nombreuses normes.";

      // SUNLIGHT
      case "Un Giardino d'inverno, una stanza outdoor studiata per ridisegnare gli spazi esterni in perfetta simbiosi con l’ambiente circostante.":
        return "SUNLIGHT est un nouveau type de jardin d’hiver conçu pour être en parfaite harmonie avec l'environnement extérieur.";
      case "Donando un nuovo senso di ampiezza e rivoluziona il concetto di comfort e luce naturale in qualsiasi stagione dell’anno.":
        return "Il donne une nouvelle sensation d'espace et révolutionne le concept de confort et permet de profiter de la lumière du jour en toutes saisons.";
      case "Sunlight è stata progettata per avere importanti prestazioni in caso di situazioni metereologiche avverse.":
        return "Sunlight a été conçu pour proposer d’importantes performances en cas de conditions météorologiques défavorables.";

      // VEGA
      case "Schermatura a lame con tetto ad impaccehttamento dal design lineare contemporaneo.":
        return "Pergola adossée bicolor à toile rétractable par empaquètement au design linéaire contemporain.";
      case "Completa modularità della struttura ed elevata possibilità di personalizzazione.":
        return "Structure modulable et grande possibilité de personnalisation grâce à différents types d'éclairage et couleurs de structure disponibles pour une gestion parfaite de l'ambiance.";
      case "Ampia selezione di tessuti tecnici e Innovativi inserti luminosi a luce diffusa lungo guide e colonne.":
        return "Large choix de toiles techniques et inserts lumineux innovants à lumière diffuse le long des guides et des colonnes.";

      // VENEZIA GOLD
      case "Tenda da sole a bracci motorizzata su barra quadra.":
        return "Store monobloc motorisé avec bras croisés possibles.";
      case "Design accattivante grazie al supporto Gold e al rivoluzionario braccio Aki.":
        return "Design unique grâce au support Gold.";
      case "Avvolgimento telo con sistema brevettato per evitare pieghe e grinze in fase di arrotolamento.":
        return "Enroulement de la toile avec système breveté pour éviter les plis et les faux plis lors de l'enroulement.";

      // ISOLA 3
      case "Schermatura a lame con tetto ad impachettamento e arco ribassato per il minimo ingombro.":
        return "Pergola autoportée à toile imperméable rétractable par empaquètement et toit légèrement vouté pour un encombrement minimal.";
      case "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni.":
        return "Modularité extrême et système de fermeture intégré (store, vitrage et/ou rideaux), ce qui rend la structure adaptée à toutes les saisons (vent fort et pluie).";
      case "Ampia selezione di tessuti tecnici per la copertura e kit luci dimmerabile integrato nel perimetro interno ed esterno.":
        return "Large choix de toiles techniques pour la fermeture du toit et bandeaux LED intégrés dans les profils de périmètre interne et externe comme dans les traverses.";

      // HYDRA
      case "Tenda a pergola con telo avvolto dal design minimal priva di viteria in vista.":
        return "Store pergola polyvalent à toile enroulable, léger et robuste, design minimaliste.";
      case "Scalabile in larghezza così da consentire la copertura di superfici di dimensioni importanti.":
        return "Modulable dans la largeur afin de permettre la couverture de grandes terrasses. Résistance à une légère pluie et vent.";
      case "Ampia selezione di tessuti tecnici e illuminazione integrata sulle guide e sul cassonetto.":
        return "Large choix de toiles techniques et bandeaux LED intégrés sur les guides, la traverse et sous le coffre.";

      // XTESA
      case "Tenda a pergola con struttura in alluminio e tessuto ad avvolgimento.":
        return "Pergola adossée robuste, structure en aluminium équipée d'une toile enroulable dans un coffre.";
      case "Ideale per installazioni in ambienti architettonici estremi, raggiunge notevoli sporgenze con pendenze contenute.":
        return "Extrêmement robuste, elle est idéale pour les installations dans des zones enclavées, elle atteint des avancées considérables avec des pentes contenues.";
      case "Il tessuto arrotolato garantisce un ottimo risultato estetico, rispetto al classico ingombro del tessuto a impacchettamento.":
        return "La toile enroulable garantit un excellent résultat esthétique, sans encombrement par rapport au volume classique d'une toile rétractée, pour une pleine entrée de luminosité dans l'intérieur de la maison. Stores latéraux et tympans s'ajoutent pour une complète fermeture et protection (visuelle et thermique).";

      default:
        return entry;
    }
  }
  if (language === "es") {
    switch (entry) {
      case "Dopo aver cambiato lingua, lo showroom verrà ricaricato.":
        return "Après le changement de langue, la salle d'exposition sera rechargée.";
      //  MENU
      case "APERTO":
        return "ABIERTO";
      case "CHIUSO":
        return "CERRADO";
      case "NOTTE":
        return "NOCHE";
      case "GIORNO":
        return "DIA";
      case "MAPPA":
        return "MAPA";
      case "POSIZIONE":
        return "POSICIÓN";
      case "LINGUA":
        return "IDIOMA";
      case "PRODOTTI":
        return "PRODUCTOS";
      case "PERCORSO":
        return "SENDERO";
      case "COLORI":
        return "COLORES";

      //  LANGUAGES
      case "Italiano":
        return "Italiano";
      case "Inglese":
        return "Inglés";
      case "Spagnolo":
        return "Español";
      case "Francese":
        return "Francés";
      case "Tedesco":
        return "Alemán";

      //  INFO
      case "Scheda Tecnica":
        return "Hoja Técnica";
      case "COLORE STRUTTURA":
        return "COLOR DE LA ESTRUCTURA";
      case "Palette Colori":
        return "Paletas de Colores";
      case "BRACCIO":
        return "BRAZO";
      case "Depliant Vele Sails":
        return "Catálogo de velas Sails";
      case "Depliant Gennius":
        return "Catálogo Gennius";
        case "Depliant Sunlight":
        return "Catálogo Sunlight";
      case "Depliant Kedry":
        return "Catálogo Kedry";
      case "Depliant Screeny":
        return "Catálogo Screeny";
      case "Depliant Keplan":
        return "Catálogo Keplan";
      case "Depliant Tende":
        return "Catálogo toldos de caída";
      case "Copertura":
        return "Cobertura";
      case "Tenda a caduta":
        return "Toldo de caída";
      case "Vetrata":
        return "Vidriera";
      case "ISOLA 3 (Sotto)":
        return "ISOLA 3 (Bajo)";
      case "Apertura della copertura":
        return "Apertura de la tapa";
      case "Chiusura della copertura":
        return "Cierre de la tapa";
      case "KEDRY SKYLIFE (Angolo)":
        return "KEDRY SKYLIFE (Esquina)";
      case "KEDRY PRIME (Sotto)":
        return "KEDRY PRIME (Bajo)";
      case "KEDRY SKYLIFE (Sotto)":
        return "KEDRY SKYLIFE (Bajo)";
      case "SUNLIGHT (Sotto)":
        return "SUNLIGHT (Bajo)";
      case "BROCHURE":
        return "CATÁLOGO";
      case "Brochure AKI":
        return "Catálogo AKI";

      // MAPPA
      case "ISOLA 3 - SOTTO":
        return "ISOLA 3 - BAJO";
      case "KEDRY PRIME - SOTTO":
        return "KEDRY PRIME - BAJO";
      case "KEDRY SKYLIFE - SOTTO":
        return "KEDRY SKYLIFE - BAJO";

      //  FORM
      case "Vuoi più informazioni sui prodotti KE?":
        return "Quieres más información sobre los productos KE?";
      case "Scrivici: ti offriamo un preventivo gratuito e senza impegno.":
        return "Escríbenos: te ofrecemos un estimado gratis y sin obligación.";
      case "Ti rispondiamo entro 24/48H":
        return "Te respondemos dentro de 24/48H";
      case "Qualcosa è andato storto.":
        return "Algo salió mal.";
      case "Grazie per averci contattato!":
        return "Gracias por contactarnos!";
      case "Il tuo nome":
        return "Tu nombre";
      case "Il tuo indirizzo e-mail *":
        return "Tu dirección de correo electrónico *";
      case "Telefono *":
        return "Teléfono *";
      case "La tua città *":
        return "Tu ciudad *";
      case "Vuoi raccontarci la tua esigenza?":
        return "Quieres decirnos tus necesidades?";
      case "Acconsento al trattamento dei miei dati personali":
        return "Autorizo el uso de datos personales";
      case "Regolamento UE 2016/679":
        return "Regulación UE 2016/679";
      case "Desidero un preventivo gratuito":
        return "Deseo un estimado gratis";
      case "Riceverai una risposta entro 24-48H":
        return "Obtendrás una respuesta dentro de 24/48H";

      //  HELP
      case "Ambiente a 360°":
        return "Ambiente en 360°";
      case "È possibile esplorare liberamente l'ambiente cliccando e trascinando il cursore, spostandosi attraverso i punti di movimento.":
        return "Puedes explorar libremente el entorno pulsando y arrastrando el cursor, moviéndose a través de los puntos de movimiento.";
      case "Interattivo":
        return "Interactivo";
      case "In ogni prodotto sono disponibili vari punti di informazione, con tutti i materiali relativi al prodotto che si sta visualizzando.":
        return "Cada producto dispone de varios puntos de información, con todo el material relacionado con el producto que está viendo.";
      case "Immersivo":
        return "Inmersivo";
      case "È possibile ingrandire o rimpicciolire a piacimento le immagini, per una visione più specifica del prodotto.":
        return "Puedes acercar y alejar las imágenes a voluntad, para una visión más específica del producto.";
      case "Immagine non trovata. Verrai riportato all'introduzione.":
        return "Imagen no encontrada. Volverá a la introducción.";
      case "Cambia Posizione":
        return "Cambiar de Posición";

      //  INTRO
      case "ENTRATA":
        return "ENTRADA";

      // A 100
      case "Tenda a pergola in alluminio con copertura scorrevole ad impacchettamento.":
        return "Pérgola en aluminio con cierre corredero por empaquetamiento.";
      case "Design squadrato ed essenziale e ideale per ampie coperture e differenti contesti.":
        return "Diseño cuadrado y esencial e ideal para grandes coberturas y diferentes contextos.";
      case "Il tamponamento e le chiusure laterali si armonizzano con il disegno pulito dei profili.":
        return "El techo y los cierres laterales armonizan con el diseño limpio de los perfiles.";

      // KEDRY PRIME
      case "Schermatura a lame in alluminio e tetto a lame orientabili fino a fino a 135°.":
        return "Protección de lamas en aluminio y techo con lamas orientables hasta 135º.";
      case "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni.":
        return "Completamente modulable y sistema de cerramientos integrados que la hacen adecuada en todas las estaciones.";
      case "Sistema di strip led integrate nei profili perimetrali esterni e interni.":
        return "Sistema de strip led integrada en los perfiles perimetrales exteriores e interiores.";

      // KEDRY SKYLIFE
      case "Perché scegliere la nuova pergola bioclimatica KE per il tuo outdoor?":
        return "¿Por qué elegir la nueva pérgola bioclimática KE para tu exterior? ";
      case "- Novità: il tetto a lame mobili retrattili":
        return "- Novedad: el techo con lamas móviles retráctiles";
      case "Per regolare l’intensità della luce e la ventilazione, in funzione delle condizioni meteo.":
        return "Para ajustar la intensidad de la luz y la ventilación, dependiendo de las condiciones climáticas.";
      case "- Il sistema innovativo":
        return "- El sistema innovador";
      case "Con un unico movimento le lame ruotano e arretrano, in apertura e chiusura, consentendo di esprimere al massimo il concetto di regolazione termica e luminosa.":
        return "Con un solo movimiento las cuchillas giran y se retraen, abriendo y cerrando, lo que le permite expresar al máximo el concepto de regulación térmica y lumínica.";
      case "- Sistema modulare":
        return "- Sistema modular";
      case "Kedry Skylife si presta ad essere inserita ed adattata in spazi di forme diverse, coprendo metrature importanti, e con un impatto estetico minimale.":
        return "Kedry Skylife se presta a ser instalada y adaptada en espacios de diferentes formas, cubriendo tamaños importantes, y con un impacto estético mínimo.";
      case "- Perfetta automazione":
        return "- Automatización perfecta";
      case "App per smartphone e tablet consentono una perfetta gestione anche da remoto, permettendo di definire tempo e modalità di apertura e chiusura della tenda.":
        return "Las aplicaciones para teléfonos inteligentes y tablet permiten una gestión perfecta incluso de forma remota, lo que le permite definir la hora y el modo de apertura y cierre de la pérgola.";
      case "- Illuminazione a LED":
        return "- Iluminación LED";
      case "È prevista la possibilità di illuminare il prodotto internamente ed esternamente grazie ad un sistema di strip led integrate nei profili perimetrali.":
        return "Es posible iluminar el producto interna y externamente gracias a un sistema de tiras LED integradas en los perfiles perimetrales.";
      case "Kedry Skylife: un nuovo modo di concepire la vita all’aria aperta, nel massimo del comfort.":
        return "Kedry Skylife: una nueva forma de concebir el aire libre, con el máximo confort.";
      case "Lame mobili retrattili che regolano l'intensità della luce e la ventilazione in funzione delle condizioni meteo.":
        return "Lamas móviles retráctiles que regulan la intensidad de la luz y la ventilación según las condiciones climáticas.";
      case "Con il suo sistema modulare, Kedry Skylife si presta ad essere inserita ed adattata in spazi di forme diverse, coprendo metrature importanti, e con impatto estetico minimale.":
        return "Con su sistema modular, Kedry Skylife se presta a ser insertado y adaptado en espacios de diferentes formas, cubriendo tamaños importantes, y con un";

      // KHEOPE
      case "Vela ombreggiante fissa dal design moderno con struttura in acciaio INOX.":
        return "Vela de sombra fija de diseño moderno y estructura en acero INOX.";
      case "Copertura di ampie dimensioni e alta resistenza agenti atmosferici e salsedine.":
        return "Cobertura de grandes dimensiones y alta resistencia a los agentes atmosféricos y a la salinidad.";
      case "Movimentazione avvolgimento telo motorizzata dotata di anemometro.":
        return "Enrolle del tejido motorizado con anemómetro.";

      // KOLIBRIE
      case "Ombreggiatore a vela con un unico palo di sostegno disassato in acciaio INOX.":
        return "Estructura sombrante de vela con un çunico mástil de sujeción descentrado en acero INOX.";
      case "Orientabile a 360° grazie al piedistallo a cuscinetti e resistente all'usura.":
        return "Orientable los 360° gracias a su base con rodamientos y resistente al desgaste.";
      case "Movimentazione avvolgimento telo manuale oppure motorizzata.":
        return "Enrolle del tejido manual o motorizado.";

      // QBICA FLAT
      case "Tenda da sole a cassonetto dalle linee essenziali ed eleganti adatta ad ogni ambiente architettonico.":
        return "Toldo cofre de líneas esenciales y elegantes apto para cualquier entorno arquitectónico.";
      case "Disponibile in 4 colori che possono essere combinati per raggiungere 16 diverse configurazioni.":
        return "Disponible en 4 colores que se pueden combinar para obtener 16 configuraciones diferentes.";
      case "È possibile scegliere tra oltre 500 tipologie diverse di tessuto tutte confezionate senza cuciture.":
        return "Es posible elegir entre más de 500 tipos diferentes de tejido todos confeccionados sin costuras.";

      // SCREENY 110 GC S12
      case "Tenda tessuto filtrante/oscurante a rullo con cassonetto da 110 mm disponibile in 2 varianti (tondo/quadro).":
        return "Toldo de tejido filtrante/oscurante enrollable con cofre de 110 mm disponible en dos variantes (redondo/cuadrado).";
      case "Adatto per la schermatura esterna di finestre e facciate continue in molte tipologie di costruzioni: dalle abitazioni private agli edifici commerciali più moderni.":
        return "Adecuado para la protección exterior de ventanas y fachadas continuas de muchos tipos de construcciones: desde viviendas particulares hasta los edificios comerciales más modernos.";
      case "Sistema brevettato che garantisce performance e standard elevati.":
        return "Sistema patentado que garantiza un alto rendimiento y estándares.";

      // SCREENY 110 GPZ UNICA S32
      case "Tenda tessuto filtrante/oscurante a rullo con cassonetto da 110 mm disponibile in 2 varianti (tondo/quadro) dotata di guida profilo zip.":
        return "Toldo de tejido filtrante/oscurante enrollable con cofre de 110 mm disponible en dos variantes (redondo/cuadrado) con perfil guía zip.";
      case "Adatto per la schermatura esterna di finestre e facciate continue in molte tipologie di costruzioni: dalle abitazioni private agli edifici commerciali più moderni.":
        return "Adecuado para la protección exterior de ventanas y fachadas continuas de muchos tipos de construcciones: desde viviendas particulares hasta los edificios comerciales más modernos.";
      case "Sistema brevettato che garantisce performance e standard elevati.":
        return "Sistema patentado que garantiza un alto rendimiento y estándares.";

      // SUNLIGHT
      case "Un Giardino d'inverno, una stanza outdoor studiata per ridisegnare gli spazi esterni in perfetta simbiosi con l’ambiente circostante.":
        return "SUNLIGHT es un jardín de invierno, una sala al aire libre creada para rediseñar lo espacios exteriores en perfecta simbiosis con el entorno circundante.";
      case "Donando un nuovo senso di ampiezza e rivoluziona il concetto di comfort e luce naturale in qualsiasi stagione dell’anno.":
        return "De una nueva sensación de amplitud y revoluciona el concepto de confort y luz natural en cualquier estación del año.";
      case "Sunlight è stata progettata per avere importanti prestazioni in caso di situazioni metereologiche avverse.":
        return "Sunlight ha sido diseñada para ofrecer un alto rendimiento en situaciones climáticas adversas.";

      // VEGA
      case "Schermatura a lame con tetto ad impaccehttamento dal design lineare contemporaneo.":
        return "Protección con techo de cierre por empaquetamiento con diseño lineal contemporáneo.";
      case "Completa modularità della struttura ed elevata possibilità di personalizzazione.":
        return "Estructura completamente modulable y grandes opciones de personalización.";
      case "Ampia selezione di tessuti tecnici e Innovativi inserti luminosi a luce diffusa lungo guide e colonne.":
        return "Gran selección de tejidos técnicos e innovadores inserciones de sistemas de luz difusa a lo largo de las guías y columnas.";

      // VENEZIA GOLD
      case "Tenda da sole a bracci motorizzata su barra quadra.":
        return "Toldo de brazo extensible motorizado con barra cuadrada.";
      case "Design accattivante grazie al supporto Gold e al rivoluzionario braccio Aki.":
        return "Diseño atravtivo gracias al soporte Gold y al revolucionario brazo AKI.";
      case "Avvolgimento telo con sistema brevettato per evitare pieghe e grinze in fase di arrotolamento.":
        return "Enrollamientos del tejido con el sistema patentado para evitar arrugas y pliegues en la fase de enrolle.";

      // ISOLA 3
      case "Schermatura a lame con tetto ad impachettamento e arco ribassato per il minimo ingombro.":
        return "Protección con techo de cierre por empaquetamiento y de arco rebajado de medidas mínimas.";
      case "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni.":
        return "Completamente modulable y sistema de cerramientos integrados que la hacen adecuada en todas las estaciones.";
      case "Ampia selezione di tessuti tecnici per la copertura e kit luci dimmerabile integrato nel perimetro interno ed esterno.":
        return "Gran selección de tejidos técnicos para la cobertura y kit de iluminación dimerable en el perimetro interior y exterior.";

      // HYDRA
      case "Tenda a pergola con telo avvolto dal design minimal priva di viteria in vista.":
        return "Pérgola con tejido enrollado de disño minimalista sin tornillería a la vista.";
      case "Scalabile in larghezza così da consentire la copertura di superfici di dimensioni importanti.":
        return "Escalable a lo ancho para permitir la cobertura de grandes superficies.";
      case "Ampia selezione di tessuti tecnici e illuminazione integrata sulle guide e sul cassonetto.":
        return "Gran selección de tejidos técnicos e iluminación integrada en las guías y el cofre.";

      // XTESA
      case "Tenda a pergola con struttura in alluminio e tessuto ad avvolgimento.":
        return "Pérgola en aluminio con tejido enrollado.";
      case "Ideale per installazioni in ambienti architettonici estremi, raggiunge notevoli sporgenze con pendenze contenute.":
        return "Ideal para instalaciones en ambientes arquitectónicos extremos, consigue grandes proyecciones con una pendiente reducida.";
      case "Il tessuto arrotolato garantisce un ottimo risultato estetico, rispetto al classico ingombro del tessuto a impacchettamento.":
        return "El tejido enrrollado garantiza un gran resultado estético respecto a la clásico del tejido empaquetado.";

        // KEPLAN
      case "Innovativa pergola in alluminio a tetto piano. Il suo design minimale rompe con il tradizionale schema della pergola a tetto inclinato.":
        return "innovadora pérgola de aluminio con techo plano. Su diseño minimalista rompe con el patrón tradicional de la pérgola de techo inclinado.";
      case "NOVITÀ SALONE DEL MOBILE 2023":
        return "NOVEDAD SALONE DEL MOBILE 2023";
      case "KE KEPLAN Design lineare e geometria piana al servizio della progettazione outdoor":
        return "KE KEPLAN Diseño lineal y geometría plana al servicio del diseño exterior";
      case "Armonia, ordine, essenzialità: tre concetti che si possono ricondurre in architettura alla linea retta. L’occhio per sottrazione distingue in questo tratto le caratteristiche di eleganza e semplicità. Fonte di ispirazione e aspirazione nel corso della storia del design, KE applica il principio della purezza delle linee a servizio del benessere abitativo e psicofisico nel nuovo prodotto KEPLAN.":
        return "Armonía, orden, esencialidad: tres conceptos que se remontan a la línea recta en la arquitectura. Estos rasgos permiten distinguir a simple vista las características de elegancia y simplicidad. Fuente de inspiración y aspiración a lo largo de la historia del diseño, KE aplica el principio de pureza de líneas al servicio de la vida y el bienestar psicofísico en el nuevo producto KEPLAN.";
      case "KEPLAN è l’innovativa pergola in alluminio a tetto piano e telo ad impacchettamento nata per integrarsi perfettamente in qualsiasi soluzione progettuale contemporanea. Il suo design minimale rompe con il tradizionale schema della pergola a tetto inclinato: funzionalità e comfort si uniscono ad una geometria piana adatta alle più diverse realizzazioni outdoor, sia in ambito residenziale sia ho.re.ca.":
        return "KEPLAN es la innovadora pérgola de aluminio con techo plano y tejido plegable creada para integrarse perfectamente en cualquier solución de diseño contemporáneo. Su diseño minimalista rompe con el patrón tradicional de la pérgola de techo inclinado: funcionalidad y confort se combinan con una geometría plana adecuada para las más diversas instalaciones al aire libre, tanto en entornos residenciales como en ho.re.ca.";
      case "Grazie all’innovativo sistema di colonne regolabili in salita e in discesa, l’installazione di KEPLAN si adatta perfettamente anche a contesti difficoltosi: questo sistema di regolazione infatti risolve la problematica di pendenze e dislivelli del terreno.":
        return "Gracias al innovador sistema de columnas regulables en subida y bajada, la instalación de KEPLAN se adapta perfectamente incluso a contextos difíciles: este sistema de regulación resuelve de hecho el problema de las pendientes y las diferencias de altura del terreno.";
      case "Disponibile sia in versione autoportante che addossata, la nuova pergola KE può essere illuminata grazie ad un sistema di strip led dimmerabili inseriti nei traversi per creare suggestive ambientazioni luminose dedicate alla convivialità e al relax.":
        return "Disponible tanto en versión autoportante como inclinada, la nueva pérgola KE se puede iluminar gracias a un sistema de tiras LED dimmerables insertadas en los travesaños para crear entornos de iluminación sugerentes para la convivencia y la relajación.";
      case "KEPLAN ha copertura con caratteristica forma del tetto ad arco ribassato che permette di diminuire l’ingombro in altezza e di migliorare la resa estetica complessiva. Per rispondere a due richieste fondamentali – la necessità di privacy e l’efficacia di protezione dal sole e dalla pioggia - la pergola è dotata di tenda, richiudibile ad impacchettamento. Il telo, che risulta teso nella sua massima apertura, è fissato su traversi ad arco e scorre orizzontalmente per mezzo di carrelli su guide motorizzate.":
        return "KEPLAN dispone de una cubierta con la característica forma de la cubierta arqueada rebajada que permite reducir las dimensiones generales y mejorar el resultado estético global. Para responder a dos peticiones fundamentales, la necesidad de privacidad y la eficacia de la protección contra el sol y la lluvia, la pérgola está equipada con un toldo que se puede plegar. El tejido resulta tensado en la máxima abertura, se fija sobre travesaños arqueados y se desliza horizontalmente por medio de carritos sobre guías motorizadas.";
      case "Illuminazione e movimentazione del telo sono regolati da sistemi di domotica integrata.":
        return "La iluminación y el movimiento del tejido están regulados por sistemas domóticos integrados.";
      case "La massima resa estetica e la duttilità di KEPLAN si confermano anche nell’ampia gamma di tessuti disponibili funzionali alla personalizzazione massima.":
        return "El rendimiento estético y la customización de KEPLAN también se confirman en la amplia gama de tejidos funcionales disponibles para la máxima personalización.";
      case "Discrezione e piacevolezza, affidabilità e funzionalità, adattabilità e tecnologia sono le caratteristiche che KE sintetizza in KEPLAN, per creare nuovi ambienti outdoor raffinati e accoglienti dove poter godere del miglior “tempo per sé” e coltivare momenti di rilassata convivialità.":
        return "Discreción, fiabilidad y funcionalidad, adaptabilidad y tecnología son las características que KE resume en KEPLAN, para crear nuevos ambientes al aire libre refinados y acogedores donde podrá disfrutar del mejor \"tiempo para usted\" y cultivar momentos de relajada convivencia.";
      case "Caratteristiche di performance":
        return "Características del productos";
      case "Design squadrato ed essenziale":
        return "Diseño descuadrado y esencial";
      case "Ideale per ampie coperture":
        return "Ideal para grandes coberturas";
      case "Resistenza al sole":
        return "Resistencia al sol";
      case "Resistenza al vento, classe 6":
        return "Resistencia al viento, clase 6";
      case "Massima larghezza: 550 cm":
        return "Máxima línea: 550 cm";
      case "Massima sporgenza: 600cm":
        return "Máxima salida: 600cm";

      default:
        return entry;
    }
  }
  if (language === "de") {
    switch (entry) {
      case "Dopo aver cambiato lingua, lo showroom verrà ricaricato.":
        return "Nach dem Sprachwechsel wird der Showroom neu geladen.";
      //  MENU
      case "APERTO":
        return "ÖFFNEN";
      case "CHIUSO":
        return "GESCHLOSSEN";
      case "NOTTE":
        return "NACHT";
      case "GIORNO":
        return "TAG";
      case "MAPPA":
        return "KARTE";
      case "POSIZIONE":
        return "POSITION";
      case "LINGUA":
        return "SPRACHE";
      case "PRODOTTI":
        return "PRODUKTE";
      case "PERCORSO":
        return "ROUTE";
      case "COLORI":
        return "FARBEN";

      //  LANGUAGES
      case "Italiano":
        return "Italienisch";
      case "Inglese":
        return "Englisch";
      case "Spagnolo":
        return "Spanisch";
      case "Francese":
        return "Französisch";
      case "Tedesco":
        return "Deutsch";

      //  INFO
      case "Scheda Tecnica":
        return "Technisches Datenblatt";
      case "COLORE STRUTTURA":
        return "FARBAUSWAHL";
      case "Palette Colori":
        return "Farbpaletten";
      case "BRACCIO":
        return "ARM";
      case "Depliant Gennius":
        return "Gennius broschüre";
      case "Depliant Sunlight":
        return "Sunlight broschüre";
      case "Depliant Kedry":
        return "Kedry broschüre";
      case "Depliant Screeny":
        return "Screeny broschüre";
      case "Depliant Keplan":
        return "Keplan broschüre";
      case "Depliant Tende":
        return "Zeltbroschüre";
      case "Depliant Vele Sails":
        return "Broschüre segelt Sails";
      case "Copertura":
        return "Lamellen öffnen/schließen";
      case "Tenda a caduta":
        return "Senkrechtmarkise";
      case "Vetrata":
        return "Klarglas/verdunkenldes Glas";
      case "ISOLA 3 (Sotto)":
        return "ISOLA 3 (Unter)";
      case "Apertura della copertura":
        return "Öffnen des Deckels";
      case "Chiusura della copertura":
        return "Schließen des Deckels";
      case "KEDRY SKYLIFE (Angolo)":
        return "KEDRY SKYLIFE (Ecke)";
      case "KEDRY PRIME (Sotto)":
        return "KEDRY PRIME (Eintreten)";
      case "KEDRY SKYLIFE (Sotto)":
        return "KEDRY SKYLIFE (Eintreten)";
      case "SUNLIGHT (Sotto)":
        return "SUNLIGHT (Eintreten)";
      case "BROCHURE":
        return "BROSCHÜRE";
      case "Brochure AKI":
        return "Broschüre AKI";

      // MAPPA
      case "ISOLA 3 - SOTTO":
        return "ISOLA 3 - UNTER";
      case "KEDRY PRIME - SOTTO":
        return "KEDRY PRIME - UNTER";
      case "KEDRY SKYLIFE - SOTTO":
        return "KEDRY SKYLIFE - UNTER";

      //  FORM
      case "Vuoi più informazioni sui prodotti KE?":
        return "Wünschen sie weitere Informationen über KE-Produkte?";
      case "Scrivici: ti offriamo un preventivo gratuito e senza impegno.":
        return "Schreiben Sie uns: Wir bieten Ihnen ein kostenloses und unverbindliches Angebot.";
      case "Ti rispondiamo entro 24/48H":
        return "Wir antworten innerhalb von 24/48H";
      case "Qualcosa è andato storto.":
        return "Etwas ist schief gelaufen.";
      case "Grazie per averci contattato!":
        return "Vielen Dank, dass Sie uns kontaktiert haben!";
      case "Il tuo nome":
        return "Ihr Name";
      case "Il tuo indirizzo e-mail *":
        return "Ihre E-Mail Adresse *";
      case "Telefono *":
        return "Telefon *";
      case "La tua città *":
        return "Deine Stadt *";
      case "Vuoi raccontarci la tua esigenza?":
        return "Möchten Sie uns etwas über Ihren Bedarf mitteilen?";
      case "Acconsento al trattamento dei miei dati personali":
        return "Ich bin mit der Verarbeitung meiner persönlichen Daten einverstanden";
      case "Regolamento UE 2016/679":
        return "Verordnung (EU) 2016/679";
      case "Desidero un preventivo gratuito":
        return "Ich möchte ein kostenloses Angebot";
      case "Riceverai una risposta entro 24-48H":
        return "Sie werden innerhalb von 24-48 Stunden eine Antwort erhalten.";

      //  HELP
      case "Ambiente a 360°":
        return "360° Umgebung";
      case "È possibile esplorare liberamente l'ambiente cliccando e trascinando il cursore, spostandosi attraverso i punti di movimento.":
        return "Sie können die Umgebung durch Klicken und Ziehen des Cursors frei erkunden und sich durch die Bewegungspunkte bewegen.";
      case "Interattivo":
        return "Interaktiv";
      case "In ogni prodotto sono disponibili vari punti di informazione, con tutti i materiali relativi al prodotto che si sta visualizzando.":
        return "Zu jedem Produkt gibt es verschiedene Informationspunkte mit allen Materialien, die sich auf das Produkt beziehen, das Sie sich ansehen.";
      case "Immersivo":
        return "Immersiv";
      case "È possibile ingrandire o rimpicciolire a piacimento le immagini, per una visione più specifica del prodotto.":
        return "Sie können die Bilder nach Belieben vergrößern und verkleinern, um eine genauere Ansicht des Produkts zu erhalten.";
      case "Immagine non trovata. Verrai riportato all'introduzione.":
        return "Bild nicht gefunden. Sie werden zur Einleitung zurückgeführt.";
      case "Cambia Posizione":
        return "Position ändern";

      //  INTRO
      case "ENTRATA":
        return "EINGABE";

      // A 100
      case "Tenda a pergola in alluminio con copertura scorrevole ad impacchettamento.":
        return "Pergolamarkise aus Aluminium mit verfahrbarem Faltdach.";
      case "Design squadrato ed essenziale e ideale per ampie coperture e differenti contesti.":
        return "Quadratisches und minimialistisches Design, ideal um große Flächen in verschiedenen Kontexten zu überdachen.";
      case "Il tamponamento e le chiusure laterali si armonizzano con il disegno pulito dei profili.":
        return "Der Behang und die seitlichen Abschlüsse harmonieren mit dem klaren Design der Profile.";

      // KEDRY PRIME
      case "Schermatura a lame in alluminio e tetto a lame orientabili fino a fino a 135°.":
        return "Lamellendach aus Aluminium mit Lamellen drehbar bis 135°.";
      case "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni.":
        return "Vollständige Modularität und ein integriertes Senkrechtelemente, die die Struktur für alle Jahreszeiten geeignet machen.";
      case "Sistema di strip led integrate nei profili perimetrali esterni e interni.":
        return "Integrierte LED Stripe Beleuchtung in den inneren und äußeren Profilen.";

      // KEDRY SKYLIFE
      case "Perché scegliere la nuova pergola bioclimatica KE per il tuo outdoor?":
        return "Warum sollten Sie sich für die neue bioklimatische Pergola von KE entscheiden?";
      case "- Novità: il tetto a lame mobili retrattili":
        return "- Neuheit: das Lamellendach mit fahrbaren Lamellen";
      case "Per regolare l’intensità della luce e la ventilazione, in funzione delle condizioni meteo.":
        return "Zur Anpassung der Sonneneinstrahlung und der Belüftung entsprechend der Witterungsverhältnisse";
      case "- Il sistema innovativo":
        return "- Das innovative System ";
      case "Con un unico movimento le lame ruotano e arretrano, in apertura e chiusura, consentendo di esprimere al massimo il concetto di regolazione termica e luminosa.":
        return "In einer einzigen Bewegung lassen sich die Lamellen drehen und einfahren, öffnen und schließen, so dass das Konzept der Wärme- und Lichtregulierung direkt zur Geltung kommt.";
      case "- Sistema modulare":
        return "- Modulares System ";
      case "Kedry Skylife si presta ad essere inserita ed adattata in spazi di forme diverse, coprendo metrature importanti, e con un impatto estetico minimale.":
        return "Kedry Skylife lässt sich in unterschiedliche Raumsituationen einfügen und anpassen, deckt große Flächen ab und besitzt eine minimalistische Ästhetik.";
      case "- Perfetta automazione":
        return "- Ideale Automatisierung";
      case "App per smartphone e tablet consentono una perfetta gestione anche da remoto, permettendo di definire tempo e modalità di apertura e chiusura della tenda.":
        return "Apps für Smartphones und Tablets ermöglichen eine komfortable Steuerung auch aus der Ferne, indem Sie den Zeitpunkt und die Art des Öffnens und Schließens des Dachs bestimmen können.";
      case "- Illuminazione a LED":
        return "- LED-Beleuchtung";
      case "È prevista la possibilità di illuminare il prodotto internamente ed esternamente grazie ad un sistema di strip led integrate nei profili perimetrali.":
        return "Es ist möglich, das Produkt innen und außen zu beleuchten, dank eines Systems von LED-Streifen, die in die Trägerprofile integriert sind.";
      case "Kedry Skylife: un nuovo modo di concepire la vita all’aria aperta, nel massimo del comfort.":
        return "Kedry Skylife: eine neue Art, das Leben im Freien mit maximalem Komfort zu gestalten.";
      case "Lame mobili retrattili che regolano l'intensità della luce e la ventilazione in funzione delle condizioni meteo.":
        return "Einfahrbare, bewegliche Lamellen, die die Sonneneinstrahlung und Belüftung entsprechend der Wetterverhältnisse regulieren.";
      case "Con il suo sistema modulare, Kedry Skylife si presta ad essere inserita ed adattata in spazi di forme diverse, coprendo metrature importanti, e con impatto estetico minimale.":
        return "Dank seines modularen Systems kann Kedry Skylife in unterschiedliche Raumsituationen eingefügt und angepasst werden, deckt große Flächen ab und besitzt dabei eine minimalistische Ästhetik.";

      // KHEOPE
      case "Vela ombreggiante fissa dal design moderno con struttura in acciaio INOX.":
        return "Festes Sonnensegel mit modernem Design und Struktur aus Edelstahl.";
      case "Copertura di ampie dimensioni e alta resistenza agenti atmosferici e salsedine.":
        return "Große Abdeckung und hohe Witterungs- und Salzbeständigkeit.";
      case "Movimentazione avvolgimento telo motorizzata dotata di anemometro.":
        return "Motorisierte Bewegung des Tuchs unterstützt von Windsensoren.";

      // KOLIBRIE
      case "Ombreggiatore a vela con un unico palo di sostegno disassato in acciaio INOX.":
        return "Sonnensegelschirm mit einem einzelnen Pfosten aus Edelstahl.";
      case "Orientabile a 360° grazie al piedistallo a cuscinetti e resistente all'usura.":
        return "360° schwenkbar dank verschleißfestem Stützfuß.";
      case "Movimentazione avvolgimento telo manuale oppure motorizzata.":
        return "Manuelle oder motorisierte Steuerung des Tuchs möglich.";

      // QBICA FLAT
      case "Tenda da sole a cassonetto dalle linee essenziali ed eleganti adatta ad ogni ambiente architettonico.":
        return "Eine Kassettenmarkise mit klaren und eleganten Linien, die sich in jede architektonische Umgebung einfügt.";
      case "Disponibile in 4 colori che possono essere combinati per raggiungere 16 diverse configurazioni.":
        return "Erhältlich in 4 Farben, die zu 16 verschiedenen Konfigurationen kombiniert werden können.";
      case "È possibile scegliere tra oltre 500 tipologie diverse di tessuto tutte confezionate senza cuciture.":
        return "Wahlmöglichkeit aus über 500 verschiedenen Stoffarten wählen ohne sichtbare Nähte.";

      // SCREENY 110 GC S12
      case "Tenda tessuto filtrante/oscurante a rullo con cassonetto da 110 mm disponibile in 2 varianti (tondo/quadro).":
        return "Senkrechtmarkise mit lichtfilternder/abdunkelnder Wirkung mit 110 mm Kassette in 2 Varianten (rund/quadratisch) erhältlich.";
      case "Adatto per la schermatura esterna di finestre e facciate continue in molte tipologie di costruzioni: dalle abitazioni private agli edifici commerciali più moderni.":
        return "Geeignet für die Außenbeschattung von Fenstern und Fassaden in vielen Gebäudetypen: vom Privathaus bis zum modernen Gewerbebau.";
      case "Sistema brevettato che garantisce performance e standard elevati.":
        return "Patentiertes System, das Leistung und hohe Standards garantiert.";

      // SCREENY 110 GPZ UNICA S32
      case "Tenda tessuto filtrante/oscurante a rullo con cassonetto da 110 mm disponibile in 2 varianti (tondo/quadro) dotata di guida profilo zip.":
        return "Senkrechtmarkise mit lichtfilternder/abdunkelnder Wirkung mit 110 mm Kassette und ZIP-Führung in 2 Varianten (rund/quadratisch) erhältlich.";
      case "Adatto per la schermatura esterna di finestre e facciate continue in molte tipologie di costruzioni: dalle abitazioni private agli edifici commerciali più moderni.":
        return "Geeignet für die Außenbeschattung von Fenstern und Fassaden in vielen Gebäudetypen: vom Privathaus bis zum modernen Gewerbebau.";
      case "Sistema brevettato che garantisce performance e standard elevati.":
        return "Patentiertes System, das Leistung und hohe Standards garantiert.";

      // SUNLIGHT
      case "Un Giardino d'inverno, una stanza outdoor studiata per ridisegnare gli spazi esterni in perfetta simbiosi con l’ambiente circostante.":
        return "Ist das erste Glashaus mit Glasdach, ein Raum im Freien, der dazu dient, Ihre Umwelt neu zu gestalten.";
      case "Donando un nuovo senso di ampiezza e rivoluziona il concetto di comfort e luce naturale in qualsiasi stagione dell’anno.":
        return "Raum für die Neugestaltung von Außenbereichen in perfekter Symbiose mit der Umgebung, was zu einem neuen Raumgefühl führt und das Design revolutioniert.";
      case "Sunlight è stata progettata per avere importanti prestazioni in caso di situazioni metereologiche avverse.":
        return "Das Konzept von Komfort und natürlichem Licht über das ganze Jahr.";

      // VEGA
      case "Schermatura a lame con tetto ad impaccehttamento dal design lineare contemporaneo.":
        return "Überdachung mit faltbarem Dach und klarem modernen Design.";
      case "Completa modularità della struttura ed elevata possibilità di personalizzazione.":
        return "Vollständige Modularität der Struktur und hohe Anpassungsmöglichkeiten.";
      case "Ampia selezione di tessuti tecnici e Innovativi inserti luminosi a luce diffusa lungo guide e colonne.":
        return "Eine große Auswahl an technischen Stoffen und innovativen Lichteinsätzen entlang der Schienen und Säulen.";

      // VENEZIA GOLD
      case "Tenda da sole a bracci motorizzata su barra quadra.":
        return "Offene Gelenkarmmarkise mit Motor und Vierkanttragrohr.";
      case "Design accattivante grazie al supporto Gold e al rivoluzionario braccio Aki.":
        return "Attraktives Design dank der 'Gold'-Halterung und des revolutionären Aki-Arms.";
      case "Avvolgimento telo con sistema brevettato per evitare pieghe e grinze in fase di arrotolamento.":
        return "Patentiertes Aufrollsystem des Markisentuchs zur Vermeidung von Falten und Knittern.";

      // ISOLA 3
      case "Schermatura a lame con tetto ad impachettamento e arco ribassato per il minimo ingombro.":
        return "Lamellendach mit einfahrbarem Dach und gewölbten Querstreben für minimalen Platzbedarf.";
      case "Completa modularità e sistema di chisure integrate che rende la struttura adatta a tutte le stagioni.":
        return "Vollständige Modularität und ein integriertes Senkrechtelemente, die die Struktur für alle Jahreszeiten geeignet machen.";
      case "Ampia selezione di tessuti tecnici per la copertura e kit luci dimmerabile integrato nel perimetro interno ed esterno.":
        return "Große Auswahl an technischen Stoffen für das Dach und dimmbare Beleuchtung innen und außen.";

      // HYDRA
      case "Tenda a pergola con telo avvolto dal design minimal priva di viteria in vista.":
        return "Pergolamarkise mit aufrollbarem Tuch in minimalistischem Design ohne sichtbare Schrauben.";
      case "Scalabile in larghezza così da consentire la copertura di superfici di dimensioni importanti.":
        return "In der Breite skalierbar, damit große Flächen abgedeckt werden können.";
      case "Ampia selezione di tessuti tecnici e illuminazione integrata sulle guide e sul cassonetto.":
        return "Große Auswahl an technischen Stoffen und integrierte Beleuchtung an den Führungen und der Kassette.";

      // XTESA
      case "Tenda a pergola con struttura in alluminio e tessuto ad avvolgimento.":
        return "Pergolamarkise mit Aluminiumstruktur und aufrollbarem Dach.";
      case "Ideale per installazioni in ambienti architettonici estremi, raggiunge notevoli sporgenze con pendenze contenute.":
        return "Ideal für die Installation in außergewöhnlichen architektonischen Umgebungen, mit einer großen überdachten Fläche mit geringen Neigungen.";
      case "Il tessuto arrotolato garantisce un ottimo risultato estetico, rispetto al classico ingombro del tessuto a impacchettamento.":
        return "Der aufgerollte Stoff garantiert ein hervorragendes ästhetisches Ergebnis im Vergleich zur klassischen Fülle eines Faltdachs.";

        // KEPLAN
      case "Innovativa pergola in alluminio a tetto piano. Il suo design minimale rompe con il tradizionale schema della pergola a tetto inclinato.":
        return "Innovative Pergola aus Aluminium mit Flachdach. Ihr minimalistisches Design bricht mit dem traditionellen Muster der Pergola mit Schrägdach.";
      case "NOVITÀ SALONE DEL MOBILE 2023":
        return "NEUHEIT AUF DEM SALONE DEL MOBILE 2023";
      case "KE KEPLAN Design lineare e geometria piana al servizio della progettazione outdoor":
        return "KE KEPLAN LINEARES DESIGN UND FLACHE BAUWEISE FÜR DIE OUTDOOR GESTALTUNG";
      case "Armonia, ordine, essenzialità: tre concetti che si possono ricondurre in architettura alla linea retta. L’occhio per sottrazione distingue in questo tratto le caratteristiche di eleganza e semplicità. Fonte di ispirazione e aspirazione nel corso della storia del design, KE applica il principio della purezza delle linee a servizio del benessere abitativo e psicofisico nel nuovo prodotto KEPLAN.":
        return "Harmonie, Ordnung, Wesentlichkeit: drei Begriffe, die sich in der Architektur in der geraden Linie wiederfinden. Diese Eigenschaften erlauben dem Auge die Merkmale Eleganz und Wesentlichkeit wahrzunehmen. KE, eine Quelle der Inspiration und der Zielstrebigkeit im Laufe der Designgeschichte, wendet das Prinzip der klaren Linien im Dienste des Wohnens und des psychophysischen Wohlbefindens auf das neue Produkt KEPLAN an.";
      case "KEPLAN è l’innovativa pergola in alluminio a tetto piano e telo ad impacchettamento nata per integrarsi perfettamente in qualsiasi soluzione progettuale contemporanea. Il suo design minimale rompe con il tradizionale schema della pergola a tetto inclinato: funzionalità e comfort si uniscono ad una geometria piana adatta alle più diverse realizzazioni outdoor, sia in ambito residenziale sia ho.re.ca.":
        return "KEPLAN ist eine innovative Pergola aus Aluminium mit flachem Dach und faltbarem Tuch, die sich perfekt in jede moderne Designlösung einfügt.  Ihr minimales Design bricht mit dem traditionellen Muster der Pergola mit schrägem Dach: Funktionalität und Komfort werden mit einer flachen Geometrie kombiniert, die sich für die unterschiedlichsten Projekte im Freien eignet, sowohl im Wohnbereich als auch bei ho.re.ca.";
      case "Grazie all’innovativo sistema di colonne regolabili in salita e in discesa, l’installazione di KEPLAN si adatta perfettamente anche a contesti difficoltosi: questo sistema di regolazione infatti risolve la problematica di pendenze e dislivelli del terreno.":
        return "Dank des innovativen Systems der verstellbaren vertikalen Pfosten ist die Installation von KEPLAN auch in schwierigen Bausituationen perfekt geeignet: tatsächlich löst dieses Verstellsystem die Schwierigkeit der Neigungen und Höhenunterschiede des Geländes.";
      case "Disponibile sia in versione autoportante che addossata, la nuova pergola KE può essere illuminata grazie ad un sistema di strip led dimmerabili inseriti nei traversi per creare suggestive ambientazioni luminose dedicate alla convivialità e al relax.":
        return "Die neue Pergola KE, die sowohl als freistehende als auch als wandmontierte Version erhältlich ist, kann mit einem System von dimmbaren LED-Leisten beleuchtet werden, die in die Profile eingesetzt werden, um einladende Lichtumgebungen zu schaffen, die dem geselligen Beisammensein und der Entspannung dienen.";
      case "KEPLAN ha copertura con caratteristica forma del tetto ad arco ribassato che permette di diminuire l’ingombro in altezza e di migliorare la resa estetica complessiva. Per rispondere a due richieste fondamentali – la necessità di privacy e l’efficacia di protezione dal sole e dalla pioggia - la pergola è dotata di tenda, richiudibile ad impacchettamento. Il telo, che risulta teso nella sua massima apertura, è fissato su traversi ad arco e scorre orizzontalmente per mezzo di carrelli su guide motorizzate.":
        return "KEPLAN hat ein Dach mit einer charakteristischen niedrigen Bogenform, die die Gesamthöhe reduziert und so die Gesamtästhetik verbessert. Um zwei grundlegenden Anforderungen gerecht zu werden - dem Bedürfnis nach Privatsphäre und einem wirksamen Schutz vor Sonne und Regen - ist die Pergola mit einem einfaltbaren Tuch ausgestattet. Das im maximal ausgefahrenen Zustand gespannte Tuch ist auf gewölbten Querträgern befestigt und gleitet horizontal mit Hilfe von Laufwagen auf motorisierten Führungen.";
      case "Illuminazione e movimentazione del telo sono regolati da sistemi di domotica integrata.":
        return "Die Beleuchtung und die Bewegung des Tuchs werden durch integrierte Hausautomationssysteme gesteuert.";
      case "La massima resa estetica e la duttilità di KEPLAN si confermano anche nell’ampia gamma di tessuti disponibili funzionali alla personalizzazione massima.":
        return "Die maximale ästhetische Leistung und die Anpassungsfähigkeit von KEPLAN werden auch durch die große Auswahl an Stoffen unterstrichen, durch die eine individuelle Gestaltung möglich ist.";
      case "Discrezione e piacevolezza, affidabilità e funzionalità, adattabilità e tecnologia sono le caratteristiche che KE sintetizza in KEPLAN, per creare nuovi ambienti outdoor raffinati e accoglienti dove poter godere del miglior “tempo per sé” e coltivare momenti di rilassata convivialità.":
        return "Diskretion und Gemütlichkeit, Zuverlässigkeit und Funktionalität, Anpassungsfähigkeit und Technologie sind die Eigenschaften, die KE in KEPLAN vereint, um neue, raffinierte und gemütliche Plätze im Freien zu schaffen, in denen man die beste Zeit alleine oder in Gesellschaft genießen kann.";
      case "Caratteristiche di performance":
        return "Leistungsmerkmale";
      case "Design squadrato ed essenziale":
        return "Quadratisches, minimalistisches Design";
      case "Ideale per ampie coperture":
        return "Ideal für große Flächen";
      case "Resistenza al sole":
        return "Sonnenbeständigkeit";
      case "Resistenza al vento, classe 6":
        return "Windbeständigkeit, Klasse 6";
      case "Massima larghezza: 550 cm":
        return "Maximale Breite: 550 cm";
      case "Massima sporgenza: 600cm":
        return "Maximale Ausladung: 600 cm";

      default:
        return entry;
    }
  }
  return entry;
};
