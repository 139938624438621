// @ts-ignore
import React from "react";
import { Close } from "../icons";
import { TechnicalSpecificsType } from "../types";
import Positions from "./positions";

const PopupPositions: React.FC<{
  currentPosition: any;
  technicalSpecifics: TechnicalSpecificsType;
  setTechnicalSpecifics: (v: TechnicalSpecificsType) => any;
  setPopupInfos: any;
  popupInfos: any;
}> = ({
  currentPosition,
  technicalSpecifics,
  setTechnicalSpecifics,
  setPopupInfos,
  popupInfos,
}) => {
  return (
    <div className="positions-list__container">
      <div className="popup-header">
        <div className="close-button" onClick={() => setPopupInfos(null)}>
          <img src={Close} alt="close-icon-white" className="close-icon" />
        </div>
      </div>
      <Positions
        currentPosition={currentPosition}
        technicalSpecifics={technicalSpecifics}
        setTechnicalSpecifics={setTechnicalSpecifics}
        setPopupInfos={setPopupInfos}
        popupInfos={popupInfos}
      />
    </div>
  );
};

export default PopupPositions;
