import {
  KedrySkylifeImage1,
  KedrySkylifeImage2,
  KedrySkylifeImage3,
  KedrySkylifeImage4,
  KedrySkylifeImage5,
  KedrySkylifeImage6,
  KedrySkylifeImage7,
  KedrySkylifeImage8,
  KedrySkylifeImage9,
  KedrySkylifeFolderIt,
  KedrySkylifeSchedaTecnica,
  KedrySkylifePaletteColori,
  KendrySkylifeGLB,
  KendrySkylifeUSDZ,
} from "./index";
import { Arrow, ArrowUp } from "../../icons";
import { getRoofStatus } from "../get-roof-status";
import { getLanguageTranslation } from "../translations/get-language-translation";

export const createAngleInfoPoints = (
  createInfoPoint,
  createOptionalPoint,
  createVideoPoint,
  createPositionPoint,
  createVrPoint,
  createColorPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [2010.48, -1816.38, -4193.1],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "KEDRY SKYLIFE",
      description:
        "<p>" +
        getLanguageTranslation(
          "Perché scegliere la nuova pergola bioclimatica KE per il tuo outdoor?"
        ) +
        "<br /></p>" +
        "<p><strong>" +
        getLanguageTranslation("- Novità: il tetto a lame mobili retrattili") +
        "</strong><br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Per regolare l’intensità della luce e la ventilazione, in funzione delle condizioni meteo."
        ) +
        "<br /></p>" +
        "<p><strong>" +
        getLanguageTranslation("- Il sistema innovativo") +
        "</strong><br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Con un unico movimento le lame ruotano e arretrano, in apertura e chiusura, consentendo di esprimere al massimo il concetto di regolazione termica e luminosa."
        ) +
        "<br /></p>" +
        "<p><strong>" +
        getLanguageTranslation("- Sistema modulare") +
        "</strong><br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Kedry Skylife si presta ad essere inserita ed adattata in spazi di forme diverse, coprendo metrature importanti, e con un impatto estetico minimale."
        ) +
        "<br /></p>" +
        "<p><strong>" +
        getLanguageTranslation("- Perfetta automazione") +
        "</strong><br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "App per smartphone e tablet consentono una perfetta gestione anche da remoto, permettendo di definire tempo e modalità di apertura e chiusura della tenda."
        ) +
        "<br /></p>" +
        "<p><strong>" +
        getLanguageTranslation("- Illuminazione a LED") +
        "</strong><br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "È prevista la possibilità di illuminare il prodotto internamente ed esternamente grazie ad un sistema di strip led integrate nei profili perimetrali."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Kedry Skylife: un nuovo modo di concepire la vita all’aria aperta, nel massimo del comfort."
        ) +
        "<br /></p>",
      images: [
        KedrySkylifeImage1,
        KedrySkylifeImage2,
        KedrySkylifeImage3,
        KedrySkylifeImage4,
        KedrySkylifeImage5,
        KedrySkylifeImage6,
        KedrySkylifeImage7,
        KedrySkylifeImage8,
        KedrySkylifeImage9,
      ],
      buttons: [
        {
          text: getLanguageTranslation("BROCHURE"),
          href: KedrySkylifeFolderIt,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: KedrySkylifeSchedaTecnica,
        },
        {
          text: "FILE BIM",
          href:
            "https://www.keoutdoordesign.com/it-it/area-media/bim-3d-models/",
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: KedrySkylifePaletteColori,
        },
      ],
    },
    undefined,
    "<p><strong>KEDRY SKYLIFE</strong><br />" +
      getLanguageTranslation(
        "Lame mobili retrattili che regolano l'intensità della luce e la ventilazione in funzione delle condizioni meteo."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Con il suo sistema modulare, Kedry Skylife si presta ad essere inserita ed adattata in spazi di forme diverse, coprendo metrature importanti, e con impatto estetico minimale."
      ) +
      "</p>"
  ),
  createOptionalPoint(
    [4178.18, 106.47, -2726.3],
    getLanguageTranslation("Tenda a caduta"),
    "curtain"
  ),
  createOptionalPoint(
    [2477.74, 29.98, -4340.15],
    getLanguageTranslation("Vetrata"),
    "glass"
  ),
  createVideoPoint(
    [4638.47, -1096.14, -1502.55],
    "Video Teaser",
    "https://www.youtube.com/watch?v=8QFP-vGRtaw&t=2s"
  ),
  createPositionPoint(
    [3894.1, -1166.77, -2906.3],
    "kedry-skylife-sotto",
    getLanguageTranslation("KEDRY SKYLIFE (Sotto)"),
    () => ({ roof: "open" })
  ),
  createVrPoint(
    [3168.01, -1638.3, -3499.2],
    KendrySkylifeGLB,
    KendrySkylifeUSDZ
  ),
  createColorPoint(
    [3922.27, -1962.76, -2388.48],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "black",
        value: "black",
        text: "CARBON RUVIDO",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createOptionalPoint(
    [3811.17, 786.06, -3128.88],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),

  createPositionPoint(
    [-4865.66, -1022.57, 476.3],
    "entrance",
    getLanguageTranslation("ENTRATA")
  ),
  createPositionPoint(
    [4934.88, -777.99, 77.37],
    "kedry-skylife_to_kolibrie",
    ""
  ),
];

export const createUnderInfoPoints = (
  createInfoPoint,
  createOptionalPoint,
  createVideoPoint,
  createPositionPoint,
  technicalSpecifics,
  createVrPoint,
  createColorPoint,
  lang
) => [
  createInfoPoint(
    [-804.61, -3678.34, -3279.93],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "KEDRY SKYLIFE",
      description:
        "<p>" +
        getLanguageTranslation(
          "Perché scegliere la nuova pergola bioclimatica KE per il tuo outdoor?"
        ) +
        "<br /></p>" +
        "<p><strong>" +
        getLanguageTranslation("- Novità: il tetto a lame mobili retrattili") +
        "</strong><br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Per regolare l’intensità della luce e la ventilazione, in funzione delle condizioni meteo."
        ) +
        "<br /></p>" +
        "<p><strong>" +
        getLanguageTranslation("- Il sistema innovativo") +
        "</strong><br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Con un unico movimento le lame ruotano e arretrano, in apertura e chiusura, consentendo di esprimere al massimo il concetto di regolazione termica e luminosa."
        ) +
        "<br /></p>" +
        "<p><strong>" +
        getLanguageTranslation("- Sistema modulare") +
        "</strong><br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Kedry Skylife si presta ad essere inserita ed adattata in spazi di forme diverse, coprendo metrature importanti, e con un impatto estetico minimale."
        ) +
        "<br /></p>" +
        "<p><strong>" +
        getLanguageTranslation(">- Perfetta automazione") +
        "</strong><br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "App per smartphone e tablet consentono una perfetta gestione anche da remoto, permettendo di definire tempo e modalità di apertura e chiusura della tenda."
        ) +
        "<br /></p>" +
        "<p><strong>" +
        getLanguageTranslation("- Illuminazione a LED") +
        "</strong><br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "È prevista la possibilità di illuminare il prodotto internamente ed esternamente grazie ad un sistema di strip led integrate nei profili perimetrali."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Kedry Skylife: un nuovo modo di concepire la vita all’aria aperta, nel massimo del comfort."
        ) +
        "<br /></p>",
      images: [
        KedrySkylifeImage1,
        KedrySkylifeImage2,
        KedrySkylifeImage3,
        KedrySkylifeImage4,
        KedrySkylifeImage5,
        KedrySkylifeImage6,
        KedrySkylifeImage7,
        KedrySkylifeImage8,
        KedrySkylifeImage9,
      ],
      buttons: [
        {
          text: getLanguageTranslation("BROCHURE"),
          href: KedrySkylifeFolderIt,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: KedrySkylifeSchedaTecnica,
        },
        {
          text: "FILE BIM",
          href:
            "https://www.keoutdoordesign.com/it-it/area-media/bim-3d-models/",
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: KedrySkylifePaletteColori,
        },
      ],
    },
    undefined,
    "<p><strong>KEDRY SKYLIFE</strong><br />" +
      getLanguageTranslation("Lame mobili retrattili che regolano l'intensità della luce e la ventilazione in funzione delle condizioni meteo.") + "<br />" +
      getLanguageTranslation("Con il suo sistema modulare, Kedry Skylife si presta ad essere inserita ed adattata in spazi di forme diverse, coprendo metrature importanti, e con impatto estetico minimale.") + "</p>"
  ),
  createOptionalPoint(
    [982.49, 853.06, 4823.28],
    getLanguageTranslation("Tenda a caduta"),
    "curtain"
  ),
  createOptionalPoint(
    [-326.02, 1278.46, -4813.39],
    getLanguageTranslation("Vetrata"),
    "glass"
  ),
  createOptionalPoint(
    [4703.83, 1119.84, 1240.44],
    getLanguageTranslation("Apertura della copertura"),
    "roof",
    Arrow,
    getRoofStatus(technicalSpecifics.roof, "up", technicalSpecifics.time)
  ),
  createOptionalPoint(
    [4713.69, 1150.96, -1179.74],
    getLanguageTranslation("Chiusura della copertura"),
    "roof",
    ArrowUp,
    getRoofStatus(technicalSpecifics.roof, "down", technicalSpecifics.time)
  ),
  createVideoPoint(
    [3160.44, -2711.89, 2756.79],
    "Video Teaser",
    "https://www.youtube.com/watch?v=8QFP-vGRtaw&t=2s"
  ),
  createPositionPoint(
    [-2840.33, -1218.08, -3927.54],
    "kedry-skylife",
    getLanguageTranslation("KEDRY SKYLIFE (Angolo)"),
    () => {
      const isOpen = technicalSpecifics.curtain || technicalSpecifics.glass;
      return {
        curtain: isOpen,
        glass: isOpen,
      };
    }
  ),
  createPositionPoint(
    [-3124.13, -1238.42, 3691.79],
    "kedry-skylife_to_kolibrie",
    ""
  ),
  createVrPoint(
    [3410.93, -2942.33, -2159.63],
    KendrySkylifeGLB,
    KendrySkylifeUSDZ
  ),
  createColorPoint(
    [4444.29, -1535.69, 1680.69],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "black",
        value: "black",
        text: "CARBON RUVIDO",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
];
