import { getLanguageTranslation } from "../translations/get-language-translation";
import {
  KheopeImage1,
  KheopeImage2,
  KheopeImage3,
  KheopeImage4,
  KheopeImage5,
  KheopeImage6,
  KheopeImage7,
  KheopeImage8,
  KheopeImage9,
  KheopeImage10,
  KheopeImage11,
  KheopeImage12,
  KheopeImage13,
  KheopeImage14,
  KheopeDepliant20x25VeleSails,
  KheopeSchedaTecnica,
  KheopeGLB,
  KheopeUSDZ,
} from "./index";

export const createKheopeInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [4523.28, -1719.72, 1218.14],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "KHEOPE",
      description:
        "<p>" +
        getLanguageTranslation(
          "Vela ombreggiante fissa dal design moderno con struttura in acciaio INOX."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Copertura di ampie dimensioni e alta resistenza agenti atmosferici e salsedine."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Movimentazione avvolgimento telo motorizzata dotata di anemometro."
        ) +
        "<br /></p>",
      images: [
        KheopeImage1,
        KheopeImage2,
        KheopeImage3,
        KheopeImage4,
        KheopeImage5,
        KheopeImage6,
        KheopeImage7,
        KheopeImage8,
        KheopeImage9,
        KheopeImage10,
        KheopeImage11,
        KheopeImage12,
        KheopeImage13,
        KheopeImage14,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Vele Sails"),
          href: KheopeDepliant20x25VeleSails,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: KheopeSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>KHEOPE</strong><br />" +
      getLanguageTranslation(
        "Vela ombreggiante fissa dal design moderno con struttura in acciaio INOX."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Copertura di ampie dimensioni e alta resistenza agenti atmosferici e salsedine."
      ) +
      "</p>"
  ),
  createPositionPoint(
    [-4952.79, -568.89, -207.93],
    "kolibrie",
    "GENNIUS SAILS KOLIBRIE"
  ),
  createPositionPoint([1667.46, -1140.63, -4566.19], "sunlight", "SUNLIGHT"),
  createOptionalPoint(
    [4967.22, 511.6, -61.94],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createVrPoint([4504.9, -1693.66, -1323.82], KheopeGLB, KheopeUSDZ),
];

export const createKheopeUsInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [4523.28, -1719.72, 1218.14],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "KHEOPE",
      description:
        "<p>" +
        getLanguageTranslation(
          "Vela ombreggiante fissa dal design moderno con struttura in acciaio INOX."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Copertura di ampie dimensioni e alta resistenza agenti atmosferici e salsedine."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Movimentazione avvolgimento telo motorizzata dotata di anemometro."
        ) +
        "<br /></p>",
      images: [
        KheopeImage1,
        KheopeImage2,
        KheopeImage3,
        KheopeImage4,
        KheopeImage5,
        KheopeImage6,
        KheopeImage7,
        KheopeImage8,
        KheopeImage9,
        KheopeImage10,
        KheopeImage11,
        KheopeImage12,
        KheopeImage13,
        KheopeImage14,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Vele Sails"),
          href: KheopeDepliant20x25VeleSails,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: KheopeSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>KHEOPE</strong><br />" +
      getLanguageTranslation(
        "Vela ombreggiante fissa dal design moderno con struttura in acciaio INOX."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Copertura di ampie dimensioni e alta resistenza agenti atmosferici e salsedine."
      ) +
      "</p>"
  ),
  createPositionPoint(
    [-4952.79, -568.89, 307.93],
    "kolibrie",
    "GENNIUS SAILS KOLIBRIE"
  ),
  createPositionPoint(
      [-4952.79, -1068.89, -207.93],
      "screeny-storm",
      "SCREENY STORM"
  ),
  createPositionPoint([1667.46, -1140.63, -4566.19], "sunlight", "SUNLIGHT"),
  createOptionalPoint(
    [4967.22, 511.6, -61.94],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createVrPoint([4504.9, -1693.66, -1323.82], KheopeGLB, KheopeUSDZ),
];
