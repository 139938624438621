//  @ts-ignore
import React from "react";
import Popup from "reactjs-popup";
import PopupMap from "./popup-map";
import PopupForm from "./popup-form";
import PopupInfo from "./popup-info";
import PopupVideo from "./popup-video";
import PopupStoreLocator from "./popup-store-locator";
import { Close } from "../icons";
import PopupVr from "./popup-vr";
import PopupColors from "./popup-colors";
import PopupPositions from "./popup-positions";
import PopupInstructions from "./popup-instructions";
import { TechnicalSpecificsType } from "../types";
import PopupPositionsMap from "./popup-positions-map";

const Popups: React.FC<{
  popupInfos: string;
  setPopupInfos: any | null;
  setTechnicalSpecifics: (v: TechnicalSpecificsType) => any;
  technicalSpecifics: TechnicalSpecificsType;
}> = ({
  popupInfos,
  setPopupInfos,
  setTechnicalSpecifics,
  technicalSpecifics,
}) => {
  if (popupInfos === null) return null;
  return (
    <Popup
      open={popupInfos !== null}
      onClose={() => setPopupInfos(null)}
      className={
        popupInfos.hasOwnProperty("popupClass")
          ? popupInfos.popupClass
          : "popup"
      }
    >
      {popupInfos.hasOwnProperty("noCloseIcon") &&
      popupInfos.noCloseIcon &&
      popupInfos.title === "" ? null : (
        <div className="header">
          <span>
            {popupInfos.hasOwnProperty("title") ? popupInfos.title : ""}
          </span>
          {popupInfos.hasOwnProperty("noCloseIcon") &&
          popupInfos.noCloseIcon ? null : (
            <div className="close-icon_container">
              <img
                src={Close}
                alt="close_icon"
                className="close-icon"
                onClick={() => setPopupInfos(null)}
              />
            </div>
          )}
        </div>
      )}
      {popupInfos === null ? null : popupInfos.type === "info" ? (
        <PopupInfo popupInfos={popupInfos} setPopupInfos={setPopupInfos} />
      ) : popupInfos.type === "video" ? (
        <PopupVideo popupInfos={popupInfos} setPopupInfos={setPopupInfos} />
      ) : popupInfos.type === "form" ? (
        <PopupForm
          technicalSpecifics={technicalSpecifics}
          setPopupInfos={setPopupInfos}
        />
      ) : popupInfos.type === "map" ? (
        <PopupMap
          currentPosition={popupInfos.currentPosition}
          map={popupInfos.map}
          setPopupInfos={setPopupInfos}
          setTechnicalSpecifics={setTechnicalSpecifics}
          technicalSpecifics={technicalSpecifics}
        />
      ) : popupInfos.type === "store-locator" ? (
        <PopupStoreLocator
          popupInfos={popupInfos}
          setPopupInfos={setPopupInfos}
        />
      ) : popupInfos.type === "vr" ? (
        <PopupVr
          GLBFile={popupInfos.GLBFile}
          USDZFile={popupInfos.USDZFile}
          setPopupInfos={setPopupInfos}
        />
      ) : popupInfos.type === "colors" ? (
        <PopupColors
          popupInfos={popupInfos}
          setPopupInfos={setPopupInfos}
          technicalSpecifics={technicalSpecifics}
          setTechnicalSpecifics={setTechnicalSpecifics}
        />
      ) : popupInfos.type === "positions" ? (
        <PopupPositions
          currentPosition={popupInfos.currentPosition}
          technicalSpecifics={technicalSpecifics}
          setTechnicalSpecifics={setTechnicalSpecifics}
          setPopupInfos={setPopupInfos}
          popupInfos={popupInfos}
        />
      ) : popupInfos.type === "instructions" ? (
        <PopupInstructions setPopupInfos={setPopupInfos} />
      ) : popupInfos.type === "positions-map" ? (
        <PopupPositionsMap
          currentPosition={popupInfos.currentPosition}
          map={popupInfos.map}
          setPopupInfos={setPopupInfos}
          popupInfos={popupInfos}
          setTechnicalSpecifics={setTechnicalSpecifics}
          technicalSpecifics={technicalSpecifics}
        />
      ) : null}
    </Popup>
  );
};

export default Popups;
