import { TechnicalSpecificsType } from "../../types";
import { getTechnicalSpecificsTime } from "./get-technical-specifics-time";

export const getEntranceImagesName = (
  technicalSpecifics: TechnicalSpecificsType
): string => `entrance${getTechnicalSpecificsTime(technicalSpecifics)}`;

export const getVegaToXtesaImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string => `vega_to_xtesa${getTechnicalSpecificsTime(technicalSpecifics)}`;

export const getScreeny1ToScreeny2ImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `screeny1_to_screeny2${getTechnicalSpecificsTime(technicalSpecifics)}`;

export const getIsola3ToVegaImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string => `isola3_to_vega${getTechnicalSpecificsTime(technicalSpecifics)}`;

export const getQubicaToKolibrieImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `qubica_to_kolibrie${getTechnicalSpecificsTime(technicalSpecifics)}`;

export const getQubicaToKolibrie2ImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `qubica_to_kolibrie2${getTechnicalSpecificsTime(technicalSpecifics)}`;

export const getXtesaToVega1ImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string => `xtesa_to_vega_1${getTechnicalSpecificsTime(technicalSpecifics)}`;

export const getScreeny2ToKolibrieImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `screeny2_to_kolibrie${getTechnicalSpecificsTime(technicalSpecifics)}`;

export const getVeneziaGoldToScreeny2ImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `venezia-gold_to_screeny2${getTechnicalSpecificsTime(technicalSpecifics)}`;

export const getIsola3ToKedrySkylifeImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `isola3_to_kedry-skylife${getTechnicalSpecificsTime(technicalSpecifics)}`;

export const getKolibrieToScreeny2ImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `kolibrie_to_screeny2${getTechnicalSpecificsTime(technicalSpecifics)}`;

export const getKedrySkylifeToKolibrieImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `kedry-skylife_to_kolibrie${getTechnicalSpecificsTime(technicalSpecifics)}`;

export const getXtesaToVega2ImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string => `xtesa_to_vega_2${getTechnicalSpecificsTime(technicalSpecifics)}`;

export const getVegaToHydraImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string => `vega_to_hydra${getTechnicalSpecificsTime(technicalSpecifics)}`;

export const getVegaToKedryPrimeImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `vega_to_kedry-prime${getTechnicalSpecificsTime(technicalSpecifics)}`;

export const getHydraToKedryPrimeImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `hydra_to_kedry-prime${getTechnicalSpecificsTime(technicalSpecifics)}`;

export const getVegaToIsola3ImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string => `vega_to_isola3${getTechnicalSpecificsTime(technicalSpecifics)}`;

export const getScreenyStormToIsola3ImageName = (
    technicalSpecifics: TechnicalSpecificsType
): string => `isola3_to_screenystorm${getTechnicalSpecificsTime(technicalSpecifics)}`;
