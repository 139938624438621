export const getPositionName = (positionName: string): string => {
  switch (positionName) {
    case "venezia-gold":
      return "Venezia Gold";
    case "qubica-flat":
      return "Qubica Flat";
    case "kolibrie":
      return "Kolibrie";
    case "kheope":
      return "Kheope";
    case "kheope-sotto":
      return "Kheope (visualizzazione sotto)";
    case "xtesa":
      return "XTesa";
    case "keplan":
      return "Keplan";
    case "screeny1":
      return "Screeny 1";
    case "screeny1-back":
      return "Screeny 1 (visualizzazione dietro)";
    case "screeny2":
      return "Screeny 2";
    case "screeny2-back":
      return "Screeny 2 (visualizzazione dietro)";
    case "vega":
      return "Vega";
    case "hydra":
      return "Hydra";
    case "isola3":
      return "Isola 3";
    case "isola3-sotto":
      return "Isola 3 (visualizzazione sotto)";
    case "kedry-prime":
      return "Kedry Prime";
    case "kedry-prime-sotto":
      return "Kedry Prime (visualizzazione sotto)";
    case "kedry-skylife":
      return "Kedry Skylife";
    case "kedry-skylife-sotto":
      return "kedry Skylife (visualizzazione sotto)";
    case "bella-plus":
      return "Bella Plus";
    case "domina":
      return "Domina";
    case "screeny-storm":
      return "Screeny Storm";
    case "screeny-storm-back":
      return "Screeny Storm - Back";
    default:
      return undefined;
  }
};
