import { TechnicalSpecificsType } from "../../types";

export const getTechnicalSpecificsColor = (
  technicalSpecifics: TechnicalSpecificsType,
  allowedColors: string[],
  defaultColor: string
): string =>
  technicalSpecifics.color !== "" &&
  allowedColors.includes(technicalSpecifics.color)
    ? "_" + technicalSpecifics.color
    : "_" + defaultColor;
