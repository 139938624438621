//  @ts-ignore
import React from "react";
import { TechnicalSpecificsType } from "../types";
import Map from "./map";
import Positions from "./positions";
import { CloseWhite } from "../icons";

const PopupPositionsMap: React.FC<{
  currentPosition: string;
  map: any;
  setPopupInfos: any;
  popupInfos: any;
  setTechnicalSpecifics: (v: TechnicalSpecificsType) => any;
  technicalSpecifics: TechnicalSpecificsType;
}> = ({
  currentPosition,
  map,
  setPopupInfos,
  popupInfos,
  setTechnicalSpecifics,
  technicalSpecifics,
}) => {
  return (
    <div className="popup-positions-map_container">
      <div className="close-button" onClick={() => setPopupInfos(null)}>
        <img src={CloseWhite} alt="close-icon-white" className="close-icon" />
      </div>
      <div className="positions__container">
        <Positions
          currentPosition={currentPosition}
          technicalSpecifics={technicalSpecifics}
          setTechnicalSpecifics={setTechnicalSpecifics}
          setPopupInfos={setPopupInfos}
          popupInfos={popupInfos}
        />
      </div>
      <div className="map__container">
        <Map
          currentPosition={currentPosition}
          map={map}
          setPopupInfos={setPopupInfos}
          setTechnicalSpecifics={setTechnicalSpecifics}
          technicalSpecifics={technicalSpecifics}
        />
      </div>
    </div>
  );
};

export default PopupPositionsMap;
