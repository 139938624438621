export const initialPositions = {
  entrance: [4994.41, -36.58, -79.27],
  "venezia-gold_to_screeny2": [4645.27, -1060.14, -1504.8],
  qubica_to_kolibrie: [4620.95, -790.79, -1718.72],
  vega_to_xtesa: [4977.9, -229.8, -249.49],
  screeny1_to_screeny2: [2613.71, -332.25, -4240.74],
  isola3_to_vega: [-858.01, -180.63, 4912.32],
  xtesa_to_vega_1: [4634.99, -546.48, -1764.65],
  screeny2_to_kolibrie: [3441.78, -99.18, 3614.09],
  "sunlight": [4995.56, -39.79, -54.81],
  "sunlight-sotto": [4988.75, -168.89, 87.96],
  "isola3_to_kedry-skylife": [4991.14, -127.26, -73.6],
  kolibrie_to_screeny2: [4972.75, -32.62, 495.47],
  "kedry-skylife_to_kolibrie": [4991.03, 135.64, 69.42],
  xtesa_to_vega_2: [4333.92, -43.64, -2488.79],
  vega_to_hydra: [-1995.14, -280.19, 4570.75],
  "vega_to_kedry-prime": [4337.62, -274.5, -2461.57],
  "hydra_to_kedry-prime": [4153.32, -908.3, -2613.38],
  vega_to_isola3: [-1470.53, -148.21, -4769.24],
  "venezia-gold": [3382.64, -1469.32, -3363.31],
  "qubica-flat": [3587.93, -1381.06, 3188.55],
  kolibrie: [4830.35, -1258.42, -110.34],
  kheope: [4922.97, -859.07, -3.4],
  "kheope-sotto": [4692.87, -1674.65, -278.79],
  xtesa: [4888.59, -153.23, -1014.66],
  screeny1: [4993.37, -21.2, 110.63],
  "screeny1-back": [4984.89, -118.14, 199.82],
  screeny2: [4978.26, -334.47, -164.11],
  "screeny2-back": [4981.63, -138.75, 246.49],
  vega: [4974.29, -305.83, -261.3],
  hydra: [4941.97, -609.04, -323.61],
  isola3: [4920.2, -804.82, -262.12],
  "isola3-sotto": [4306.16, -1870.16, -1698.97],
  "kedry-prime": [4983.45, 3.7, -313.11],
  "kedry-prime-sotto": [-3042.51, -1718.64, 3564.47],
  "kedry-skylife": [3454.33, -641.29, -3543.66],
  "kedry-skylife-sotto": [4980.76, -335.08, -115.97],
};

export const getInitialPosition: (position: string) => number[] = (position) => 
  initialPositions[position];
