import { AntaliaCompacto } from "./index";
import { getLanguageTranslation } from "../translations/get-language-translation";

export const createAntaliaInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [4393.61, -1497.43, -1842.81],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "COMPACTO",
      description:
      "<p>Bien que discret, son coffre protège aussi bien la toile que le mécanisme. Ses supports universels (pose de face ou en plafond) permettent une installation facile.<br /></p>" +
        "<p>100% aluminium, la barre de charge et les joues ont été modifiées pour la rendre plus moderne. Le choix est parmi plus de 500 types de toiles différentes.<br /></p>" +
        "<p>Enroulement de la toile avec système breveté pour éviter les plis et les faux plis lors de l'enroulement. Des bras à leds sont proposés en option.<br /></p>",
      images: [],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: AntaliaCompacto,
        },
      ],
    },
    undefined,
    "<p><strong>COMPACTO</strong><br />Bien que discret, son coffre protège aussi bien la toile que le mécanisme. Ses supports universels (pose de face ou en plafond) permettent une installation facile.<br />" +
      "100% aluminium, la barre de charge et les joues ont été modifiées pour la rendre plus moderne. Le choix est parmi plus de 500 types de toiles différentes.</p>"
  ),
  createPositionPoint(
    [-2664.08, -1305.47, 4046.64],
    "entrance",
    getLanguageTranslation("ENTRATA")
  ),
  createPositionPoint(
    [-4613.45, -1246.41, -1455.2],
    "kedry-skylife-sotto",
    getLanguageTranslation("KEDRY SKYLIFE (Sotto)")
  ),
  createPositionPoint(
    [536.21, -1358.22, 4776.32],
    "venezia-gold",
    "GENEROSA GOLD"
  ),
  createOptionalPoint(
    [3926.84, 293.61, -3066.64],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createPositionPoint(
    [156.21, -989.48, -4889.02],
    "venezia-gold_to_screeny2",
    ""
  ),
];
