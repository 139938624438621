import { getLanguageTranslation } from "../translations/get-language-translation";
import {
  VegaImage1,
  VegaImage2,
  VegaImage3,
  VegaImage4,
  VegaImage5,
  VegaImage6,
  VegaImage7,
  VegaImage8,
  VegaImage9,
  VegaImage10,
  VegaImage11,
  VegaImage12,
  VegaDepliant20x25Gennius,
  VegaDepliantLineGlassIt,
  VegaDepliantPaletteColori2021It,
  VegaSchedaTecnica,
  VegaGLB,
  VegaUSDZ,
} from "./index";

export const createVegaInfoPoints = (
  createInfoPoint,
  createVideoPoint,
  createPositionPoint,
  createOptionalPoint,
  createVrPoint,
  createColorPoint,
  lang
) => [
  createInfoPoint(
    [4777.61, -1148.64, -895.49],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "GENNIUS VEGA",
      description:
        "<p>" +
        getLanguageTranslation(
          "Schermatura a lame con tetto ad impaccehttamento dal design lineare contemporaneo."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Completa modularità della struttura ed elevata possibilità di personalizzazione."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Ampia selezione di tessuti tecnici e Innovativi inserti luminosi a luce diffusa lungo guide e colonne."
        ) +
        "<br /></p>",
      images: [
        VegaImage1,
        VegaImage2,
        VegaImage3,
        VegaImage4,
        VegaImage5,
        VegaImage6,
        VegaImage7,
        VegaImage8,
        VegaImage9,
        VegaImage10,
        VegaImage11,
        VegaImage12,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Gennius"),
          href: VegaDepliant20x25Gennius,
        },
        {
          text: "Line Glass",
          href: VegaDepliantLineGlassIt,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: VegaDepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: VegaSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>GENNIUS VEGA</strong><br />" +
      getLanguageTranslation(
        "Schermatura a lame con tetto ad impaccehttamento dal design lineare contemporaneo."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Completa modularità della struttura ed elevata possibilità di personalizzazione."
      ) +
      "</p>"
  ),
  createVideoPoint(
    [4841.13, -975.45, 714.36],
    "Video",
    "https://www.youtube.com/watch?v=Njkrsq8g3CA"
  ),
  createOptionalPoint(
    [4875.54, -80.74, -1084.03],
    getLanguageTranslation("Vetrata"),
    "glass"
  ),
  createColorPoint(
    [4597.53, -803.8, -1772.52],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "black",
        value: "black",
        text: "CARBON RUVIDO",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),

  createPositionPoint([-3241.12, -189.2, 3790.82], "vega_to_xtesa", ""),
  createPositionPoint([3647.45, -1551.16, -3038.08], "vega_to_hydra", ""),
  createVrPoint([4720.01, -457.89, 1572.38], VegaGLB, VegaUSDZ),
];
