import {
  VeneziaImage1,
  VeneziaImage2,
  VeneziaImage3,
  VeneziaDepliant20x25Tende,
  VeneziaBrochureAKI,
  VeneziaDepliantPaletteColori2021It,
  VeneziaSchedaTecnica,
  VeneziaGLB,
  VeneziaUSDZ,
  Aky,
  Balteus,
  GenerosaTechnicalSheet,
} from "./index";
import { Settings } from "../../icons";
import { getLanguageTranslation } from "../translations/get-language-translation";

export const createVeneziaGoldInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [1382.27, -1892.57, -4413.37],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "VENEZIA GOLD",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda da sole a bracci motorizzata su barra quadra."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Design accattivante grazie al supporto Gold e al rivoluzionario braccio Aki."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Avvolgimento telo con sistema brevettato per evitare pieghe e grinze in fase di arrotolamento."
        ) +
        "<br /></p>",
      images: [VeneziaImage1, VeneziaImage2, VeneziaImage3],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Tende"),
          href: VeneziaDepliant20x25Tende,
        },
        {
          text: getLanguageTranslation("Brochure AKI"),
          href: VeneziaBrochureAKI,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: VeneziaDepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: VeneziaSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>VENEZIA GOLD</strong><br />" +
      getLanguageTranslation(
        "Tenda da sole a bracci motorizzata su barra quadra."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Design accattivante grazie al supporto Gold e al rivoluzionario braccio Aki."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Avvolgimento telo con sistema brevettato per evitare pieghe e grinze in fase di arrotolamento."
      ) +
      "</p>"
  ),
  createPositionPoint([-2664.08, -1305.47, 4046.64], "entrance", getLanguageTranslation("ENTRATA")),
  createPositionPoint(
    [-3097.34, -903.04, -3806.81],
    "kedry-skylife-sotto",
    getLanguageTranslation("KEDRY SKYLIFE (Sotto)")
  ),
  createOptionalPoint(
    [3926.84, 293.61, -3066.64],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createColorPoint(
    [4721.23, -514.22, -1550.63],
    [
      {
        technicalValue: "aky",
        value: "white",
        text: "Aky",
        image: Aky,
      },
      {
        technicalValue: "balteus",
        value: "white",
        text: "Balteus",
        image: Balteus,
      },
    ],
    getLanguageTranslation("BRACCIO"),
    Settings,
    technicalSpecifics.time === "day"
  ),
  createPositionPoint(
    [148.71, -586.67, -4953.79],
    "venezia-gold_to_screeny2",
    ""
  ),
  createVrPoint([2675.79, -2807.27, -3144.15], VeneziaGLB, VeneziaUSDZ),
];

export const createVeneziaGoldFrInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [1382.27, -1892.57, -4413.37],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "GENEROSA GOLD",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda da sole a bracci motorizzata su barra quadra."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Design accattivante grazie al supporto Gold e al rivoluzionario braccio Aki."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Avvolgimento telo con sistema brevettato per evitare pieghe e grinze in fase di arrotolamento."
        ) +
        "<br /></p>",
      images: [VeneziaImage1, VeneziaImage2, VeneziaImage3],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Tende"),
          href: VeneziaDepliant20x25Tende,
        },
        {
          text: getLanguageTranslation("Brochure AKI"),
          href: VeneziaBrochureAKI,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: VeneziaDepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: GenerosaTechnicalSheet,
        },
      ],
    },
    undefined,
    "<p><strong>GENEROSA GOLD</strong><br />" +
      getLanguageTranslation(
        "Tenda da sole a bracci motorizzata su barra quadra."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Design accattivante grazie al supporto Gold e al rivoluzionario braccio Aki."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Avvolgimento telo con sistema brevettato per evitare pieghe e grinze in fase di arrotolamento."
      ) +
      "</p>"
  ),
  createPositionPoint([-2664.08, -1305.47, 4046.64], "entrance", getLanguageTranslation("ENTRATA")),
  createPositionPoint(
    [-3097.34, -903.04, -3806.81],
    "kedry-skylife-sotto",
    getLanguageTranslation("KEDRY SKYLIFE (Sotto)")
  ),
  createPositionPoint(
    [227.48, -1202.80, -4839.90],
    "antalia",
    "COMPACTO"
  ),
  createOptionalPoint(
    [3926.84, 293.61, -3066.64],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  /*createColorPoint(
    [4721.23, -514.22, -1550.63],
    [
      {
        technicalValue: "aky",
        value: "white",
        text: "Aky",
        image: Aky,
      },
      {
        technicalValue: "balteus",
        value: "white",
        text: "Balteus",
        image: Balteus,
      },
    ],
    getLanguageTranslation("BRACCIO"),
    Settings,
    technicalSpecifics.time === "day"
  ),*/
  createPositionPoint(
    [148.71, -586.67, -4953.79],
    "venezia-gold_to_screeny2",
    ""
  ),
  createVrPoint([2675.79, -2807.27, -3144.15], VeneziaGLB, VeneziaUSDZ),
];
