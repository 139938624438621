import { getLanguageTranslation } from "../translations/get-language-translation";
import {
    KeplanImage1,
    KeplanImage2,
    KeplanImage3,
    KeplanImage4,
    KeplanImage5,
    KeplanImage6,
    KeplanImage7,
    KeplanDepliant20x25Gennius,
    KeplanSchedaTecnica,
} from "./index";

export const createKeplanInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [5197.76, -1264.17, -2395.75],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "Keplan",
      description:
        "<h4>" + getLanguageTranslation("NOVITÀ SALONE DEL MOBILE 2023") + "</h4>" + "<p><strong>" + getLanguageTranslation("KE KEPLAN Design lineare e geometria piana al servizio della progettazione outdoor") + "</strong></p>" +
        "<p>" +
        getLanguageTranslation(
          "Armonia, ordine, essenzialità: tre concetti che si possono ricondurre in architettura alla linea retta. L’occhio per sottrazione distingue in questo tratto le caratteristiche di eleganza e semplicità. Fonte di ispirazione e aspirazione nel corso della storia del design, KE applica il principio della purezza delle linee a servizio del benessere abitativo e psicofisico nel nuovo prodotto KEPLAN."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "KEPLAN è l’innovativa pergola in alluminio a tetto piano e telo ad impacchettamento nata per integrarsi perfettamente in qualsiasi soluzione progettuale contemporanea. Il suo design minimale rompe con il tradizionale schema della pergola a tetto inclinato: funzionalità e comfort si uniscono ad una geometria piana adatta alle più diverse realizzazioni outdoor, sia in ambito residenziale sia ho.re.ca."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation("Grazie all’innovativo sistema di colonne regolabili in salita e in discesa, l’installazione di KEPLAN si adatta perfettamente anche a contesti difficoltosi: questo sistema di regolazione infatti risolve la problematica di pendenze e dislivelli del terreno.") +
        "</br></p>" +
        "<p>" +
        getLanguageTranslation("Disponibile sia in versione autoportante che addossata, la nuova pergola KE può essere illuminata grazie ad un sistema di strip led dimmerabili inseriti nei traversi per creare suggestive ambientazioni luminose dedicate alla convivialità e al relax.") +
        "</br></p>" +
        "<p>" +
        getLanguageTranslation("KEPLAN ha copertura con caratteristica forma del tetto ad arco ribassato che permette di diminuire l’ingombro in altezza e di migliorare la resa estetica complessiva. Per rispondere a due richieste fondamentali – la necessità di privacy e l’efficacia di protezione dal sole e dalla pioggia - la pergola è dotata di tenda, richiudibile ad impacchettamento. Il telo, che risulta teso nella sua massima apertura, è fissato su traversi ad arco e scorre orizzontalmente per mezzo di carrelli su guide motorizzate.") +
        "</br>" +
        getLanguageTranslation("Illuminazione e movimentazione del telo sono regolati da sistemi di domotica integrata.") +
        "</br>" +
        getLanguageTranslation("La massima resa estetica e la duttilità di KEPLAN si confermano anche nell’ampia gamma di tessuti disponibili funzionali alla personalizzazione massima.") +
        "</br></p>" +
        "<p>" +
        getLanguageTranslation("Discrezione e piacevolezza, affidabilità e funzionalità, adattabilità e tecnologia sono le caratteristiche che KE sintetizza in KEPLAN, per creare nuovi ambienti outdoor raffinati e accoglienti dove poter godere del miglior “tempo per sé” e coltivare momenti di rilassata convivialità.") +
        "</br></p>" +
        "<p><strong>" + getLanguageTranslation("Caratteristiche di performance") + "</strong></p>" +
        "<ul>" +
        "<li>" + getLanguageTranslation("Design squadrato ed essenziale") + "</li>" +
        "<li>" + getLanguageTranslation("Ideale per ampie coperture") + "</li>" +
        "<li>" + getLanguageTranslation("Resistenza al sole") + "</li>" +
        "<li>" + getLanguageTranslation("Resistenza al vento, classe 6") + "</li>" +
        "<li>" + getLanguageTranslation("Massima larghezza: 550 cm") + "</li>" +
        "<li>" + getLanguageTranslation("Massima sporgenza: 600cm") + "</li>" +
        "</ul>",
      images: [KeplanImage1, KeplanImage2, KeplanImage3, KeplanImage4, KeplanImage5, KeplanImage6, KeplanImage7,],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Keplan"),
          href: KeplanDepliant20x25Gennius,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: KeplanSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>KEPLAN</strong><br />" +
      getLanguageTranslation(
        "Innovativa pergola in alluminio a tetto piano. Il suo design minimale rompe con il tradizionale schema della pergola a tetto inclinato."
      ) +
      "</p>"
  ),
  createPositionPoint(
    [455.32, -889.51, 7873.67],
    "xtesa",
    "XTESA"
  ),
  createOptionalPoint(
    [4811.55, 1239.41, -527.66],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createColorPoint(
    [4545.78, -1240.76, 1648.09],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "black",
        value: "black",
        text: "CARBON RUVIDO",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createPositionPoint([-3433.47, -796.32, 1632.05], "xtesa_to_vega_1", ""),
];
