import { TechnicalSpecificsType } from "../types";
import { useCreateOptionalPoint } from "./use-create-optional-point";
import { useCreateInfoPoint } from "./use-create-info-point";
import { useCreatePositionPoint } from "./use-create-position-point";
import { useCreateVideoPoint } from "./use-create-video-point";
import { createXtesaInfoPoints } from "./info-points/xtesa";
import { createKeplanInfoPoints } from "./info-points/keplan";
import {
  createKheopeInfoPoints,
  createKheopeUsInfoPoints,
} from "./info-points/kheope";
import {
  createAngleInfoPoints,
  createUnderInfoPoints,
} from "./info-points/kedry-skylife";
import {
  createEntranceInfoPoints,
  createEntranceUsInfoPoints,
} from "./info-points/entrance";
import {
  createVeneziaGoldFrInfoPoints,
  createVeneziaGoldInfoPoints,
} from "./info-points/venezia-gold";
import {
  createKolibrieEsInfoPoints,
  createKolibrieFrInfoPoints,
  createKolibrieInfoPoints,
  createKolibrieUsInfoPoints,
} from "./info-points/kolibrie";
import {
  createQubicaInfoPoints,
  createQubicaUsInfoPoints,
} from "./info-points/qubica-flat";
import {
  createScreeny2BackFrInfoPoints,
  createScreeny2BackInfoPoints,
  createScreeny2FrInfoPoints,
  createScreeny2InfoPoints,
} from "./info-points/screeny2";
import {
  createKedryPrimeInfoPoints,
  createKedryPrimeSottoInfoPoints,
  createKedryPrimeUsInfoPoints,
} from "./info-points/kedry-prime";
import {
  createHydraFrInfoPoints,
  createHydraInfoPoints,
  createHydraUsInfoPoints,
} from "./info-points/hydra";
import { createVegaInfoPoints } from "./info-points/vega";
import {
  createIsola3EsInfoPoints,
  createIsola3FrInfoPoints,
  createIsola3InfoPoints,
  createIsola3SottoEsInfoPoints,
  createIsola3SottoFrInfoPoints,
  createIsola3SottoInfoPoints,
} from "./info-points/isola";
import {
  createScreeny1BackFrInfoPoints,
  createScreeny1BackInfoPoints,
  createScreeny1FrInfoPoints,
  createScreeny1InfoPoints,
} from "./info-points/screeny1";
import { useCreateVrPoint } from "./use-create-vr-point";
import { useCreateColorPoint } from "./use-create-color-point";
import {
  createVeneziaGoldToScreeny2InfoPoints,
  createHydraToKedryPrimeInfoPoints,
  createIsola3ToVegaInfoPoints,
  createQubicaToKolibrieInfoPoints,
  createScreeny2ToKolibrieInfoPoints,
  createVegaToHydraInfoPoints,
  createVegaToKedryPrimeInfoPoints,
  createVegaToXtesaInfoPoints,
  createXtesaToVega2InfoPoints,
  createScreeny1ToScreeny2InfoPoints,
  createXtesaToVega1InfoPoints,
  createIsola3ToKedrySkylifeInfoPoints,
  createKedrySkylifeToKolibrieInfoPoints,
  createKolibrieToScreeny2InfoPoints,
  createVegaToIsola3InfoPoints,
  createIsola3ToVegaUsInfoPoints,
  createHydraToKedryPrimeUsInfoPoints,
  createVegaToHydraUsInfoPoints,
  createVeneziaGoldToScreeny2UsInfoPoints,
  createXtesaToVega2UsInfoPoints,
  createKedrySkylifeToKolibrieUsInfoPoints,
  createVeneziaGoldToScreeny2FrInfoPoints,
  createQubicaToKolibrieFrInfoPoints,
  createQubicaToKolibrieUsInfoPoints,
  createQubicaToKolibrie2FrInfoPoints,
  createVeneziaGoldToScreeny2EsInfoPoints,
  createQubicaToKolibrieEsInfoPoints,
  createScreeny2ToKolibrieEsInfoPoints,
  createHydraToKedryPrimeFrInfoPoints,
  createVegaToHydraFrInfoPoints,
  createScreeny1ToScreeny2FrInfoPoints,
  createScreeny2ToKolibrieFrInfoPoints,
  createScreenyStormToIsola3InfoPoints
} from "./info-points/percorso";
import { createA100UsInfoPoints } from "./info-points/a100";
import { LOCALSTORAGE_LANGUAGE } from "../consts";
import { createAntaliaInfoPoints } from "./info-points/antalia";
import { createBellaPlusUsInfoPoints } from "./info-points/bella-plus";
import { createDominaUsInfoPoints } from "./info-points/domina";
import { createScreenyStormUsInfoPoints } from "./info-points/screeny-storm";
import { createScreenyStormUsBackInfoPoints } from "./info-points/screeny-storm";
import { createQubicaLiteInfoPoints } from "./info-points/qubica-lite";
import { createSunlightInfoPoints, createSunlightSottoInfoPoints, createSunlightUsInfoPoints } from "./info-points/sunlight";

export const createInfoPoints: (
  setPopupInfos: any,
  setTechnicalSpecifics: any,
  technicalSpecifics: TechnicalSpecificsType,
  lang: string
) => Record<string, any> = (
  setPopupInfos,
  setTechnicalSpecifics,
  technicalSpecifics,
  langDefault
) => {
  const lang = localStorage.getItem(LOCALSTORAGE_LANGUAGE);

  const createOptionalPoint = useCreateOptionalPoint(
    setTechnicalSpecifics,
    technicalSpecifics,
    lang
  );
  const createInfoPoint = useCreateInfoPoint(setPopupInfos, lang);
  const createPositionPoint = useCreatePositionPoint(
    setTechnicalSpecifics,
    technicalSpecifics,
    lang
  );
  const createVideoPoint = useCreateVideoPoint(setPopupInfos, lang);
  const createVrPoint = useCreateVrPoint(setPopupInfos, lang);
  const createColorPoint = useCreateColorPoint(setPopupInfos, lang);

  switch (lang) {
    case "it":
      return {
        entrance: createEntranceInfoPoints(createPositionPoint, lang),
        vega_to_xtesa: createVegaToXtesaInfoPoints(createPositionPoint, lang),
        screeny1_to_screeny2: createScreeny1ToScreeny2InfoPoints(
          createPositionPoint,
          lang
        ),
        isola3_to_vega: createIsola3ToVegaInfoPoints(createPositionPoint, lang),
        qubica_to_kolibrie: createQubicaToKolibrieInfoPoints(
          createPositionPoint,
          lang
        ),
        xtesa_to_vega_1: createXtesaToVega1InfoPoints(
          createPositionPoint,
          lang
        ),
        screeny2_to_kolibrie: createScreeny2ToKolibrieInfoPoints(
          createPositionPoint,
          lang
        ),
        "venezia-gold_to_screeny2": createVeneziaGoldToScreeny2InfoPoints(
          createPositionPoint,
          lang
        ),
        "isola3_to_kedry-skylife": createIsola3ToKedrySkylifeInfoPoints(
          createPositionPoint,
          lang
        ),
        kolibrie_to_screeny2: createKolibrieToScreeny2InfoPoints(
          createPositionPoint,
          lang
        ),
        "kedry-skylife_to_kolibrie": createKedrySkylifeToKolibrieInfoPoints(
          createPositionPoint,
          lang
        ),
        xtesa_to_vega_2: createXtesaToVega2InfoPoints(
          createPositionPoint,
          lang
        ),
        vega_to_hydra: createVegaToHydraInfoPoints(createPositionPoint, lang),
        "vega_to_kedry-prime": createVegaToKedryPrimeInfoPoints(
          createPositionPoint,
          lang
        ),
        "hydra_to_kedry-prime": createHydraToKedryPrimeInfoPoints(
          createPositionPoint,
          lang
        ),
        vega_to_isola3: createVegaToIsola3InfoPoints(createPositionPoint, lang),
        "venezia-gold": createVeneziaGoldInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "qubica-flat": createQubicaInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        kolibrie: createKolibrieInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        kheope: createKheopeInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        xtesa: createXtesaInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        keplan: createKeplanInfoPoints(
            createInfoPoint,
            createPositionPoint,
            createOptionalPoint,
            createColorPoint,
            technicalSpecifics,
            lang
        ),
        screeny1: createScreeny1InfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "screeny1-back": createScreeny1BackInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        screeny2: createScreeny2InfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "screeny2-back": createScreeny2BackInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        vega: createVegaInfoPoints(
          createInfoPoint,
          createVideoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          createColorPoint,
          lang
        ),
        hydra: createHydraInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        isola3: createIsola3InfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          createColorPoint,
          technicalSpecifics,
          lang
        ),
        "isola3-sotto": createIsola3SottoInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "kedry-prime": createKedryPrimeInfoPoints(
          createInfoPoint,
          createVideoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          createColorPoint,
          lang
        ),
        "kedry-prime-sotto": createKedryPrimeSottoInfoPoints(
          createInfoPoint,
          createVideoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "kedry-skylife": createAngleInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          createVrPoint,
          createColorPoint,
          technicalSpecifics,
          lang
        ),
        "kedry-skylife-sotto": createUnderInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          technicalSpecifics,
          createVrPoint,
          createColorPoint,
          lang
        ),
        "sunlight": createSunlightInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          technicalSpecifics,
          createVrPoint,
          createColorPoint,
          lang,
        ),
        "sunlight-sotto": createSunlightSottoInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          technicalSpecifics,
          createVrPoint,
          createColorPoint,
          lang,
        ),
      };
    case "en":
      return {
        entrance: createEntranceInfoPoints(createPositionPoint, lang),
        vega_to_xtesa: createVegaToXtesaInfoPoints(createPositionPoint, lang),
        screeny1_to_screeny2: createScreeny1ToScreeny2InfoPoints(
          createPositionPoint,
          lang
        ),
        isola3_to_vega: createIsola3ToVegaInfoPoints(createPositionPoint, lang),
        qubica_to_kolibrie: createQubicaToKolibrieInfoPoints(
          createPositionPoint,
          lang
        ),
        xtesa_to_vega_1: createXtesaToVega1InfoPoints(
          createPositionPoint,
          lang
        ),
        screeny2_to_kolibrie: createScreeny2ToKolibrieInfoPoints(
          createPositionPoint,
          lang
        ),
        "venezia-gold_to_screeny2": createVeneziaGoldToScreeny2InfoPoints(
          createPositionPoint,
          lang
        ),
        "isola3_to_kedry-skylife": createIsola3ToKedrySkylifeInfoPoints(
          createPositionPoint,
          lang
        ),
        kolibrie_to_screeny2: createKolibrieToScreeny2InfoPoints(
          createPositionPoint,
          lang
        ),
        "kedry-skylife_to_kolibrie": createKedrySkylifeToKolibrieInfoPoints(
          createPositionPoint,
          lang
        ),
        xtesa_to_vega_2: createXtesaToVega2InfoPoints(
          createPositionPoint,
          lang
        ),
        vega_to_hydra: createVegaToHydraInfoPoints(createPositionPoint, lang),
        "vega_to_kedry-prime": createVegaToKedryPrimeInfoPoints(
          createPositionPoint,
          lang
        ),
        "hydra_to_kedry-prime": createHydraToKedryPrimeInfoPoints(
          createPositionPoint,
          lang
        ),
        vega_to_isola3: createVegaToIsola3InfoPoints(createPositionPoint, lang),
        "venezia-gold": createVeneziaGoldInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "qubica-flat": createQubicaInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        kolibrie: createKolibrieInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        kheope: createKheopeInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        xtesa: createXtesaInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        keplan: createKeplanInfoPoints(
            createInfoPoint,
            createPositionPoint,
            createOptionalPoint,
            createColorPoint,
            technicalSpecifics,
            lang
        ),
        screeny1: createScreeny1InfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "screeny1-back": createScreeny1BackInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        screeny2: createScreeny2InfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "screeny2-back": createScreeny2BackInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        vega: createVegaInfoPoints(
          createInfoPoint,
          createVideoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          createColorPoint,
          lang
        ),
        hydra: createHydraInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        isola3: createIsola3InfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          createColorPoint,
          technicalSpecifics,
          lang
        ),
        "isola3-sotto": createIsola3SottoInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "kedry-prime": createKedryPrimeInfoPoints(
          createInfoPoint,
          createVideoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          createColorPoint,
          lang
        ),
        "kedry-prime-sotto": createKedryPrimeSottoInfoPoints(
          createInfoPoint,
          createVideoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "kedry-skylife": createAngleInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          createVrPoint,
          createColorPoint,
          technicalSpecifics,
          lang
        ),
        "kedry-skylife-sotto": createUnderInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          technicalSpecifics,
          createVrPoint,
          createColorPoint,
          lang
        ),
        "sunlight": createSunlightInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          technicalSpecifics,
          createVrPoint,
          createColorPoint,
          lang,
        ),
        "sunlight-sotto": createSunlightSottoInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          technicalSpecifics,
          createVrPoint,
          createColorPoint,
          lang,
        ),
      };
    case "en-us":
      return {
        entrance: createEntranceUsInfoPoints(createPositionPoint, lang),
        vega_to_xtesa: createVegaToXtesaInfoPoints(createPositionPoint, lang),
        isola3_to_vega: createIsola3ToVegaUsInfoPoints(
          createPositionPoint,
          lang
        ),
        "screenystorm_to_isola3": createScreenyStormToIsola3InfoPoints(
          createPositionPoint,
          lang
        ),
        qubica_to_kolibrie: createQubicaToKolibrieUsInfoPoints(
          createPositionPoint,
          lang
        ),
        xtesa_to_vega_1: createXtesaToVega1InfoPoints(
          createPositionPoint,
          lang
        ),
        "venezia-gold_to_screeny2": createVeneziaGoldToScreeny2UsInfoPoints(
          createPositionPoint,
          lang
        ),
        "isola3_to_kedry-skylife": createIsola3ToKedrySkylifeInfoPoints(
          createPositionPoint,
          lang
        ),
        "kedry-skylife_to_kolibrie": createKedrySkylifeToKolibrieUsInfoPoints(
          createPositionPoint,
          lang
        ),
        xtesa_to_vega_2: createXtesaToVega2UsInfoPoints(
          createPositionPoint,
          lang
        ),
        vega_to_hydra: createVegaToHydraUsInfoPoints(createPositionPoint, lang),
        "vega_to_kedry-prime": createVegaToKedryPrimeInfoPoints(
          createPositionPoint,
          lang
        ),
        "hydra_to_kedry-prime": createHydraToKedryPrimeUsInfoPoints(
          createPositionPoint,
          lang
        ),
        vega_to_isola3: createVegaToIsola3InfoPoints(createPositionPoint, lang),
        a100: createA100UsInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "qubica-flat": createQubicaUsInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        kolibrie: createKolibrieUsInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        kheope: createKheopeUsInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        vega: createVegaInfoPoints(
          createInfoPoint,
          createVideoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          createColorPoint,
          lang
        ),
        "kedry-prime": createKedryPrimeUsInfoPoints(
          createInfoPoint,
          createVideoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          createColorPoint,
          lang
        ),
        "kedry-prime-sotto": createKedryPrimeSottoInfoPoints(
          createInfoPoint,
          createVideoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "sunlight": createSunlightUsInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          technicalSpecifics,
          createVrPoint,
          createColorPoint,
          lang,
        ),
        "sunlight-sotto": createSunlightSottoInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          technicalSpecifics,
          createVrPoint,
          createColorPoint,
          lang,
        ),
        "bella-plus": createBellaPlusUsInfoPoints(
            createInfoPoint,
            createPositionPoint,
            createOptionalPoint,
            createColorPoint,
            createVrPoint,
            technicalSpecifics,
            lang,
        ),
        domina: createDominaUsInfoPoints(
            createInfoPoint,
            createPositionPoint,
            createOptionalPoint,
            createColorPoint,
            createVrPoint,
            technicalSpecifics,
            lang,
        ),
        "screeny-storm": createScreenyStormUsInfoPoints(
            createInfoPoint,
            createPositionPoint,
            createOptionalPoint,
            createColorPoint,
            createVrPoint,
            technicalSpecifics,
            lang
        ),
        "screeny-storm-back": createScreenyStormUsBackInfoPoints(
            createInfoPoint,
            createPositionPoint,
            createOptionalPoint,
            createColorPoint,
            createVrPoint,
            technicalSpecifics,
            lang
        ),
      };
    case "de":
      return {
        entrance: createEntranceInfoPoints(createPositionPoint, lang),
        vega_to_xtesa: createVegaToXtesaInfoPoints(createPositionPoint, lang),
        screeny1_to_screeny2: createScreeny1ToScreeny2InfoPoints(
          createPositionPoint,
          lang
        ),
        isola3_to_vega: createIsola3ToVegaInfoPoints(createPositionPoint, lang),
        qubica_to_kolibrie: createQubicaToKolibrieInfoPoints(
          createPositionPoint,
          lang
        ),
        xtesa_to_vega_1: createXtesaToVega1InfoPoints(
          createPositionPoint,
          lang
        ),
        screeny2_to_kolibrie: createScreeny2ToKolibrieInfoPoints(
          createPositionPoint,
          lang
        ),
        "venezia-gold_to_screeny2": createVeneziaGoldToScreeny2InfoPoints(
          createPositionPoint,
          lang
        ),
        "isola3_to_kedry-skylife": createIsola3ToKedrySkylifeInfoPoints(
          createPositionPoint,
          lang
        ),
        kolibrie_to_screeny2: createKolibrieToScreeny2InfoPoints(
          createPositionPoint,
          lang
        ),
        "kedry-skylife_to_kolibrie": createKedrySkylifeToKolibrieInfoPoints(
          createPositionPoint,
          lang
        ),
        xtesa_to_vega_2: createXtesaToVega2InfoPoints(
          createPositionPoint,
          lang
        ),
        vega_to_hydra: createVegaToHydraInfoPoints(createPositionPoint, lang),
        "vega_to_kedry-prime": createVegaToKedryPrimeInfoPoints(
          createPositionPoint,
          lang
        ),
        "hydra_to_kedry-prime": createHydraToKedryPrimeInfoPoints(
          createPositionPoint,
          lang
        ),
        vega_to_isola3: createVegaToIsola3InfoPoints(createPositionPoint, lang),
        "venezia-gold": createVeneziaGoldInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "qubica-flat": createQubicaInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        kolibrie: createKolibrieInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        kheope: createKheopeInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        xtesa: createXtesaInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        keplan: createKeplanInfoPoints(
            createInfoPoint,
            createPositionPoint,
            createOptionalPoint,
            createColorPoint,
            technicalSpecifics,
            lang
        ),
        screeny1: createScreeny1InfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "screeny1-back": createScreeny1BackInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        screeny2: createScreeny2InfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "screeny2-back": createScreeny2BackInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        vega: createVegaInfoPoints(
          createInfoPoint,
          createVideoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          createColorPoint,
          lang
        ),
        hydra: createHydraInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        isola3: createIsola3InfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          createColorPoint,
          technicalSpecifics,
          lang
        ),
        "isola3-sotto": createIsola3SottoInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "kedry-prime": createKedryPrimeInfoPoints(
          createInfoPoint,
          createVideoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          createColorPoint,
          lang
        ),
        "kedry-prime-sotto": createKedryPrimeSottoInfoPoints(
          createInfoPoint,
          createVideoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "kedry-skylife": createAngleInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          createVrPoint,
          createColorPoint,
          technicalSpecifics,
          lang
        ),
        "kedry-skylife-sotto": createUnderInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          technicalSpecifics,
          createVrPoint,
          createColorPoint,
          lang
        ),
        "sunlight": createSunlightInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          technicalSpecifics,
          createVrPoint,
          createColorPoint,
          lang,
        ),
        "sunlight-sotto": createSunlightSottoInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          technicalSpecifics,
          createVrPoint,
          createColorPoint,
          lang,
        ),
      };
    case "fr":
      return {
        entrance: createEntranceInfoPoints(createPositionPoint, lang),
        "kedry-skylife": createAngleInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          createVrPoint,
          createColorPoint,
          technicalSpecifics,
          lang
        ),
        "kedry-skylife-sotto": createUnderInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          technicalSpecifics,
          createVrPoint,
          createColorPoint,
          lang
        ),
        "kedry-skylife_to_kolibrie": createKedrySkylifeToKolibrieInfoPoints(
          createPositionPoint,
          lang
        ),
        "venezia-gold_to_screeny2": createVeneziaGoldToScreeny2FrInfoPoints(
          createPositionPoint,
          lang
        ),
        antalia: createAntaliaInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "venezia-gold": createVeneziaGoldFrInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        qubica_to_kolibrie: createQubicaToKolibrieFrInfoPoints(
          createPositionPoint,
          lang
        ),
        "qubica-lite": createQubicaLiteInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        kolibrie: createKolibrieFrInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        qubica_to_kolibrie2: createQubicaToKolibrie2FrInfoPoints(
          createPositionPoint,
          lang
        ),
        screeny1_to_screeny2: createScreeny1ToScreeny2FrInfoPoints(
          createPositionPoint,
          lang
        ),
        kolibrie_to_screeny2: createKolibrieToScreeny2InfoPoints(
          createPositionPoint,
          lang
        ),
        screeny2_to_kolibrie: createScreeny2ToKolibrieFrInfoPoints(
          createPositionPoint,
          lang
        ),
        screeny1: createScreeny1FrInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "screeny1-back": createScreeny1BackFrInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        screeny2: createScreeny2FrInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "screeny2-back": createScreeny2BackFrInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        isola3: createIsola3FrInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          createColorPoint,
          technicalSpecifics,
          lang
        ),
        "isola3-sotto": createIsola3SottoFrInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "isola3_to_kedry-skylife": createIsola3ToKedrySkylifeInfoPoints(
          createPositionPoint,
          lang
        ),
        isola3_to_vega: createIsola3ToVegaInfoPoints(createPositionPoint, lang),
        "vega_to_kedry-prime": createVegaToKedryPrimeInfoPoints(
          createPositionPoint,
          lang
        ),
        "hydra_to_kedry-prime": createHydraToKedryPrimeFrInfoPoints(
          createPositionPoint,
          lang
        ),
        "kedry-prime": createKedryPrimeInfoPoints(
          createInfoPoint,
          createVideoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          createColorPoint,
          lang
        ),
        "kedry-prime-sotto": createKedryPrimeSottoInfoPoints(
          createInfoPoint,
          createVideoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        vega: createVegaInfoPoints(
          createInfoPoint,
          createVideoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          createColorPoint,
          lang
        ),
        vega_to_isola3: createVegaToIsola3InfoPoints(createPositionPoint, lang),
        hydra: createHydraFrInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        kheope: createKheopeInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        xtesa_to_vega_1: createXtesaToVega1InfoPoints(
          createPositionPoint,
          lang
        ),
        xtesa_to_vega_2: createXtesaToVega2InfoPoints(
          createPositionPoint,
          lang
        ),
        vega_to_xtesa: createVegaToXtesaInfoPoints(createPositionPoint, lang),
        vega_to_hydra: createVegaToHydraFrInfoPoints(createPositionPoint, lang),
        xtesa: createXtesaInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "sunlight": createSunlightInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          technicalSpecifics,
          createVrPoint,
          createColorPoint,
          lang,
        ),
        "sunlight-sotto": createSunlightSottoInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          technicalSpecifics,
          createVrPoint,
          createColorPoint,
          lang,
        ),
      };
    case "es":
      return {
        entrance: createEntranceInfoPoints(createPositionPoint, lang),
        "kedry-skylife": createAngleInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          createVrPoint,
          createColorPoint,
          technicalSpecifics,
          lang
        ),
        "kedry-skylife-sotto": createUnderInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          technicalSpecifics,
          createVrPoint,
          createColorPoint,
          lang
        ),
        "kedry-skylife_to_kolibrie": createKedrySkylifeToKolibrieInfoPoints(
          createPositionPoint,
          lang
        ),
        "venezia-gold_to_screeny2": createVeneziaGoldToScreeny2EsInfoPoints(
          createPositionPoint,
          lang
        ),
        qubica_to_kolibrie: createQubicaToKolibrieEsInfoPoints(
          createPositionPoint,
          lang
        ),
        kolibrie: createKolibrieEsInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        kheope: createKheopeInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        xtesa: createXtesaInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        kolibrie_to_screeny2: createKolibrieToScreeny2InfoPoints(
          createPositionPoint,
          lang
        ),
        screeny2_to_kolibrie: createScreeny2ToKolibrieEsInfoPoints(
          createPositionPoint,
          lang
        ),
        isola3: createIsola3EsInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          createColorPoint,
          technicalSpecifics,
          lang
        ),
        "isola3-sotto": createIsola3SottoEsInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "isola3_to_kedry-skylife": createIsola3ToKedrySkylifeInfoPoints(
          createPositionPoint,
          lang
        ),
        isola3_to_vega: createIsola3ToVegaInfoPoints(createPositionPoint, lang),
        "vega_to_kedry-prime": createVegaToKedryPrimeInfoPoints(
          createPositionPoint,
          lang
        ),
        vega_to_isola3: createVegaToIsola3InfoPoints(createPositionPoint, lang),
        "hydra_to_kedry-prime": createHydraToKedryPrimeInfoPoints(
          createPositionPoint,
          lang
        ),
        "kedry-prime": createKedryPrimeInfoPoints(
          createInfoPoint,
          createVideoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          createColorPoint,
          lang
        ),
        "kedry-prime-sotto": createKedryPrimeSottoInfoPoints(
          createInfoPoint,
          createVideoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        vega_to_hydra: createVegaToHydraInfoPoints(createPositionPoint, lang),
        vega: createVegaInfoPoints(
          createInfoPoint,
          createVideoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          createColorPoint,
          lang
        ),
        hydra: createHydraInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        xtesa_to_vega_1: createXtesaToVega1InfoPoints(
          createPositionPoint,
          lang
        ),
        xtesa_to_vega_2: createXtesaToVega2InfoPoints(
          createPositionPoint,
          lang
        ),
        vega_to_xtesa: createVegaToXtesaInfoPoints(createPositionPoint, lang),
        "sunlight": createSunlightInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          technicalSpecifics,
          createVrPoint,
          createColorPoint,
          lang,
        ),
        "sunlight-sotto": createSunlightSottoInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          technicalSpecifics,
          createVrPoint,
          createColorPoint,
          lang,
        ),
      };
    default:
      return {
        entrance: createEntranceInfoPoints(createPositionPoint, lang),
        vega_to_xtesa: createVegaToXtesaInfoPoints(createPositionPoint, lang),
        screeny1_to_screeny2: createScreeny1ToScreeny2InfoPoints(
          createPositionPoint,
          lang
        ),
        isola3_to_vega: createIsola3ToVegaInfoPoints(createPositionPoint, lang),
        qubica_to_kolibrie: createQubicaToKolibrieInfoPoints(
          createPositionPoint,
          lang
        ),
        xtesa_to_vega_1: createXtesaToVega1InfoPoints(
          createPositionPoint,
          lang
        ),
        screeny2_to_kolibrie: createScreeny2ToKolibrieInfoPoints(
          createPositionPoint,
          lang
        ),
        "venezia-gold_to_screeny2": createVeneziaGoldToScreeny2InfoPoints(
          createPositionPoint,
          lang
        ),
        "isola3_to_kedry-skylife": createIsola3ToKedrySkylifeInfoPoints(
          createPositionPoint,
          lang
        ),
        kolibrie_to_screeny2: createKolibrieToScreeny2InfoPoints(
          createPositionPoint,
          lang
        ),
        "kedry-skylife_to_kolibrie": createKedrySkylifeToKolibrieInfoPoints(
          createPositionPoint,
          lang
        ),
        xtesa_to_vega_2: createXtesaToVega2InfoPoints(
          createPositionPoint,
          lang
        ),
        vega_to_hydra: createVegaToHydraInfoPoints(createPositionPoint, lang),
        "vega_to_kedry-prime": createVegaToKedryPrimeInfoPoints(
          createPositionPoint,
          lang
        ),
        "hydra_to_kedry-prime": createHydraToKedryPrimeInfoPoints(
          createPositionPoint,
          lang
        ),
        vega_to_isola3: createVegaToIsola3InfoPoints(createPositionPoint, lang),
        "venezia-gold": createVeneziaGoldInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "qubica-flat": createQubicaInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        kolibrie: createKolibrieInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        kheope: createKheopeInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        xtesa: createXtesaInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        keplan: createKeplanInfoPoints(
            createInfoPoint,
            createPositionPoint,
            createOptionalPoint,
            createColorPoint,
            technicalSpecifics,
            lang
        ),
        screeny1: createScreeny1InfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "screeny1-back": createScreeny1BackInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        screeny2: createScreeny2InfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "screeny2-back": createScreeny2BackInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        vega: createVegaInfoPoints(
          createInfoPoint,
          createVideoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          createColorPoint,
          lang
        ),
        hydra: createHydraInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        isola3: createIsola3InfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          createColorPoint,
          technicalSpecifics,
          lang
        ),
        "isola3-sotto": createIsola3SottoInfoPoints(
          createInfoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "kedry-prime": createKedryPrimeInfoPoints(
          createInfoPoint,
          createVideoPoint,
          createPositionPoint,
          createOptionalPoint,
          createVrPoint,
          createColorPoint,
          lang
        ),
        "kedry-prime-sotto": createKedryPrimeSottoInfoPoints(
          createInfoPoint,
          createVideoPoint,
          createPositionPoint,
          createOptionalPoint,
          createColorPoint,
          createVrPoint,
          technicalSpecifics,
          lang
        ),
        "kedry-skylife": createAngleInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          createVrPoint,
          createColorPoint,
          technicalSpecifics,
          lang
        ),
        "kedry-skylife-sotto": createUnderInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          technicalSpecifics,
          createVrPoint,
          createColorPoint,
          lang
        ),
        "sunlight": createSunlightInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          technicalSpecifics,
          createVrPoint,
          createColorPoint,
          lang,
        ),
        "sunlight-sotto": createSunlightSottoInfoPoints(
          createInfoPoint,
          createOptionalPoint,
          createVideoPoint,
          createPositionPoint,
          technicalSpecifics,
          createVrPoint,
          createColorPoint,
          lang,        ),
      };
  }
};
