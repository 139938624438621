import { TechnicalSpecificsType } from "../../types";
import { getTechnicalSpecificsColor } from "./get-technical-specifics-color";
import { getTechnicalSpecificsTime } from "./get-technical-specifics-time";
import { getTechnicalSpecificsGlass } from "./get-technical-specifics-glass";
import { getTechnicalSpecificsCurtain } from "./get-technical-specifics-curtain";

export const getSunlightSottoImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `sunlight-sotto${getTechnicalSpecificsColor(
    technicalSpecifics,
    ["white", "black"],
    "black"
  )}${getTechnicalSpecificsTime(
    technicalSpecifics
  )}${getTechnicalSpecificsGlass(
    technicalSpecifics
  )}${getTechnicalSpecificsCurtain(technicalSpecifics)}`;
