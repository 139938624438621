//  @ts-ignore
import React, { useEffect, useState } from "react";
import { Formik, Field, Form, FormikHelpers } from "formik";
import { CloseWhite } from "../icons";
import { TechnicalSpecificsType } from "../types";
import { getLanguageTranslation } from "../utils/translations/get-language-translation";
import { LOCALSTORAGE_LANGUAGE } from "../consts";

const PopupForm: React.FC<{
  technicalSpecifics: TechnicalSpecificsType;
  setPopupInfos: any;
}> = ({ technicalSpecifics, setPopupInfos }) => {
  const [formMessage, setFormMessage] = useState({ text: "", color: "" });
  interface Values {
    firstName: string;
    lastName: string;
    email: string;
    message: string;
    phone: string;
    city: string;
    gdpr: boolean;
  }

  const [formStrings, setFormStrings] = useState<{
    init: string;
    writeUs: string;
    reply: string;
    name: string;
    email: string;
    phone: string;
    city: string;
    message: string;
    rules: string;
    rulesNumber: string;
    desire: string;
    replyIn: string;
  }>({
    init: "",
    writeUs: "",
    reply: "",
    name: "",
    email: "",
    phone: "",
    city: "",
    message: "",
    rules: "",
    rulesNumber: "",
    desire: "",
    replyIn: "",
  });

  useEffect(
    () => {
      setFormStrings({
        init: getLanguageTranslation("Vuoi più informazioni sui prodotti KE?"),
        writeUs: getLanguageTranslation(
          "Scrivici: ti offriamo un preventivo gratuito e senza impegno."
        ),
        reply: getLanguageTranslation("Ti rispondiamo entro 24/48H"),

        name: getLanguageTranslation("Il tuo nome"),
        email: getLanguageTranslation("Il tuo indirizzo e-mail *"),
        phone: getLanguageTranslation("Telefono *"),
        city: getLanguageTranslation("La tua città *"),
        message: getLanguageTranslation("Vuoi raccontarci la tua esigenza?"),
        rules: getLanguageTranslation(
          "Acconsento al trattamento dei miei dati personali"
        ),
        rulesNumber: getLanguageTranslation("Regolamento UE 2016/679"),
        desire: getLanguageTranslation("Desidero un preventivo gratuito"),
        replyIn: getLanguageTranslation("Riceverai una risposta entro 24-48H"),
      });
    },
    typeof window !== `undefined`
      ? [setFormStrings, localStorage.getItem(LOCALSTORAGE_LANGUAGE)]
      : [setFormStrings]
  );

  return (
    <div className="form-wrapper">
      <div className="form-header">
        <div className="close-button" onClick={() => setPopupInfos(null)}>
          <img src={CloseWhite} alt="close-icon-white" className="close-icon" />
        </div>
      </div>
      <div className="form-initial">
        <h2>{formStrings.init}</h2>
        <p>{formStrings.writeUs}</p>
        <small>{formStrings.reply}</small>
      </div>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          message: "",
          phone: "",
          city: "",
          gdpr: false,
        }}
        onSubmit={(
          values: Values,
          { setSubmitting, resetForm }: FormikHelpers<Values>
        ) => {
          fetch(
            "https://showroom.keoutdoordesign.com/__api/content-manager/v1/documents/data/@ke-showroom--showroom/Contact/create-one",
            {
              method: "POST",
              body: JSON.stringify({ data: values }),
              headers: {
                "content-type": "application/json",
              },
            }
          ).then((res) => {
            if (res.status === 200) {
              console.log('Sending "submit-form-contatti-ok"');
              //    @ts-ignore
              window.dataLayer = window.dataLayer || [];
              //    @ts-ignore
              dataLayer.push({
                event: "submit-form-contatti-ok",
              });
              setFormMessage({
                text: "Grazie per averci contattato!",
                color: "#00C000",
              });
            } else {
              setFormMessage({
                text: "Qualcosa è andato storto.",
                color: "#E02020",
              });
            }
            setTimeout(() => {
              setFormMessage({ text: "", color: "" });
            }, 4000);
            resetForm({});
          });
          setSubmitting(false);
        }}
        render={() => (
          <Form className="form-container">
            <div className="form-row">
              <label htmlFor="firstName">{formStrings.name}</label>
              <Field id="firstName" name="firstName" required type="text" />
            </div>
            <div className="form-row">
              <label htmlFor="email">{formStrings.email}</label>
              <Field id="email" name="email" required type="email" />
            </div>
            <div className="form-row">
              <label htmlFor="phone">{formStrings.phone}</label>
              <Field
                id="phone"
                name="phone"
                type="tel"
                required
                title="1234567890"
                pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
              />
            </div>
            <div className="form-row">
              <label htmlFor="city">{formStrings.city}</label>
              <Field id="city" name="city" type="text" required />
            </div>
            <div className="form-row">
              <label htmlFor="message">{formStrings.message}</label>
              <Field
                id="message"
                name="message"
                type="text"
                as="textarea"
                rows="6"
              />
            </div>
            <div className="form-gdpr-row">
              <Field id="gdpr" name="gdpr" type="checkbox" required />
              <label htmlFor="gdpr">
                {formStrings.rules} (
                <a
                  href="https://www.keoutdoordesign.com/it-it/azienda/privacy-cookies-policy"
                  target="_blank"
                  className="privacy-policy__link"
                >
                  {formStrings.rulesNumber}
                </a>
                )*
              </label>
            </div>
            <div className="submit-row">
              <span style={{ color: formMessage.color }}>
                {formMessage.text}
              </span>
              <div className="button-container">
                <button type="submit" style={{ display: "block" }}>
                  {formStrings.desire}
                </button>
                <small>{formStrings.replyIn}</small>
              </div>
            </div>
          </Form>
        )}
      />
    </div>
  );
};

export default PopupForm;
