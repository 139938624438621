//  @ts-ignore
import React, { useEffect, useState } from "react";
import {
  getMapPositionsByLang,
  getPositionsTitlesByLang,
  mapPositionsDe,
  mapPositionsEn,
  mapPositionsEs,
  mapPositionsIt,
  mapPositionsUs,
  positionsTitlesDe,
  positionsTitlesEn,
  positionsTitlesEs,
  positionsTitlesIt,
  positionsTitlesUs,
} from "../map-positions";
import { TechnicalSpecificsType } from "../../types";
import { LOCALSTORAGE_LANGUAGE } from "../../consts";
import { getLanguageTranslation } from "../../utils/translations/get-language-translation";

const Positions: React.FC<{
  currentPosition: any;
  technicalSpecifics: TechnicalSpecificsType;
  setTechnicalSpecifics: (v: TechnicalSpecificsType) => any;
  setPopupInfos: any;
  popupInfos: any;
}> = ({
  currentPosition,
  technicalSpecifics,
  setTechnicalSpecifics,
  setPopupInfos,
}) => {
  const [currentPositionTitles, setCurrentPositionTitles] = useState<any[]>([]);
  const [mapPosition, setMapPosition] = useState<any[]>([]);
  const [title, setTitle] = useState<string>("");
  const [pathTitle, setPathTitle] = useState<string>("");

  useEffect(
    () => {
      setTitle(getLanguageTranslation("PRODOTTI"));
      setPathTitle(getLanguageTranslation("PERCORSO"));

      setCurrentPositionTitles(getPositionsTitlesByLang());

      setMapPosition(getMapPositionsByLang());
    },
    typeof window !== `undefined`
      ? [
          setTitle,
          setPathTitle,
          setCurrentPositionTitles,
          getMapPositionsByLang,
          setMapPosition,
          getPositionsTitlesByLang,
          localStorage.getItem(LOCALSTORAGE_LANGUAGE),
        ]
      : [
          setTitle,
          setPathTitle,
          getPositionsTitlesByLang,
          setCurrentPositionTitles,
          getMapPositionsByLang,
          setMapPosition,
        ]
  );

  useEffect(() => {
    console.log(currentPositionTitles);
  }, [currentPositionTitles]);

  return (
    <div className="positions-list">
      <div className="position-title__container title" key={"product-title"}>
        <span className="position-title">{title}</span>
      </div>

      {/* TODO: FILTER MAP POSITIONS BY LANG */}
      {mapPosition
        .filter((p) => p.type === "product")
        .map((p, i) => (
          <div
            className={
              currentPosition === p.name
                ? "position-title__container active"
                : "position-title__container"
            }
            key={i}
            onClick={() => {
              setTechnicalSpecifics({
                ...technicalSpecifics,
                position: p.name,
              });
              setPopupInfos(null);
            }}
          >
            <span className="position-title">{p.title}</span>
          </div>
        ))}
      <div className="position-title__container title" key={"path-title"}>
        <span className="position-title">{pathTitle}</span>
      </div>
      {currentPositionTitles
        .filter((p) => p.type === "path")
        .map((p, i) => (
          <div
            className={
              currentPosition === p.name
                ? "position-title__container active"
                : "position-title__container"
            }
            key={i}
            onClick={() => {
              setTechnicalSpecifics({
                ...technicalSpecifics,
                position: p.name,
              });
              setPopupInfos(null);
            }}
          >
            <span className="position-title">{p.title}</span>
          </div>
        ))}
    </div>
  );
};

export default Positions;
