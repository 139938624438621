import { Vr } from "../icons";

export const useCreateVrPoint: (
  setPopupInfos: any,
  lang: string
) => (
  position: number[],
  GLBFile: any,
  USDZFile: any,
  title?: string
) => Record<string, any> = (setPopupInfos) => (
  position,
  GLBFile,
  USDZFile,
  title = "VR"
) => ({
  position,
  text: title,
  image: Vr,
  type: "vr",
  description: null,
  clickListener: () => {
    setPopupInfos({
      title: "",
      noCloseIcon: true,
      type: "vr",
      popupClass: "popup-vr_container",
      GLBFile,
      USDZFile,
    });
  },
});
