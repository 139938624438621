import { TechnicalSpecificsType } from "../../types";
import { getTechnicalSpecificsTime } from "./get-technical-specifics-time";
import { getTechnicalSpecificsBooleanRoof } from "./get-technical-specifics-boolean-roof";

export const getScreenyStormImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `screeny-storm${getTechnicalSpecificsTime(
    technicalSpecifics
  )}${getTechnicalSpecificsBooleanRoof(technicalSpecifics)}`;
