import { getLanguageTranslation } from "../translations/get-language-translation";
import {
  HydraDepliant20x25Gennius,
  HydraDepliantLineGlassIt,
  HydraDepliantPaletteColori2021It,
  HydraePlusSchedaTecnica,
  HydraGLB,
  HydraImage1,
  HydraImage2,
  HydraImage3,
  HydraImage4,
  HydraImage5,
  HydraSchedaTecnica,
  HydraUSDZ,
} from "./index";

export const createHydraInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [4720.5, -1609.76, -208.42],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "HYDRA",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda a pergola con telo avvolto dal design minimal priva di viteria in vista."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Scalabile in larghezza così da consentire la copertura di superfici di dimensioni importanti."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Ampia selezione di tessuti tecnici e illuminazione integrata sulle guide e sul cassonetto."
        ) +
        "<br /></p>",
      images: [HydraImage1, HydraImage2, HydraImage3, HydraImage4, HydraImage5],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Gennius"),
          href: HydraDepliant20x25Gennius,
        },
        {
          text: "Line Glass",
          href: HydraDepliantLineGlassIt,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: HydraDepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: HydraSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>HYDRA</strong><br />" +
      getLanguageTranslation(
        "Tenda a pergola con telo avvolto dal design minimal priva di viteria in vista."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Scalabile in larghezza così da consentire la copertura di superfici di dimensioni importanti."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Ampia selezione di tessuti tecnici e illuminazione integrata sulle guide e sul cassonetto."
      ) +
      "</p>"
  ),
  createOptionalPoint(
    [4917.65, 748.75, 472.17],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createColorPoint(
    [4650.29, -1072.18, -1476.84],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "black",
        value: "black",
        text: "CARBON RUVIDO",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createVrPoint([4642.09, -947.77, 1576.64], HydraGLB, HydraUSDZ),

  createPositionPoint([3489.5, -488.92, 3533.95], "vega_to_hydra", ""),
  createPositionPoint(
    [-3244.57, -1160.35, 3621.81],
    "hydra_to_kedry-prime",
    ""
  ),
];

export const createHydraFrInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    [4720.5, -1609.76, -208.42],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "HYDRAE PLUS",
      description:
        "<p>" +
        getLanguageTranslation(
          "Tenda a pergola con telo avvolto dal design minimal priva di viteria in vista."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Scalabile in larghezza così da consentire la copertura di superfici di dimensioni importanti."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Ampia selezione di tessuti tecnici e illuminazione integrata sulle guide e sul cassonetto."
        ) +
        "<br /></p>",
      images: [HydraImage1, HydraImage2, HydraImage3, HydraImage4, HydraImage5],
      buttons: [
        {
          text: getLanguageTranslation("Depliant Gennius"),
          href: HydraDepliant20x25Gennius,
        },
        {
          text: "Line Glass",
          href: HydraDepliantLineGlassIt,
        },
        {
          text: getLanguageTranslation("Palette Colori"),
          href: HydraDepliantPaletteColori2021It,
        },
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: HydraePlusSchedaTecnica,
        },
      ],
    },
    undefined,
    "<p><strong>HYDRAE PLUS</strong><br />" +
      getLanguageTranslation(
        "Tenda a pergola con telo avvolto dal design minimal priva di viteria in vista."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Scalabile in larghezza così da consentire la copertura di superfici di dimensioni importanti."
      ) +
      "<br />" +
      getLanguageTranslation(
        "Ampia selezione di tessuti tecnici e illuminazione integrata sulle guide e sul cassonetto."
      ) +
      "</p>"
  ),
  createOptionalPoint(
    [4917.65, 748.75, 472.17],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createColorPoint(
    [4650.29, -1072.18, -1476.84],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "black",
        value: "black",
        text: "CARBON RUVIDO",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
  createVrPoint([4642.09, -947.77, 1576.64], HydraGLB, HydraUSDZ),

  createPositionPoint([3489.5, -488.92, 3533.95], "vega_to_hydra", ""),
  createPositionPoint(
    [-3244.57, -1160.35, 3621.81],
    "hydra_to_kedry-prime",
    ""
  ),
];

export const createHydraUsInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics
) => [
  /*createInfoPoint(
    [4720.5, -1609.76, -208.42],
    hydraInfoPopupDatas,
    undefined,
    "<p><strong>HYDRA</strong><br />" +
  getLanguageTranslation(
    "Tenda a pergola con telo avvolto dal design minimal priva di viteria in vista."
  ) +
  "<br />" +
  getLanguageTranslation(
    "Scalabile in larghezza così da consentire la copertura di superfici di dimensioni importanti."
  ) +
  "<br />" +
  getLanguageTranslation(
    "Ampia selezione di tessuti tecnici e illuminazione integrata sulle guide e sul cassonetto."
  ) +
  "</p>"
  ),
  createOptionalPoint(
    [4917.65, 748.75, 472.17],
    "Copertura",
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createColorPoint(
    [4650.29, -1072.18, -1476.84],
    [
      {
        technicalValue: "white",
        value: "white",
        text: "RAL9010",
      },
      {
        technicalValue: "black",
        value: "black",
        text: "CARBON RUVIDO",
      },
    ],
    getLanguageTranslation("COLORE STRUTTURA")
  ),
    createVrPoint([4642.09, -947.77, 1576.64], HydraGLB, HydraUSDZ),
  */

  createPositionPoint([3489.5, -488.92, 3533.95], "vega_to_hydra", ""),
  createPositionPoint(
    [-3244.57, -1160.35, 3621.81],
    "hydra_to_kedry-prime",
    ""
  ),
];
