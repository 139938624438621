//  @ts-ignore
import React, { useEffect } from "react";

const GoogleTagManager: React.FC = () => {
  useEffect(() => {
    const newScript = document.createElement("script");
    // @ts-ignore
    newScript.onload = (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      // @ts-ignore
      j.async = true;
      // @ts-ignore
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "GTM-TRL8M89");
    document.head.insertBefore(newScript, document.head.children[0]);
  }, []);
  useEffect(() => {
    const newNoScript = document.createElement("noscript");
    const newIFrame = document.createElement("iframe");
    newIFrame.src = "https://www.googletagmanager.com/ns.html?id=GTM-TRL8M89";
    newIFrame.height = "0";
    newIFrame.width = "0";
    newIFrame.style.display = "none";
    newIFrame.style.visibility = "hidden";
    newNoScript.insertBefore(newIFrame, null);

    document.body.insertBefore(newNoScript, document.body.children[0]);
  }, []);

  return null;
};
export default GoogleTagManager;
