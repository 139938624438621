import { TechnicalSpecificsType } from "../../types";
import { getTechnicalSpecificsColor } from "./get-technical-specifics-color";
import { getTechnicalSpecificsTime } from "./get-technical-specifics-time";
import { getTechnicalSpecificsGlass } from "./get-technical-specifics-glass";
import { getTechnicalSpecificsCurtain } from "./get-technical-specifics-curtain";

export const getSunlightImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `sunlight${getTechnicalSpecificsColor(
    technicalSpecifics,
    ["white", "black"],
    "white"
  )}${getTechnicalSpecificsTime(
    technicalSpecifics
  )}${getTechnicalSpecificsGlass(
    technicalSpecifics
  )}${getTechnicalSpecificsCurtain(technicalSpecifics)}`;
