//  @ts-ignore
import React from "react";

const Loading: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  return (
    <div
      className="loading-container"
      style={!isLoading ? { display: "none" } : null}
    />
  );
};

export default Loading;
