import { TechnicalSpecificsType } from "../../types";
import { getTechnicalSpecificsColor } from "./get-technical-specifics-color";
import { getTechnicalSpecificsTime } from "./get-technical-specifics-time";
import { getTechnicalSpecificsGlass } from "./get-technical-specifics-glass";
import { getTechnicalSpecificsCurtain } from "./get-technical-specifics-curtain";

export const getKedryPrimeImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `kedry-prime${getTechnicalSpecificsColor(
    technicalSpecifics,
    ["black", "white"],
    "black"
  )}${getTechnicalSpecificsTime(
    technicalSpecifics
  )}_close${getTechnicalSpecificsGlass(
    technicalSpecifics
  )}${getTechnicalSpecificsCurtain(technicalSpecifics)}`;
