import { getLanguageTranslation } from "../translations/get-language-translation";
import {
  ScreenyStormImage1,
  ScreenyStormImage2,
  ScreenyStormImage3,
  ScreenyStormImage4,
  ScreenyStormDepliant20x25
} from "./index";

export const createScreenyStormUsInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    //[3993.94, -2993.03, 162.19],
      [3998.64, -1174.2, 753.53],
    {
      type: "info",
      title: "",
      noCloseIcon: true,
      popupClass: "popup-info_container",
      sectionTitle: "KE STORM RAIL",
      description:
        "<h2>" + getLanguageTranslation("REINFORCED ZIPPERED SCREEN") + "</h2>" +
        "<p>" +
        getLanguageTranslation(
          "This innovative design locks the Hem Bar and fabric into the Guide Rail. This keeps the edges of the screen secure under pressure from high winds and shock from impact."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "Smooth movement of the Hem Bar is achieved with a polyethylene ZIP Rail Guide, placed vertically along the entire length of the guide. Fabric slides are held in place by stainless steel compensation springs."
        ) +
        "<br /></p>" +
        "<p>" +
        getLanguageTranslation(
          "The ZIP system contained in the guide provides a reliable seal against light and heat along the edges of the fabric. The telescopic end caps are not fixed to the profile, which reduces stress on the fabric in the corners."
        ) +
        "<br /></p>"  +
          "<p>" +
          getLanguageTranslation(
              "The addition of adjustable spacers makes installation of the guides simpler and more precise. Guides can be quickly and easily plumbed for perfect positioning."
          ) +
          "<br /></p>",
      images: [
          ScreenyStormImage1,
          ScreenyStormImage2,
          ScreenyStormImage3,
          ScreenyStormImage4,
      ],
      buttons: [
        {
          text: getLanguageTranslation("Scheda Tecnica"),
          href: ScreenyStormDepliant20x25,
        },
      ],
    },
    undefined,
    "<p><strong>SCREENY STORM</strong><br />" +
      getLanguageTranslation(
        "This innovative design locks the Hem Bar and fabric into the Guide Rail. This keeps the edges of the screen secure under pressure from high winds and shock from impact."
      ) +
      "</p>"
  ),
  createPositionPoint([-52.42, -972.55, -4897.83], "screenystorm_to_isola3", ""),
    createPositionPoint([3752.42, -1772.55, -4897.83], "screeny-storm-back", "SCREENY STORM - BACK"),
    createPositionPoint([-752.42, 1772.55, 2897.83], "kolibrie", "GENNIUS SAILS KOLIBRIE"),
    createPositionPoint([552.42, 1772.55, 2897.83], "kheope", "GENNIUS SAILS KHEOPE K1"),
  //createPositionPoint([-3889.67, -120.87, -3123.32], "vega", "GENNIUS VEGA"),
  createOptionalPoint(
    [3998.64, 1174.2, 2753.53],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "close"
  ),
];

export const createScreenyStormUsBackInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
    //[3944.36, -3021.71, -502.26],
      [4230.43, -1550.05, -502.30],
    {
        type: "info",
        title: "",
        noCloseIcon: true,
        popupClass: "popup-info_container",
        sectionTitle: "KE STORM RAIL",
        description:
            "<h2>" + getLanguageTranslation("REINFORCED ZIPPERED SCREEN") + "</h2>" +
            "<p>" +
            getLanguageTranslation(
                "This innovative design locks the Hem Bar and fabric into the Guide Rail. This keeps the edges of the screen secure under pressure from high winds and shock from impact."
            ) +
            "<br /></p>" +
            "<p>" +
            getLanguageTranslation(
                "Smooth movement of the Hem Bar is achieved with a polyethylene ZIP Rail Guide, placed vertically along the entire length of the guide. Fabric slides are held in place by stainless steel compensation springs."
            ) +
            "<br /></p>" +
            "<p>" +
            getLanguageTranslation(
                "The ZIP system contained in the guide provides a reliable seal against light and heat along the edges of the fabric. The telescopic end caps are not fixed to the profile, which reduces stress on the fabric in the corners."
            ) +
            "<br /></p>"  +
            "<p>" +
            getLanguageTranslation(
                "The addition of adjustable spacers makes installation of the guides simpler and more precise. Guides can be quickly and easily plumbed for perfect positioning."
            ) +
            "<br /></p>",
        images: [
            ScreenyStormImage1,
            ScreenyStormImage2,
            ScreenyStormImage3,
            ScreenyStormImage4,
        ],
        buttons: [
            {
                text: getLanguageTranslation("Scheda Tecnica"),
                href: ScreenyStormDepliant20x25,
            },
        ],
    },
      undefined,
      "<p><strong>SCREENY STORM</strong><br />" +
      getLanguageTranslation(
          "This innovative design locks the Hem Bar and fabric into the Guide Rail. This keeps the edges of the screen secure under pressure from high winds and shock from impact."
      ) +
      "</p>"
  ),
  createPositionPoint(
    [2820.96, -653.56, 4285.49],
    "screenystorm_to_isola3",
    ""
  ),
    createPositionPoint([3452.42, -1372.55, 3997.83], "screeny-storm", "SCREENY STORM"),
  createOptionalPoint(
    [4230.43, 1150.05, 2399.3],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "close"
  ),
];
