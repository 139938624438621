export const getRoofStatus = (roof, direction, time) => {
  // if(time === 'night') {
  //   return direction === 'up' ? 'open' : 'close'
  // }
  switch (roof) {
    case "close":
      return direction === "up" ? "semi-open" : "close";
    case "semi-open":
      return direction === "up" ? "open" : "close";
    case "open":
      return direction === "up" ? "open" : "semi-open";
    default:
      return "close";
  }
};
