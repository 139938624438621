//  @ts-ignore
import React from "react";
import { CloseWhite } from "../icons";
import ReactPlayer from "react-player";

const PopupVideo: React.FC<{ popupInfos: any; setPopupInfos: any }> = ({
  popupInfos,
  setPopupInfos,
}) => {
  return (
    <div className="video-wrapper">
      <div className="close-button" onClick={() => setPopupInfos(null)}>
        <img src={CloseWhite} alt="close-icon-white" className="close-icon" />
      </div>
      <ReactPlayer url={popupInfos.video} className="yt-player" />
    </div>
  );
};

export default PopupVideo;
