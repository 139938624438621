import { TechnicalSpecificsType } from "../../types";
import { getTechnicalSpecificsColor } from "./get-technical-specifics-color";
import { getTechnicalSpecificsTime } from "./get-technical-specifics-time";
import { getTechnicalSpecificsBooleanRoof } from "./get-technical-specifics-boolean-roof";

export const getVeneziaGoldImageName = (
  technicalSpecifics: TechnicalSpecificsType
): string =>
  `venezia-gold${
    technicalSpecifics.time === "night"
      ? "_aky"
      : getTechnicalSpecificsColor(
          technicalSpecifics,
          ["aky", "balteus"],
          "aky"
        )
  }${getTechnicalSpecificsTime(
    technicalSpecifics
  )}${getTechnicalSpecificsBooleanRoof(technicalSpecifics)}`;
