//  @ts-ignore
import React, { useCallback, useEffect, useState } from "react";
import { getImagesByTechnicalSpecifics } from "./images-by-position";
import { TechnicalSpecificsType } from "../types";
import { filterLoadedImages } from "./filter-loaded-images";
import { LOCALSTORAGE_LANGUAGE } from "../consts";

const LoadImagesByImage: React.FC<{
  images: Record<string, string>;
  technicalSpecifics: TechnicalSpecificsType;
}> = ({ images, technicalSpecifics }) => {
  const [currentPosition, setCurrentPosition] = useState<string>("");
  const [loadedImages, setLoadedImages] = useState<string[]>([]);
  const [toLoad, setToLoad] = useState<string[]>([]);

  useEffect(() => {
    setLoadedImages([]);
    setCurrentPosition(technicalSpecifics.position);
  }, []);

  const loadImages = useCallback(() => {
    if (currentPosition !== technicalSpecifics.position) {
      const imagesToLoad = getImagesByTechnicalSpecifics(
        technicalSpecifics,
        images
      );
      const filteredImagesToLoad = filterLoadedImages(
        imagesToLoad,
        loadedImages
      );
      setToLoad(filteredImagesToLoad);
    }
  }, [
    loadedImages,
    setLoadedImages,
    technicalSpecifics,
    images,
    currentPosition,
  ]);

  const imagesToLoad = useCallback(async () => {
    let index = 0;
    const o = loadedImages;
    while (index < toLoad.length) {
      await fetch(toLoad[index]);
      o.push(toLoad[index]);
      index++;
    }
    setLoadedImages(o);
  }, [toLoad, setLoadedImages, loadedImages]);

  useEffect(() => {
    loadImages();
    return loadImages();
  }, [technicalSpecifics]);

  useEffect(() => {
    imagesToLoad().then();
  }, [toLoad]);

  return null;
};
export default LoadImagesByImage;
