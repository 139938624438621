// @ts-ignore
import React from "react";
import { Close, CloseWhite } from "../icons";
import { TechnicalSpecificsType } from "../types";

const PopupColors: React.FC<{
  popupInfos: any;
  setPopupInfos: any;
  technicalSpecifics: TechnicalSpecificsType;
  setTechnicalSpecifics: (v: TechnicalSpecificsType) => any;
}> = ({
  popupInfos,
  setPopupInfos,
  technicalSpecifics,
  setTechnicalSpecifics,
}) => (
  <div className="colors-container">
    <div className="popup-header">
      <div className="close-button" onClick={() => setPopupInfos(null)}>
        <img
          src={CloseWhite}
          alt="close-icon-white"
          className="close-icon only-mobile"
        />
        <img
          src={Close}
          alt="close-icon-white"
          className="close-icon only-desktop"
        />
      </div>
    </div>
    <div className="choose-colors__container">
      {popupInfos.colors.map((color, i) => (
        <div className="button-circle__container" key={i}>
          {color.hasOwnProperty("image") &&
          color.image !== null &&
          color.image !== undefined ? (
            <img
              src={color.image}
              alt={"img_" + i}
              className="color-image"
              onClick={() => {
                setTechnicalSpecifics({
                  ...technicalSpecifics,
                  color: color.technicalValue,
                });
                setPopupInfos(null);
              }}
            />
          ) : (
            <button
              onClick={() => {
                setTechnicalSpecifics({
                  ...technicalSpecifics,
                  color: color.technicalValue,
                });
                setPopupInfos(null);
              }}
              className="button-circle"
              style={{ background: color.value }}
            />
          )}
          <span>{color.text}</span>
        </div>
      ))}
    </div>
  </div>
);

export default PopupColors;
