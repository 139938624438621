import { QubicaLiteDepliant20x25, QubicaLiteSchedaTecnica } from "./index";
import { getLanguageTranslation } from "../translations/get-language-translation";

export const createQubicaLiteInfoPoints = (
  createInfoPoint,
  createPositionPoint,
  createOptionalPoint,
  createColorPoint,
  createVrPoint,
  technicalSpecifics,
  lang
) => [
  createInfoPoint(
      [4393.61, -1497.43, -1842.81],
      {
        type: "info",
        title: "",
        noCloseIcon: true,
        popupClass: "popup-info_container",
        sectionTitle: "QUBICA LITE",
        description:
        "<p>Qubica Lite se caractérise par la pureté des lignes et son design avant-gardiste. Sa polyvalence fait de QUBICA un produit exclusif. Disponible en 3 couleurs pouvant être combinées pour atteindre 9 configurations différentes.<br /></p>" +
        "<p>La toile représente une caractéristique personnalisable supplémentaire : Un choix entre plus de 500 types est possible.<br /></p>" +
        "<p>Un système breveté permet au tissu d'être enroulé sans déformations ni plis. Des bras à leds AKI sont proposés en option.<br /></p>",
        images: [],
        buttons: [
          {
            text: getLanguageTranslation("Depliant Tende"),
            href: QubicaLiteDepliant20x25,
          },
          {
            text: getLanguageTranslation("Scheda Tecnica"),
            href: QubicaLiteSchedaTecnica,
          },
        ],
      },
      undefined,
      "<p><strong>QUBICA LITE</strong><br />Qubica Lite se caractérise par la pureté des lignes et son design avant-gardiste. Sa polyvalence fait de QUBICA un produit exclusif. Disponible en 3 couleurs pouvant être combinées pour atteindre 9 configurations différentes.<br />" +
      "La toile représente une caractéristique personnalisable supplémentaire : Un choix entre plus de 500 types est possible.</p>"
    ),
  createPositionPoint(
    [-3308.07, -1410.82, 3461.17],
    "entrance",
    getLanguageTranslation("ENTRATA")
  ),
  createPositionPoint(
    [-4703.97, -1429.4, -864.86],
    "kedry-skylife-sotto",
    getLanguageTranslation("KEDRY SKYLIFE (Sotto)")
  ),
  createOptionalPoint(
    [3926.84, 293.61, -3066.64],
    getLanguageTranslation("Copertura"),
    "roof",
    undefined,
    technicalSpecifics.roof === "close" ? "open" : "close",
    (roof: "close" | "open") => roof === "open"
  ),
  createPositionPoint([679.26, -941.21, -4853.58], "qubica_to_kolibrie", ""),
  // createVrPoint([3018.55, -1082.46, -3824.75], VeneziaGLB, VeneziaUSDZ),
];
