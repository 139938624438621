import { Colors } from "../icons";

export type Color = {
  technicalValue: string; // Quello che andrá a mettersi sulle technicalSpecifics
  value: string; // Il valore che prenderá il bottone sul popup
  text: string; // Il testo sotto al bottone del popup
  image?: any;
};

export const useCreateColorPoint: (
  setPopupInfos: any,
  lang: string
) => (
  position: number[],
  colors: Color[],
  title?: string,
  icon?: any,
  show?: boolean
) => Record<string, any> = (setPopupInfos) => (
  position,
  colors,
  title = "COLORS",
  icon,
  show = true
) =>
  show
    ? {
        position,
        text: title,
        image: icon !== undefined ? icon : Colors,
        type: "colors",
        description: null,
        clickListener: () => {
          setPopupInfos({
            title: "",
            noCloseIcon: true,
            type: "colors",
            popupClass: "popup-colors_container",
            colors,
          });
        },
      }
    : null;
